// @devloper:Jinay Patel
// @created:26th april 2023
// @desc:In this page a form of apllicants
import React, { useState, useEffect } from "react";
import axios from "axios";
import { Autocomplete, CircularProgress, TextField } from "@mui/material";
import Config from "../../config";
import AlertBox from "../../Alert";
import HeaderNavbarHOC from "../../HOC/HeaderNavbar";
import { trackEvent, EVENT_NAME } from "../../constant/MixpanelConstant";
import Useuser from "../../Contexct";
import * as AxiosActions from "../../Actions/axiosActions";
import { Alert_message , Alert_title } from "../../Alert/Alertmessage";
import BackgroundImage from "../../components/Common/CardImage";

function ApplicantsAdd() {
  const { adminData } = Useuser();
  const openAlert = false;
  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      setInputList(initialvalue);
      setFilterJobOpening("Select Job");
      setSource("Select Source");
      setFilterStatus("Applied");
      setGender("Male");
      return;
    }
  };
  const [filterJobOpening, setFilterJobOpening] = useState("Select Job");
  const [source, setSource] = useState("Select Source");
  const [jobOpening, setJobOpening] = useState([]);
  const [jobId, setJobId] = useState("");
  const [filterStatus, setFilterStatus] = useState("Applied");
  const [gender, setGender] = useState("Male");
  const inputArr = [];
  const [isDisable, setIsDisable] = useState(false);
  const [errMsg, setErrMsg] = useState({
    source: "",
  });
  const sourceOptions = [
    "LinkedIn",
    "Email",
    "Naukri",
    "Ads",
    "WhatsApp",
    "Website",
    "Others",
  ];
  const newArray = [];
  const token = localStorage.getItem("access_token");

  useEffect(() => {
    AxiosActions.JobList({}).then((data) => {
      setJobOpening(data.data.data);
    });
  }, []);
  const handleFilterJobOpeningChange = (event) => {
    setFilterJobOpening(event.target.textContent);
    jobOpening.forEach((item) => {
      if (item.job_title === event.target.textContent) {
        const tempjobId = item._id;
        setJobId(tempjobId);
      }
      return [];
    });
  };

  const initialvalue = {
    source: "",
    name: "",
    email: "",
    phone: "",
    qualification: "",
    experience: "",
    currentLocation: "",
    city: "",
    currentCTC: "",
    expectedCTC: "",
    offeredCTC: "",
    remarks: "",
    state: "",
  };
  const [fileName, setFileName] = useState("");
  const [resume, setResume] = useState("");
  const [inputList, setInputList] = useState(initialvalue);
  const changeHandle = (event) => {
    setInputList({
      ...inputList,
      [event.target.name]: event.target.value,
    });
  };
  const handleGenderChange = (event) => {
    setGender(event.target.value);
  };

  const handleFileInput = (e) => {
    const fileInput = document.getElementById("customFile");
    if (!fileInput) {
      console.log("File input element not found");
      return;
    }

    const res_field = fileInput?.value;
    const extension = res_field.substring(res_field.lastIndexOf(".") + 1).toLowerCase();
    const allowedExtensions = ["jpg", "png", "jpeg", "pdf"];
  
    if (res_field.length > 0) {
      if (allowedExtensions.indexOf(extension) !== -1) {
        const data = e.target.files[0];
  
        const timestamp = new Date().getTime();
        const fileName = timestamp + "_" + data.name;
  
        const formData = new FormData();
  
        formData.append("file", data);
        formData.append("name", fileName);
  
        axios
          .post(`${Config.APIURL}user/file/upload`, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            if(response.data.code === 200) {
              setFileName(fileName);
              setResume(response.data.data);
            }
          })
          .catch((error) => {
            console.log("file upload getting response error", error);
          });
      } else {
        document.getElementById('customFile').value = '';
      }
    }
  };
  

  const submit = () => {
    trackEvent(EVENT_NAME.APPLICANT_USER_ADD, { data: inputList.name });
    newArray.push(...inputArr, {
      source: source,
      name: inputList.name,
      email: inputList.email,
      phone: inputList.phone,
      qualification: inputList.qualification,
      experience: inputList.experience,
      currentLocation: inputList.currentLocation,
      city: inputList.city,
      currentCTC: inputList.currentCTC,
      expectedCTC: inputList.expectedCTC,
      offeredCTC: inputList.offeredCTC,
      remarks: inputList.remarks,
      resume: resume,
      fileName: fileName,
      gender: gender,
      job: jobId,
      status: filterStatus,
      state: inputList.state,
      adminId: adminData._id,
    });

    let errObject = {
      source: "",
    };
    let err = 0;

    if (source === "Select Source") {
      err++;
      errObject.source = "Please Enter Valid Source";
    }

    if (err !== 0) {
      console.log("has error", err, errObject);
      setErrMsg(errObject);
    } else {
      setIsDisable(true);
      AxiosActions.ApplicantsAdd({
        data: newArray,
        token: token,
      })
        .then((data) => {
          if(data.code === 200) {
            setIsDisable(false);
            setInputList(initialvalue);
            setFilterJobOpening("Select Job");
            setSource("Select Source");
            setFilterStatus("Applied");
            setGender("Male");
            setFileName("");
            document.getElementById('customFile').value = '';
          }
        })
        .catch((err) => {
          console.log(err);
          setIsDisable(false);
        });
      setErrMsg("");
    }
  };

  return (
    <>
      {token === null ? (
        (window.location.href = "/")
      ) : (
        <>
          <AlertBox
            open={openAlert}
            onClose={handleCloseAlert}
            message={Alert_message.Applicant_Add}
            title={Alert_title.Applicant_Add}
            ok={false}
          />
          <main id="content" role="main" className="main">
            <BackgroundImage/>
            <div
              className="content container-fluid"
              style={{ justifyItems: "center", maxWidth: "60rem" }}
            >
              <div className="page-header">
                <div className="row align-items-end">
                  <div className="col-sm mb-2 mb-sm-0">
                    <h1 className="page-header-title">Applicant Details</h1>
                  </div>
                </div>
              </div>

              <div className="card w-70">
                <div className="card-header">
                  <h1 className="card-title h1">Basic informations</h1>
                </div>
                <div className="card-body">
                  <div className="row mb-4">
                    <label
                      htmlFor="source"
                      className="col-sm-3 col-form-label form-label"
                      style={{ fontSize: 17 }}
                    >
                      Source
                    </label>
                    <div className="col-sm-9">
                      <Autocomplete
                      name="template"
                      options={sourceOptions}
                      getOptionLabel={(option) => option || ""}
                      value={source || "Select Source"}
                      onChange={
                        (newValue) => {
                          setSource(newValue.target.textContent);
                        }
                      }
                      size="small"
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          autoComplete="off"
                        />
                      )}                      
                      />
                      {errMsg.source !== "" ? (
                        <label
                          className=" form-label"
                          style={{ fontSize: 15, color: "red" }}
                        >
                          {errMsg.source}
                        </label>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>

                  <div className="row mb-4">
                    <label
                      htmlFor="name"
                      className="col-sm-3 col-form-label form-label"
                      style={{ fontSize: 17 }}
                    >
                      Name
                    </label>
                    <div className="col-sm-9">
                      <div className="input-group input-group-sm-vertical">
                        <input
                          type="text"
                          className="form-control"
                          name="name"
                          value={inputList.name}
                          onChange={(e) => changeHandle(e)}
                          id="firstNameLabel"
                          placeholder="Name"
                          aria-label="Your first name"
                        />
                      </div>
                      {errMsg.name !== "" ? (
                        <label
                          className=" form-label"
                          style={{ fontSize: 15, color: "red" }}
                        >
                          {errMsg.name}
                        </label>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>

                  <div className="row mb-4">
                    <label
                      htmlFor="emailLabel"
                      className="col-sm-3 col-form-label form-label"
                      style={{ fontSize: 17 }}
                    >
                      Email
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="email"
                        className="form-control"
                        name="email"
                        value={inputList.email}
                        onChange={(e) => changeHandle(e)}
                        id="emailLabel"
                        placeholder="Email"
                        aria-label="Email"
                      />
                      {errMsg.email !== "" ? (
                        <label
                          className=" form-label"
                          style={{ fontSize: 15, color: "red" }}
                        >
                          {errMsg.email}
                        </label>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>

                  <div className="row mb-4">
                    <label
                      htmlFor="phone"
                      className="col-sm-3 col-form-label form-label"
                      style={{ fontSize: 18 }}
                    >
                      Phone
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        className="js-input-mask form-control"
                        name="phone"
                        value={inputList.phone}
                        onChange={(e) => changeHandle(e)}
                        id="phoneLabel"
                        placeholder="+xx(xxx)xxx-xx-xx"
                        aria-label="+xx(xxx)xxx-xx-xx"
                      />
                      {errMsg.phone !== "" ? (
                        <label
                          className=" form-label"
                          style={{ fontSize: 15, color: "red" }}
                        >
                          {errMsg.phone}
                        </label>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>

                  <div id="accountType" className="row mb-4">
                    <label
                      htmlFor="gender"
                      className="col-sm-3 col-form-label form-label"
                      style={{ fontSize: 18 }}
                    >
                      Gender
                    </label>
                    <div className="col-sm-9">
                      <div className="input-group input-group-sm-vertical">
                        <div className="form-control">
                          <span className="form-check">
                            <input
                              type="radio"
                              className="form-check-input"
                              name="userAccountTypeRadio"
                              id="genderMale"
                              value="Male"
                              onChange={handleGenderChange}
                              defaultChecked
                            />
                            <span className="form-check-label">Male</span>
                          </span>
                        </div>

                        <div className="form-control">
                          <span className="form-check">
                            <input
                              type="radio"
                              className="form-check-input"
                              name="userAccountTypeRadio"
                              id="genderFemale"
                              onChange={handleGenderChange}
                              value="Female"
                            />
                            <span className="form-check-label">Female</span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row mb-4">
                    <label
                      htmlFor="location"
                      className="col-sm-3 col-form-label form-label"
                      style={{ fontSize: 18 }}
                    >
                      Location
                    </label>
                    <div className="col-sm-9">
                      <div className="mb-3">
                        <input
                          type="text"
                          className="form-control"
                          name="currentLocation"
                          value={inputList.currentLocation}
                          onChange={(e) => changeHandle(e)}
                          id="CurrentLocation"
                          placeholder="Current Location"
                          aria-label="Current Location"
                        />
                        {errMsg.currentLocation !== "" ? (
                          <label
                            className=" form-label"
                            style={{ fontSize: 15, color: "red" }}
                          >
                            {errMsg.currentLocation}
                          </label>
                        ) : (
                          ""
                        )}
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        name="city"
                        value={inputList.city}
                        onChange={(e) => changeHandle(e)}
                        id="City"
                        placeholder="City"
                        aria-label="City"
                      />
                      {errMsg.city !== "" ? (
                        <label
                          className=" form-label"
                          style={{ fontSize: 15, color: "red" }}
                        >
                          {errMsg.city}
                        </label>
                      ) : (
                        ""
                      )}
                      <br></br>
                      <input
                        type="text"
                        className="form-control"
                        name="state"
                        value={inputList.state}
                        onChange={(e) => changeHandle(e)}
                        id="state"
                        placeholder="State"
                        aria-label="State"
                      />
                      {errMsg.state !== "" ? (
                        <label
                          className=" form-label"
                          style={{ fontSize: 15, color: "red" }}
                        >
                          {errMsg.state}
                        </label>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div></div>
              <br></br>
              <br></br>
              <div className="card w-70">
                <div className="card-header">
                  <h2 className="card-title h2">Job information</h2>
                </div>
                <div className="card-body">
                  <div className="row mb-4">
                    <label
                      htmlFor="qualification"
                      className="col-sm-3 col-form-label form-label"
                      style={{ fontSize: 17 }}
                    >
                      Qualification
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        className="form-control"
                        name="qualification"
                        value={inputList.qualification}
                        onChange={(e) => changeHandle(e)}
                        id="Qualification"
                        placeholder="Qualification"
                        aria-label="Qualification"
                      />
                      {errMsg.qualification !== "" ? (
                        <label
                          className=" form-label"
                          style={{ fontSize: 15, color: "red" }}
                        >
                          {errMsg.qualification}
                        </label>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>

                  <div className="row mb-4">
                    <label
                      htmlFor="experience"
                      className="col-sm-3 col-form-label form-label"
                      style={{ fontSize: 17 }}
                    >
                      Experience
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        className="form-control"
                        name="experience"
                        value={inputList.experience}
                        onChange={(e) => changeHandle(e)}
                        id="Experience"
                        placeholder="Experience"
                        aria-label="Experience"
                      />
                      {errMsg.experience !== "" ? (
                        <label
                          className=" form-label"
                          style={{ fontSize: 15, color: "red" }}
                        >
                          {errMsg.experience}
                        </label>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>

                  <div className="row mb-4">
                    <label
                      htmlFor="job"
                      className="col-sm-3 col-form-label form-label"
                      style={{ fontSize: 17 }}
                    >
                      Job
                    </label>
                    <div className="col-sm-9">
                      
                      <Autocomplete
                        options={jobOpening || []}
                        getOptionLabel={(option) => option.job_title}
                        onChange={handleFilterJobOpeningChange}
                        size="small"
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Select Job"
                            variant="outlined"
                            autoComplete="off"
                          />
                        )}
                      />
                      {errMsg.job !== "" ? (
                        <label
                          className=" form-label"
                          style={{ fontSize: 15, color: "red" }}
                        >
                          {errMsg.job}
                        </label>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="row mb-4">
                    <label
                      htmlFor="currentCTC"
                      className="col-sm-3 col-form-label form-label"
                      style={{ fontSize: 17 }}
                    >
                      Current CTC
                    </label>
                    <div className="col-sm-9">
                      <div className="input-group mb-3">
                        <input
                          type="number"
                          className="form-control"
                          name="currentCTC"
                          value={inputList.currentCTC}
                          onChange={(e) => changeHandle(e)}
                          id="CurrentCTC"
                          placeholder="Current CTC"
                          aria-label="Current CTC"
                        />
                        <span className="input-group-text" id="basic-addon2">
                          Per Month
                        </span>
                      </div>
                      {errMsg.currentCTC !== "" ? (
                        <label
                          className=" form-label"
                          style={{ fontSize: 15, color: "red" }}
                        >
                          {errMsg.currentCTC}
                        </label>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>

                  <div className="row mb-4">
                    <label
                      htmlFor="expectedCTC"
                      className="col-sm-3 col-form-label form-label"
                      style={{ fontSize: 17 }}
                    >
                      Expected CTC
                    </label>
                    <div className="col-sm-9">
                      <div className="input-group mb-3">
                        <input
                          type="number"
                          className="form-control"
                          name="expectedCTC"
                          value={inputList.expectedCTC}
                          onChange={(e) => changeHandle(e)}
                          id="ExpectedCTC"
                          placeholder="Expected CTC"
                          aria-label="Expected CTC"
                        />
                        <span className="input-group-text" id="basic-addon2">
                          Per Month
                        </span>
                      </div>
                      {errMsg.expectedCTC !== "" ? (
                        <label
                          className=" form-label"
                          style={{ fontSize: 15, color: "red" }}
                        >
                          {errMsg.expectedCTC}
                        </label>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>

                  <div className="row mb-4">
                    <label
                      htmlFor="offeredCTC"
                      className="col-sm-3 col-form-label form-label"
                      style={{ fontSize: 17 }}
                    >
                      Offered CTC
                    </label>
                    <div className="col-sm-9">
                      <div className="input-group mb-3">
                        <input
                          type="text"
                          className="form-control"
                          name="offeredCTC"
                          value={inputList.offeredCTC}
                          onChange={(e) => changeHandle(e)}
                          id="Offered CTC"
                          placeholder="Offered CTC"
                          aria-label="Offered CTC"
                        />
                        <span className="input-group-text" id="basic-addon2">
                          Per Month
                        </span>
                      </div>
                      {errMsg.offeredCTC !== "" ? (
                        <label
                          className=" form-label"
                          style={{ fontSize: 15, color: "red" }}
                        >
                          {errMsg.offeredCTC}
                        </label>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>

                  <div className="row mb-4">
                    <label
                      htmlFor="customFile"
                      className="col-sm-3 col-form-label form-label"
                      style={{ fontSize: 17 }}
                    >
                      Resume Link
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="file"
                        className="form-control"
                        onChange={(e) => handleFileInput(e)}
                        id="customFile"
                        placeholder="Resume Link"
                        aria-label="resume Link"
                      />
                    </div>
                  </div>

                  <div className="row mb-4">
                    <label
                      htmlFor="remarks"
                      className="col-sm-3 col-form-label form-label"
                      style={{ fontSize: 17 }}
                    >
                      Remarks
                    </label>
                    <div className="col-sm-9">
                      <textarea
                        className="form-control"
                        placeholder="Remarks"
                        id="validationValidTextarea1"
                        rows="3"
                        name="remarks"
                        value={inputList.remarks}
                        onChange={(e) => changeHandle(e)}
                      ></textarea>
                    </div>
                  </div>
                  <div className="row mb-4">
                    <label
                      htmlFor="status"
                      className="col-sm-3 col-form-label form-label"
                      style={{ fontSize: 17 }}
                    >
                      Status
                    </label>
                    <div className="col-sm-9">                     
                      <Autocomplete
                        options={Config.STATUS_LIST || []}
                        getOptionLabel={(option) => option.value || ""}
                        value={
                          Config.STATUS_LIST.find( (item) => item.value === filterStatus) || "select Status"
                        }
                        onChange={
                          (newValue) => {
                            setFilterStatus(newValue.target.textContent);
                          }
                        }
                        size="small"
                        renderInput={(params) => (
                          <TextField
                          {...params}
                          variant="outlined"
                          autoComplete="off"
                          />
                        )}
                        />
                    </div>
                  </div>
                  <div className="d-flex justify-content-end">
                    <button
                      disabled={isDisable}
                      type="button"
                      className="btn btn-warning d-flex justify-content-end align-items-center"
                      onClick={submit}
                    >
                      Save changes {isDisable && (<CircularProgress style={{ marginLeft: 2, color: 'black', width: '20px', height: '20px' }} />)}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="footer">
              <div className="row justify-content-between align-items-center">
                <div className="col"></div>
                <div className="col-auto">
                  <div className="d-flex justify-content-end"></div>
                </div>
              </div>
            </div>
          </main>
        </>
      )}
    </>
  );
}

export default HeaderNavbarHOC(ApplicantsAdd);
