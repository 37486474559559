export const leaves = {
    ADD_LEAVE_INFO : 'Add leave information',
    EDIT_LEAVES : 'Edit leaves',
    VIEW_LEAVE : 'View leaves',
    EMPLOYEE_NAME : 'Employee Name',
    SELECT_EMPLOYEE_NAME : 'Select employee name',
    LEAVE_TYPE : 'Leave type',
    SELECT_LEAVE_TYPE : 'Select leave type',
    FROM_DATE : 'From date',
    TO_DATE : 'To date',
    REASON : 'Reason',
    STATUS : 'Status',
    SELECT_STATUS : 'Select status',
    APPROVER : 'Approver',
    APPROVER_DATE : 'Approver date',
    NAME : ' Name',
    TYPE : 'Type',
    ACTION : 'Action',
    NO_RECORD_FOUND : 'No record found',
    LEAVES_INFO : 'Leaves information',

    //ACTION 
    SAVE_CHANGES : 'Save changes',
    CLOSE : 'Close',
}