import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import Config from "../../config";
import HeaderNavbarHOC from "../../HOC/HeaderNavbar";
import AlertBox from "../../Alert";
import { trackEvent, EVENT_NAME } from "../../constant/MixpanelConstant";
import * as AxiosActions from "../../Actions/axiosActions";
import Table from "./Table";
import Edit from "./Edit";
import View from "./View";
import Note from "./Note";
import { Pagination, AlertDelete, Filter } from "../../components/Common";
import {
  ADD,
  DATAUPDATESUCCESSFULLY,
  RESEND_EMAIL_FAILED,
  RESENDEMAILSUCCESSFULLY,
  SUCCESS,
} from "../../constant/Label";
import ResendEmail from "./ResendEmail";
import Add from "./AddNew";

function EmailList() {
  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
    setResendEmailOpenAlert(false);
  };
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [openAlert, setOpenAlert] = useState(false);
  const [resendEmailOpenAlert, setResendEmailOpenAlert] = useState(false);
  const [filterMonth, setFilterMonth] = useState("all");
  const [filterYear, setFilterYear] = useState("all");
  const [filterDate, setFilterDate] = useState("all");
  const [applicants, setApplicants] = useState([]);
  const [isFiltered, setIsFiltered] = useState(false);

  const [updateId, setUpdateId] = useState("");
  const [deleteId, setDeleteId] = useState("");
  const [fullReport, setFullReport] = useState([]);
  const [filterStatus, setFilterStatus] = useState("all");
  const [filterCountry, setFilterCountry] = useState("all");
  const [count, setCount] = useState(0);
  const [isDisable, setIsDisable] = useState(false);
  const [filterName, setFilterName] = useState("");
  const [filterEmail, setFilterEmail] = useState("");
  const [resendEmail, setResendEmail] = useState("");

  const [countryList, setCountryList] = useState([]);
  const [templateFormValue, setTemplateFormValue] = useState(
    Config.EMAIL_TYPE[2].value
  );

  const currentDate = new Date();
  const currentMonth = Config.SALARY_MONTH[currentDate.getMonth()];
  const currentYear = currentDate.getFullYear().toString();
  const [errMsg, setErrMsg] = useState({
    email: "",
    emailType: "",
    message: "",
  });
  const [selectedinfo, setSelectedInfo] = useState({
    selectedID: [],
  });
  const [controller, setController] = useState({
    page: 0,
    rowsPerPage: 10,
    preventRefresh: false,
  });

  const [inputValue, setInputValue] = useState({
    name: "",
    email: "",
    emailType: "",
    status: "",
    positions: "",
    companyName: "",
    isDeleted: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputValue({ ...inputValue, [name]: value });
  };

  const getCountryList = () => {
    AxiosActions.CountryList()
      .then((response) => {
        console.log("get country list", response);
        if (response.status == 200) {
          setCountryList(response.data.data);
        }
      })
      .catch((error) => {
        console.log("Error in get country list", error);
      });
  };

  // FOR SELECT ALL

  const isAllSelected =
    applicants?.length > 0 &&
    selectedinfo.selectedID.length === applicants?.length;
  const token = localStorage.getItem("access_token");

  // RENDER DATA

  const renderdata = async () => {
    try {
      setLoading(true);
      AxiosActions.EmailMarketingList({
        page: controller.page,
        limit: controller.rowsPerPage,
        year: Number(filterYear) ? Number(filterYear) : filterYear,
        type: filterDate,
        month: filterMonth,
        status: filterStatus,
        country: filterCountry,
        startDate: startDate,
        endDate: endDate,
        name: filterName,
        email: filterEmail,
      })
        .then((data) => {
          if (data?.status === 200) {
            if (data?.data?.data?.resultData) {
              setApplicants(data?.data?.data?.resultData);
              setCount(data?.data?.data?.count);
            }
          }
          setLoading(false);
        })
        .catch((error) => {
          console.log("Catch Err in Pagination ", error);
        });
    } catch (err) {
      console.log("Catch err in render data", err);
    }
  };

  useEffect(() => {
    getCountryList();
    if (isFiltered === true) {
      if (!controller.preventRefresh) filterRecord();
    } else {
      renderdata();
    }
  }, [controller]);

  // New Pagination

  const handlePageChange = (event, newPage) => {
    setController({
      ...controller,
      page: newPage,
      preventRefresh: false,
    });
    setSelectedInfo({ selectedID: [] });
  };

  const handleChangeRowsPerPage1 = (event) => {
    setController({
      ...controller,
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0,
      preventRefresh: false,
    });

    setSelectedInfo({ selectedID: [] });
  };

  // Filter record

  const filterRecord = async () => {
    trackEvent(EVENT_NAME.EMAIL_MARKETING_LIST_FILTER, { data: "" });
    setIsFiltered(true);
    if (!isFiltered) {
      let data = {
        page: 0,
        rowsPerPage: 10,
        preventRefresh: false,
      };
      setController(data);
    }
    renderdata();
    setSelectedInfo({ selectedID: [] });
  };

  // Update status
  const changeStatus = (statusid, title) => {
    let errObject = {
      email: "",
      emailType: "",
    };
    let err = 0;
    if (inputValue?.email === "") {
      err++;
      errObject.email = "Enter Email";
    }
    if (inputValue?.emailType === "") {
      err++;
      errObject.emailType = "Select Email Type";
    }
    if (err !== 0) {
      setErrMsg(errObject);
    } else {
      setErrMsg({
        email: "",
        emailType: "",
      });

      setIsDisable(true);
      trackEvent(EVENT_NAME.EMAIL_MARKETING_EDIT, { data: statusid });
      AxiosActions.EditEmailMarketing({
        _id: statusid,
        name: inputValue?.name,
        email: inputValue?.email,
        emailType: inputValue?.emailType,
        status: inputValue?.status,
        positions: inputValue?.positions,
        companyName: inputValue?.companyName,
        month: currentMonth,
        year: Number(currentYear),
      })
        .then((data) => {
          if (data.status === 200) {
            setOpenAlert(true);
            renderdata();
            setIsDisable(false);
          }
        })
        .catch((err) => {
          console.log(err);
          setIsDisable(false);
        });

      setSelectedInfo({ selectedID: [] });
    }
  };

  //Reset Filter

  const reset = () => {
    trackEvent(EVENT_NAME.EMAIL_MARKETING_RESET, { data: "" });
    setFilterStatus("all");
    setFilterCountry("all");
    setController({
      ...controller,
      page: 0,
      rowsPerPage: 10,
      preventRefresh: false,
    });
    setSelectedInfo({ selectedID: [] });
    setIsFiltered(false);
    setFilterDate("all");
    setStartDate("");
    setEndDate("");
    setFilterName("");
    setFilterEmail("");
    setFilterMonth("all");
    setFilterYear("all");
  };

  const handleDeleteClick = (event, id) => {
    setDeleteId(id);
    event.preventDefault();
  };

  // Delete Record

  const deleteRecord = (event, id) => {
    setIsDisable(true);
    trackEvent(EVENT_NAME.EMAIL_MARKETING_DELETE, { data: id });
    AxiosActions.DeleteEmailMarketing({
      _ids: id,
    })
      .then((data) => {
        if (data.status === 200) {
          renderdata();
          setIsDisable(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsDisable(false);
      });

    event.preventDefault();
  };

  // Adds notes to the email marketing reference. This is called when the user enters a note
  const addNotes = (event) => {
    trackEvent(EVENT_NAME.EMAIL_MARKETING_NOTE, {});
    console.log("inputValue: ", inputValue);
    if (inputValue?.message === "") {
      setErrMsg({
        message: "Enter Email Notes",
      });
    } else {
      setErrMsg({
        message: "",
      });

      setIsDisable(true);
      AxiosActions.EmailMarketingAddNote({
        marketingReference: fullReport[0]._id,
        template: "", //fullReport[0].emailType
        message: inputValue.message,
        noteType: inputValue.status,
      })
        .then((data) => {
          if (data.status === 200) {
            renderdata();
            setIsDisable(false);
          }
        })
        .catch((err) => {
          console.log(err);
          setIsDisable(false);
        });
    }
    event.preventDefault();
  };

  //View Full Report

  const handleViewReportClick = (event, id) => {
    trackEvent(EVENT_NAME.EMAIL_MARKETING_VIEW, { data: id });
    const filterRecord = applicants?.filter((item) => {
      if (id === item._id) {
        const data = {
          name: item?.name,
          email: item?.email,
          emailType: item?.emailType,
          status: item?.status,
          positions: item?.positions,
          companyName: item?.companyName,
          isDeleted: item?.isDeleted,
          message: "",
        };
        setInputValue(data);
        return true;
      }
    });

    setFullReport(filterRecord);
    setUpdateId(id);
  };
  const handleResendEmail = (event, app) => {
    setResendEmail(app);
    event.preventDefault();
  };

  // Resend Email
  const handleResendEmailClick = (app) => {
    trackEvent(EVENT_NAME.EMAIL_MARKETING_RESEND_EMAIL, { data: app._id });
    AxiosActions.ResendEmailMarketing({
      name: app.name,
      email: app.email,
      emailType: app.emailType,
      _id: app._id,
    })
      .then((data) => {
        if (data.status === 200) {
          renderdata();
          setResendEmailOpenAlert(true);
        } else {
          renderdata();
          toast.dismiss();
          toast.error(RESEND_EMAIL_FAILED);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //Select Multiple

  const handleCheckBoxChange = (e) => {
    const value = e.target.value;
    const { checked } = e.target;
    const list = [];
    const { selectedID } = selectedinfo;

    if (value === "all") {
      if (value === "all" && checked) {
        applicants?.map((item) => {
          list.push(item._id);
        });
        setSelectedInfo({
          selectedID: list,
        });
      } else {
        setSelectedInfo({
          selectedID: list.filter((e) => e !== value),
        });
      }
      return;
    } else if (checked) {
      setSelectedInfo({
        selectedID: [...selectedID, value],
      });
    } else {
      setSelectedInfo({
        selectedID: selectedID.filter((e) => e !== value),
      });
    }
  };

  //Delete Multiple

  const handleDeleteMultipleClick = (event) => {
    event.preventDefault();
  };
  const deleteAllRecord = (event) => {
    setIsDisable(true);
    trackEvent(EVENT_NAME.EMAIL_MARKETING_DELETE_ALL, { data: "" });
    AxiosActions.DeleteEmailMarketing({
      _ids: selectedinfo.selectedID,
    })
      .then((data) => {
        if (data?.data?.code === 200) {
          renderdata();
          setIsDisable(false);
        }
      })
      .catch((err) => {
        alert("Please Delete Record Again");
        setIsDisable(false);
      });

    setSelectedInfo({ selectedID: [] });
    event.preventDefault();
  };

  /**
   * @desc Handles the multi-resend of email marketing campaigns.
   * It prevents the default form submission, tracks the event,
   * and sends a request to resend emails using the selected IDs.
   * On success, it updates the rendered data; on error, it alerts
   * the user and resets the button state. Finally, it clears
   * the selected IDs.
   * @return {void}
   */
  const handleMultiResend = (event) => {
    event.preventDefault();

    setIsDisable(true);
    AxiosActions.ResendMultiEmailMarketing({
      ids: selectedinfo.selectedID,
      templateType: templateFormValue,
    })
      .then((data) => {
        if (data?.data?.code === 200) {
          const successCount = data?.data?.data?.successCount || 0;
          const failureCount = data?.data?.data?.failureCount || 0;

          renderdata();
          setIsDisable(false);
          toast.dismiss(); // Dismiss any existing toasts
          toast.success(
            <>
              Success: {successCount} <br />
              Failure: {failureCount}
            </>,
            {
              autoClose: 5000, // Auto close after 5 seconds
            }
          );
        } else {
          setIsDisable(false);
        }
      })
      .catch((err) => {
        alert("Multi Resend Email Marketing Error");
        setIsDisable(false);
      });

    setSelectedInfo({ selectedID: [] });
  };

  return (
    <>
      <AlertBox
        open={openAlert}
        onClose={handleCloseAlert}
        message={DATAUPDATESUCCESSFULLY}
        title={SUCCESS}
        ok={false}
      />
      {resendEmailOpenAlert && (
        <AlertBox
          open={resendEmailOpenAlert}
          onClose={handleCloseAlert}
          message={RESENDEMAILSUCCESSFULLY}
          title={SUCCESS}
          ok={false}
        />
      )}
      {token === null ? (
        true((window.location.href = "/"))
      ) : (
        <main id="content" role="main" className="main">
          <div className="content container-fluid">
            <div className="card mb-3 mb-lg-5">
              <div className="card-header">
                <div className="container-fluid d-flex justify-content-end align-items-center mb-3">
                  <button
                    type="button"
                    className="icon icon-success ps-5 pe-5"
                    title={ADD}
                    data-bs-toggle="modal"
                    data-bs-target="#addExampleModal"
                    onClick={(event) => handleViewReportClick(event)}
                  >
                    {ADD}
                    <i className="bi bi-plus"></i>
                  </button>
                </div>
                <Filter
                  year={true}
                  yearValue={filterYear}
                  yearUpdate={setFilterYear}
                  month={true}
                  monthValue={filterMonth}
                  monthUpdate={setFilterMonth}
                  status={true}
                  country={true}
                  statusValue={filterStatus}
                  countryValue={filterCountry}
                  statusUpdate={setFilterStatus}
                  countryUpdate={setFilterCountry}
                  statusList={Config?.EMAIL_STATUS}
                  countryList={countryList}
                  date={true}
                  dateValue={filterDate}
                  dateUpdate={setFilterDate}
                  startDateValue={startDate}
                  startDateUpdate={setStartDate}
                  endDateValue={endDate}
                  endDateUpdate={setEndDate}
                  searchName={true}
                  searchNameValue={filterName}
                  searchNameUpdate={setFilterName}
                  searchEmail={true}
                  searchEmailValue={filterEmail}
                  searchEmailUpdate={setFilterEmail}
                  multipleSelect={selectedinfo?.selectedID?.length}
                  multipleDelete={true}
                  multipleResend={true}
                  isDisable={isDisable}
                  handleDelete={handleDeleteMultipleClick}
                  handleMultiResend={handleMultiResend}
                  setTemplateFormValue={setTemplateFormValue}
                  templateFormValue={templateFormValue}
                  deleteAllRecord={deleteAllRecord}
                  filterRecord={filterRecord}
                  resetRecord={reset}
                />
              </div>
              <div className="table-responsive datatable-custom">
                <Table
                  handleCheckBoxChange={handleCheckBoxChange}
                  isAllSelected={isAllSelected}
                  loading={loading}
                  applicants={applicants}
                  selectedinfo={selectedinfo}
                  handleViewReportClick={handleViewReportClick}
                  handleDeleteClick={handleDeleteClick}
                  handleResendEmail={handleResendEmail}
                  countryList={countryList}
                />
              </div>
              <Add
                countryList={countryList}
                setCountryList={setCountryList}
                renderdata={() => {
                  renderdata();
                }}
              />
              <Edit
                handleChange={handleChange}
                inputValue={inputValue}
                isDisable={isDisable}
                changeStatus={changeStatus}
                updateId={updateId}
                errMsg={errMsg}
              />
              <ResendEmail
                isDisable={isDisable}
                handleResendEmailClick={handleResendEmailClick}
                resendEmail={resendEmail}
              />

              <AlertDelete
                isDisable={isDisable}
                deleteRecord={deleteRecord}
                deleteId={deleteId}
              />

              <View fullReport={fullReport} />
              <Note
                fullReport={fullReport}
                handleChange={handleChange}
                inputValue={inputValue}
                isDisable={isDisable}
                addNotes={addNotes}
                errMsg={errMsg}
              />

              <div className="card-footer">
                <Pagination
                  handlePageChange={handlePageChange}
                  controller={controller}
                  count={count}
                  handleChangeRowsPerPage1={handleChangeRowsPerPage1}
                />
              </div>
            </div>
          </div>
          <div className="footer"></div>
        </main>
      )}
    </>
  );
}

export default HeaderNavbarHOC(EmailList);
