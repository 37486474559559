import axios from "axios";
import API_URL from "../config/index";

console.log("API_URL", API_URL.APIURL);
export const httpBase = () => {
  const api = axios.create({
    baseURL: `${API_URL.APIURL}`,
    responseType: "json",
  });

  api.interceptors.request.use((request) => {
    return request;
  });

  api.interceptors.response.use(
    (response) => {
      return response;
    },
    (error, stats) => {
      if (error && error.response) {
        return Promise.reject(error.response.data);
      } else {
        return Promise.reject(error);
      }
    }
  );

  return api;
};
