import React, { useState, useEffect, useContext } from "react";
import HeaderNavbarHOC from "../../HOC/HeaderNavbar";
import Config from "../../config";
import FullCalendar from "@fullcalendar/react"; // must go before plugins
import dayGridPlugin from "@fullcalendar/daygrid";
import Axios from "axios";
import { AuthContext } from "../../Login/Protected";
import * as AxiosActions from "../../Actions/axiosActions";
import { reminder } from "../../constant/reminder";
//import { DATAUPDATESUCCESSFULLY } from "../../constant/Label";

function ReminderSheet() {
  const adminData = useContext(AuthContext);
  const [modalOpen, setModalOpen] = useState(true);
  const [   data, setdata] = useState();
  const [getdata, setgetdata] = useState([]);
  const [empid, setempid] = useState("");
  const [currentMonth, setCurrentMonth] = useState("");
  const [currentYear, setCurrentYear] = useState("");
  const handleDatesSet = async (arg) => {
    const start = arg.view.currentStart;
    const month = start.getMonth();
    const year = start.getFullYear();
  
    if (month !== currentMonth || year !== currentYear) {
      setCurrentMonth(month);
      setCurrentYear(year);
      console.log('hy')
      await geTdata();
    }
  };
  console.log("currentMonth",currentMonth)
  const geTdata = async () => {
    try {
      const response = await Axios.post(Config.LOCALURL +"remainder/month/count", {
        month: currentMonth,
        year: currentYear,
        empid: adminData.adminData.isAdmin ? empid : adminData.adminData.empid && adminData.adminData.empid._id,
      });
  
      if (response.status === 200) {
        setdata(response.data.remainderData)
        
      }
    } catch (error) {
      console.log("Error in getdata", error);
      // Handle the error if the API request fails
    }
  };

  const getEmployeeList = () => {
    AxiosActions.EmployeeList({})
      .then((response) => {
        console.log("getEmployeeList", response);
        if (response.status == 200) {
          setgetdata(response.data.data);
        }
      })
      .catch((error) => {
        console.log("Error in get employee list", error);
      });
  }; 
  useEffect(() => {
    geTdata();
    getEmployeeList()
  }, [adminData.adminData]);
  const formattedEvents =
    data &&
    data.map((event) => ({
      title:event.remainderName,
      start: event.date,
      end: event.date,
      id:event._id
    }));
    console.log(formattedEvents)
    const handleChange = async (event) => {
     
      try {
        Axios.post(Config.LOCALURL + "remainder/month/count", {
          month: currentMonth + 1,
          year: currentYear,
          empid: adminData.adminData.isAdmin ? empid : adminData.adminData.empid && adminData.adminData.empid._id,
        })
          .then((response) => {
            console.log("getdata", response.data.attendanceData);
            if (response.status == 200) {
                console.log(response.data.remainderData)
      setdata(response.data.remainderData)
            }
          })
          .catch((error) => {
            console.log("Error in getdata", error);
          });
      } catch (error) {
        console.error(error);
        // Handle the error if the API request fails
      }
    };
  return (
    <>
      {/* <AlertBox
        open={openAlert}
        onClose={handleCloseAlert}
        message={DATAUPDATESUCCESSFULLY}
        title="Success"
        ok={false}
      /> */}

      <main id="content" role="main" className="main">
        <div className="content container-fluid">
          <>
            <div className="card">
              <div className="card-body ">
                <h1 className="card-title" style={{ marginLeft: 20 }}>
                  {reminder.REMINDER_SHEET}
                </h1>
              </div>
              <div className="col-auto" style={{marginLeft:20,marginBottom:20}}>
                  <div className="row align-items-sm-center">
                    <div className="col-sm-auto">
                      <div className="row align-items-center gx-0">
                        <div className="col">
                          <span className="text-secondary me-2">{reminder.NAME}</span>
                        </div>
                        <div className="col-auto">
                          <div className="tom-select-custom tom-select-custom-end">
                            <select
                              className="form-select"
                              autoComplete="off"
                              name="Name"
                              onChange={(event) => setempid(event.target.value)}
                              value={empid}
                            >
                             
                             <option value="" selected>
                               {reminder.SELECT_EMPLOYEE}
                              </option>
                              {getdata.map((item, index) => {
                               
                                  return (
                                    <option value={item._id}>
                                      {item.employeeName}
                                    </option>
                                  );
                               
                              })}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
              
                    <div className="col-sm-auto">
                      <div className="row align-items-center pe-0">
                        <div className="col">
                          <button
                            type="button"
                            className="btn btn-danger"
                            onClick={(event)=>{handleChange(event)}}
                          >
                            {reminder.FILTER}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              <>
                {/* Hello world */}
                <div className="card" style={{ padding: 25 }}>
                  <div className="card-body">
                    <FullCalendar
                      datesSet={handleDatesSet}
                      plugins={[dayGridPlugin]}
                      initialView="dayGridMonth"
                      events={formattedEvents}
                    />
                    {modalOpen && (
                         <div
                         className="modal fade bd-example-modal-xl"
                         id="viewpage"
                         tabIndex="-1"
                         role="dialog"
                         aria-labelledby="exampleModalCenterTitle"
                         aria-hidden="true"
                       >
                         <div
                           className="modal-dialog modal-dialog-centered"
                           role="document"
                         >
                           <div className="modal-content">
                             <div className="modal-header">
                               <h1 className="modal-title" id="exampleModalLongTitle">
                                 {reminder.VIEW_REMINDER}
                               </h1>
                               <button
                                 type="button"
                                 className="btn-close"
                                 data-bs-dismiss="modal"
                                 aria-label="Close"
                               ></button>
                             </div>
                             <div className="modal-body">
                                <h1>{reminder.HYY}</h1>
                               {/* {fullReport.map((item, index) => {
                                 return (
                                   <>
                                     <h3 style={{ color: "red" }}>
                                     Reminder information
                                     </h3>
                                     <table className="table table-align-middle">
                                       <thead>
                                         <tr>
                                           <th scope="col"></th>
                                           <th scope="col"></th>
                                         </tr>
                                       </thead>
                                       <tbody>
                                         <tr>
                                           <td>
                                             <h4>REMINDER NAME</h4>
                                             {item.remainderName}
                                           </td>
                                         </tr>
                                         <tr>
                                           <td>
                                             <h4>EMPLOYEE NAME</h4>
                                             {item.empid.map((item, index) => {
                                           return (
                                             <React.Fragment key={index}>
                                               {item.employeeName}
                                               <br />
                                             </React.Fragment>
                                           );
                                         })}
                                           </td>
                                         </tr>
                                         <tr>
                                           <td>
                                             <h4>DATE & TIME</h4>
                                             {moment.utc(item.date).format('DD-MM-YYYY hh:mm:ss a')}
                                           </td>
                                         </tr>
                                         <tr>
                                           <td>
                                             <h4>DESCRIPTION</h4>
                                             {item.description}
                                           </td>
                                         </tr>
                                         <tr>
                                           <td>
                                             <h4>Status</h4>
                                            {item.status}
                                           </td>
                                         </tr>
                                       </tbody>
                                     </table>
                                   </>
                                 );
                               })} */}
                             </div>
                             <div className="modal-footer"></div>
                           </div>
                         </div>
                       </div>
    
    )}
                  </div>
                </div>
              </>
            </div>
          </>
        </div>
        <div className="footer"></div>
      </main>
    </>
  );
}

export default HeaderNavbarHOC(ReminderSheet);
