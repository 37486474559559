import React, { useState, useEffect, useContext, useMemo } from "react";
import Config from "../../config";
import AlertBox from "../../Alert";
import HeaderNavbarHOC from "../../HOC/HeaderNavbar";
import { AuthContext } from "../../Login/Protected";
import { trackEvent, EVENT_NAME } from "../../constant/MixpanelConstant";
import * as AxiosActions from "../../Actions/axiosActions";
import Axios from "axios";
import Edit from "./Edit";
import View from "./View";
import {
  HeaderTitle,
  Pagination,
  Filter,
  BarChart,
  LineChart,
  Loader,
} from "../../components/Common";
import Table from "./Table";
import NotesStatus from "./NotesStatus";
import AddNotes from "./AddNotes";
import { productivity } from "../../constant/Productivity";
import { DATAUPDATESUCCESSFULLY } from "../../constant/Label";

function ProList() {
  const [openAlert, setOpenAlert] = useState(false);
  const adminData = useContext(AuthContext);
  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
  };
  const [notedata, setNotedata] = useState([]);
  const [addnote, setAddnote] = useState("");
  const [startdate, setStartdate] = useState("");
  const [enddate, setEnddate] = useState("");
  const [empid, setEmpid] = useState("");
  const [note, setNote] = useState("");
  const [filterhours, setFilterhours] = useState("all");
  const [taskStatus, setTaskStatus] = useState("all");
  const [getdata, setGetdata] = useState([]);
  const [applicants, setApplicants] = useState([]);
  const [isFiltered, setIsFiltered] = useState(false);
  const [updateId, setUpdateId] = useState("");
  const [deleteId, setDeleteId] = useState("");
  const [fullReport, setFullReport] = useState([]);
  const [filterStatus, setFilterStatus] = useState("all");
  const [count, setCount] = useState(0);
  const [hourstype, setHourstype] = useState();
  const [filterArr, setFilterArr] = useState([]);
  const [trackingTools, setTrackingTools] = useState([]);
  const [getclient, setGetclient] = useState([]);
  const [estimatedhours, setEstimatedhours] = useState();
  const [actualhours, setActualhours] = useState();
  const [clientName, setClientName] = useState("");
  const [taskId, setTaskId] = useState("");
  const [trackingTool, setTrackingTool] = useState("");
  const [status, setStatus] = useState("");
  const [loading, setLoading] = useState(false);
  const [addloading, setAddLoading] = useState(false);
  const [filterdate, setFilterdate] = useState("all");
  const [gettask, setGettask] = useState([]);
  const [emp, setEmp] = useState("");
  const [selectedinfo, setSelectedInfo] = useState({
    selectedID: [],
  });
  const [controller, setController] = useState({
    page: 0,
    rowsPerPage: 10,
    preventRefresh: false,
  });
  const [isDisable, setIsDisable] = useState(false);

  const [chartCountLoading, setChartCountLoading] = useState(false);
  const [chartProductivityCount, setChartProductivityCount] = useState([]);
  const [chartProductivityStatus, setChartProductivityStatus] = useState([]);
  const [monthFilter, setMonthFilter] = useState("all");
  const [yearFilter, setYearFilter] = useState("all");
  const [userIdFilter, setUserIdFilter] = useState("");
  const [userNameFilter, setUserNameFilter] = useState("");
  const [chartStatusLoading, setChartStatusLoading] = useState(false);
  const [yearStatusFilter, setYearStatusFilter] = useState("all");
  const [monthStatusFilter, setMonthStatusFilter] = useState("all");

  const isAllSelected =
    applicants.length > 0 &&
    selectedinfo.selectedID.length === applicants.length;
  const token = localStorage.getItem("access_token");

  useEffect(() => {
    if (getdata?.length > 0 && !userIdFilter) {
      setUserIdFilter(adminData.adminData.empid._id);
      setUserNameFilter(adminData.adminData.empid.employeeName);
    }
  }, [getdata]);

  // renderdata(getdata from api)
  const renderdata = async () => {
    try {
      setLoading(true);
      await AxiosActions.ProductivityList({
        page: controller.page,
        limit: controller.rowsPerPage,
        type: filterdate,
        startDate: startdate,
        endDate: enddate,
        status: taskStatus,
        empid: adminData.adminData.isAdmin
          ? empid
          : adminData.adminData.empid._id,
        typeOfHours:
          filterhours === "all"
            ? "all"
            : "" || filterhours === "true"
            ? true
            : "" || filterhours === "false"
            ? false
            : "",
      })
        .then((data) => {
          if (data.status === 200) {
            setRenderState(data.data.results, data.data.count);
          }
        })
        .catch((error) => {
          console.log("Productivity data error", error);
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const setRenderState = (applicant, noOfRecords) => {
    setApplicants(applicant);
    setCount(noOfRecords);
  };
  const getTask = (event) => {
    Axios.post(Config.LOCALURL + "tasks/list", { clientId: event })
      .then((response) => {
        if (response.status == 200) {
          setGettask(response.data.data);
        }
      })
      .catch((error) => {
        console.log("Error in get Client", error);
      });
  };

  const getchartdata = async () => {
    try {
      await Axios.post(
        Config.LOCALURL + "employee/productivity/withoutPagination",
        {
          page: controller.page,
          limit: controller.rowsPerPage,
          type: filterdate,
          startDate: startdate,
          endDate: enddate,
          status: taskStatus,
          empid: adminData.adminData.isAdmin
            ? empid
            : adminData.adminData.empid._id,
          typeOfHours:
            filterhours === "all"
              ? "all"
              : "" || filterhours === "true"
              ? true
              : "" || filterhours === "false"
              ? false
              : "",
        }
      )
        .then((response) => {})
        .catch((error) => {
          console.log("Error in get Client", error);
        });
    } catch (err) {
      console.log(err);
    }
  };
  function changeclient(event) {
    setClientName(event);
    getTask(event);
  }
  const getEmployeeList = () => {
    AxiosActions.EmployeeList({})
      .then((response) => {
        if (response.status == 200) {
          setGetdata(response.data.data);
        }
      })
      .catch((error) => {
        console.log("Error in get employee list", error);
      });
  };

  const getTrackingTools = () => {
    AxiosActions.TrackingList({})
      .then((response) => {
        if (response.status == 200) {
          setTrackingTools(response.data.data);
        }
      })
      .catch((error) => {
        console.log("Error in getTrackingTools", error);
      });
  };

  const getClient = () => {
    AxiosActions.ProdutivityClientList({})
      .then((response) => {
        if (response.status === 200) {
          setGetclient(response.data.data);
        }
      })
      .catch((error) => {
        console.log("Error in get Client", error);
      });
  };

  const dayWiseProductivityCount = async (month, year, empId) => {
    try {
      setChartCountLoading(true);
      await AxiosActions.dayWiseProductivityCount({
        startDate: "",
        endDate: "",
        type: "all",
        month: month,
        year: year,
        status: "",
        empid: empId,
      })
        .then((data) => {
          if (data.status === 200) {
            setChartProductivityCount(data?.data?.data);
          }
        })
        .catch((error) => {
          console.log("Productivity count Data error", error);
        })
        .finally(() => {
          setChartCountLoading(false);
        });
    } catch (err) {
      console.log(err);
    }
  };
  const productivityStatusCount = async (year, month) => {
    try {
      setChartStatusLoading(true);
      await AxiosActions.productivityStatusCount({
        startDate: "",
        endDate: "",
        type: "all",
        month: month,
        year: year,
        status: "",
      })
        .then((data) => {
          if (data.status === 200) {
            setChartProductivityStatus(data?.data?.data);
          }
        })
        .catch((error) => {
          console.log("Productivity status count Data error", error);
        })
        .finally(() => {
          setChartStatusLoading(false);
        });
    } catch (err) {
      console.log(err);
    }
  };

  // Creates a series that is composed of productivity hours and non - productivity hours

  const productivityCount = useMemo(() => {
    if (chartProductivityCount.length === 0) return [];
    const convertDate = chartProductivityCount
      .map((obj) => ({
        ...obj,
        date: new Date(obj.date).getTime(),
      }))
      .sort((a, b) => b.date - a.date);

    const productivity = convertDate?.map((obj) => [
      obj?.date,
      Number(obj?.totalProductivityHours.toFixed(2)),
    ]);
    const NonProductivity = convertDate?.map((obj) => [
      obj?.date,
      Number(obj?.totalNonProductivityHours.toFixed(2)),
    ]);

    const seriesData = [
      {
        name: "Productivity Hours",
        data: productivity,
      },
      {
        name: "Non-Productivity Hours",
        data: NonProductivity,
      },
    ];
    return seriesData;
  }, [chartProductivityCount]);

  // Returns an array of categories and series that are in the productivity status.
  // Each category is represented by a series of ` Productivity ` and ` Non - Productivity `

  const productivityStatusData = useMemo(() => {
    if (chartProductivityStatus?.length === 0) return [];

    const categories = chartProductivityStatus?.map((obj) => obj.employeeName);

    const productivity = chartProductivityStatus?.map((obj) =>
      Number(obj?.totalProductivityHours.toFixed(2))
    );
    const nonProductivity = chartProductivityStatus?.map((obj) =>
      Number(obj?.totalNonProductivityHours.toFixed(2))
    );
    const series = [
      {
        name: "Productivity Hours",
        data: productivity,
      },
      {
        name: "Non-Productivity Hours",
        data: nonProductivity,
      },
    ];
    return { categories, series };
  }, [chartProductivityStatus]);

  useEffect(() => {
    if (userIdFilter) {
      dayWiseProductivityCount(monthFilter, yearFilter, userIdFilter);
    }
    productivityStatusCount(yearStatusFilter, monthStatusFilter);
  }, [getdata]);

  const ChartFilter = async () => {
    getdata?.map((obj) => {
      if (obj._id === userIdFilter) {
        setUserNameFilter(obj?.employeeName);
      }
    });
    dayWiseProductivityCount(monthFilter, yearFilter, userIdFilter);
  };
  const chartReset = () => {
    setUserIdFilter(adminData.adminData.empid._id);
    setUserNameFilter(adminData.adminData.empid.employeeName);
    setYearFilter("all");
    setMonthFilter("all");
    dayWiseProductivityCount("all", "all", adminData.adminData.empid._id);
  };
  const chartStatusFilter = async () => {
    productivityStatusCount(yearStatusFilter, monthStatusFilter);
  };
  const chartStatusReset = () => {
    setYearStatusFilter("all");
    setMonthStatusFilter("all");
    productivityStatusCount("all", "all");
  };

  useEffect(() => {
    getchartdata();
    getEmployeeList();
    getTrackingTools();
    getClient();
    getTask(clientName);
    if (isFiltered === true) {
      if (!controller.preventRefresh) filterRecord();
    } else {
      renderdata();
    }
  }, [controller, adminData.adminData, clientName]);

  // New Pagination

  const handlePageChange = (event, newPage) => {
    setController({
      ...controller,
      page: newPage,
      preventRefresh: false,
    });
    setSelectedInfo({ selectedID: [] });
  };

  const handleChangeRowsPerPage1 = (event) => {
    setController({
      ...controller,
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0,
      preventRefresh: false,
    });

    setSelectedInfo({ selectedID: [] });
  };

  // Filter record

  const filterRecord = async () => {
    trackEvent(EVENT_NAME.PRODUCTIVITY_LIST_FILTER, { data: "" });
    let pageNumber = controller.page;

    if (!isFiltered) {
      pageNumber = 0;
      setIsFiltered(true);
      setController((prevController) => {
        return {
          ...prevController,
          page: 0,
          preventRefresh: true,
        };
      });
    }
    renderdata();
    setSelectedInfo({ selectedID: [] });
  };

  // Update status

  const changeStatus = (statusid, title) => {
    setIsDisable(true);
    trackEvent(EVENT_NAME.PRODUCTIVITY_EDIT, { data: statusid });
    AxiosActions.EditProductivity({
      id: statusid,
      clientId: clientName,
      trackingToolId: trackingTool,
      actualHours: actualhours,
      estimatedHours: estimatedhours,
      status: status,
      typeOfHours: hourstype,
      note: note,
      adminId: adminData._id,
      taskId: taskId,
    })
      .then(async (data) => {
        if (data.status === 200) {
          setOpenAlert(true);
          setIsDisable(false);
          renderdata();
        }
      })
      .catch((err) => {
        setIsDisable(false);
        console.log("update error", err);
        alert("Please Delete Record Again.");
      });

    setSelectedInfo({ selectedID: [] });
  };

  const Addnotes = (statusid, title) => {
    setIsDisable(true);
    AxiosActions.addnotes({
      pro_id: statusid,
      Task_id: taskId,
      notes: addnote,
      adminId: adminData.adminData._id,
      admin_id: adminData.adminData._id,
    })
      .then(async (data) => {
        if (data.status === 200) {
          setOpenAlert(true);
          setIsDisable(false);
          renderdata();
        }
      })
      .catch((err) => {
        setIsDisable(false);
        console.log("update error", err);
        alert("Please Delete Record Again.");
      });

    setSelectedInfo({ selectedID: [] });
  };

  const Viewnote = (event, statusid) => {
    setAddLoading(true);
    AxiosActions.viewnote({
      pro_id: statusid,
    })
      .then((data) => {
        if (data.status === 200) {
          setNotedata(data.data.results);

          setAddLoading(false);
        }
      })
      .catch((err) => {
        setIsDisable(false);
        console.log("Viewnote error", err);
        alert("Please Delete Record Again.");
      });
    event.preventDefault();
    setSelectedInfo({ selectedID: [] });
  };
  //Reset Filter

  const reset = () => {
    trackEvent(EVENT_NAME.PRODUCTIVITY_RESET, { data: "" });
    setApplicants(filterArr);
    setEmpid("");
    setFilterStatus("all");
    setController({
      ...controller,
      page: 0,
      rowsPerPage: 10,
      preventRefresh: false,
    });
    setSelectedInfo({ selectedID: [] });
    setIsFiltered(false);
  };

  const handleDeleteClick = (event, id) => {
    setDeleteId(id);

    event.preventDefault();
  };

  //Delete Record

  const deleteRecord = (event, id) => {
    setIsDisable(true);
    trackEvent(EVENT_NAME.PRODUCTIVITY_DELETE, { data: id });
    AxiosActions.DeleteProductivity({
      id: id,
      adminId: adminData._id,
    })
      .then((data) => {
        renderdata();
        setIsDisable(false);
      })
      .catch((err) => {
        setIsDisable(false);
        console.log("delete error", err);
        alert("Please Delete Record Again.");
      });

    event.preventDefault();
  };

  //View Full Report

  const handleViewReportClick = (event, id) => {
    trackEvent(EVENT_NAME.PRODUCTIVITY_VIEW, { data: id });
    const filterRecord = applicants.filter((applicantsItem) => {
      if (id === applicantsItem._id) {
        setEmp(applicantsItem.empid._id);
        setTrackingTool(applicantsItem.trackingToolId._id);
        setClientName(applicantsItem.clientId._id);
        setHourstype(applicantsItem.typeOfHours);
        setEstimatedhours(applicantsItem.estimatedHours);
        setActualhours(applicantsItem.actualHours);
        setStatus(applicantsItem.status);
        setNote(applicantsItem.note);
        setTaskId(applicantsItem.TaskId._id);
        return true;
      }
    });
    setUpdateId(id);

    setFullReport(filterRecord);
    event.preventDefault();
  };
  //Select Multiple

  const handleCheckBoxChange = (e) => {
    const value = e.target.value;
    const { checked } = e.target;
    const list = [];
    const { selectedID } = selectedinfo;

    if (value === "all") {
      if (value === "all" && checked) {
        applicants.map((item) => {
          list.push(item._id);
        });
        setSelectedInfo({
          selectedID: list,
        });
      } else {
        setSelectedInfo({
          selectedID: list.filter((e) => e !== value),
        });
      }
      return;
    } else if (checked) {
      setSelectedInfo({
        selectedID: [...selectedID, value],
      });
    } else {
      setSelectedInfo({
        selectedID: selectedID.filter((e) => e !== value),
      });
    }
  };

  //Delete Multiple

  const handleDeleteMultipleClick = (event) => {
    event.preventDefault();
  };
  const deleteAllRecord = (event) => {
    setIsDisable(true);
    trackEvent(EVENT_NAME.PRODUCTIVITY_DELETE_ALL, { data: "" });
    AxiosActions.MultipleDeleteProductivity({
      id: selectedinfo.selectedID,
      adminId: adminData._id,
    })
      .then((data) => {
        renderdata();
        setIsDisable(false);
      })
      .catch((err) => {
        alert("Please Delete Record Again");
        setIsDisable(false);
      });

    setSelectedInfo({ selectedID: [] });
    event.preventDefault();
  };

  return (
    <>
      {token === null ? (
        true((window.location.href = "/"))
      ) : (
        <>
          <AlertBox
            open={openAlert}
            onClose={handleCloseAlert}
            message={DATAUPDATESUCCESSFULLY}
            title="Success"
            ok={false}
          />
          <main id="content" role="main" className="main">
            <div className="content container-fluid">
              <HeaderTitle title="Productivities" />

              <div className="card card-bordered h-100">
                <div className="card-body">
                  <div className="card-header">
                    <Filter
                      name={true}
                      nameValue={userIdFilter}
                      nameUpdate={setUserIdFilter}
                      employeeList={getdata}
                      year={true}
                      yearValue={yearFilter}
                      yearUpdate={setYearFilter}
                      month={true}
                      monthValue={monthFilter}
                      monthUpdate={setMonthFilter}
                      filterRecord={ChartFilter}
                      resetRecord={chartReset}
                    />
                  </div>
                  {chartCountLoading ? (
                    <Loader />
                  ) : productivityCount.length > 0 ? (
                    <LineChart
                      series={productivityCount}
                      title={"Day Wise Productivity Count"}
                      yAxisTitle={"Number of count"}
                      xAxisTitle={userNameFilter}
                      tickPixelInterval={70}
                    />
                  ) : (
                    <div
                      style={{
                        height: "200px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <h1>No Data Found</h1>
                    </div>
                  )}
                </div>
              </div>
              <div className="card card-bordered h-100">
                <div className="card-body">
                  <div className="card-header">
                    <Filter
                      year={true}
                      yearValue={yearStatusFilter}
                      yearUpdate={setYearStatusFilter}
                      month={true}
                      monthValue={monthStatusFilter}
                      monthUpdate={setMonthStatusFilter}
                      filterRecord={chartStatusFilter}
                      resetRecord={chartStatusReset}
                    />
                  </div>
                  {chartStatusLoading ? (
                    <Loader />
                  ) : Object.keys(productivityStatusData).length > 0 ? (
                    <BarChart
                      categories={productivityStatusData?.categories}
                      series={productivityStatusData?.series}
                      title={"Productivity Status Count"}
                      yAxisTitle={"Number of count"}
                      xAxisTitle={"Employee"}
                      barWidth={40}
                    />
                  ) : (
                    <div
                      style={{
                        height: "200px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <h1>No Data Found</h1>
                    </div>
                  )}
                </div>
              </div>

              <div className="card mb-3 mb-lg-5">
                <div className="card-header">
                  {adminData.adminData.isAdmin ? (
                    <Filter
                      status={true}
                      statusValue={filterStatus}
                      statusUpdate={setFilterStatus}
                      statusList={Config?.PRODDUCTIVITY_STATUS}
                      hours={true}
                      hoursValue={filterhours}
                      hoursUpdate={setFilterhours}
                      hoursList={Config?.PRODDUCTIVITY_HOURS_TYPE}
                      date={true}
                      dateValue={filterStatus}
                      dateUpdate={setFilterStatus}
                      startDateValue={startdate}
                      startDateUpdate={setStartdate}
                      endDateValue={enddate}
                      endDateUpdate={setEnddate}
                      multipleSelect={selectedinfo?.selectedID?.length}
                      multipleDelete={true}
                      isDisable={isDisable}
                      handleDelete={handleDeleteMultipleClick}
                      deleteAllRecord={deleteAllRecord}
                      name={true}
                      nameValue={empid}
                      nameUpdate={setEmpid}
                      employeeList={getdata}
                      filterRecord={filterRecord}
                      resetRecord={reset}
                    />
                  ) : (
                    <Filter
                      status={true}
                      statusValue={filterStatus}
                      statusUpdate={setFilterStatus}
                      statusList={Config?.PRODDUCTIVITY_STATUS}
                      hours={true}
                      hoursValue={filterhours}
                      hoursUpdate={setFilterhours}
                      hoursList={Config?.PRODDUCTIVITY_HOURS_TYPE}
                      date={true}
                      dateValue={filterStatus}
                      dateUpdate={setFilterStatus}
                      startDateValue={startdate}
                      startDateUpdate={setStartdate}
                      endDateValue={enddate}
                      endDateUpdate={setEnddate}
                      filterRecord={filterRecord}
                      resetRecord={reset}
                    />
                  )}
                </div>
                <div className="table-responsive datatable-custom">
                  <Table
                    handleCheckBoxChange={handleCheckBoxChange}
                    isAllSelected={isAllSelected}
                    loading={loading}
                    applicants={applicants}
                    selectedinfo={selectedinfo}
                    adminData={adminData}
                    handleViewReportClick={handleViewReportClick}
                    handleDeleteClick={handleDeleteClick}
                    Viewnote={Viewnote}
                  />
                </div>
                <View fullReport={fullReport} />
                <Edit
                  fullReport={fullReport}
                  changeclient={changeclient}
                  clientName={clientName}
                  getclient={getclient}
                  settaskId={setTaskId}
                  taskId={taskId}
                  gettask={gettask}
                  settrackingTool={setTrackingTool}
                  trackingTool={trackingTool}
                  TrackingTools={trackingTools}
                  sethourstype={setHourstype}
                  hourstype={hourstype}
                  setestimatedhours={setEstimatedhours}
                  estimatedhours={estimatedhours}
                  setactualhours={setActualhours}
                  actualhours={actualhours}
                  setstatus={setStatus}
                  status={status}
                  setnote={setNote}
                  note={note}
                  isDisable={isDisable}
                  changeStatus={changeStatus}
                  updateId={updateId}
                />
                <AddNotes
                  fullReport={fullReport}
                  adminData={adminData}
                  setaddnote={setAddnote}
                  addnote={addnote}
                  isDisable={isDisable}
                  Addnotes={Addnotes}
                  updateId={updateId}
                />
                <div
                  className="modal fade"
                  id="delete"
                  tabIndex="-1"
                  role="dialog"
                  aria-labelledby="exampleModalCenterTitle"
                  aria-hidden="true"
                >
                  <div
                    className="modal-dialog modal-dialog-centered"
                    role="document"
                  >
                    <div className="modal-content">
                      <div className="modal-header">
                        <h1
                          className="modal-title"
                          id="exampleModalLongTitle"
                        >
                          {productivity.DELETE}
                        </h1>

                        <button
                          type="button"
                          className="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        ></button>
                      </div>
                      <div className="modal-body">
                        <br></br>
                        <label
                          style={{
                            fontSize: 18,
                            marginLeft: 60,
                          }}
                        >
                          {productivity.ALERT_MSG}
                        </label>
                      </div>
                      <div className="modal-footer">
                        <button
                          type="button"
                          className="btn btn-danger"
                          data-bs-dismiss="modal"
                        >
                          {productivity.NO}
                        </button>
                        <button
                          disabled={isDisable === true}
                          type="button"
                          className="btn btn-primary"
                          data-bs-dismiss="modal"
                          onClick={(event) => {
                            deleteRecord(event, deleteId);
                          }}
                        >
                          {productivity.YES}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <NotesStatus addloading={addloading} notedata={notedata} />
                <div className="card-footer">
                  <Pagination
                    handlePageChange={handlePageChange}
                    controller={controller}
                    count={count}
                    handleChangeRowsPerPage1={handleChangeRowsPerPage1}
                  />
                </div>
              </div>
            </div>
            <div className="footer"></div>
          </main>
        </>
      )}
    </>
  );
}

export default HeaderNavbarHOC(ProList);
