import React from "react";

function Delete({ isDisable, deleteRecord, deleteId }) {
  return (
    <div
      className="modal fade"
      id="delete"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title" id="exampleModalLongTitle">
              Delete
            </h1>

            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <br></br>
            <label
              style={{
                fontSize: 18,
                marginLeft: 60,
              }}
            >
              “Are you sure, you want to delete the record?”
            </label>
          </div>

          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-danger"
              data-bs-dismiss="modal"
            >
              No
            </button>
            <button
              disabled={isDisable === true}
              type="button"
              className="btn btn-primary"
              data-bs-dismiss="modal"
              onClick={(event) => {
                deleteRecord(event, deleteId);
              }}
            >
              Yes
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Delete;
