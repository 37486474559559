import React from "react";
import moment from "moment";
import { history } from "../../constant/History";

function Table({ applicants }) {
  return (
    <table
      id="datatable"
      className="table table-borderless table-thead-bordered table-nowrap table-align-middle card-table"
    >
      <thead className="thead-light">
        <tr>
          <th>{history.DATE}</th>
          <th>{history.EMAIL}</th>
          <th>{history.TARGET_FIELD}</th>
          <th>{history.ACTION}</th>
          <th>{history.TARGET_NAME}</th>
        </tr>
      </thead>
      <tbody>
        {applicants.map((app, index) => {
          var date = new Date(app.date).toLocaleString(undefined, {
            timeZone: "Asia/Kolkata",
          });
          const date1 = date.split(",");
          const time = date1[1].split(" ");
          return (
            <tr key={"app" + index}>
              <td style={{ width: "5%" }}>
                {moment(app.date).format("YYYY-MM-DD")}
              </td>
              <td>
                <h5>{app.resourceName}</h5>
              </td>

              <td>{app.apploye}</td>
              <td>{app.upwork}</td>
              <td>{app.productivityHours}</td>
              <td>{app.nonProductivityHours}</td>

              <></>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

export default Table;
