import React from "react";
import Checkbox from "@mui/material/Checkbox";
import moment from "moment";
import PropTypes from "prop-types";

function Table({
  handleCheckBoxChange,
  isAllSelected,
  loading,
  applicants,
  selectedinfo,
  adminData,
  handleViewReportClick,
  handleDeleteClick,
}) {
  return (
    <table
      id="datatable"
      className="table table-borderless table-thead-bordered text-nowrap table-align-middle card-table"
    >
      <thead className="thead-light">
        <tr>
          <th scope="col" className="table-column-pe-0">
            <Checkbox
              size="medium"
              value="all"
              onChange={handleCheckBoxChange}
              checked={isAllSelected}
            ></Checkbox>
          </th>

          <th>Project</th>
          <th>Summary</th>
          <th>Employee</th>
          <th>Date</th>
          <th>Status</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        {loading ? (
          <tr>
            <td colSpan={8} style={{ textAlign: "center" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <status className="spinner-border text-dark m-5">
                  <span className="sr-only"></span>
                </status>
              </div>
            </td>
          </tr>
        ) : (
          <>
            {applicants.length === 0 && (
              <tr>
                <td colSpan={7} style={{ textAlign: "center" }}>
                  <h3>No record found</h3>
                </td>
              </tr>
            )}
            {applicants.map((app, index) => {
              return (
                <tr key={"app" + index}>
                  <td className="table-column-pe-0">
                    <Checkbox
                      key={app._id}
                      size="medium"
                      value={app._id}
                      onChange={handleCheckBoxChange}
                      checked={selectedinfo.selectedID.includes(app._id)}
                    ></Checkbox>
                  </td>

                  <td className="table-column-ps-1">
                    <h5 className="text-inherit mb-0">
                      {app.clientId?.clientName}
                    </h5>
                  </td>

                  <td className="text-wrap" style={{ width: "35%" }}>
                    {app.summary}
                  </td>
                  <td>{app.empid?.employeeName}</td>
                  <td>{moment(app.date).format("DD-MM-YYYY")}</td>
                  <td>{app.status}</td>

                  <td>
                    <button
                      title="Edit Task" 
                      type="button"
                      className="icon icon-danger icon-circle"
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModalLong"
                      onClick={(event) => handleViewReportClick(event, app._id)}
                    >
                      <i className="bi-pen"></i>
                    </button>
                    &nbsp;
                    <button
                      title="Delete Task"
                      type="button"
                      className="icon icon-info icon-circle"
                      data-bs-toggle="modal"
                      data-bs-target="#delete"
                      onClick={(event) => handleDeleteClick(event, app._id)}
                    >
                      <i className="bi-trash"></i>
                    </button>
                    &nbsp;
                    <button
                      title="View Task"
                      type="button"
                      className="icon icon-dark icon-circle"
                      data-bs-toggle="modal"
                      data-bs-target="#viewpage"
                      onClick={(event) => handleViewReportClick(event, app._id)}
                    >
                      <i className="bi-eye"></i>
                    </button>
                  </td>
                  <></>
                </tr>
              );
            })}
          </>
        )}
      </tbody>
    </table>
  );
}

export default Table;

Table.propTypes = {
  handleCheckBoxChange: PropTypes.func.isRequired,
  isAllSelected: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  applicants: PropTypes.array.isRequired,
  selectedinfo: PropTypes.object.isRequired,
  adminData: PropTypes.object.isRequired,
  handleViewReportClick: PropTypes.func.isRequired,
  handleDeleteClick: PropTypes.func.isRequired,
};
