const Config = {
  APIURL: "https://api.3elixir.com:9002/",
  LOCALURL: "https://api.3elixir.com:9002/",
  // APIURL: "http://192.168.1.16:9003/",
  // LOCALURL: "http://192.168.1.16:9003/",
  ACTION: {
    add: "add",
    edit: "edit",
    delete: "delete",
    view: "view",
    csv: "csv",
    employee: "employee",
    trackingtools: "trackingtools",
    salary: "salary",
    client: "client",
  },
  STATUS_LIST: [
    { name: "Applied", value: "Applied" },
    { name: "Contacted", value: "Contacted" },
    { name: "Interview Scheduled", value: "Interview Scheduled" },
    { name: "Interviewed", value: "Interviewed" },
    {
      name: "Didn't appear for an interview",
      value: "Didn't appear for an interview",
    },
    { name: "Offer Accepted", value: "Offer Accepted" },
    { name: "Joined", value: "Joined" },
    { name: "Pending", value: "Pending" },
    { name: "On Hold", value: "On Hold" },
    { name: "Not Interested", value: "Not Interested" },
    { name: "Interview Rejected", value: "Interview Rejected" },
    { name: "Offer Rejected", value: "Offer Rejected" },
    { name: "Rejected for Location", value: "Rejected for Location" },
    { name: "Rejected for Qualification", value: "Rejected for Qualification" },
    { name: "Rejected for Communication", value: "Rejected for Communication" },
    { name: "Unmatched Skills", value: "Unmatched Skills" },
    { name: "Rejected", value: "Rejected" },
    { name: "Not Shortlisted", value: "Not Shortlisted" },
  ],
  STATUS_CLASS_LIST: [
    "applied",
    "contacted",
    "interviewscheduled",
    "interviewed",
    "didntappearforaninterview",
    "offeraccepted",
    "joined",
    "pending",
    "onhold",
    "notinterested",
    "interviewrejected",
    "offerrejected",
    "rejectedforlocation",
    "rejectedforqualification",
    "rejectedforcommunication",
    "unmatchedskills",
    "rejected",
    "notshortlisted",
  ],

  CONTACT_LIST: [
    "Interest ?",
    "Website Maintenance (After Care)",
    "Web Design",
    "Web Development",
    "E-commerce Development",
    "Business Consultation",
    "Job",
    "Other",
  ],
  PRODDUCTIVITY_HOURS_TYPE: [
    { name: "Productivity Hours", value: true },
    { name: "Non Productivity Hours", value: false },
  ],
  EMPLOYEE_STATUS: [
    { name: "Active", value: "active" },
    { name: "Inactive", value: "inactive" },
    { name: "Completed", value: "completed" },
    { name: "In Completed", value: "incompleted" },
    { name: "Rejected", value: "rejected" },
    { name: "Terminated", value: "terminated" },
  ],
  USER_STATUS: [
    { name: "Active", value: "Active" },
    { name: "Inactive", value: "Inactive" },
  ],
  USER_TYPE: [
    { name: "Admin", value: true },
    { name: "Employee", value: false },
  ],
  ADMIN_STATUS: ["false", "true"],
  ADMIN_ROLES: [
    { name: "Administrator", value: "administrator" },
    { name: "Developer", value: "developer" },
    { name: "Business Development Executive (BDE)", value: "BDE" },
    { name: "Human Resources (HR)", value: "HR" },
  ],
  TRAKING_TOOLS: ["Clockify", "Upwork", "Apploye"],
  PRODDUCTIVITY_STATUS: [
    "Blocked",
    "Hold",
    "Achieved",
    "Not achieved",
    "Over",
    "In Progress",
  ],
  DOCUMENT: [
    "ID Proof (PAN card/ Driving License/ Aadhar Card etc.)",
    "Address Proof",
    "Offer/Experience letter of past company, if applicable",
    "Degree/Diploma Certificate (Last semester marksheet if pending)",
    "Salary slip from previous company, if applicable",
  ],
  CLIENT_STATUS: [
    { name: "Enable", value: true },
    { name: "Disable", value: false },
  ],
  TRACKING_TOOLS_STATUS: [
    { name: "Active", value: true },
    { name: "Inactive", value: false },
  ],
  JOBS_STATUS: [
    { name: "Active", value: true },
    { name: "Inactive", value: false },
  ],
  LEAVE_TYPE: [
    { name: "Casual Leave", value: "CL", type: "Paid" },
    { name: "Sick Leave", value: "SL", type: "Paid" },
    { name: "Earned Leave", value: "EL", type: "Paid" },
    { name: "Leave Without Pay", value: "LP", type: "Unpaid" },
    { name: "Half-Day Casual Leave", value: "HCL", type: "Paid" },
    { name: "Half-Day Sick Leave", value: "HSL", type: "Paid" },
    { name: "Half-Day Earned Leave", value: "HEL", type: "Paid" },
    { name: "Holiday", value: "HOL", type: "Paid" },
    { name: "Half-Day Leave Without Pay", value: "HLP", type: "Unpaid" },
    { name: "Paternity Leave", value: "PU", type: "Paid" },
    { name: "Compensatory Off", value: "CO", type: "Paid" },
    { name: "Bereavement Leave", value: "B", type: "Paid" },
    { name: "Week Holiday Unpaid", value: "WHU", type: "Unpaid" },
    { name: "Week Holiday Paid", value: "WHP", type: "Paid" },
  ],
  LEAVE_STATUS: [
    { name: "Requested", value: "Requested" },
    { name: "Approved", value: "Approved" },
    { name: "Not Approved", value: "Not Approved" },
    { name: "Rejected", value: "Rejected" },
    { name: "Cancel", value: "Cancel" },
  ],
  HISTORY_TRAGETED_FILEDS: ["productivity", "employee", "jobs", "applicant"],
  HISTORY_ACTION: ["add", "edit", "delete"],
  TASK_STATUS: ["TO DO", "BLOCKED", "IN PROGRESS", "DONE", "REOPEN"],
  SALARY_STATUS: [
    { name: "PAID", value: true },
    { name: "UNPAID", value: false },
  ],
  EXPENSES_STATUS: [
    { name: "PAID", value: true },
    { name: "UNPAID", value: false },
  ],

  EMAIL_TYPE: [
    { name: "Sales", value: "sales" },
    { name: "Quirkplus", value: "quirkplus" },
    { name: "Promotions Elitist", value: "promotions-elitist" },
    { name: "Promotions Elixir", value: "promotions-elixir" },
  ],
  TEMPLATE_TYPE: [{ name: "Appointment", value: "appointment" }],
  EMAIL_STATUS: [
    { name: "Sent", value: "Sent" },
    { name: "Resent", value: "Resent" },
    { name: "Failed", value: "Failed" },
    { name: "Pending", value: "pending" },
    { name: "Rejected", value: "rejected" },
    { name: "Contacted", value: "contacted" },
    { name: "Not shortlisted", value: "notshortlisted" },
    { name: "Shortlisted", value: "Shortlisted" },
    { name: "On-hold", value: "onhold" },
    { name: "Joined", value: "Joined" },
    { name: "Offer Accepted", value: "offer-accepted " },
    { name: "Contract Started", value: "contract-started " },
    { name: "Contract Ended", value: "contract-ended " },
    { name: "Contract Paused", value: "contract-paused " },
    { name: "Contract Completed", value: "contract-completed " },
  ],
  EVENTTYPE : [
    { name: "Celebration", value: "Celebration" },
    { name: "Holiday", value: "Holiday" },
  ],
  DATE_CATEGORY: [
    { name: "Customize", value: "customize" },
    { name: "Today", value: "today" },
    { name: "Yesterday", value: "yesterday" },
    { name: "Last Week", value: "last_week" },
    { name: "Current Week", value: "current_week" },
    { name: "Last Month", value: "last_month" },
    { name: "This Month", value: "this_month" },
  ],
  SALARY_MONTH: [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ],
  SALARY_YEAR: [2018, 2019, 2020, 2021, 2022, 2023],
  EXPENSES_CATEGORY: ["Misc", "Salary"],
  REMINDER_STATUS: [
    { name: "Rescheduled", value: "Rescheduled" },
    { name: "Compulsory", value: "Compulsory" },
    { name: "Scheduled", value: "Scheduled" },
    { name: "On Going", value: "On Going" },
    { name: "Canceled", value: "Canceled" },
  ],
  ROWS_PER_PAGE_OPTIONS: [10, 20, 50, 100],
};
export default Config;

const startYear = 2020;
const endYear = 2050;

export const yearArray = Array.from(
  { length: endYear - startYear + 1 },
  (_, index) => startYear + index
);
