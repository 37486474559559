import React, { useState, useEffect } from "react";
import Config from "../../config";
import HeaderNavbarHOC from "../../HOC/HeaderNavbar";
import AlertBox from "../../Alert";
import { trackEvent, EVENT_NAME } from "../../constant/MixpanelConstant";
import * as AxiosActions from "../../Actions/axiosActions";
import Table from "./Table";
import Edit from "./Edit";
import View from "./View";
import {
  HeaderTitle,
  Pagination,
  AlertDelete,
  Filter,
} from "../../components/Common";
import {
  DATAUPDATESUCCESSFULLY,
  LABLENOTEMPTY,
  SUCCESS,
  WHATSAPPMARKETING,
} from "../../constant/Label";

function WhatsappList() {
  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
  };
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [openAlert, setOpenAlert] = useState(false);
  const [filterMonth, setFilterMonth] = useState("all");
  const [filterYear, setFilterYear] = useState("all");
  const [filterDate, setFilterDate] = useState("all");
  const [applicants, setApplicants] = useState([]);
  const [isFiltered, setIsFiltered] = useState(false);
  const [updateId, setUpdateId] = useState("");
  const [deleteId, setDeleteId] = useState("");
  const [fullReport, setFullReport] = useState([]);
  const [filterStatus, setFilterStatus] = useState("all");
  const [count, setCount] = useState(0);
  const [isDisable, setIsDisable] = useState(false);
  const [phoneValue, setPhoneValue] = useState("");
  const currentDate = new Date();
  const currentMonth = Config.SALARY_MONTH[currentDate.getMonth()];
  const currentYear = currentDate.getFullYear().toString();

  const [selectedinfo, setSelectedInfo] = useState({
    selectedID: [],
  });
  const [controller, setController] = useState({
    page: 0,
    rowsPerPage: 10,
    preventRefresh: false,
  });

  const [inputValue, setInputalue] = useState({
    phone: "",
    template: "appointment",
    label: [
      { type: "text", text: "" },
      { type: "text", text: "" },
      { type: "text", text: "" },
      { type: "text", text: "" },
      { type: "text", text: "" },
    ],
  });

  const [errMsg, setErrMsg] = useState({
    phone: "",
    template: "",
    labels: inputValue?.label?.map(() => ""),
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "phone") {
      const sanitizedValue = value.replace(/[^\d,\s\n]+/g, "");
      setInputalue((prevFormValue) => ({
        ...prevFormValue,
        phone: sanitizedValue,
      }));
    } else if (name.startsWith("label")) {
      const index = parseInt(name.slice(-1), 10) - 1;
      setInputalue((prevFormValue) => ({
        ...prevFormValue,
        label: prevFormValue.label.map((item, i) =>
          i === index ? { ...item, text: value } : item
        ),
      }));
    } else {
      setInputalue((prevFormValue) => ({
        ...prevFormValue,
        [name]: value,
      }));
    }
  };

  // FOR SELECT ALL

  const isAllSelected =
    applicants?.length > 0 &&
    selectedinfo.selectedID.length === applicants?.length;
  const token = localStorage.getItem("access_token");

  // RENDER DATA

  const renderdata = async () => {
    try {
      setLoading(true);
      AxiosActions.WhatsAppMarketingList({
        page: controller.page,
        limit: controller.rowsPerPage,
        year: Number(filterYear) ? Number(filterYear) : filterYear,
        type: filterDate,
        month: filterMonth,
        messageType: filterStatus,
        startDate: startDate,
        endDate: endDate,
        phoneNumber: phoneValue,
      })
        .then((data) => {
          if (data?.status === 200) {
            if (data?.data?.data?.resultData) {
              setApplicants(data?.data?.data?.resultData);
              setCount(data?.data?.data?.count);
            }
          }
          setLoading(false);
        })
        .catch((error) => {
          console.log("Catch Err in Pagination ", error);
        });
    } catch (err) {
      console.log("Catch err in render data", err);
    }
  };

  useEffect(() => {
    if (isFiltered === true) {
      if (!controller.preventRefresh) filterRecord();
    } else {
      renderdata();
    }
  }, [controller]);

  // New Pagination

  const handlePageChange = (event, newPage) => {
    setController({
      ...controller,
      page: newPage,
      preventRefresh: false,
    });
    setSelectedInfo({ selectedID: [] });
  };

  const handleChangeRowsPerPage1 = (event) => {
    setController({
      ...controller,
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0,
      preventRefresh: false,
    });

    setSelectedInfo({ selectedID: [] });
  };

  // Filter record

  const filterRecord = async () => {
    trackEvent(EVENT_NAME.WHATSAPP_MARKETING_LIST_FILTER, { data: "" });
    setIsFiltered(true);
    if (!isFiltered) {
      let data = {
        page: 0,
        rowsPerPage: 10,
        preventRefresh: false,
      };
      setController(data);
    }
    renderdata();
    setSelectedInfo({ selectedID: [] });
  };

  const handleFilterStatusChange = (event) => {
    setFilterStatus(event.target.value);
  };

  // Update status
  const changeStatus = (statusid, title) => {
    const phoneNumbersArray = inputValue?.phone
      ?.split(/\n/)
      .map((number) => number.replace(/\s+/g, "").replace(/,+$/, ""))
      .filter(Boolean);

    const formattedMobileString = phoneNumbersArray.join(",");

    const isValid = formattedMobileString
      .split(",")
      .every((number) => /^91\d{10}$/.test(number));

    trackEvent(EVENT_NAME.WHATSAPP_MARKETING_EDIT, { data: statusid });
    let errObject = {
      phone: "",
      template: "",
      labels: inputValue?.label?.map((labelItem) =>
        labelItem?.text.trim() === "" ? LABLENOTEMPTY : ""
      ),
    };
    let err = 0;
    if (errObject?.labels.some((labelError) => labelError !== "")) {
      err++;
    }
    if (!isValid) {
      err++;
      errObject.phone = "Phone number must be 10 digit";
    }
    if (inputValue?.template === "") {
      err++;
      errObject.template = "Select template";
    }
    if (err !== 0) {
      setErrMsg(errObject);
    } else {
      setErrMsg({
        phone: "",
        template: "",
        labels: inputValue?.label?.map(() => ""),
      });
      setIsDisable(true);
      AxiosActions.WhatsAppEmailMarketing({
        _id: statusid,
        messageType: inputValue?.template,
        phoneNumber: formattedMobileString,
        templateParameters: inputValue?.label,
        month: currentMonth,
        year: Number(currentYear),
      })
        .then((data) => {
          if (data.status === 200) {
            setOpenAlert(true);
            renderdata();
            setIsDisable(false);
          }
        })
        .catch((err) => {
          console.log(err);
          setIsDisable(false);
        });

      setSelectedInfo({ selectedID: [] });
    }
  };

  //Reset Filter

  const reset = () => {
    trackEvent(EVENT_NAME.WHATSAPP_MARKETING_RESET, { data: "" });
    setFilterStatus("all");
    setController({
      ...controller,
      page: 0,
      rowsPerPage: 10,
      preventRefresh: false,
    });
    setSelectedInfo({ selectedID: [] });
    setIsFiltered(false);
    setFilterDate("all");
    setPhoneValue("");
    setStartDate("");
    setEndDate("");
    setFilterMonth("all");
    setFilterYear("all");
  };

  const handleDeleteClick = (event, id) => {
    setDeleteId(id);
    event.preventDefault();
  };

  // Delete Record

  const deleteRecord = (event, id) => {
    setIsDisable(true);
    trackEvent(EVENT_NAME.WHATSAPP_MARKETING_DELETE, { data: id });
    AxiosActions.DeleteWhatsAppMarketing({
      _ids: id,
    })
      .then((data) => {
        if (data.status === 200) {
          renderdata();
          setIsDisable(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsDisable(false);
      });

    event.preventDefault();
  };

  //View Full Report

  const handleViewReportClick = (event, id) => {
    trackEvent(EVENT_NAME.WHATSAPP_MARKETING_VIEW, { data: id });
    const filterRecord = applicants?.filter((item) => {
      if (id === item._id) {
        const data = {
          phone: item?.phoneNumber,
          template: item?.messageType,
          label: item?.templateParameters,
        };
        setInputalue(data);
        return true;
      }
    });

    setFullReport(filterRecord);
    setUpdateId(id);
  };

  //Select Multiple

  const handleCheckBoxChange = (e) => {
    const value = e.target.value;
    const { checked } = e.target;
    const list = [];
    const { selectedID } = selectedinfo;

    if (value === "all") {
      if (value === "all" && checked) {
        applicants?.map((item) => {
          list.push(item._id);
        });
        setSelectedInfo({
          selectedID: list,
        });
      } else {
        setSelectedInfo({
          selectedID: list.filter((e) => e !== value),
        });
      }
      return;
    } else if (checked) {
      setSelectedInfo({
        selectedID: [...selectedID, value],
      });
    } else {
      setSelectedInfo({
        selectedID: selectedID.filter((e) => e !== value),
      });
    }
  };

  //Delete Multiple

  const handleDeleteMultipleClick = (event) => {
    event.preventDefault();
  };
  const deleteAllRecord = (event) => {
    setIsDisable(true);
    trackEvent(EVENT_NAME.WHATSAPP_MARKETING_DELETE_ALL, { data: "" });
    AxiosActions.DeleteWhatsAppMarketing({
      _ids: selectedinfo.selectedID,
    })
      .then((data) => {
        if (data?.data?.code === 200) {
          renderdata();
          setIsDisable(false);
        }
      })
      .catch((err) => {
        setIsDisable(false);
      });

    setSelectedInfo({ selectedID: [] });
    event.preventDefault();
  };

  return (
    <>
      <AlertBox
        open={openAlert}
        onClose={handleCloseAlert}
        message={DATAUPDATESUCCESSFULLY}
        title={SUCCESS}
        ok={false}
      />
      {token === null ? (
        true((window.location.href = "/"))
      ) : (
        <main id="content" role="main" className="main">
          <div className="content container-fluid">
            <HeaderTitle title={WHATSAPPMARKETING} />
            <div className="card mb-3 mb-lg-5">
              <div className="card-header">
                <Filter
                  year={true}
                  yearValue={filterYear}
                  yearUpdate={setFilterYear}
                  month={true}
                  monthValue={filterMonth}
                  monthUpdate={setFilterMonth}
                  date={true}
                  dateValue={filterDate}
                  dateUpdate={setFilterDate}
                  startDateValue={startDate}
                  startDateUpdate={setStartDate}
                  endDateValue={endDate}
                  endDateUpdate={setEndDate}
                  template={true}
                  templateValue={filterStatus}
                  templateUpdate={setFilterStatus}
                  templateList={Config?.TEMPLATE_TYPE}
                  searchPhone={true}
                  searchPhoneValue={phoneValue}
                  searchPhoneUpdate={setPhoneValue}
                  multipleSelect={selectedinfo?.selectedID?.length}
                  multipleDelete={true}
                  isDisable={isDisable}
                  handleDelete={handleDeleteMultipleClick}
                  deleteAllRecord={deleteAllRecord}
                  filterRecord={filterRecord}
                  resetRecord={reset}
                />
              </div>
              <div className="table-responsive datatable-custom">
                <Table
                  handleCheckBoxChange={handleCheckBoxChange}
                  isAllSelected={isAllSelected}
                  loading={loading}
                  applicants={applicants}
                  selectedinfo={selectedinfo}
                  handleViewReportClick={handleViewReportClick}
                  handleDeleteClick={handleDeleteClick}
                />
              </div>
              <Edit
                handleChange={handleChange}
                inputValue={inputValue}
                errMsg={errMsg}
                isDisable={isDisable}
                changeStatus={changeStatus}
                updateId={updateId}
              />

              <AlertDelete
                isDisable={isDisable}
                deleteRecord={deleteRecord}
                deleteId={deleteId}
              />

              <View fullReport={fullReport} />

              <div className="card-footer">
                <Pagination
                  handlePageChange={handlePageChange}
                  controller={controller}
                  count={count}
                  handleChangeRowsPerPage1={handleChangeRowsPerPage1}
                />
              </div>
            </div>
          </div>
          <div className="footer"></div>
        </main>
      )}
    </>
  );
}

export default HeaderNavbarHOC(WhatsappList);
