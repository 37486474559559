import React from 'react'

function Footer() {
  return (
   <>
   <div className="footer">
            <div className="row justify-content-between align-items-center">
              <div className="col">
                <p className="fs-6 mb-0">
                  © admin.
                  <span className="d-none d-sm-inline-block">
                    elitist@2023
                  </span>
                </p>
              </div>
              {/* End Col */}
              <div className="col-auto">
                <div className="d-flex justify-content-end">
                  {/* List Separator */}
                  <ul className="list-inline list-separator">
                    <li className="list-inline-item">
                      <a className="list-separator-link" target='_blank' rel="noreferrer" href="https://elitistsoftwaresolutionsllp.com">
                      elitist.com
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
   </>
  )
}

export default Footer