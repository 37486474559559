import React, { useState, useEffect, useMemo } from "react";
import moment from "moment";
import HeaderNavbarHOC from "../../HOC/HeaderNavbar";
import { BarChart, Loader, Filter } from "../../components/Common";
import { trackEvent, EVENT_NAME } from "../../constant/MixpanelConstant";
import * as AxiosActions from "../../Actions/axiosActions";
import Config from "../../config";
import { EMPLOYEE_SALARY } from "../../constant/Chart";
import Pagination from "../../components/Common/Pagination";

function Salary() {
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [getData, setGetData] = useState([]);
  const [applicants, setApplicants] = useState([]);
  const [isFiltered, setIsFiltered] = useState(false);
  const [filterStatus, setFilterStatus] = useState("all");
  const [count, setCount] = useState(0);
  const [empId, setEmpId] = useState("");
  const [filterDate, setFilterDate] = useState("all");
  const [filterMonth, setFilterMonth] = useState("all");
  const [filterYear, setFilterYear] = useState(moment().year());
  const [filterYearChart, setFilterYearChart] = useState(moment().year());
  const [chartData, setChartData] = useState({});

  const [controller, setController] = useState({
    page: 0,
    rowsPerPage: 10,
    preventRefresh: false,
  });

  const token = localStorage.getItem("access_token");

  // renderData(get data from api)
  const renderData = async () => {
    try {
      setLoading(true);
      await AxiosActions.ExpensesSummary({
        page: controller.page,
        limit: controller.rowsPerPage,
        type: filterDate,
        startDate: startDate,
        endDate: endDate,
        empid: empId,
        month: filterMonth,
        year: Number(filterYear),
        status: filterStatus,
      })
        .then((data) => {
          console.log("ExpensesSummary Data", data.data);
          if (data.status === 200) {
            setRenderState(data.data.data, data.data.count);
          }
          setLoading(false);
        })
        .catch((error) => {
          console.log("ExpensesSummary data error", error);
        });
    } catch (err) {
      console.log(err);
    }
  };

  //chart Data
  const renderChartData = async (filterYearChart) => {
    try {
      AxiosActions.annualSalarySummary({
        page: 0,
        limit: 10,
        empid: "",
        year: parseInt(filterYearChart),
        type: "all",
        month: "",
        category: "",
        status: "all",
      })
        .then((data) => {
          if (data.status === 200) {
            console.log("render chart data", data.data.data);
            if (data.data?.data?.count > 0) {
              setChartData(data.data?.data?.data);
            }
          }
        })
        .catch((error) => {
          console.log("Catch Err chart data ", error);
        });
    } catch (err) {
      console.log("Catch err in render chart data", err);
    }
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      await AxiosActions.ExpensesMonthSummary({
        page: controller.page,
        limit: controller.rowsPerPage,
        type: filterDate,
        startDate: startDate,
        endDate: endDate,
        empid: empId,
        month: filterMonth,
        year: Number(filterYear),
        status: filterStatus,
      })
        .then((data) => {
          console.log("ExpensesMonthSummary Data", data);
          if (data.status === 200) {
            console.log("===res==");
            // setRenderState(data.data.data, data.data.count);
          }
          setLoading(false);
        })
        .catch((error) => {
          console.log("ExpensesMonthSummary data error", error);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const setRenderState = (applicant, noOfRecords) => {
    setApplicants(applicant);
    setCount(noOfRecords);
  };

  const getEmployeeList = () => {
    AxiosActions.EmployeeList({})
      .then((response) => {
        console.log("getEmployeeList", response);
        if (response.status == 200) {
          setGetData(response.data.data);
        }
      })
      .catch((error) => {
        console.log("Error in get employee list", error);
      });
  };
  useEffect(() => {
    renderChartData(filterYearChart);
  }, []);

  useEffect(() => {
    getEmployeeList();
    fetchData();
    if (isFiltered === true) {
      if (!controller.preventRefresh) filterRecord();
    } else {
      renderData();
    }
  }, [controller]);

  // New Pagination
  const handlePageChange = (event, newPage) => {
    setController({
      ...controller,
      page: newPage,
      preventRefresh: false,
    });
  };

  const handleChangeRowsPerPage1 = (event) => {
    setController({
      ...controller,
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0,
      preventRefresh: false,
    });
  };

  // Filter record
  const filterRecord = async () => {
    trackEvent(EVENT_NAME.SALARY_SUMMARY_FILTER, {
      data: "Filter salary List in Summary",
    });
    let pageNumber = controller.page;

    if (!isFiltered) {
      pageNumber = 0;
      setIsFiltered(true);
      setController((prevController) => {
        return {
          ...prevController,
          page: 0,
          preventRefresh: true,
        };
      });
    }
    renderData();
  };

  const reset = () => {
    trackEvent(EVENT_NAME.SALARY_SUMMARY_RESET, {
      data: "Reset salary List in Summary",
    });
    setApplicants([]);

    setFilterStatus("all");
    setController({
      ...controller,
      page: 0,
      rowsPerPage: 10,
      preventRefresh: false,
    });

    setIsFiltered(false);
  };

  const filterChartRecord = async () => {
    renderChartData(filterYearChart);
  };

  const resetChartRecord = async () => {
    setFilterYearChart(moment().year());
    renderChartData(moment().year());
  };

  const empName = useMemo(() => {
    if (Object.keys(chartData).length === 0) {
      return [];
    } else {
      return chartData?.map((item) => item.employeeName);
    }
  }, [chartData]);

  const seriesChartData = useMemo(() => {
    if (Object.keys(chartData).length === 0) {
      return [];
    } else {
      return chartData?.map(({ employeeName, totalSalary }, index) => ({
        name: employeeName,
        data: chartData?.map(({ totalSalary }, i) => {
          if (i === index) {
            return [totalSalary];
          } else {
            return null;
          }
        }),
      }));
    }
  }, [chartData]);

  return (
    <>
      {token === null ? (
        true((window.location.href = "/"))
      ) : (
        <main id="content" role="main" className="main">
          <div className="content container-fluid">
            <div className="page-header">
              <div className="row align-items-center">
                <div
                  className="col align-items-center"
                  style={{ alignItems: "center" }}
                >
                  <h1
                    className="page-header-title align-items-center"
                    style={{ alignSelf: "center" }}
                  >
                    Salary summary
                  </h1>
                </div>
              </div>
            </div>
            <Filter
              year={true}
              yearValue={filterYearChart}
              yearUpdate={setFilterYearChart}
              filterRecord={filterChartRecord}
              resetRecord={resetChartRecord}
            />
            <div className="card card-bordered h-100">
              <div className="card-body">
                {seriesChartData?.length > 0 ? (
                  <BarChart
                    categories={empName}
                    series={seriesChartData}
                    title={EMPLOYEE_SALARY.CHART_TITLE}
                    yAxisTitle={EMPLOYEE_SALARY.Y_TITLE}
                    xAxisTitle={EMPLOYEE_SALARY.X_TITLE}
                    barWidth={30}
                  />
                ) : (
                  <Loader />
                )}
              </div>
            </div>
            <br></br>
            <>
              <div className="card mb-3 mb-lg-5">
                <div className="card-header">
                  <Filter
                    year={true}
                    yearValue={filterYear}
                    yearUpdate={setFilterYear}
                    month={true}
                    monthValue={filterMonth}
                    monthUpdate={setFilterMonth}
                    status={true}
                    statusValue={filterStatus}
                    statusUpdate={setFilterStatus}
                    statusList={Config?.SALARY_STATUS}
                    date={true}
                    dateValue={filterDate}
                    dateUpdate={setFilterDate}
                    startDateValue={startDate}
                    startDateUpdate={setStartDate}
                    endDateValue={endDate}
                    endDateUpdate={setEndDate}
                    name={true}
                    nameValue={empId}
                    nameUpdate={setEmpId}
                    employeeList={getData}
                    filterRecord={filterRecord}
                    resetRecord={reset}
                  />
                </div>
                <div className="table-responsive datatable-custom">
                  <table
                    id="datatable"
                    className="table table-borderless table-thead-bordered table-nowrap table-align-middle card-table"
                  >
                    <thead className="thead-light">
                      <tr>
                        <th>Employee Name</th>

                        {Config.SALARY_MONTH.map((item) => {
                          return <th>{item}</th>;
                        })}
                      </tr>
                    </thead>
                    <tbody>
                      {loading ? (
                        <>
                          <tr>
                            <td colSpan={8} style={{ textAlign: "center" }}>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <Loader />
                              </div>
                            </td>
                          </tr>
                        </>
                      ) : (
                        <>
                          {" "}
                          {applicants.length === 0 && (
                            <>
                              <tr>
                                <td
                                  colSpan={50}
                                  style={{ textAlign: "center" }}
                                >
                                  {" "}
                                  <h3>No record found</h3>
                                </td>
                              </tr>
                            </>
                          )}
                          {applicants.map((app, index) => {
                            return (
                              <tr key={"app" + index}>
                                <td>
                                  <h5>{app.employeeName}</h5>
                                </td>

                                {Config.SALARY_MONTH.map((month) => (
                                  <td key={month}>{app[month] || "-"}</td>
                                ))}

                                <></>
                              </tr>
                            );
                          })}
                        </>
                      )}
                    </tbody>
                  </table>
                </div>

                <div className="card-footer">
                  <Pagination
                    handlePageChange={handlePageChange}
                    controller={controller}
                    count={count}
                    handleChangeRowsPerPage1={handleChangeRowsPerPage1}
                  />
                </div>
              </div>
            </>
          </div>
          <div className="footer"></div>
        </main>
      )}
    </>
  );
}

export default HeaderNavbarHOC(Salary);
