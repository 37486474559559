import React from 'react';
import { Autocomplete, TextField } from "@mui/material";
import { EventDateTimePicker } from "../../Datepicker";
import config from "../../config/index";
import PropTypes from 'prop-types';

const EditEventModal = ({ formik, isDisable, changeStatus, updateId }) => (
  <div
    className="modal fade"
    id="exampleModalLong"
    tabIndex="-1"
    role="dialog"
    aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true"
  >
    <div className="modal-dialog modal-dialog-centered" role="document">
      <div className="modal-content">
        <div className="modal-header">
          <h2 className="modal-title" id="exampleModalLongTitle">
            Edit Event Details
          </h2>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div className="modal-body">
          <div className="modal-content">
            <table className="table-borderless table-sm">
              <tbody>
                <tr>
                  <td>
                    <h4>Activity Name</h4>
                    <input
                      type="text"
                      className="form-control"
                      name="activityName"
                      id="activityName"
                      placeholder="Activity Name"
                      onChange={formik.handleChange}
                      value={formik.values.activityName || ""}
                    />
                  </td>
                </tr>
                <br></br>
                <tr>
                  <td>
                    <h4>Date and Time</h4>{" "}
                    <EventDateTimePicker
                      onChange={(newDate, date) => {
                        formik.setFieldValue("dateAndTime", newDate);
                      }}
                      value={formik.values.dateAndTime}
                    />
                  </td>
                </tr>
                <br></br>
                <tr>
                  <td>
                    <h4>Duration</h4>
                    <input
                      type="text"
                      className="form-control"
                      name="duration"
                      id="duration"
                      placeholder="Activity Name"
                      onChange={formik.handleChange}
                      value={formik.values.duration || ""}
                    />
                  </td>
                </tr>
                <br></br>
                <tr>
                  <td colSpan={2}>
                    <h4>Description</h4>{" "}
                    <textarea
                      className="form-control"
                      placeholder="Description"
                      id="description"
                      rows="3"
                      name="description"
                      onChange={formik.handleChange}
                      value={formik.values.description}
                    ></textarea>
                  </td>
                </tr>{" "}
                <br></br>
                <tr>
                  <td>
                    <h4>Event Type</h4>
                    <Autocomplete
                      options={config.EVENTTYPE}
                      getOptionLabel={(option) => option.name}
                      onChange={(event, newValue) => {
                        if (newValue) {
                          formik.setFieldValue("eventType", newValue.value);
                        }
                      }}
                      value={
                        config.EVENTTYPE.find(
                          (eventType) =>
                            eventType.value === formik.values.eventType
                        ) || { name: "Select event Type" }
                      }
                      renderInput={(params) => (
                        <TextField {...params} variant="outlined" size="small" />
                      )}
                      fullWidth
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-white"
            data-bs-dismiss="modal"
          >
            Close
          </button>
          <button
            disabled={isDisable === true}
            type="button"
            className="btn btn-primary"
            data-bs-dismiss="modal"
            onClick={() => {
              changeStatus(updateId);
            }}
          >
            Save Changes
          </button>
        </div>
      </div>
    </div>
  </div>
);

export default EditEventModal;

EditEventModal.propTypes = {
  formik: PropTypes.object.isRequired,
  isDisable: PropTypes.bool.isRequired,
  changeStatus: PropTypes.func.isRequired,
  updateId: PropTypes.number.isRequired,
};