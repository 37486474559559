import React, { useState, useEffect, useMemo } from "react";
import moment from "moment";
import HeaderNavbarHOC from "../../HOC/HeaderNavbar";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { trackEvent, EVENT_NAME } from "../../constant/MixpanelConstant";
import * as AxiosActions from "../../Actions/axiosActions";
import { Pagination, Filter } from "../../components/Common";

function Client() {
  const [loading, setLoading] = useState(false);
  const [getclient, setgetcilent] = useState([]);
  const [startdate, setstartdate] = useState("");
  const [enddate, setenddate] = useState("");
  const [clientId, setempid] = useState("");
  const [getdata, setgetdata] = useState([]);
  const [applicants, setApplicants] = useState([]);
  const [isFiltered, setIsFiltered] = useState(false);
  const [filterStatus, setFilterStatus] = useState("all");
  const [count, setCount] = useState(0);
  const [filterArr, setFilterArr] = useState([]);
  const [totalproductivityhours, settotalproductivityhours] = useState();
  const [totalnonproductivityhours, settotalnonproductivityhours] = useState();
  const [controller, setController] = useState({
    page: 0,
    rowsPerPage: 10,
    preventRefresh: false,
  });

  const token = localStorage.getItem("access_token");

  // renderdata(getdata from api)

  const renderdata = async () => {
    try {
      setLoading(true);
      await AxiosActions.ClientSummary({
        page: controller.page,
        limit: controller.rowsPerPage,
        type: filterStatus,
        startDate: startdate,
        endDate: enddate,
        //empid: empid,
        clientId: clientId,
      })
        .then((data) => {
          console.log("Productivity Data", data.data);
          if (data.status === 200) {
            setRenderState(data.data.results, data.data.count);
          }
          setLoading(false);
        })
        .catch((error) => {
          console.log("Productivity data error", error);
        });
      await AxiosActions.ClientSummaryCount({
        type: filterStatus,
        startDate: startdate,
        endDate: enddate,
        clientId: clientId,
      }).then((data) => {
        console.log(data);
        if (data.status === 200) {
          settotalproductivityhours(
            data.data.totalProductivityHours.toFixed(3)
          );
          settotalnonproductivityhours(
            data.data.totalNonProductivityHours.toFixed(3)
          );
        }
      });
    } catch (err) {
      console.log(err);
    }
  };

  const setRenderState = (applicant, noOfRecords) => {
    setApplicants(applicant);
    setCount(noOfRecords);
  };

  const getClient = () => {
    AxiosActions.ProdutivityClientList({})
      .then((response) => {
        console.log("get Client", response.data.data);
        if (response.status == 200) {
          setgetcilent(response.data.data);
        }
      })
      .catch((error) => {
        console.log("Error in get Client", error);
      });
  };

  useEffect(() => {
    getClient();
    if (isFiltered === true) {
      if (!controller.preventRefresh) filterRecord();
    } else {
      renderdata();
    }
  }, [controller]);

  // New Pagination

  const handlePageChange = (event, newPage) => {
    setController({
      ...controller,
      page: newPage,
      preventRefresh: false,
    });
  };

  const handleChangeRowsPerPage1 = (event) => {
    setController({
      ...controller,
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0,
      preventRefresh: false,
    });
  };

  // Filter record

  const filterRecord = async () => {
    trackEvent(EVENT_NAME.CLIENT_SUMMARY_FILTER, {
      data: "Filter list of client summary",
    });
    let pageNumber = controller.page;

    if (!isFiltered) {
      pageNumber = 0;
      setIsFiltered(true);
      setController((prevController) => {
        return {
          ...prevController,
          page: 0,
          preventRefresh: true,
        };
      });
    }
    renderdata();
  };

  const reset = () => {
    trackEvent(EVENT_NAME.CLIENT_SUMMARY_RESET, {
      data: "Reset list of client summary",
    });
    setApplicants(filterArr);
    setempid("");
    setFilterStatus("all");
    setController({
      ...controller,
      page: 0,
      rowsPerPage: 10,
      preventRefresh: false,
    });

    setIsFiltered(false);
  };
  const applicantFilter = [...applicants].sort((a, b) =>
    a.date > b.date ? 1 : -1
  );

  const datefilter = useMemo(() => {
    const applicantDate = applicantFilter.map((ele) =>
      moment(ele.date).format("DD-MM-YYYY")
    );
    const applicantFilteredArr = [...new Set(applicantDate)];
    return applicantFilteredArr;
  }, [applicants]);
  const productivity = useMemo(() => {
    const series = [];
    console.log("series", series);
    applicantFilter.forEach((item) => {
      const existingSeries = series.find(
        (s) => s.name.toLowerCase() === item.clientName.toLowerCase()
      );
      const ProductivityFormatted =
        Math.round(parseFloat(item.productivityHours) * 100) / 100;
      if (existingSeries) {
        existingSeries.data.push(ProductivityFormatted);
      } else {
        const newSeries = {
          name: item.clientName,
          data: [],
        };
        newSeries.data.push(ProductivityFormatted);
        series.push(newSeries);
      }
    });

    return series;
  }, [applicants, datefilter]);

  const nonproductivity = useMemo(() => {
    const series = [];
    applicantFilter.forEach((item) => {
      const existingSeries = series.find(
        (s) => s.name.toLowerCase() === item.clientName.toLowerCase()
      );
      const nonProductivityFormatted =
        Math.round(parseFloat(item.nonProductivityHours) * 100) / 100;
      if (existingSeries) {
        existingSeries.data.push(nonProductivityFormatted);
      } else {
        const newSeries = {
          name: item.clientName,
          data: [],
        };
        newSeries.data.push(nonProductivityFormatted);
        series.push(newSeries);
      }
    });

    return series;
  }, [applicants, datefilter]);
  console.log("productivity", productivity);
  return (
    <>
      {token === null ? (
        true((window.location.href = "/"))
      ) : (
        <main id="content" role="main" className="main">
          <div className="content container-fluid">
            <div className="page-header">
              <div className="row align-items-center">
                <div
                  className="col align-items-center"
                  style={{ alignItems: "center" }}
                >
                  <h1
                    className="page-header-title align-items-center"
                    style={{ alignSelf: "center" }}
                  >
                    Client Summary
                  </h1>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6 col-lg-3 mb-3 mb-lg-5">
                {/* Card */}
                <a className="card card-hover-shadow h-100" href="#">
                  <div className="card-body">
                    <h3 className="card-subtitle">Total Productivity Hours</h3>
                    <div className="row align-items-center gx-2 mb-1">
                      <div className="col-6">
                        <h2 className="card-title text-inherit">
                          {totalproductivityhours}
                        </h2>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
              <div className="col-sm-6 col-lg-3 mb-3 mb-lg-5">
                {/* Card */}
                <a className="card card-hover-shadow h-100" href="#">
                  <div className="card-body">
                    <h3 className="card-subtitle">
                      Total Non Productivity Hours
                    </h3>
                    <div className="row align-items-center gx-2 mb-1">
                      <div className="col-6">
                        <h2 className="card-title text-inherit">
                          {totalnonproductivityhours}
                        </h2>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <>
              <div className="row mb-4">
                <div className="col-md-4 mb-4" style={{ width: 1000 }}>
                  <div className="card card-bordered h-100">
                    <div className="card-body">
                      {productivity.length > 0 && (
                        <HighchartsReact
                          highcharts={Highcharts}
                          options={{
                            title: {
                              text: "Productivity Hours",
                              align: "center",
                            },
                            yAxis: {
                              title: {
                                text: "Number of hours",
                              },
                            },

                            xAxis: {
                              categories: datefilter,
                              crosshair: true,
                              accessibility: {
                                rangeDescription: "Date",
                              },
                            },

                            legend: {
                              layout: "vertical",
                              align: "right",
                              verticalAlign: "middle",
                            },

                            plotOptions: {
                              series: {
                                dataLabels: {
                                  enabled: true,
                                },
                                label: {
                                  connectorAllowed: false,
                                },
                              },
                            },
                            series: productivity,
                            responsive: {
                              rules: [
                                {
                                  condition: {
                                    maxWidth: 500,
                                  },
                                  chartOptions: {
                                    legend: {
                                      layout: "horizontal",
                                      align: "center",
                                      verticalAlign: "bottom",
                                    },
                                  },
                                },
                              ],
                            },
                          }}
                        />
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-md-4 mb-4" style={{ width: 1000 }}>
                  <div className="card card-bordered h-100">
                    {loading ? (
                      <>
                        {" "}
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <div
                            className="spinner-border text-dark m-5"
                            role="status"
                          >
                            <span className="sr-only"></span>
                          </div>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                    <div className="card-body">
                      {nonproductivity.length > 0 && (
                        <HighchartsReact
                          highcharts={Highcharts}
                          options={{
                            title: {
                              text: "Non Productivity Hours",
                              align: "center",
                            },
                            yAxis: {
                              title: {
                                text: "Number of hours",
                              },
                            },

                            xAxis: {
                              categories: datefilter,
                              crosshair: true,
                              accessibility: {
                                rangeDescription: "Date",
                              },
                            },

                            legend: {
                              layout: "vertical",
                              align: "right",
                              verticalAlign: "middle",
                            },

                            plotOptions: {
                              series: {
                                dataLabels: {
                                  enabled: true,
                                },
                                label: {
                                  connectorAllowed: false,
                                },
                              },
                            },
                            series: nonproductivity,
                            responsive: {
                              rules: [
                                {
                                  condition: {
                                    maxWidth: 500,
                                  },
                                  chartOptions: {
                                    legend: {
                                      layout: "horizontal",
                                      align: "center",
                                      verticalAlign: "bottom",
                                    },
                                  },
                                },
                              ],
                            },
                          }}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="card mb-3 mb-lg-5">
                <div className="card-header">
                  <Filter
                    date={true}
                    dateValue={filterStatus}
                    dateUpdate={setFilterStatus}
                    startDateValue={startdate}
                    startDateUpdate={setstartdate}
                    endDateValue={enddate}
                    endDateUpdate={setenddate}
                    project={true}
                    projectValue={clientId}
                    projectUpdate={setempid}
                    projectList={getclient}
                    filterRecord={filterRecord}
                    resetRecord={reset}
                  />
                </div>
                <div className="table-responsive datatable-custom">
                  <table
                    id="datatable"
                    className="table table-borderless table-thead-bordered table-nowrap table-align-middle card-table"
                  >
                    <thead className="thead-light">
                      <tr>
                        <th>Date</th>
                        <th>Client Name</th>
                        <th>Productivity Hours</th>
                        <th>Non Productivity Hours</th>
                        <th>Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      {loading ? (
                        <>
                          <tr>
                            <td colSpan={8} style={{ textAlign: "center" }}>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <div
                                  className="spinner-border text-dark m-5"
                                  role="status"
                                >
                                  <span className="sr-only"></span>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </>
                      ) : (
                        <>
                          {" "}
                          {applicants.length === 0 && (
                            <>
                              <tr>
                                <td colSpan={5} style={{ textAlign: "center" }}>
                                  {" "}
                                  <h3>No record found</h3>
                                </td>
                              </tr>
                            </>
                          )}
                          {applicants.map((app, index) => {
                            return (
                              <tr key={"app" + index}>
                                <td style={{ width: "5%" }}>
                                  {moment(app.date).format("DD-MM-YYYY")}
                                </td>
                                <td>
                                  <h5> {app.clientName}</h5>
                                </td>

                                <td>{app.productivityHours.toFixed(3)}</td>
                                <td>{app.nonProductivityHours.toFixed(3)}</td>
                                <td>{app.total.toFixed(3)}</td>

                                <></>
                              </tr>
                            );
                          })}
                        </>
                      )}
                    </tbody>
                  </table>
                </div>

                <div className="card-footer">
                  <Pagination
                    handlePageChange={handlePageChange}
                    controller={controller}
                    count={count}
                    handleChangeRowsPerPage1={handleChangeRowsPerPage1}
                  />
                </div>
              </div>
            </>
          </div>
          <div className="footer"></div>
        </main>
      )}
    </>
  );
}

export default HeaderNavbarHOC(Client);
