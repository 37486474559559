import React, { useEffect } from "react";
import * as AxiosActions from "../../Actions/axiosActions";
import { trackEvent, EVENT_NAME } from "../../constant/MixpanelConstant";
import { useParams } from "react-router";

function Open() {
  const token = localStorage.getItem("access_token");
  console.log('token: ', token)
  const { id } = useParams();

  useEffect(() => {
    trackEvent(EVENT_NAME.APPLICANT_DOWNLOAD, { id: id });
    AxiosActions.openResume(id)
      .then((data) => {
        console.log("data: ", data);
        if (data.data.code === 200) {
          window.location.href = data?.data?.data?.url;
        }
      })
      .catch((err) => {
        console.log("Please open resume Again.");
      });
  }, []);

  return <>{token === null ? (window.location.href = "/") : ""}</>;
}

export default Open;
