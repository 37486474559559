import React, { useState, useEffect, useCallback } from "react";
import { toast } from "react-toastify";
import Config from "../../config";
import HeaderNavbarHOC from "../../HOC/HeaderNavbar";
import Useuser from "../../Contexct";
import AlertBox from "../../Alert";
import { trackEvent, EVENT_NAME } from "../../constant/MixpanelConstant";
import * as AxiosActions from "../../Actions/axiosActions";
import moment from "moment";
import { HeaderTitle, AlertDelete, Pagination, Filter } from "../../components/Common";
import View from "./View";
import Table from "./Table";
import Edit from "./Edit";
import { DATAUPDATESUCCESSFULLY } from "../../constant/Label";

function ReminderList() {
  const { adminData } = Useuser();
  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
  };
  const [datetime, setdatetime] = useState();
  const [dec, setdec] = useState();
  const [getdata, setgetdata] = useState([]);
  const getEmployeeList = () => {
    AxiosActions.EmployeeList({})
      .then((response) => {
        if (response.status === 200) {
          setgetdata(response.data.data);
        }
      })
      .catch((error) => {
        console.log("Error in get employee list", error);
      });
  };
  const [selectedValues, setSelectedValues] = useState([]);
  
  const handleSelectChange = (event, values) => {
    const value = values.map((item) => item._id);
    setSelectedValues(value);
  };

  const [startdate, setstartdate] = useState(moment().format('YYYY-MM-DD'));
  const [enddate, setenddate] = useState(moment().format('YYYY-MM-DD'));
  const [filterdate, setfilterdate] = useState("all");
  const [loading, setLoading] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [applicants, setApplicants] = useState([]);
  const [isFiltered, setIsFiltered] = useState(false);
  const [updateId, setUpdateId] = useState("");
  const [deleteId, setDeleteId] = useState("");
  const [fullReport, setFullReport] = useState([]);
  const [filterStatus, setFilterStatus] = useState("all");
  const [filterMonth, setFilterMonth] = useState("all");
  const [filterYear, setFilterYear] = useState("all");
  const [count, setCount] = useState(0);
  const [inputList, setInputList] = useState("");
  const [status, setstatus] = useState();
  const [name, setname] = useState("");
  const [isDisable, setIsDisable] = useState(false);

  const [selectedinfo, setSelectedInfo] = useState({
    selectedID: [],
  });
  const [controller, setController] = useState({
    page: 0,
    rowsPerPage: 10,
    preventRefresh: false,
  });

  // FOR SELECT ALL

  const isAllSelected =
    applicants.length > 0 &&
    selectedinfo.selectedID.length === applicants.length;
  const token = localStorage.getItem("access_token");

  // RENDER DATA

  const renderdata = useCallback(async () => {
    try {
      setLoading(true);
      AxiosActions.ReminderList({
        page: controller.page,
        limit: controller.rowsPerPage,
        startDate: startdate,
        endDate: enddate,
        type: filterdate,
        remainderName: inputList,
        status: filterStatus,
        year: Number(filterYear) ? Number(filterYear) : filterYear,
        month: filterMonth,
      })
        .then((data) => {
          if (data.status === 200) {
            setRenderState(data.data.results, data.data.count);
          }
          setLoading(false);
        })
        .catch((error) => {
          console.log("Catch Err in Pagination ", error);
        });
    } catch (err) {
      console.log("Catch err in render data", err);
    }
  }, [controller.page, controller.rowsPerPage, enddate, filterMonth, filterStatus, filterYear, filterdate, inputList, startdate]);

  const setRenderState = (applicant, noOfRecords) => {
    setApplicants(applicant);
    setCount(noOfRecords);
  };

  // Filter record
  const filterRecord = useCallback(async () => {
    trackEvent(EVENT_NAME.TRACKING_TOOLS_LIST_FILTER, { data: "" });
    if (!isFiltered) {
      setIsFiltered(true);
      setController((prevController) => {
        return {
          ...prevController,
          page: 0,
          preventRefresh: true,
        };
      });
    }
    renderdata();
    setSelectedInfo({ selectedID: [] });
  }, [isFiltered, renderdata]);

  useEffect(() => {
    getEmployeeList();
    if (isFiltered === true) {
      if (!controller.preventRefresh) filterRecord();
    } else {
      renderdata();
    }
  }, [controller, filterRecord, isFiltered, renderdata]);

  // New Pagination

  const handlePageChange = (event, newPage) => {
    setController({
      ...controller,
      page: newPage,
      preventRefresh: false,
    });
    setSelectedInfo({ selectedID: [] });
  };

  const handleChangeRowsPerPage1 = (event) => {
    setController({
      ...controller,
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0,
      preventRefresh: false,
    });

    setSelectedInfo({ selectedID: [] });
  };

  // Update status
  const changeStatus = (statusid, title) => {
    setIsDisable(true);
    trackEvent(EVENT_NAME.TRACKING_TOOLS_EDIT, { data: statusid });
    AxiosActions.EditReminder({
      id: statusid,
      empid: selectedValues,
      date: datetime,
      remainderName: name,
      description: dec,
      status: status,
    })
      .then((response) => {
        if (response.status === 200) {
          setOpenAlert(true);
          renderdata();
          setIsDisable(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsDisable(false);
        toast.dismiss();
        toast.error(err);
      });

    setSelectedInfo({ selectedID: [] });
  };

  //Reset Filter

  const reset = () => {
    trackEvent(EVENT_NAME.TRACKING_TOOLS_RESET, { data: "" });
    setApplicants([]);
    setInputList("");
    setFilterStatus("all");
    setFilterYear("all");
    setFilterMonth("all");
    setfilterdate("all");
    setController({
      ...controller,
      page: 0,
      rowsPerPage: 10,
      preventRefresh: false,
    });
    setSelectedInfo({ selectedID: [] });
    setIsFiltered(false);
  };

  const handleDeleteClick = (event, id) => {
    setDeleteId(id);

    event.preventDefault();
  };

  // Delete Record

  const deleteRecord = (event, id) => {
    setIsDisable(true);
    trackEvent(EVENT_NAME.TRACKING_TOOLS_DELETE, { data: id });
    AxiosActions.DeleteReminder({
      id: id,
      adminId: adminData._id,
    })
      .then((data) => {
        renderdata();
        setIsDisable(false);
      })
      .catch((err) => {
        console.log(err);
        setIsDisable(false);
        alert("Please Delete Record Again.");
      });

    event.preventDefault();
  };

  //View Full Report

  const handleViewReportClick = (event, id) => {
    trackEvent(EVENT_NAME.TRACKING_TOOLS_VIEW, { data: id });
    const filterRecord = applicants.filter((item) => {
      if (id === item._id) {
        const filteredIds = item.empid
          .filter((item) => item._id)
          .map((item) => item._id);

        setSelectedValues(filteredIds);
        setname(item.remainderName);
        setdec(item.description);
        setdatetime(moment.utc(item.date).format("YYYY-MM-DD HH:MM"));
        setstatus(item.status);
        return true;
      }
      return false;
    });

    setFullReport(filterRecord);
    setUpdateId(id);
  };

  //Select Multiple

  const handleCheckBoxChange = (e) => {
    const value = e.target.value;
    const { checked } = e.target;
    const list = [];
    const { selectedID } = selectedinfo;

    if (value === "all") {
      if (value === "all" && checked) {
        applicants.map((item) => {
          return list.push(item._id);
        });
        setSelectedInfo({
          selectedID: list,
        });
      } else {
        setSelectedInfo({
          selectedID: list.filter((e) => e !== value),
        });
      }
      return;
    } else if (checked) {
      setSelectedInfo({
        selectedID: [...selectedID, value],
      });
    } else {
      setSelectedInfo({
        selectedID: selectedID.filter((e) => e !== value),
      });
    }
  };

  //Delete Multiple

  const handleDeleteMultipleClick = (event) => {
    event.preventDefault();
  };
  const deleteAllRecord = (event) => {
    setIsDisable(true);
    trackEvent(EVENT_NAME.TRACKING_TOOLS_DELETE_ALL, { data: "" });
    AxiosActions.MultipleDeleteReminder({
      ids: selectedinfo.selectedID,
      adminid: adminData._id,
    })
      .then((data) => {
        renderdata();
        setIsDisable(false);
      })
      .catch((err) => {
        alert("Please Delete Record Again");
        setIsDisable(false);
      });

    setSelectedInfo({ selectedID: [] });
    event.preventDefault();
  };

  return (
    <>
      <AlertBox
        open={openAlert}
        onClose={handleCloseAlert}
        message={DATAUPDATESUCCESSFULLY}
        title="Success"
        ok={false}
      />
      {token === null ? (
        true((window.location.href = "/"))
      ) : (
        <main id="content" role="main" className="main">
          <div className="content container-fluid">
            <HeaderTitle title="Reminder" />

            <div className="card mb-3 mb-lg-5">
              <div className="card-header">
                <Filter
                  year={true}
                  yearValue={filterYear}
                  yearUpdate={setFilterYear}
                  month={true}
                  monthValue={filterMonth}
                  monthUpdate={setFilterMonth}
                  searchName={true}
                  searchNameValue={inputList}
                  searchNameUpdate={setInputList}
                  status={true}
                  statusValue={filterStatus}
                  statusUpdate={setFilterStatus}
                  statusList={Config?.REMINDER_STATUS}
                  date={true}
                  dateValue={filterdate}
                  dateUpdate={setfilterdate}
                  startDateValue={startdate}
                  startDateUpdate={setstartdate}
                  endDateValue={enddate}
                  endDateUpdate={setenddate}
                  multipleSelect={selectedinfo?.selectedID?.length}
                  multipleDelete={true}
                  isDisable={isDisable}
                  handleDelete={handleDeleteMultipleClick}
                  deleteAllRecord={deleteAllRecord}
                  filterRecord={filterRecord}
                  resetRecord={reset}
                />
              </div>
              <div className="table-responsive datatable-custom">
                <Table
                  handleCheckBoxChange={handleCheckBoxChange}
                  isAllSelected={isAllSelected}
                  loading={loading}
                  applicants={applicants}
                  selectedinfo={selectedinfo}
                  handleViewReportClick={handleViewReportClick}
                  handleDeleteClick={handleDeleteClick}
                />
              </div>
              <Edit
                fullReport={fullReport}
                setname={setname}
                selectedValues={selectedValues}
                handleSelectChange={handleSelectChange}
                getdata={getdata}
                setdatetime={setdatetime}
                datetime={datetime}
                name={name}
                status={status}
                setdec={setdec}
                dec={dec}
                setstatus={setstatus}
                isDisable={isDisable}
                changeStatus={changeStatus}
                updateId={updateId}
              />
              <AlertDelete
                isDisable={isDisable}
                deleteRecord={deleteRecord}
                deleteId={deleteId}
              />
              <View fullReport={fullReport} />
              <div className="card-footer">
                <Pagination
                  handlePageChange={handlePageChange}
                  controller={controller}
                  count={count}
                  handleChangeRowsPerPage1={handleChangeRowsPerPage1}
                />
              </div>
            </div>
          </div>
          <div className="footer"></div>
        </main>
      )}
    </>
  );
}

export default HeaderNavbarHOC(ReminderList);
