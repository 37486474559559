import React, { useContext, useEffect, useState } from 'react'
import { permissions } from '../../../permissions';
import Config from '../../../config';
import { AuthContext } from '../../../Login/Protected';

const ButtonGroup = ({ handleViewReportClick, handleDeleteClick, appId ,feature}) => {
    
    const adminData = useContext(AuthContext);
    const [userRole, setUserRole] = useState("");
    const actions = Config.ACTION;
  
    useEffect(() => {
      setUserRole(adminData.adminData.role);
    }, [adminData]);
  
    const hasPermission = (feature, action) => {
        return permissions[userRole]?.[feature]?.actions[action] || false;
    };
  
    return (
      <div>
          {(adminData.adminData.isAdmin && (hasPermission(feature, actions.edit)) || hasPermission(feature, actions.delete)) ? (
                      <td>
                     {hasPermission(feature, actions.edit) && handleViewReportClick && (
                        <button
                          title="Edit attendance"
                          type="button"
                          className="icon icon-danger icon-circle"
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModalLong"
                          onClick={(event) =>
                            handleViewReportClick(event, appId)
                          }
                        >
                          <i className="bi-pen"></i>
                        </button>
                     )}
                        &nbsp;
                        {hasPermission(feature, actions.delete) && handleDeleteClick && (
                        <button
                          title="Delete attendance"
                          type="button"
                          className="icon icon-info icon-circle"
                          data-bs-toggle="modal"
                          data-bs-target="#delete"
                          onClick={(event) => handleDeleteClick(event, appId)}
                        >
                          <i className="bi-trash"></i>
                        </button>
                        )}
                      </td>
                    ) : (
                      ""
                    )}
      </div>
    )
}

export default ButtonGroup;

export const useHasPermission = (userRole,feature, action) => {
    return permissions[userRole]?.[feature]?.actions[action] || false;
};
