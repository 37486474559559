import React from "react";
import moment from "moment";

function View({ fullReport, getClientStatus }) {
  return (
    <div
      className="modal fade bd-example-modal-xl"
      id="viewpage"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title" id="exampleModalLongTitle">
              View Salary
            </h1>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            {fullReport.map((item, index) => {
              return (
                <div key={index}>
                  <h3 style={{ color: "red" }}>Salary information</h3>
                  <table className="table table-align-middle">
                    <thead>
                      <tr>
                        <th scope="col"></th>
                        <th scope="col"></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <h4>Employee Name</h4>
                          {item.empid.employeeName}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <h4>Month</h4>
                          {item.month}
                        </td>
                        <td>
                          <h4>Year</h4>
                          {item.year}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <h4>Total Days</h4>
                          {item.totalDays}
                        </td>
                        <td>
                          <h4>Days(30)</h4>
                          {item.days}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <h4>Basic/DA</h4>
                          {item.basicSalary}
                        </td>
                        <td>
                          <h4>HRA</h4>
                          {item.hra}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <h4>Conveyance</h4>
                          {item.conveyanceAllowance}
                        </td>
                        <td>
                          <h4>Total Addition</h4>
                          {item.totalAddition}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <h4>P.F</h4>
                          {item.pf}
                        </td>
                        <td>
                          <h4>E.S.I</h4>
                          {item.employees_State_Insurance}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <h4>Profession Tax</h4>
                          {item.professionTax}
                        </td>
                        <td>
                          <h4>Total Deduction</h4>
                          {item.totalDeduction}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <h4>Net Amount</h4>
                          {item.netAmount}
                        </td>
                        <td>
                          <h4>Amount in words</h4>
                          {item.salary_Amt_In_Words}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <h4>Name of Bank</h4>
                          {item.bank_Name}
                        </td>
                        <td>
                          <h4>Cheque/DD no</h4>
                          {item.cheque_No}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <h4>Date</h4>
                          {moment(item.date).format("DD-MM-YYYY")}
                        </td>
                        <td>
                          <h4>Mode</h4>
                          {item.mode}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <h4>Status</h4>
                          {getClientStatus(item.status, "salary")}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              );
            })}
          </div>
          <div className="modal-footer"></div>
        </div>
      </div>
    </div>
  );
}

export default View;
