import React, { useCallback, useEffect, useState } from "react";
import { IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { EVENT_NAME, trackNavigate } from "../constant/MixpanelConstant";
import Useuser from "../Contexct";
import UseFullPath from "../constant/GetPath";
import { ROUTES } from "../constant/Route";
import { permissions } from "../permissions";
import Config from "../config";
import './navbar.css'

const actions = Config.ACTION;

function Navbar({ isSidebarVisible, setIsSidebarVisible }) {
  const { adminData } = Useuser();
  const [userRole, setUserRole] = useState("");

  useEffect(() => {
    setUserRole(adminData.role);
  }, [adminData]);
  const fullPath = UseFullPath();

  /*
   * @desc Adds style for active tab based on URL list
   * @param {Array} page - url list.
   * @return {Object}
   */
  const activeTab = (urls) => {
    let style = { color: "#132144" };
    if (fullPath.pathname && urls.length > 0) {
      urls?.forEach((path) => {
        if (fullPath.pathname === path) {
          style = { ...style, backgroundColor: "#333399",color: '#ffffff'};
        }
      });
    }
    return style;
  };

  const hasPermission = (feature, action) => {
    return permissions[userRole]?.[feature]?.actions[action] || false;
  };

  // Function to handle screen resize
  const handleResize = useCallback(() => {
    if (window.innerWidth > 1024) {
      setIsSidebarVisible(false);
    }
  }, [setIsSidebarVisible]);

  useEffect(() => {
    // Listen to window resize events
    window.addEventListener('resize', handleResize);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize]);

  return (
    <aside className={`${isSidebarVisible ? 'position-md-fixed zi-2 w-25' : 'navbar-vertical-fixed'} js-navbar-vertical-aside navbar navbar-vertical-aside navbar-vertical navbar-expand-xxl navbar-bordered bg-white `}
      style={isSidebarVisible ? { top: '60px' } : {} }
    >
      <div className="navbar-vertical-container">
        <div className="navbar-vertical-footer-offset">
          {/* Logo */}
          <div className="d-flex justify-content-between align-items-center">
            <a
              className="navbar-brand justify-content-between"
              href={ROUTES.DASHBOARD}
              aria-label="Front"
            >
              <img
                className=""
                height={45}
                width={45}
                src="https://admin.3elixir.com/assets/logo/elitist-logo.jpg"
                alt="Logo"
                data-hs-theme-appearance="default"
              />
            </a>
            {isSidebarVisible && (
              <IconButton
                className="border me-0"
                edge="end"
                color="black"
                aria-label="close-sidebar"
                onClick={() => setIsSidebarVisible(false)}
              >
                <CloseIcon />
              </IconButton>
            )}
          </div>
          {/* End Logo */}
          {/* Navbar Vertical Toggle */}
          <button
            type="button"
            className="js-navbar-vertical-aside-toggle-invoker navbar-aside-toggler"
          >
            <i
              className="bi-arrow-bar-left navbar-toggler-short-align"
              data-bs-template='<div class="tooltip d-none d-md-block" role="tooltip"><div class="arrow"></div><div class="tooltip-inner"></div></div>'
              data-bs-toggle="tooltip"
              data-bs-placement="right"
              title="Collapse"
            />
            <i
              className="bi-arrow-bar-right navbar-toggler-full-align"
              data-bs-template='<div class="tooltip d-none d-md-block" role="tooltip"><div class="arrow"></div><div class="tooltip-inner"></div></div>'
              data-bs-toggle="tooltip"
              data-bs-placement="right"
              title="Expand"
            />
          </button>
          <div className="navbar-vertical-content">
            <div
              id="navbarVerticalMenu"
              className="nav nav-pills nav-vertical card-navbar-nav"
            >
              {hasPermission("dashboard", actions.view) && (
                <div className="nav-item">
                  <a
                    className="nav-link "
                    href={ROUTES.DASHBOARD}
                    data-placement="left"
                    style={activeTab([ROUTES.DASHBOARD])}
                  >
                    <i className="bi-house-door-fill nav-icon" />
                    <span className="nav-link-title">Dashboard</span>
                  </a>
                </div>
              )}

              {hasPermission("email", actions.view) && (
                <div className="nav-item">
                  <a
                    className="nav-link dropdown-toggle "
                    href="#navbarVerticalMenuPagesProjectMenu"
                    role="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#emailmarketing"
                    aria-expanded="false"
                    aria-controls="navbarVerticalMenuPagesProjectMenu"
                    style={activeTab([
                      ROUTES.EMAIL.CSV,
                      ROUTES.EMAIL.LIST,
                      ROUTES.EMAIL.NOTE,
                    ])}
                  >
                    <i className="bi bi-briefcase-fill nav-icon" />
                    <span className="nav-link-title">Email</span>
                  </a>
                  <div
                    id="emailmarketing"
                    className="nav-collapse collapse "
                    data-bs-parent="#navbarVerticalMenuPagesMenu"
                  >
                    <a className="nav-link " href={ROUTES.EMAIL.LIST}>
                      List
                    </a>
                    {hasPermission("email", actions.csv) && (
                      <a className="nav-link " href={ROUTES.EMAIL.CSV}>
                        CSV Import
                      </a>
                    )}
                  </div>
                </div>
              )}
              {hasPermission("whatsapp", actions.view) && (
                <div className="nav-item">
                  <a
                    className="nav-link dropdown-toggle "
                    href="#navbarVerticalMenuPagesProjectMenu"
                    role="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#whatsappmarketing"
                    aria-expanded="false"
                    aria-controls="navbarVerticalMenuPagesProjectMenu"
                    style={activeTab([
                      ROUTES.WHATSAPP.CSV,
                      ROUTES.WHATSAPP.ADD,
                      ROUTES.WHATSAPP.LIST,
                    ])}
                  >
                    <i className="bi bi-briefcase-fill nav-icon" />
                    <span className="nav-link-title">Whatsapp</span>
                  </a>
                  <div
                    id="whatsappmarketing"
                    className="nav-collapse collapse "
                    data-bs-parent="#navbarVerticalMenuPagesMenu"
                  >
                    {hasPermission("whatsapp", actions.add) && (
                      <a className="nav-link " href={ROUTES.WHATSAPP.ADD}>
                        Add
                      </a>
                    )}
                    <a className="nav-link " href={ROUTES.WHATSAPP.LIST}>
                      List
                    </a>
                    {hasPermission("whatsapp", actions.csv) && (
                      <a className="nav-link " href={ROUTES.WHATSAPP.CSV}>
                        CSV Import
                      </a>
                    )}
                  </div>
                </div>
              )}

              {hasPermission("applicant", actions.view) && (
                <div className="nav-item">
                  <a
                    className="nav-link dropdown-toggle "
                    href="#navbarVerticalMenuPagesUsersMenu"
                    role="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarVerticalMenuPagesUsersMenu"
                    aria-expanded="false"
                    aria-controls="navbarVerticalMenuPagesUsersMenu"
                    style={activeTab([
                      ROUTES.APPLICANT.ADD,
                      ROUTES.APPLICANT.LIST,
                      ROUTES.APPLICANT.IMPORT,
                    ])}
                  >
                    <i className="bi bi-person-lines-fill nav-icon" />
                    <span className="nav-link-title">Applicants</span>
                  </a>
                  <div
                    id="navbarVerticalMenuPagesUsersMenu"
                    className="nav-collapse collapse "
                    data-bs-parent="#navbarVerticalMenuPagesMenu"
                  >
                    {hasPermission("applicant", actions.add) && (
                      <>
                        <a className="nav-link " href={ROUTES.APPLICANT.ADD}>
                          Add
                        </a>
                        <a
                          className="nav-link "
                          href={ROUTES.APPLICANT.IMPORT}
                        >
                          Import
                        </a>
                      </>
                    )}
                    <a className="nav-link " href={ROUTES.APPLICANT.LIST}>
                      List
                    </a>
                  </div>
                </div>
              )}

              {hasPermission("employee", actions.view) && (
                <div className="nav-item">
                  <a
                    className="nav-link dropdown-toggle "
                    href="#navbarVerticalMenuPagesProjectsMenu"
                    role="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#employee"
                    aria-expanded="false"
                    aria-controls="navbarVerticalMenuPagesProjectsMenu"
                    style={activeTab([
                      ROUTES.EMPLOYEE.ADD,
                      ROUTES.EMPLOYEE.LIST,
                    ])}
                  >
                    <i className="bi bi-person-fill nav-icon" />
                    <span className="nav-link-title">Employee</span>
                  </a>
                  <div
                    id="employee"
                    className="nav-collapse collapse "
                    data-bs-parent="#navbarVerticalMenuPagesMenu"
                  >
                    {hasPermission("employee", actions.add) && (
                      <a className="nav-link " href={ROUTES.EMPLOYEE.ADD}>
                        Add
                      </a>
                    )}
                    <a className="nav-link " href={ROUTES.EMPLOYEE.LIST}>
                      List
                    </a>
                  </div>
                </div>
              )}

              {hasPermission("user", actions.view) && (
                <div className="nav-item">
                  <a
                    className="nav-link dropdown-toggle "
                    href="#navbarVerticalMenuPagesProjectsMenu"
                    role="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#user"
                    aria-expanded="false"
                    aria-controls="navbarVerticalMenuPagesProjectsMenu"
                    style={activeTab([ROUTES.USER.ADD, ROUTES.USER.LIST])}
                  >
                    <i className="bi bi-person-fill nav-icon" />
                    <span className="nav-link-title">Users</span>
                  </a>
                  <div
                    id="user"
                    className="nav-collapse collapse "
                    data-bs-parent="#navbarVerticalMenuPagesMenu"
                  >
                    {hasPermission("user", actions.add) && (
                      <a
                        id="add-user"
                        className="nav-link "
                        href={ROUTES.USER.ADD}
                        onClick={() => {
                          console.log("add user link");
                          trackNavigate("#add-user", EVENT_NAME.ADD_USER, "");
                        }}
                      >
                        Add
                      </a>
                    )}
                    <a
                      id="add-user"
                      className="nav-link "
                      href={ROUTES.USER.LIST}
                      onClick={() => {
                        console.log("list user link");
                        trackNavigate("#list-user", EVENT_NAME.LIST_USER, "");
                      }}
                    >
                      List
                    </a>
                  </div>
                </div>
              )}

              {hasPermission("attendance", actions.view) && (
                <div className="nav-item">
                  <a
                    className="nav-link dropdown-toggle "
                    href="#navbarVerticalMenuPagesUsersMenu"
                    role="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#attendance"
                    aria-expanded="false"
                    aria-controls="navbarVerticalMenuPagesUsersMenu"
                    style={activeTab([
                      ROUTES.ATTENDANCE.ADD,
                      ROUTES.ATTENDANCE.LIST,
                      ROUTES.ATTENDANCE.SHEET,
                    ])}
                  >
                    <i className="bi bi-calendar2-check-fill nav-icon" />
                    <span className="nav-link-title">Attendance</span>
                  </a>
                  <div
                    id="attendance"
                    className="nav-collapse collapse "
                    data-bs-parent="#navbarVerticalMenuPagesMenu"
                  >
                    {hasPermission("attendance", actions.add) && (
                      <a className="nav-link " href={ROUTES.ATTENDANCE.ADD}>
                        Add
                      </a>
                    )}
                    <a className="nav-link " href={ROUTES.ATTENDANCE.LIST}>
                      List
                    </a>
                    <a className="nav-link " href={ROUTES.ATTENDANCE.SHEET}>
                      Attendence Sheet
                    </a>
                  </div>
                </div>
              )}
              {hasPermission("client", actions.view) && (
                <div className="nav-item">
                  <a
                    className="nav-link dropdown-toggle "
                    href="#navbarVerticalMenuPagesProjectsMenu"
                    role="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#client"
                    aria-expanded="false"
                    aria-controls="navbarVerticalMenuPagesProjectsMenu"
                    style={activeTab([ROUTES.CLIENT.ADD, ROUTES.CLIENT.LIST])}
                  >
                    <i className="bi bi-collection-fill nav-icon" />
                    <span className="nav-link-title">Client</span>
                  </a>
                  <div
                    id="client"
                    className="nav-collapse collapse "
                    data-bs-parent="#navbarVerticalMenuPagesMenu"
                  >
                    {hasPermission("client", actions.view) && (
                      <a
                        id="add-user"
                        className="nav-link "
                        href={ROUTES.CLIENT.ADD}
                        onClick={() => {
                          console.log("add user link");
                          trackNavigate(
                            "#add-client",
                            EVENT_NAME.ADD_USER,
                            ""
                          );
                        }}
                      >
                        Add
                      </a>
                    )}
                    <a
                      id="add-user"
                      className="nav-link "
                      href={ROUTES.CLIENT.LIST}
                      onClick={() => {
                        console.log("list user link");
                        trackNavigate(
                          "#list-client",
                          EVENT_NAME.LIST_USER,
                          ""
                        );
                      }}
                    >
                      List
                    </a>
                  </div>
                </div>
              )}

              {hasPermission("trackingtools", actions.view) && (
                <div className="nav-item">
                  <a
                    className="nav-link dropdown-toggle "
                    href="#navbarVerticalMenuPagesProjectsMenu"
                    role="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#takingtools"
                    aria-expanded="false"
                    aria-controls="navbarVerticalMenuPagesProjectsMenu"
                    style={activeTab([
                      ROUTES.TRACKING_TOOLS.ADD,
                      ROUTES.TRACKING_TOOLS.LIST,
                    ])}
                  >
                    <i className="bi bi-compass-fill nav-icon" />
                    <span className="nav-link-title">Tracking Tools</span>
                  </a>
                  <div
                    id="takingtools"
                    className="nav-collapse collapse "
                    data-bs-parent="#navbarVerticalMenuPagesMenu"
                  >
                    {hasPermission("trackingtools", actions.add) && (
                      <a
                        className="nav-link "
                        href={ROUTES.TRACKING_TOOLS.ADD}
                      >
                        Add
                      </a>
                    )}
                    <a
                      className="nav-link "
                      href={ROUTES.TRACKING_TOOLS.LIST}
                    >
                      List
                    </a>
                  </div>
                </div>
              )}
              {hasPermission("leaves", actions.view) && (
                <div className="nav-item">
                  <a
                    className="nav-link dropdown-toggle "
                    href="#navbarVerticalMenuPagesUsersMenu"
                    role="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#leaves"
                    aria-expanded="false"
                    aria-controls="navbarVerticalMenuPagesUsersMenu"
                    style={activeTab([ROUTES.LEAVES.ADD, ROUTES.LEAVES.LIST])}
                  >
                    <i className="bi bi-calendar-event-fill nav-icon" />
                    <span className="nav-link-title">Leaves</span>
                  </a>
                  <div
                    id="leaves"
                    className="nav-collapse collapse "
                    data-bs-parent="#navbarVerticalMenuPagesMenu"
                  >
                    {hasPermission("leaves", actions.add) && (
                      <a className="nav-link " href={ROUTES.LEAVES.ADD}>
                        Add
                      </a>
                    )}
                    <a className="nav-link " href={ROUTES.LEAVES.LIST}>
                      List
                    </a>
                  </div>
                </div>
              )}
              {hasPermission("salary", actions.view) && (
                <div className="nav-item">
                  <a
                    className="nav-link dropdown-toggle "
                    href="#navbarVerticalMenuPagesUsersMenu"
                    role="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#salary"
                    aria-expanded="false"
                    aria-controls="navbarVerticalMenuPagesUsersMenu"
                    style={activeTab([ROUTES.SALARY.ADD, ROUTES.SALARY.LIST])}
                  >
                    <i className="bi bi-credit-card-fill nav-icon" />
                    <span className="nav-link-title">Salary</span>
                  </a>
                  <div
                    id="salary"
                    className="nav-collapse collapse "
                    data-bs-parent="#navbarVerticalMenuPagesMenu"
                  >
                    {hasPermission("salary", actions.add) && (
                      <a className="nav-link " href={ROUTES.SALARY.ADD}>
                        Add
                      </a>
                    )}
                    <a className="nav-link " href={ROUTES.SALARY.LIST}>
                      List
                    </a>
                  </div>
                </div>
              )}

              {hasPermission("expenses", actions.view) && (
                <div className="nav-item">
                  <a
                    className="nav-link dropdown-toggle "
                    href="#navbarVerticalMenuPagesUsersMenu"
                    role="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#expenses"
                    aria-expanded="false"
                    aria-controls="navbarVerticalMenuPagesUsersMenu"
                    style={activeTab([
                      ROUTES.EXPENSES.ADD,
                      ROUTES.EXPENSES.LIST,
                    ])}
                  >
                    <i className="bi bi-briefcase-fill nav-icon" />
                    <span className="nav-link-title">Expenses</span>
                  </a>
                  <div
                    id="expenses"
                    className="nav-collapse collapse "
                    data-bs-parent="#navbarVerticalMenuPagesMenu"
                  >
                    {hasPermission("expenses", actions.add) && (
                      <a className="nav-link " href={ROUTES.EXPENSES.ADD}>
                        Add
                      </a>
                    )}
                    <a className="nav-link " href={ROUTES.EXPENSES.LIST}>
                      List
                    </a>
                  </div>
                </div>
              )}
              {hasPermission("reminder", actions.view) && (
                <div className="nav-item">
                  <a
                    className="nav-link dropdown-toggle "
                    href="#navbarVerticalMenuPagesProjectMenu"
                    role="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#Reminder"
                    aria-expanded="false"
                    aria-controls="navbarVerticalMenuPagesProjectMenu"
                    style={activeTab([
                      ROUTES.REMINDER.ADD,
                      ROUTES.REMINDER.LIST,
                      ROUTES.REMINDER.SHEET,
                    ])}
                  >
                    <i className="bi bi-alarm-fill nav-icon" />
                    <span className="nav-link-title">Reminder</span>
                  </a>
                  <div
                    id="Reminder"
                    className="nav-collapse collapse "
                    data-bs-parent="#navbarVerticalMenuPagesMenu"
                  >
                    {hasPermission("reminder", actions.add) && (
                      <a className="nav-link " href={ROUTES.REMINDER.ADD}>
                        Add
                      </a>
                    )}
                    <a className="nav-link " href={ROUTES.REMINDER.LIST}>
                      List
                    </a>
                    <a className="nav-link " href={ROUTES.REMINDER.SHEET}>
                      Sheet
                    </a>
                  </div>
                </div>
              )}
              {hasPermission("history", actions.view) && (
                <div className="nav-item">
                  <a
                    className="nav-link "
                    href={ROUTES.HISTORY.LIST}
                    data-placement="left"
                    style={activeTab([ROUTES.HISTORY.LIST])}
                  >
                    <i className="bi bi-clock-fill nav-icon" />
                    <span className="nav-link-title">History</span>
                  </a>
                </div>
              )}

              {hasPermission("contacts", actions.view) && (
                <div className="nav-item">
                  <a
                    className="nav-link "
                    href={ROUTES.CONTACTS}
                    data-placement="left"
                    style={activeTab([ROUTES.CONTACTS])}
                  >
                    <i className="bi-stickies-fill nav-icon" />
                    <span className="nav-link-title">Contacts</span>
                  </a>
                </div>
              )}
              {hasPermission("quotes", actions.view) && (
                <div className="nav-item">
                  <a
                    className="nav-link "
                    href={ROUTES.QUOTES}
                    data-placement="left"
                    style={activeTab([ROUTES.QUOTES])}
                  >
                    <i className="bi bi-chat-right-quote-fill nav-icon" />
                    <span className="nav-link-title">Quotes </span>
                  </a>
                </div>
              )}
              {hasPermission("tasks", actions.view) && (
                <div className="nav-item">
                  <a
                    className="nav-link dropdown-toggle "
                    href="#navbarVerticalMenuPagesUsersMenu"
                    role="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#task"
                    aria-expanded="false"
                    aria-controls="navbarVerticalMenuPagesUsersMenu"
                    style={activeTab([ROUTES.TASKS.ADD, ROUTES.TASKS.LIST])}
                  >
                    <i className="bi bi-clipboard2-plus-fill nav-icon" />
                    <span className="nav-link-title">Task</span>
                  </a>
                  <div
                    id="task"
                    className="nav-collapse collapse "
                    data-bs-parent="#navbarVerticalMenuPagesMenu"
                  >
                    {hasPermission("tasks", actions.add) && (
                      <a className="nav-link " href={ROUTES.TASKS.ADD}>
                        Add
                      </a>
                    )}
                    <a className="nav-link " href={ROUTES.TASKS.LIST}>
                      List
                    </a>
                  </div>
                </div>
              )}
              {hasPermission("track", actions.view) && (
                <div className="nav-item">
                  <a
                    className="nav-link dropdown-toggle "
                    href="#navbarVerticalMenuPagesProjectsMenu"
                    role="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#Tracking"
                    aria-expanded="false"
                    aria-controls="navbarVerticalMenuPagesProjectsMenu"
                    style={activeTab([
                      ROUTES.TRACK.ADD,
                      ROUTES.TRACK.DAILY,
                      ROUTES.TRACK.SHEET,
                    ])}
                  >
                    <i className="bi bi-stopwatch-fill nav-icon" />
                    <span className="nav-link-title">Tracking</span>
                  </a>
                  <div
                    id="Tracking"
                    className="nav-collapse collapse "
                    data-bs-parent="#navbarVerticalMenuPagesMenu"
                  >
                    {hasPermission("track", actions.add) && (
                      <a className="nav-link " href={ROUTES.TRACK.ADD}>
                        Add
                      </a>
                    )}
                    <a className="nav-link " href={ROUTES.TRACK.DAILY}>
                      Daily
                    </a>
                    <a className="nav-link " href={ROUTES.TRACK.SHEET}>
                      Time Sheet
                    </a>
                  </div>
                </div>
              )}
              {hasPermission("productivity", actions.view) && (
                <div className="nav-item">
                  <a
                    className="nav-link dropdown-toggle "
                    href="#navbarVerticalMenuPagesProjectsMenu"
                    role="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarVerticalMenuPagesProjectsMenu"
                    aria-expanded="false"
                    aria-controls="navbarVerticalMenuPagesProjectsMenu"
                    style={activeTab([
                      ROUTES.PRODUCTIVITY.ADD,
                      ROUTES.PRODUCTIVITY.LIST,
                    ])}
                  >
                    <i className="bi bi-bar-chart-fill nav-icon" />
                    <span className="nav-link-title">Productivity</span>
                  </a>
                  <div
                    id="navbarVerticalMenuPagesProjectsMenu"
                    className="nav-collapse collapse "
                    data-bs-parent="#navbarVerticalMenuPagesMenu"
                  >
                    {hasPermission("productivity", actions.add) && (
                      <a className="nav-link " href={ROUTES.PRODUCTIVITY.ADD}>
                        Add
                      </a>
                    )}

                    <a className="nav-link " href={ROUTES.PRODUCTIVITY.LIST}>
                      List
                    </a>
                  </div>
                </div>
              )}

              {hasPermission("summary", actions.view) && (
                <div className="nav-item">
                  <a
                    className="nav-link dropdown-toggle "
                    href="#navbarVerticalMenuPagesProjectsMenu"
                    role="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#summary"
                    aria-expanded="false"
                    aria-controls="navbarVerticalMenuPagesProjectsMenu"
                    style={activeTab([
                      ROUTES.SUMMARY.EMPLOYEE,
                      ROUTES.SUMMARY.CLIENT,
                      ROUTES.SUMMARY.TRACKING_TOOLS,
                      ROUTES.SUMMARY.SALARY,
                    ])}
                  >
                    <i className="bi bi-file-text-fill nav-icon" />
                    <span className="nav-link-title">Summary</span>
                  </a>
                  <div
                    id="summary"
                    className="nav-collapse collapse "
                    data-bs-parent="#navbarVerticalMenuPagesMenu"
                  >
                    {hasPermission("summary", actions.employee) && (
                      <a className="nav-link " href={ROUTES.SUMMARY.EMPLOYEE}>
                        Employee
                      </a>
                    )}
                    {hasPermission("summary", actions.client) && (
                      <a className="nav-link " href={ROUTES.SUMMARY.CLIENT}>
                        Client
                      </a>
                    )}
                    {hasPermission("summary", actions.trackingtools) && (
                      <a
                        className="nav-link "
                        href={ROUTES.SUMMARY.TRACKING_TOOLS}
                      >
                        Tracking Tools
                      </a>
                    )}
                    {hasPermission("summary", actions.salary) && (
                      <a className="nav-link " href={ROUTES.SUMMARY.SALARY}>
                        Salary
                      </a>
                    )}
                  </div>
                </div>
              )}
              {hasPermission("jobs", actions.view) && (
                <div className="nav-item">
                  <a
                    className="nav-link dropdown-toggle "
                    href="#navbarVerticalMenuPagesProjectMenu"
                    role="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarVerticalMenuPagesProjectMenu"
                    aria-expanded="false"
                    aria-controls="navbarVerticalMenuPagesProjectMenu"
                    style={activeTab([ROUTES.JOBS.ADD, ROUTES.JOBS.LIST])}
                  >
                    <i className="bi bi-briefcase-fill nav-icon" />
                    <span className="nav-link-title">Jobs</span>
                  </a>
                  <div
                    id="navbarVerticalMenuPagesProjectMenu"
                    className="nav-collapse collapse "
                    data-bs-parent="#navbarVerticalMenuPagesMenu"
                  >
                    {hasPermission("jobs", actions.view) && (
                      <a className="nav-link " href={ROUTES.JOBS.ADD}>
                        Add
                      </a>
                    )}
                    <a className="nav-link " href={ROUTES.JOBS.LIST}>
                      List
                    </a>
                  </div>
                </div>
              )}
              {hasPermission("event", actions.view) && (
                <div className="nav-item">
                  <a
                    className="nav-link dropdown-toggle "
                    href="#navbarVerticalMenuPagesProjectsMenu"
                    role="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#user"
                    aria-expanded="false"
                    aria-controls="navbarVerticalMenuPagesProjectsMenu"
                    style={activeTab([ROUTES.EVENT.ADD, ROUTES.EVENT.LIST])}
                  >
                    <i className="bi bi-easel-fill nav-icon"/>
                    <span className="nav-link-title">Event</span>
                  </a>
                  <div
                    id="user"
                    className="nav-collapse collapse "
                    data-bs-parent="#navbarVerticalMenuPagesMenu"
                  >
                    {hasPermission("event", actions.add) && (
                      <a
                        id="add-user"
                        className="nav-link "
                        href={ROUTES.EVENT.ADD}
                        onClick={() => {
                          console.log("add user link");
                          trackNavigate("#add-user", EVENT_NAME.ADD_USER, "");
                        }}
                      >
                        Add
                      </a>
                    )}
                    <a
                      id="add-user"
                      className="nav-link "
                      href={ROUTES.EVENT.LIST}
                      onClick={() => {
                        console.log("list user link");
                        trackNavigate("#list-user", EVENT_NAME.LIST_USER, "");
                      }}
                    >
                      List
                    </a>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="navbar-vertical-footer"></div>
      </div>
    </aside>
  );
}

export default Navbar;
