import { useLocation } from "react-router-dom";

function UseFullPath() {
  const location = useLocation();

  // Combine pathname and search to get the full path
  //const fullPath = location.pathname + location.search;
  return location;
}

export default UseFullPath;
