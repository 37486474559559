export const expense = {
    ADD_EXPENSES : 'Add Expenses',
    MONTH : 'Month',
    SELECT_MONTH : 'Select Month',
    YEAR : 'Year',
    SELECT_YEAR : 'Select Year',
    ITEM : 'Item',
    AMOUNT : 'Amount',
    DESCRIPTION : 'Description',
    DATE : 'Date',
    STATUS : 'Status',
    SELECT_STATUS : 'Select Status',
    EDIT_EXPENSES : 'Edit Expenses',
    CATEGORY : 'Category',
    ACTION : 'Action',
    NO_RECORD_FOUND : 'No record found',
    VIEW_EXPENSES : 'View Expenses',
    EXPENSES_INFO : 'Expenses Information',

    //ACTION 
    SAVE_CHANGES : 'Save Changes',
    CLOSE : 'Close',
}