import React from "react";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineDot from "@mui/lab/TimelineDot";
import VerifiedIcon from "@mui/icons-material/Verified";
import { contact } from "../../constant/Contact";

function View({ applicantsNotes }) {
  return (
    <div
      className="modal fade"
      id="view"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title" id="exampleModalLongTitle">
              {contact.STATUS}
            </h1>

            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            {applicantsNotes.length === 0 ? (
              <Typography
                sx={{ marginLeft: 30 }}
                className="typograpy-status"
                variant="body"
              >
                {contact.NO_RECORD_FOUND}
              </Typography>
            ) : (
              applicantsNotes.map((item, index) => {
                var date = new Date(item.createdAt).toLocaleString(undefined, {
                  timeZone: "Asia/Kolkata",
                });
                const date1 = date.split(",");
                const date2 = date1[0].split("/");
                const time = date1[1].split(" ");
                const time1 = time[1].split(":");
                return (
                  <Timeline
                    className="timeline-content"
                    key={index}
                    position="alternate"
                  >
                    <TimelineItem>
                      <TimelineOppositeContent
                        sx={{ m: "auto 0" }}
                        align="right"
                        variant="body"
                        color="secondary.main"
                        className="status-timeline"
                      >
                        {`${date2[1] + "/" + date2[0] + "/" + date2[2]}`}{" "}
                        {`${time1[0] + ":" + time1[1]}`} {time[2]}
                      </TimelineOppositeContent>
                      <TimelineSeparator>
                        <TimelineConnector
                          sx={{
                            bgcolor: "secondary.main",
                          }}
                        />
                        <TimelineDot sx={{ bgcolor: "#388e3c" }}>
                          <VerifiedIcon />
                        </TimelineDot>
                        <TimelineConnector
                          sx={{
                            bgcolor: "secondary.main",
                          }}
                        />
                      </TimelineSeparator>
                      <TimelineContent
                        variant="body"
                        sx={{ py: "12px", px: 2 }}
                      >
                        <div>
                          <Chip
                            color="secondary"
                            className="chip-status"
                            label={`${item.status}`}
                          />
                        </div>
                        <div
                          style={{
                            marginTop: 5,
                            marginLeft: 7,
                          }}
                        >
                          <Typography
                            className="typograpy-status"
                            variant="div"
                          >
                            {item.note}
                          </Typography>
                        </div>
                        <div
                          style={{
                            marginTop: 7,
                            marginLeft: 7,
                          }}
                        >
                          <Typography
                            className="typograpy-status"
                            variant="div"
                          >
                            {contact.UPDATED_BY}{item.userId.username}
                          </Typography>
                        </div>
                      </TimelineContent>
                    </TimelineItem>
                  </Timeline>
                );
              })
            )}
          </div>
          <div className="modal-footer"></div>
        </div>
      </div>
    </div>
  );
}

export default View;
