export const history = {
    ADMIN : 'Admin',
    ACTION : 'Action',
    TARGET_FIELD : 'Target field',
    START_DATE : 'StartDate',
    END_DATE : 'EndDate',
    FILTER : 'Filter',
    RESET : 'Reset',
    DATE : 'Date',
    EMAIL : 'Email',
    TARGET_NAME : 'Target name',

    //SELECT OPTION
    ALL : 'All',
    CUSTOMIZE : 'Customize',
    TODAY : 'TODAY',
    YESTERDAY : 'Yesterday',
    LAST_WEEK : 'Last week',
    LAST_MONTH : 'Last month',
    CURRENT_WEEK : 'Current week',
    THIS_MONTH : 'This month',
}