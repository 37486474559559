import React from 'react';
import { Checkbox } from "@mui/material";
import moment from "moment";
import { Loader } from "../../components/Common";
import config from "../../config/index";
import PropTypes from 'prop-types';

const EventTable = ({
  activities,
  loading,
  handleCheckBoxChange,
  isAllSelected,
  selectedinfo,
  handleViewReportClick,
  handleDeleteClick,
}) => (
  <div className="table-responsive datatable-custom">
    <table
      id="datatable"
      className="table table-borderless table-thead-bordered table-nowrap table-align-middle card-table"
    >
      <thead className="thead-light">
        <tr>
          <th scope="col" className="table-column-pe-0">
            <Checkbox
              size="medium"
              value="all"
              onChange={handleCheckBoxChange}
              checked={isAllSelected}
            ></Checkbox>
          </th>
          <th>Event Name</th>
          <th>Event Type</th>
          <th>description</th>
          <th>Start Date/Time - End Date/Time</th>
          <th>Status</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        {loading ? (
          <tr>
            <td colSpan={8} style={{ textAlign: "center" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Loader />
              </div>
            </td>
          </tr>
        ) : (
          <>
            {" "}
            {Array.isArray(activities) && activities.length === 0 && (
              <tr>
                <td colSpan={7} style={{ textAlign: "center" }}>
                  {" "}
                  <h3>No Record Found</h3>
                </td>
              </tr>
            )}
            {Array.isArray(activities) &&
              activities.map((activity, index) => {
                const eventStatus = config.JOBS_STATUS?.find(
                  (item) => item.value === activity.status
                )?.name;
                return (
                  <tr key={"activity" + index}>
                    <td className="table-column-pe-0">
                      <Checkbox
                        key={activity._id}
                        size="medium"
                        value={activity._id}
                        onChange={handleCheckBoxChange}
                        checked={selectedinfo.selectedID.includes(
                          activity._id
                        )}
                      ></Checkbox>
                    </td>
                    <td className="table-column-ps-1">
                      <h5 className="text-inherit mb-0">
                        {activity.activityName}
                      </h5>
                    </td>
                    <td>{activity.description}</td>
                    <td>{activity.activityType}</td>
                    <td style={{ width: "5%" }}>
                      {activity.dateAndTime != null &&
                      activity.endTime != null
                        ? `${moment(activity.dateAndTime).format(
                            "DD MMM YYYY hh:mm A"
                          )} - ${moment(activity.endTime).format(
                            "DD MMM YYYY hh:mm A"
                          )}`
                        : ""}
                    </td>
                    <td>{eventStatus || "-"}</td>
                    <td>
                      <button
                        key={activity._id}
                        title="Edit activity"
                        type="button"
                        className="icon icon-danger icon-circle"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModalLong"
                        onClick={(event) => {
                          handleViewReportClick(event, activity._id);
                        }}
                      >
                        <i className="bi-pen"></i>
                      </button>
                      &nbsp;
                      <button
                        title="Delete activity"
                        type="button"
                        className="icon icon-info icon-circle"
                        data-bs-toggle="modal"
                        data-bs-target="#delete"
                        onClick={(event) => {
                          handleDeleteClick(event, activity._id);
                          console.log("activity._id: ", activity._id);
                        }}
                      >
                        <i className="bi-trash"></i>
                      </button>
                      &nbsp;
                      <button
                        title="View activity"
                        type="button"
                        className="icon icon-dark icon-circle"
                        data-bs-toggle="modal"
                        data-bs-target="#viewpage"
                        onClick={(event) =>
                          handleViewReportClick(event, activity._id)
                        }
                      >
                        <i className="bi-eye"></i>
                      </button>
                    </td>
                    <></>
                  </tr>
                );
              })}
          </>
        )}
      </tbody>
    </table>
  </div>
);

export default EventTable;

EventTable.propTypes = {
    activities: PropTypes.arrayOf(PropTypes.object),
    loading: PropTypes.bool,
    handleCheckBoxChange: PropTypes.func,
    isAllSelected: PropTypes.bool,
    selectedinfo: PropTypes.object,
    handleViewReportClick: PropTypes.func,
    handleDeleteClick: PropTypes.func,
};