export const permissions = {
  administrator: {
    dashboard: {
      actions: {
        add: true,
        edit: true,
        delete: true,
        view: true,
      },
    },
    email: {
      actions: {
        add: true,
        edit: true,
        delete: true,
        view: true,
        csv: true,
      },
    },
    whatsapp: {
      actions: {
        add: true,
        edit: true,
        delete: true,
        view: true,
        csv: true,
      },
    },
    applicant: {
      actions: {
        add: true,
        edit: true,
        delete: true,
        view: true,
      },
    },
    employee: {
      actions: {
        add: true,
        edit: true,
        delete: true,
        view: true,
      },
    },
    user: {
      actions: {
        add: true,
        edit: true,
        delete: true,
        view: true,
      },
    },
    attendance: {
      actions: {
        add: true,
        edit: true,
        delete: true,
        view: true,
      },
    },
    client: {
      actions: {
        add: true,
        edit: true,
        delete: true,
        view: true,
      },
    },
    trackingtools: {
      actions: {
        add: true,
        edit: true,
        delete: true,
        view: true,
      },
    },
    leaves: {
      actions: {
        add: true,
        edit: true,
        delete: true,
        view: true,
      },
    },
    salary: {
      actions: {
        add: true,
        edit: true,
        delete: true,
        view: true,
      },
    },
    expenses: {
      actions: {
        add: true,
        edit: true,
        delete: true,
        view: true,
      },
    },
    reminder: {
      actions: {
        add: true,
        edit: true,
        delete: true,
        view: true,
      },
    },
    history: {
      actions: {
        add: true,
        edit: true,
        delete: true,
        view: true,
      },
    },
    contacts: {
      actions: {
        add: true,
        edit: true,
        delete: true,
        view: true,
      },
    },
    quotes: {
      actions: {
        add: true,
        edit: true,
        delete: true,
        view: true,
      },
    },
    tasks: {
      actions: {
        add: true,
        edit: true,
        delete: true,
        view: true,
      },
    },
    track: {
      actions: {
        add: true,
        edit: true,
        delete: true,
        view: true,
      },
    },
    productivity: {
      actions: {
        add: true,
        edit: true,
        delete: true,
        view: true,
      },
    },
    summary: {
      actions: {
        add: true,
        edit: true,
        delete: true,
        view: true,
        employee: true,
        trackingtools: true,
        salary: true,
        client: true,
      },
    },
    jobs: {
      actions: {
        add: true,
        edit: true,
        delete: true,
        view: true,
      },
    },
    event:{
      actions:{
        add: true,
        edit: true,
        delete: true,
        view: true,
      },
    },
  },
  developer: {
    dashboard: {
      actions: {
        add: true,
        edit: true,
        delete: true,
        view: true,
      },
    },
    attendance: {
      actions: {
        add: true,
        edit: false,
        delete: false,
        view: true,
      },
    },
    tasks: {
      actions: {
        add: true,
        edit: true,
        delete: true,
        view: true,
      },
    },
    track: {
      actions: {
        add: true,
        edit: true,
        delete: true,
        view: true,
      },
    },
    productivity: {
      actions: {
        add: true,
        edit: true,
        delete: true,
        view: true,
      },
    },
  },
  HR: {
    dashboard: {
      actions: {
        add: true,
        edit: true,
        delete: true,
        view: true,
      },
    },
    event:{
      actions:{
        add: true,
        edit: true,
        delete: true,
        view: true,
      },
    },
    applicant: {
      actions: {
        add: true,
        edit: true,
        delete: true,
        view: true,
      },
    },
    employee: {
      actions: {
        add: true,
        edit: true,
        delete: true,
        view: true,
      },
    },
    user: {
      actions: {
        add: true,
        edit: true,
        delete: true,
        view: true,
      },
    },
    attendance: {
      actions: {
        add: true,
        edit: true,
        delete: true,
        view: true,
      },
    },
    leaves: {
      actions: {
        add: true,
        edit: true,
        delete: true,
        view: true,
      },
    },
    salary: {
      actions: {
        add: true,
        edit: true,
        delete: true,
        view: true,
      },
    },
    expenses: {
      actions: {
        add: true,
        edit: true,
        delete: true,
        view: true,
      },
    },
    reminder: {
      actions: {
        add: true,
        edit: true,
        delete: true,
        view: true,
      },
    },
    summary: {
      actions: {
        add: true,
        edit: true,
        delete: true,
        view: true,
        employee: true,
        trackingtools: true,
        salary: true,
        client: true,
      },
    },
    jobs: {
      actions: {
        add: true,
        edit: true,
        delete: true,
        view: true,
      },
    },
  },
  BDE: {
    tasks: {
      actions: {
        add: true,
        edit: true,
        delete: true,
        view: true,
      },
    },
    track: {
      actions: {
        add: true,
        edit: true,
        delete: true,
        view: true,
      },
    },
    productivity: {
      actions: {
        add: true,
        edit: true,
        delete: true,
        view: true,
      },
    },
    dashboard: {
      actions: {
        add: true,
        edit: true,
        delete: true,
        view: true,
      },
    },
    email: {
      actions: {
        add: true,
        edit: true,
        delete: true,
        view: true,
        csv: true,
      },
    },
    whatsapp: {
      actions: {
        add: true,
        edit: true,
        delete: true,
        view: true,
        csv: true,
      },
    },
    attendance: {
      actions: {
        add: true,
        edit: true,
        delete: true,
        view: true,
      },
    },
    client: {
      actions: {
        add: true,
        edit: true,
        delete: true,
        view: true,
      },
    },
  },
};
