import React from "react";
import { Autocomplete, TextField } from "@mui/material";
import Config from "../../config";
import {
  CLOSE,
  COMPANYNAME,
  EDITEMAIL,
  EMAIL,
  EMAILTYPE,
  NAME,
  SAVECHANGES,
  STATUS,
} from "../../constant/Label";
import { emailmarketing } from "../../constant/EmailMarketing";

function Edit({
  handleChange,
  inputValue,
  isDisable,
  changeStatus,
  updateId,
  errMsg,
}) {
  return (
    <div
      className="modal fade"
      id="exampleModalLong"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h2 className="modal-title" id="exampleModalLongTitle">
              {EDITEMAIL}
            </h2>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <div className="modal-content">
              <table>
                <tbody>
                  <tr>
                    <td>
                      <h4>{NAME}</h4>{" "}
                      <input
                        type="text"
                        className="form-control"
                        name="name"
                        onChange={handleChange}
                        value={inputValue?.name}
                        id="nameLabel"
                        placeholder="Name"
                        aria-label="Name"
                      />{" "}
                    </td>
                  </tr>

                  <br></br>
                  <tr>
                    <td>
                      <h4>{EMAIL}</h4>{" "}
                      <input
                        type="text"
                        className="form-control"
                        name="email"
                        onChange={handleChange}
                        value={inputValue?.email}
                        id="emailLabel"
                        placeholder="Email"
                        aria-label="Email"
                      />{" "}
                      {errMsg.email !== "" ? (
                        <label
                          className=" form-label"
                          style={{ fontSize: 15, color: "red" }}
                        >
                          {errMsg.email}
                        </label>
                      ) : (
                        ""
                      )}
                    </td>
                  </tr>

                  <br></br>
                  <tr>
                    <td>
                      <h4>{EMAILTYPE}</h4>{" "}
                      <Autocomplete
                        key={inputValue?.emailType}
                        options={Config.EMAIL_TYPE || []}
                        getOptionLabel={(option) => option.value || ""}
                        value={
                          Config.EMAIL_TYPE.find(
                            (item) => item.value === inputValue?.emailType
                          ) || null
                        }
                        onChange={handleChange}
                        size="small"
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            autoComplete="off"
                          />
                        )}
                      />
                      {errMsg.emailType !== "" ? (
                        <label
                          className=" form-label"
                          style={{ fontSize: 15, color: "red" }}
                        >
                          {errMsg.emailType}
                        </label>
                      ) : (
                        ""
                      )}
                    </td>
                  </tr>

                  <br></br>
                  <tr>
                    <td>
                      <h4>{STATUS}</h4>{" "}
                      <Autocomplete
                        key={inputValue?.status}
                        options={Config.EMAIL_STATUS || []}
                        getOptionLabel={(option) => option.value || ""}
                        value={
                          Config.EMAIL_STATUS.find(
                            (item) => item.value === inputValue?.status
                          ) || null
                        }
                        onChange={handleChange}
                        size="small"
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            autoComplete="off"
                          />
                        )}
                      />
                    </td>
                  </tr>

                  <br></br>
                  <tr>
                    <td>
                      <h4>{emailmarketing.POSITIONS}</h4>{" "}
                      <input
                        type="text"
                        className="form-control"
                        name="positions"
                        onChange={handleChange}
                        value={inputValue?.positions}
                        id="positionsLabel"
                        placeholder="Positions"
                        aria-label="positions"
                      />{" "}
                    </td>
                  </tr>

                  <br></br>
                  <tr>
                    <td>
                      <h4>{COMPANYNAME}</h4>{" "}
                      <input
                        type="text"
                        className="form-control"
                        name="companyName"
                        onChange={handleChange}
                        value={inputValue?.companyName}
                        id="positionsLabel"
                        placeholder="Company Name"
                        aria-label="companyName"
                      />{" "}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-white"
              data-bs-dismiss="modal"
            >
              {CLOSE}
            </button>
            <button
              disabled={isDisable === true}
              type="button"
              className="btn btn-primary"
              data-bs-dismiss={inputValue?.email === "" ? "" : "modal"}
              onClick={() => {
                changeStatus(updateId);
              }}
            >
              {SAVECHANGES}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Edit;
