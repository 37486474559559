import React, { useState, useEffect, useContext, useCallback } from "react";
import Axios from "axios";
import FullCalendar from "@fullcalendar/react"; // must go before plugins
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import { Modal, Box } from "@mui/material";
import Config from "../../config";
import HeaderNavbarHOC from "../../HOC/HeaderNavbar";
import { AuthContext } from "../../Login/Protected";
import * as AxiosActions from "../../Actions/axiosActions";
import { Filter } from "../../components/Common";
import ModalContent from "../../components/Common/Modal/attendance";
import "./Attendencesheet.css";

function AttendenceSheet() {
  const adminData = useContext(AuthContext);
  const [data, setData] = useState([]);
  const [getData, setGetData] = useState([]);
  const [empId, setEmpId] = useState(adminData?.adminData?.empid?._id);
  const currentDate = new Date();
  const nowMonth = currentDate.getMonth().toString();
  const nowYear = currentDate.getFullYear().toString();
  const [currentMonth, setCurrentMonth] = useState(Number(nowMonth));
  const [currentYear, setCurrentYear] = useState(nowYear);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedDateDetails, setSelectedDateDetails] = useState(null);
  const [inTime, setInTime] = useState(null);
  const [outTime, setOutTime] = useState(null);
  const [openModal, setOpenModal] = useState(false);

  const [attendanceSummary, setAttendanceSummary] = useState({
    noOfDays: 0,
    noOfLeave: 0,
    noOfPaidHoliday: 0,
    noOfUnpaidHoliday: 0,
    noOfUnpaid: 0,
    noOfPaid: 0,
    noOfCL: 0,
    noOfPublicHoliday: 0,
    noOfWeeklyHoliday: 0,
    totalWorkingHours: 0,
    totalWorkingDays: 0,
  });

  const handleDatesSet = async (arg) => {
    const start = arg.view.currentStart;
    const month = start.getMonth();
    const year = start.getFullYear();

    if (month !== currentMonth || year !== currentYear) {
      setCurrentMonth(month);
      setCurrentYear(year);
      geTdata(month, year);
      getAttendanceSummary();
    }
  };

  const geTdata = useCallback(
    (month, year) => {
      Axios.post(Config.LOCALURL + "attendance/month/count", {
        month: month ? month + 1 : currentMonth + 1,
        year: year || currentYear,
        empid: empId,
      })
        .then((response) => {
          if (response.data.success) {
            setData(response.data.attendanceData);
          }
        })
        .catch((error) => {
          console.log("Error in getData", error);
        });
    },
    [currentMonth, currentYear, empId]
  );

  const getAttendanceSummary = useCallback(
    (month, year) => {
      Axios.post(Config.LOCALURL + "api/v2/leaves/count ", {
        month: Config.SALARY_MONTH[currentMonth],
        year: currentYear,
        empid: empId,
      })
        .then((response) => {
          if (response.data.code === 200) {
            const {
              noOfDays,
              noOfLeave,
              noOfPaidHoliday,
              noOfUnpaidHoliday,
              noOfUnpaid,
              noOfPaid,
              noOfCL,
              noOfPublicHoliday,
              noOfWeeklyHoliday,
              totalWorkingHours,
              totalWorkingDays,
            } = response.data.data;
            setAttendanceSummary({
              noOfDays,
              noOfLeave,
              noOfPaidHoliday,
              noOfUnpaidHoliday,
              noOfUnpaid,
              noOfPaid,
              noOfCL,
              noOfPublicHoliday,
              noOfWeeklyHoliday,
              totalWorkingHours,
              totalWorkingDays,
            });
          }
        })
        .catch((error) => {
          console.log("Error in getData", error);
        });
    },
    [currentMonth, currentYear, empId]
  );

  const getEmployeeList = () => {
    AxiosActions.EmployeeList({})
      .then((response) => {
        if (response.status === 200) {
          setGetData(response.data.data);
        }
      })
      .catch((error) => {
        console.log("Error in get employee list", error);
      });
  };
  useEffect(() => {
    if (adminData?.adminData?.isAdmin) {
      getEmployeeList();
      geTdata();
      getAttendanceSummary();
    }
  }, [adminData.adminData, geTdata, getAttendanceSummary]);

  const formattedEvents = data?.map((event) => {
    let title;
    if(event.attendanceStatus === null){
      title = event.totalHours.toFixed(2) + " h";
    }
    else{
      title = event.attendanceStatus;
    }
    return {
      title,
      start: event.date,
      end: event.date,
    };
  });

  const handleEventContent = (e) => {
    const title = e.event.title;
    const color =
      (parseFloat(title) >= 9 && !isNaN(parseFloat(title)))
        ? "green"
        : "red";
    const weigth = (title === "Week Holiday" || title === "Public Holiday")
        ? "bold"
        : "normal";
    return {
      html: `<div className='attendance-hour' style="color: ${color}; background-color: white; font-size: 13px; padding: 5px; text-align:center; font-weight: ${weigth};">${title}</div>`,
    };
  };

  const handleDateClick = (info) => {
    const clickedDate = new Date(info.dateStr).toISOString().split("T")[0];
    const eventDetails = data.find((event) => event.date === clickedDate);

    if (eventDetails) {
      setSelectedDateDetails(eventDetails);
      setSelectedDate(clickedDate);
      setInTime(eventDetails?.inTime || null);
      setOutTime(eventDetails?.outTime || null);
      setOpenModal(true);
    } else {
      setSelectedDateDetails(null);
      setSelectedDate(clickedDate);
      setInTime(null);
      setOutTime(null);
      setOpenModal(true);
    }
  };

  const handleEventClick = (event) => {
    const clickedDate = event.event.startStr;
    setSelectedDate(clickedDate);
    const eventDetails = data.find((event) => event.date === clickedDate);
    setSelectedDateDetails(eventDetails);
    setInTime(eventDetails?.inTime || null);
    setOutTime(eventDetails?.outTime || null);
    setOpenModal(true);
  };

  const handleChange = async () => {
    Axios.post(Config.LOCALURL + "attendance/month/count", {
      month: currentMonth + 1,
      year: currentYear,
      empid: empId,
    })
      .then((response) => {
        if (response.status === 200) {
          setData(response.data.attendanceData);
          getAttendanceSummary();
        }
      })
      .catch((error) => {
        console.log("Error in getData", error);
      });
  };

  //Reset Filter
  const reset = () => {
    if (adminData?.adminData?.isAdmin) {
      setEmpId(adminData?.adminData?.empid?._id);
      getEmployeeList();
      geTdata();
      getAttendanceSummary();
    }
  };

  const attendanceFields = [
    { label: "No of Days", key: "noOfDays" },
    { label: "No of Leave", key: "noOfLeave" },
    { label: "No of Paid Leave", key: "noOfPaid" },
    { label: "No of Unpaid Leave", key: "noOfUnpaid" },
    { label: "No of Paid Holiday", key: "noOfPaidHoliday" },
    { label: "No of Unpaid Holiday", key: "noOfUnpaidHoliday" },
    { label: "No of CL", key: "noOfCL" },
    { label: "No of Public Holiday", key: "noOfPublicHoliday" },
    { label: "No of Weekly Holiday", key: "noOfWeeklyHoliday" },
    { label: "Total Working Hours", key: "totalWorkingHours" },
    { label: "Total Working Days", key: "totalWorkingDays" },
  ];

  return (
    <main id="content" role="main" className="main">
      <div className="content container-fluid">
        <div className="card">
          <div className="card-body ">
            <h1 className="card-title" style={{ marginLeft: 20 }}>
              Attendence sheet
            </h1>
          </div>
          <div className="col-auto p-3">
            <div className="row justify-content-end align-items-sm-center">
              <div className="col-sm-auto">
                <div className="row align-items-center gx-0">
                  <div className="col">
                    <span className="text-secondary me-2">Name</span>
                  </div>
                  <div className="col-auto">
                    <div className="tom-select-custom tom-select-custom-end">
                      <select
                        className="form-select"
                        autoComplete="off"
                        name="Name"
                        onChange={(event) => setEmpId(event.target.value)}
                        value={empId}
                      >
                        {getData?.map((item, index) => {
                          return (
                            <option value={item._id} key={item._id}>
                              {item.employeeName}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-sm-auto">
                <Filter filterRecord={handleChange} resetRecord={reset} />
              </div>
            </div>
            <div className="col-sm-auto mt-2">
              <div className="attendance-summary-card">
                <h4 className="attendance-summary-title">Attendance Summary</h4>
                <div className="attendance-summary-details">
                  <div className="attendance-summary-left">
                    {attendanceFields.slice(0, 6).map((field) => (
                      <div className="attendance-summary-item" key={field.key}>
                        <span className="attendance-summary-label">
                          {field.label}:
                        </span>
                        <span className="attendance-summary-value">
                          {attendanceSummary[field.key]}
                        </span>
                      </div>
                    ))}
                  </div>
                  <div className="attendance-summary-right">
                    {attendanceFields.slice(6).map((field) => (
                      <div className="attendance-summary-item" key={field.key}>
                        <span className="attendance-summary-label">
                          {field.label}:
                        </span>
                        <span className="attendance-summary-value">
                          {attendanceSummary[field.key]}
                        </span>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card" style={{ padding: 25 }}>
            <div className="card-body">
              <FullCalendar
                datesSet={handleDatesSet}
                plugins={[dayGridPlugin, interactionPlugin]}
                initialView="dayGridMonth"
                events={formattedEvents}
                eventContent={handleEventContent}
                dateClick={handleDateClick}
                eventClick={handleEventClick}
              />
            </div>
          </div>
        </div>
        <Modal open={openModal} onClose={() => setOpenModal(false)}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              bgcolor: "background.paper",
              border: "1px solid black",
              outline: "none",
              overflow: "auto",
              overflowX: "hidden",
              padding: "20px",
              boxShadow: 24,
              p: 4,
            }}
          >
            <ModalContent
              selectedDate={selectedDate}
              selectedDateDetails={selectedDateDetails}
              inTime={inTime}
              outTime={outTime}
            />
          </Box>
        </Modal>
      </div>
      <div className="footer"></div>
    </main>
  );
}

export default HeaderNavbarHOC(AttendenceSheet);
