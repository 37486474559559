import React, { useState, useCallback } from "react";
import {
  ThemeProvider,
  createTheme,
  Box,
  Typography,
  Button,
  Grid,
  TablePagination,
  Slide,
  Chip,
  CircularProgress,
} from "@mui/material";
import Papa from 'papaparse';
import { useDropzone } from "react-dropzone";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import CallOutlined from "../../Images/uploadcsv.svg";
import DownloadIcon from "@mui/icons-material/Download";
import { useNavigate } from "react-router-dom";
import HeaderNavbarHOC from "../../HOC/HeaderNavbar";
import * as AxiosActions from "../../Actions/axiosActions";
import Useuser from "../../Contexct";
import { CSV_TABLE_HEADERS, SAMPLE_CSV_DATA, SAMPLE_CSV_HEADERS } from "../../constant/Applicants";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Transition1 = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Transition8 = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const theme = createTheme({
  palette: {
    primary: {
      main: "#f44336",
    },
    secondary: {
      main: "#4c4d4f",
    },
  },
});

function ImportCSV() {
  const { adminData } = Useuser();
  const [studentArray, setStudentArray] = useState([]);
  const [uploadResponse, setUploadResponse] = useState();
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [pagination, setPagination] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [anchorEl7, setAnchorEl7] = useState(false);
  const open7 = Boolean(anchorEl7);
  const navigate = useNavigate();
  const [isDisable, setIsDisable] = useState(false);
  const token = localStorage.getItem("access_token");

  const handleClose = () => {
    setOpen(false);
  };

  const handleLimitClose = () => {
    setOpen1(false);
    setStudentArray([]);
  };

  const handleReset = () => {
    setStudentArray([]);
  };

  const handleServerNotWorkingClose = () => {
    setAnchorEl7(false);
    setStudentArray([]);
  };

  const onDrop = useCallback((acceptedFiles) => {
    try {
      if(acceptedFiles[0] && acceptedFiles[0].type === 'text/csv'){
        const file = acceptedFiles[0];
        if (file) {
            Papa.parse(file, {
              complete: (result) => {
                  // Function to trim each cell in a row
                  const trimCell = (cell) => cell.trim();

                  // Function to process each row by trimming all cells
                  const processRow = (row) => row.map(trimCell);

                  // Main function to handle the data processing
                  const processData = (data) => {
                    return data.map(processRow);
                  };

                  // Using the functions
                  if (result.data) {
                    const trimmedData = processData(result.data);
                    setStudentArray(trimmedData);
                  }
              },
              header: false,
            });
        }
    } else {
      setStudentArray([]);
    }
    } catch (error) {
      console.log("issue in drop");
    }
  }, []);
  const { getRootProps, getInputProps } = useDropzone({ accept: { 'text/csv': ['.csv','.xlsx','.xls'] }, onDrop });

  function transformStudentArray(studentArray) {
    // Extract headers from the first row
    const headers = studentArray[0];
  
    // Map the rest of the rows to objects
    const transformedData = studentArray.slice(1).reduce((result, row) => {
      // Ignore empty rows
      if (row.every(cell => cell.trim() === "")) return result;
  
      const rowData = {};
      headers.forEach((header, index) => {
        if (header) {
          rowData[header] = row[index] || "";
        }
      });
  
      result.push(rowData);
  
      return result;
    }, []);
  
    return transformedData;
  }
  
  const handleUpload = async () => {
    setIsDisable(true);
    const token = localStorage.getItem("access_token");

    if (token === null) {
      navigate("/login");
    } else {

      if (studentArray.length <= 250) {
        AxiosActions.ImportCSV({
          token: token,
          data: transformStudentArray(studentArray),
          adminId : adminData._id,
        })
          .then((data) => {
            if(data.code === 200){
              setIsDisable(false);
              setStudentArray([]);
              setUploadResponse(data.data);
              setOpen(true);
            }
          })
          .catch((err) => {
            setAnchorEl7(true);
            setIsDisable(false);
          });
      } else {
        alert("Please Add Below 250 Records");
      }
    }
  };

  const handleDownload = (link) => {
    window.open(link);
  };

  const generateCSVContent = () => {
    const headers = SAMPLE_CSV_HEADERS;
    const sampleData = [SAMPLE_CSV_DATA];

    let csvContent = 'data:text/csv;charset=utf-8,';
    csvContent += headers.join(',') + '\n';
    sampleData.forEach(row => {
        csvContent += row.join(',') + '\n';
    });

    return encodeURI(csvContent);
  }

  const downloadSampleFile = () => {
    const csvContent = generateCSVContent();
    const link = document.createElement('a');
    link.setAttribute('href', csvContent);
    link.setAttribute('download', 'applicants_imports.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleChangePage = (event, newpage) => {
    setPagination(newpage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    const rowsPerPage =
      event.target.value === "All" ? studentArray.length : event.target.value;
    setRowsPerPage(rowsPerPage);
    setPagination(0);
  };

  // Get the data for the current page
  const currentItems = studentArray.slice(
    pagination * rowsPerPage,
    pagination * rowsPerPage + rowsPerPage + 1
  );

  return (
    <>
      {token === null ? (
        true((window.location.href = "/"))
      ) : (
        <main id="content" role="main" className="main">
          <div className="content container-fluid">
            <div className="page-header">
              <div className="row align-items-center">
                <div
                  className="col align-items-center"
                  style={{ alignItems: "center" }}
                >
                  <h1
                    className="page-header-title align-items-center"
                    style={{ alignSelf: "center" }}
                  >
                    Import CSV
                  </h1>
                </div>
              </div>
            </div>

            <div className="card mb-3 mb-lg-5">
              <div className="table-responsive datatable-custom">
                <ThemeProvider theme={theme}>
                  <div className="dashboard">
                    {anchorEl7 === true ? (
                      <Dialog
                        open={open7}
                        TransitionComponent={Transition8}
                        // maxWidth="lg"
                        scroll="body"
                        fullWidth={true}
                        className="dialog"
                        sx={{ height: 500, marginTop: 7 }}
                        keepMounted
                        onClose={handleServerNotWorkingClose}
                        aria-describedby="alert-dialog-slide-description"
                      >
                        <div className="row-heading">
                          <Typography
                            variant="body"
                            sx={{
                              marginLeft: 3,
                            }}
                          >
                            Applicants
                          </Typography>
                          <CloseIcon
                            className="close-icon cursor-pointer"
                            fontSize="large"
                            sx={{
                              marginLeft: 55.5,
                              marginTop: 2,
                            }}
                            onClick={handleServerNotWorkingClose}
                          />
                        </div>
                        <div>
                          <Typography
                            className="typograpy-status"
                            variant="div"
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            Server not working please try again.
                          </Typography>
                        </div>

                        <div className="dialog-div"></div>
                      </Dialog>
                    ) : (
                      <Box
                        sx={{
                          width: "100%",
                          height: "100%",
                          display: "flex",
                          flexDirection: "column",
                          p: 3,
                        }}
                      >
                        <Box>
                          {uploadResponse && (
                            <Dialog
                              fullWidth={true}
                              maxWidth={"sm"}
                              open={open}
                              onClose={handleClose}
                              TransitionComponent={Transition1}
                              aria-describedby="alert-dialog-slide-description"
                            >
                              <span
                                className={"popup-title d-flex justify-content-between align-items-center"}
                                style={{ marginBottom: 20 }}
                              >
                                Applicants Imported!
                                <IconButton
                                  onClick={handleClose}
                                  className="pop-right-icon"
                                >
                                  <CloseIcon
                                    className="close-icon"
                                    fontSize="large"
                                  />
                                </IconButton>{" "}
                              </span>

                              <DialogContent
                                style={{ width: "100%", paddingTop: 0 }}
                              >
                                <Box
                                  sx={{
                                    m: "auto",
                                    textAlign: 'center'
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      marginRight: 2,
                                      color: "green",
                                      fontSize: "20px",
                                      fontWeight: "600",
                                      fontFamily: "Quicksand",
                                    }}
                                    variant="div"
                                  >
                                    {uploadResponse.added} Applicants Added.
                                  </Typography>

                                  {uploadResponse.errors > 0 && (
                                    <Typography
                                      sx={{
                                        marginRight: 2,
                                        color: "red",
                                        fontSize: "20px",
                                        fontWeight: "600",
                                        fontFamily: "Quicksand",
                                      }}
                                    >
                                      {uploadResponse.errors}
                                      {uploadResponse.errors === 1
                                        ? " Applicant has error"
                                        : " Applicant have error."}
                                    </Typography>
                                  )}
                                </Box>
                              </DialogContent>
                            </Dialog>
                          )}

                          <Box
                            sx={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "flex-end",
                              paddingBottom: 2,
                            }}
                          >
                            <Button
                              onClick={() => downloadSampleFile()}
                              variant="contained"
                              className="filter-button"
                              color="primary"
                              style={{
                                // minWidth: "16%",
                                textTransform: "capitalize",
                              }}
                            >
                              Download CSV Template
                            </Button>
                          </Box>

                          <Grid
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <h4 className="Bulk-text">Bulk Uploads</h4>
                            <Button
                              disabled={
                                studentArray.length === 0 ? true : false
                              }
                              variant="contained"
                              color="secondary"
                              style={{
                                textTransform: "capitalize",
                              }}
                              onClick={() => handleReset()}
                            >
                              Reset Import
                            </Button>
                          </Grid>
                          {uploadResponse && (
                            <Typography className="up-text" variant="div">
                              Upload it below for processing
                            </Typography>
                          )}
                        </Box>

                        <Box
                          className="border cursor-pointer border-upload"
                          sx={{ textAlign: "center", mt: 2, mb: 2 }}
                        >
                          <div {...getRootProps()}>
                            <input className="cursor-pointer" {...getInputProps()} />

                            <img
                              src={CallOutlined}
                              style={{ marginTop: "5%" }}
                              alt="csv-file"
                            ></img>
                            <p className="text-files">
                              Upload your files here
                            </p>
                            <p className="text-Drag">
                              Drag and Drop, or Click to select
                            </p>
                          </div>
                        </Box>

                        {studentArray.length < 250 ? (
                          <></>
                        ) : (
                          <Dialog
                            TransitionComponent={Transition}
                            fullWidth={true}
                            maxWidth={"sm"}
                            open={open1}
                            onClose={handleLimitClose}
                            aria-describedby="alert-dialog-slide-description"
                          >
                            <div
                              className={"popup-title d-flex justify-content-between align-items-center"}
                              style={{ marginBottom: 20 }}
                            >
                              <p className="fs-2 mb-0 p-2">Applicants Limit!</p>
                              <IconButton
                                onClick={handleLimitClose}
                                className="pop-right-icon"
                              >
                                <CloseIcon
                                  className="close-icon"
                                  fontSize="large"
                                />
                              </IconButton>{" "}
                            </div>

                            <DialogContent
                              style={{ width: "100%", paddingTop: 0 }}
                            >
                              <Box
                                sx={{
                                  m: "auto",
                                }}
                              >
                                <Typography
                                  sx={{
                                    marginRight: 2,
                                    color: "#f44336",
                                    fontSize: "20px",
                                    fontWeight: "600",
                                    fontFamily: "Quicksand",
                                  }}
                                  variant="div"
                                >
                                  Applicants Limit 250.
                                </Typography>
                              </Box>
                            </DialogContent>
                          </Dialog>
                        )}
                        {studentArray.length > 0 &&
                          studentArray.length < 250 && (
                            <Box>
                              <Box
                                sx={{
                                  width: "100%",
                                  paddingBottom: 5,
                                  paddingTop: 5,
                                  display: "flex",
                                  justifyContent: "space-betwen",
                                  alignItems: "center",
                                }}
                              >
                                <Box sx={{ width: "100%" }}>
                                  <h4 className="importtext">
                                    Applicants Data
                                  </h4>
                                </Box>
                                <Box
                                  sx={{
                                    display: "flex",
                                    width: "100%",
                                    justifyContent: "flex-end",
                                    alignItems: "center",
                                  }}
                                >
                                  <Button
                                    disabled={isDisable}
                                    onClick={() => handleUpload()}
                                    variant="contained"
                                    color="primary"
                                    sx={{ textTransform: "capitalize" }}
                                    style={{
                                      minWidth: "16%",
                                      textTransform: "capitalize",
                                    }}
                                  >
                                    Upload {isDisable && (<CircularProgress style={{ marginLeft: 2, color: 'black', width: '20px', height: '20px' }} />)}
                                  </Button>
                                </Box>
                              </Box>

                              <Box sx={{ paddingBottom: 5 }}>
                                <TableContainer
                                  sx={{
                                    minHeight: "50%",
                                    marginBottom: 5,
                                    paddingBottom: 5,
                                  }}
                                >
                                  <Table
                                    className="table custom-table"
                                    aria-label="simple table"
                                    stickyHeader
                                  >
                                    <TableHead className="row-heading">
                                      <TableRow>
                                        {CSV_TABLE_HEADERS.map((header, index) => (
                                          <td key={`${header}-${index}`} className="border text-nowrap px-4 py-2">{header}</td>
                                        ))}
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                    {currentItems.slice(1).map((row, rowIndex) => (
                                      row.some(cell => cell.trim() !== '') && (
                                        <tr key={`${row[3]}-${rowIndex}`}>
                                          {row?.map((cell, cellIndex) => {
                                            let content;
                                            
                                            if (cellIndex === 15) {
                                              content = <Chip size="small" label={cell} />;
                                            } else {
                                              content = cell;
                                            }

                                            return (
                                              <td key={`${row[3]}-${cellIndex}`} className="border text-nowrap px-4 py-2">
                                                {content}
                                              </td>
                                            );
                                          })}
                                        </tr>
                                      )
                                    ))}
                                    </TableBody>
                                  </Table>
                                </TableContainer>
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                  }}
                                >
                                  <TablePagination
                                    className="pagination align-items-baseline"
                                    rowsPerPageOptions={[
                                      10,
                                      20,
                                      50,
                                      100,
                                      "All",
                                    ]}
                                    count={studentArray.length}
                                    rowsPerPage={rowsPerPage}
                                    page={pagination}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={
                                      handleChangeRowsPerPage
                                    }
                                    labelRowsPerPage="Records"
                                    labelDisplayedRows={() => {
                                      return `${pagination + 1}-${
                                        Math.floor(
                                          studentArray.length / rowsPerPage
                                        ) + 1
                                      }`;
                                    }}
                                  />
                                </Box>
                              </Box>
                            </Box>
                          )}
                      </Box>
                    )}
                  </div>
                </ThemeProvider>
              </div>
            </div>
          </div>
        </main>
      )}
    </>
  );
}

export default HeaderNavbarHOC(ImportCSV);
