import React, { useState, useEffect, useCallback } from "react";
import { useFormik } from "formik";
import moment from "moment";
import AlertBox from "../../Alert";
import HeaderNavbarHOC from "../../HOC/HeaderNavbar";
import Useuser from "../../Contexct";
import * as AxiosActions from "../../Actions/axiosActions";
import Pagination from "../../components/Common/Pagination";
import { Filter } from "../../components/Common";
import config from "../../config/index";
import { EVENT_NAME, trackEvent } from "../../constant/MixpanelConstant";
import Axios from "axios";
import { toast } from "react-toastify";
import Delete from "../../components/Common/AlertBox/Delete";
import ViewEventModal from "./View";
import EditEventModal from "./Edit";
import EventTable from "./Table";
import { DATAUPDATESUCCESSFULLY } from "../../constant/Label";

function EventList() {
  const formik = useFormik({
    initialValues: {
      activityName: "",
      dateAndTime: "",
      description: "",
      eventType: "",
      endTime: "",
      status: "",
      duration: "",
    },
    onSubmit: (values) => {
      changeStatus(updateId);
    },
  });

  const [openAlert, setOpenAlert] = useState(false);
  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
  };
  const { adminData } = Useuser();
  const [loading, setLoading] = useState(false);
  const [activities, setActivities] = useState([]);
  const [isFiltered, setIsFiltered] = useState(false);
  const [updateId, setUpdateId] = useState("");
  const [deleteId, setDeleteId] = useState("");
  const [filterMonth, setFilterMonth] = useState("all");
  const [filterYear, setFilterYear] = useState(moment().year());
  const [count, setCount] = useState(0);
  const [inputList, setInputList] = useState("");
  const [selectedinfo, setSelectedinfo] = useState({
    selectedID: [],
  });
  const [controller, setController] = useState({
    page: 0,
    rowsPerPage: 10,
    preventRefresh: false,
  });
  const [isDisable, setIsDisable] = useState(false);

  const isAllSelected =
    activities.length > 0 &&
    selectedinfo.selectedID.length === activities.length;
  const token = localStorage.getItem("access_token");

  const renderdata = useCallback(async () => {
    try {
      setLoading(true);
      await AxiosActions.EventList({
        nextPage: controller.page + 1,
        rowsPerPage: controller.rowsPerPage,
        activityName: inputList,
        year: filterYear,
        month: filterMonth,
        timezone: "Asia/Kolkata",
      })
        .then((data) => {
          if (data.status === 200) {
            setActivities(data?.data?.data?.activities);
            setCount(data?.data?.data?.count);
          }
          setLoading(false);
        })
        .catch((error) => {
          console.log("Catch Err in Pagination ", error);
        });
    } catch (err) {
      console.log("Catch err in render data", err);
    }
  }, [
    controller.page,
    controller.rowsPerPage,
    filterMonth,
    filterYear,
    inputList,
  ]);

  const filterRecord = useCallback(async () => {
    if (!isFiltered) {
      setIsFiltered(true);
      setController((prevController) => {
        return {
          ...prevController,
          page: 0,
          preventRefresh: true,
        };
      });
    }
    renderdata();
    setSelectedinfo({ selectedID: [] });
  }, [isFiltered, renderdata]);

  useEffect(() => {
    if (isFiltered === true) {
      if (!controller.preventRefresh) filterRecord();
    } else {
      renderdata();
    }
  }, [controller, filterRecord, isFiltered, renderdata]);

  const handlePageChange = (event, newPage) => {
    setController({
      ...controller,
      page: newPage,
      preventRefresh: false,
    });
    setSelectedinfo({ selectedID: [] });
  };

  const handleChangeRowsPerPage1 = (event) => {
    setController({
      ...controller,
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0,
      preventRefresh: false,
    });

    setSelectedinfo({ selectedID: [] });
  };

  const changeStatus = (statusid) => {
    setIsDisable(true);
    AxiosActions.EditEvent({
      _id: statusid,
      activityName: formik.values.activityName,
      dateAndTime: formik.values.dateAndTime,
      description: formik.values.description,
      status : formik.values.status,
      duration : formik.values.duration,
      adminId: adminData._id,
      activityType: formik.values.eventType,
      nextPage: controller.page + 1,
      rowsPerPage: controller.rowsPerPage,
    })
      .then((data) => {
        setIsDisable(false);
        setOpenAlert(true);
        renderdata();
      })
      .catch((err) => {
        setIsDisable(false);
        console.log(err);
        alert("Please Delete Record Again.");
      });

    setSelectedinfo({ selectedID: [] });
  };

  const reset = () => {
    trackEvent(EVENT_NAME.EVENT_RESET, { data: "" });
    setActivities([]);
    setInputList("");
    setFilterYear(moment().year());
    setFilterMonth("all");
    setController({
      ...controller,
      page: 0,
      rowsPerPage: 10,
      preventRefresh: false,
    });
    setSelectedinfo({ selectedID: [] });
    setIsFiltered(false);
  };

  const handleDeleteClick = (event, id) => {
    setDeleteId(id);
    event.preventDefault();
  };

  const deleteRecord =  (event, id) => {
    setIsDisable(true);
    trackEvent(EVENT_NAME.EVENT_DELETE, { data: id });
    Axios.delete(config.LOCALURL + "api/v1/activity/delete/" + id, {
      id: id,
    })
      .then((data) => {
        renderdata();
        setIsDisable(false);
        toast.success("Event deleted successfully");
      })
      .catch((err) => {
        console.log(err);
        setIsDisable(false);
        toast.error("Please Delete Record Again.");
      });

    event.preventDefault();
  };

  const handleViewReportClick = (event, id) => {
    trackEvent(EVENT_NAME.EVENT_VIEW, { data: id });
    const filterRecord = activities.filter((item) => item._id === id);
    filterRecord.forEach((item) => {
      formik.setFieldValue("activityName", item.activityName);
      formik.setFieldValue("description", item.description);
      formik.setFieldValue("eventType", item.activityType);
      formik.setFieldValue(
        "dateAndTime",
        moment(item.dateAndTime).toISOString()
      );
      formik.setFieldValue("endTime", moment(item.endTime).format("hh:mm A"));
      formik.setFieldValue("status", item.status);
      formik.setFieldValue("duration", item.duration);
    });
    setUpdateId(id);
  };

  const handleCheckBoxChange = (e) => {
    const value = e.target.value;
    const { checked } = e.target;
    const list = [];
    const { selectedID } = selectedinfo;

    if (value === "all") {
      if (value === "all" && checked) {
        activities.forEach((item) => {
          return list.push(item._id);
        });
        setSelectedinfo({
          selectedID: list,
        });
      } else {
        setSelectedinfo({
          selectedID: list.filter((e) => e !== value),
        });
      }
    } else if (checked) {
      setSelectedinfo({
        selectedID: [...selectedID, value],
      });
    } else {
      setSelectedinfo({
        selectedID: selectedID.filter((e) => e !== value),
      });
    }
  };

  const handleDeleteMultipleClick = (event) => {
    event.preventDefault();
  };
  const deleteAllRecord = (event) => {
    setIsDisable(true);
    trackEvent(EVENT_NAME.EVENT_DELETE_ALL, { data: "" });
    AxiosActions.MultipleDeleteEvent({
      ids: selectedinfo.selectedID,
    })
      .then((data) => {
        renderdata();
        setIsDisable(false);
        toast.success("Record Deleted successfully")
      })
      .catch((err) => {
        setIsDisable(false);
        toast.error("Some Error occurred");
      });

    setSelectedinfo({ selectedID: [] });
    event.preventDefault();
  };

  return (
    <>
      <AlertBox
        open={openAlert}
        onClose={handleCloseAlert}
        message={DATAUPDATESUCCESSFULLY}
        title="Success"
        ok={false}
      />
      {token === null ? (
        true((window.location.href = "/"))
      ) : (
        <main id="content" role="main" className="main">
          <div className="content container-fluid">
            <div className="page-header">
              <div className="row align-items-center">
                <div
                  className="col align-items-center"
                  style={{ alignItems: "center" }}
                >
                  <h1
                    className="page-header-title align-items-center"
                    style={{ alignSelf: "center" }}
                  >
                    Events
                  </h1>
                </div>
              </div>
            </div>
            <div className="card mb-3 mb-lg-5">
              <div className="card-header">
                <Filter
                  year={true}
                  yearValue={filterYear}
                  yearUpdate={setFilterYear}
                  month={true}
                  monthValue={filterMonth}
                  monthUpdate={setFilterMonth}
                  searchName={true}
                  searchNameValue={inputList}
                  searchNameUpdate={setInputList}
                  multipleSelect={selectedinfo?.selectedID?.length}
                  isDisable={isDisable}
                  multipleDelete={true}
                  handleDelete={handleDeleteMultipleClick}
                  deleteAllRecord={deleteAllRecord}
                  filterRecord={filterRecord}
                  resetRecord={reset}
                />

                <div className="row justify-content-between align-items-center flex-grow-1">
                  <div className="col-md">
                    <div className="d-flex justify-content-between align-items-center"></div>
                  </div>
                </div>
              </div>
              <EventTable
                activities={activities}
                loading={loading}
                handleCheckBoxChange={handleCheckBoxChange}
                isAllSelected={isAllSelected}
                selectedinfo={selectedinfo}
                handleViewReportClick={handleViewReportClick}
                handleDeleteClick={handleDeleteClick}
              />
              <EditEventModal
                formik={formik}
                isDisable={isDisable}
                changeStatus={changeStatus}
                updateId={updateId}
              />
              <Delete
                isDisable={isDisable}
                deleteRecord={deleteRecord}
                deleteId={deleteId}
              />
              <ViewEventModal formik={formik} />
              <div className="card-footer">
                <Pagination
                  handlePageChange={handlePageChange}
                  controller={controller}
                  count={count}
                  handleChangeRowsPerPage1={handleChangeRowsPerPage1}
                />
              </div>
            </div>
          </div>
          <div className="footer"></div>
        </main>
      )}
    </>
  );
}

export default HeaderNavbarHOC(EventList);
