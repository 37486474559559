import React, { useState } from "react";
import { TextField,Autocomplete } from "@mui/material";
import { useFormik } from "formik";
import HeaderNavbarHOC from "../../HOC/HeaderNavbar";
import * as AxiosActions from "../../Actions/axiosActions";
import AlertBox from "../../Alert";
import { trackEvent, EVENT_NAME } from "../../constant/MixpanelConstant";
import { EventDateTimePicker, TimeDurationProvider } from "../../Datepicker";
import config from "../../config/index";
import moment from "moment";
import BackgroundImage from "../../components/Common/CardImage";
import { DATAADDEDSUCCESSFULLY } from "../../constant/Label";

function EventAdd() {
  const [openAlert, setOpenAlert] = useState(false);
  const [isDisable, setIsDisable] = useState(false);
  
  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
    setIsDisable(false);
    formik.resetForm();
    formik.setFieldValue("eventType", "");
    formik.setFieldValue("timeRange", [null, null]);
  };
  
  const formik = useFormik({
    initialValues: {
      eventName: "",
      description: "",
      duration: "",
      date: "",
      eventType: null,
      timeRange : [null, null]
    },
    validate: (values) => {
      const errors = {};
      if (!values.eventName) {
        errors.eventName = "Please enter valid event name";
      }
      if (!values.description) {
        errors.description = "Please enter valid description";
      }
      if (!values.duration) {
        errors.duration = "Please enter valid duration";
      }
      if (!values.date) {
        errors.date = "Please select valid date";
      }
      if (!values.eventType) {
        errors.eventType = "Please select a valid event type";
      }
      return errors;
    },
    onSubmit: (values) => {
      setOpenAlert(true);
      setIsDisable(true);
      trackEvent(EVENT_NAME.EVENT_ADD, { data: "" });
      
      const hours = Math.floor(values.duration / 60);
      const minutes = values.duration % 60;
      const decimalHours = hours + minutes / 60;
      let formattedDuration;
      if (decimalHours % 1 === 0){
        formattedDuration = `${decimalHours} HR`;
      } else{
        formattedDuration = `${decimalHours.toFixed(2)} HR`;
      } 
      AxiosActions.EventAdd({
        activityName: values.eventName,
        description: values.description,
        duration: formattedDuration,
        dateAndTime: values.date,
        activityType: values.eventType,
      })
        .then((response) => {
          if (response.status === 200 && response.data.success === true) {
            setIsDisable(false);
            setOpenAlert(true);
            formik.resetForm();
            formik.setFieldValue("timeRange", [null, null]);
          }
        })
        .catch((error) => {
          setIsDisable(false);
          console.log("Tracking tools error", error);
        });
      },
    });

  return (
    <>
      <AlertBox
        open={openAlert}
        onClose={handleCloseAlert}
        message={DATAADDEDSUCCESSFULLY}
        title="Success"
        ok={false}
      />
      <main id="content" role="main" className="main">
        <BackgroundImage/>
        <div
          className="content container-fluid"
          style={{ justifyItems: "center", maxWidth: "60rem" }}
        >
          <div className="page-header">
            <div className="row align-items-end">
              <div className="col-sm mb-2 mb-sm-0"></div>
            </div>
          </div>

          <div className="card w-50">
            <div className="card-header">
              <h1 className="card-title h1">Add Event</h1>
            </div>

            <div className="card-body">
              <form onSubmit={formik.handleSubmit}>
                <div className="row justify-content-center mb-4">
                  <div className="col-sm-9">
                    <div className="input-group input-group-sm-vertical">
                      <TextField
                        fullWidth
                        id="eventName"
                        name="eventName"
                        label="Event Name"
                        value={formik.values.eventName}
                        onChange={formik.handleChange}
                        size="small"
                        variant="outlined"
                        error={formik.touched.eventName && Boolean(formik.errors.eventName)}
                        helperText={formik.touched.eventName && formik.errors.eventName}
                      />
                    </div>
                  </div>
                </div>
                <div className="row justify-content-center mb-4">
                  <div className="col-sm-9">
                    <div className="input-group input-group-sm-vertical">
                      <EventDateTimePicker
                        onChange={(newDate) => {
                          formik.setFieldValue("date", newDate);
                        }}
                        value={formik.values.date}
                      />
                    </div>
                  </div>
                </div>
                <div className="row justify-content-center mb-4">
                  <div className="col-sm-9">
                    <div className="input-group input-group-sm-vertical">
                      <TextField
                        fullWidth
                        id="description"
                        name="description"
                        label="Description"
                        value={formik.values.description}
                        onChange={formik.handleChange}
                        size="small"
                        variant="outlined"
                        multiline
                        error={formik.touched.description && Boolean(formik.errors.description)}
                        helperText={formik.touched.description && formik.errors.description}
                      />
                    </div>
                  </div>
                </div>
                <div className="row justify-content-center mb-4">
                  <div className="col-sm-9">
                    <div className="input-group input-group-sm-vertical">
                      <TimeDurationProvider
                        value={formik.values.timeRange}
                        onChange={(newValue) => {
                          formik.setFieldValue("timeRange", newValue);
                          const startTime = newValue[0];
                          const endTime = newValue[1];
                          const duration = moment.duration(
                            moment(endTime).diff(moment(startTime))
                          );
                          const totalMinutes = duration.asMinutes();
                          formik.setFieldValue("duration", totalMinutes);
                        }}
                        />
                    </div>
                  </div>
                </div>
                <div className="row justify-content-center mb-4">
                  <div className="col-sm-9">
                    <div className="input-group input-group-sm-vertical">
                      <Autocomplete
                      options={config.EVENTTYPE}
                      getOptionLabel={(option) => option.name}
                      value={config.EVENTTYPE.find((option) => option.value === formik.values.eventType) || null}
                      onChange={(event, newValue) => {
                        if (newValue) {
                          formik.setFieldValue("eventType", newValue.value || " ");
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                        {...params}
                        variant="outlined"
                        size="small"
                        label="Select Event Type"
                        error={formik.touched.eventType && Boolean(formik.errors.eventType)}
                        helperText={formik.touched.eventType && formik.errors.eventType}
                        />
                      )}
                        fullWidth
                      />
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-end">
                  <button
                    disabled={isDisable}
                    type="submit"
                    className="btn btn-warning"
                  >
                    Save changes
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div></div>
          <br></br>
          <br></br>
        </div>
        <div className="footer">
          <div className="row justify-content-between align-items-center">
            <div className="col"></div>
            <div className="col-auto">
              <div className="d-flex justify-content-end"></div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default HeaderNavbarHOC(EventAdd);
