// @devloper:Jinay Patel
// @created:26th april 2023
// @desc:In this page List of apliicate ,get data from api and display in table format
import React, { useState, useEffect, useContext, useCallback } from "react";
import { toast } from "react-toastify";
import Checkbox from "@mui/material/Checkbox";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineDot from "@mui/lab/TimelineDot";
import moment from "moment";
import VerifiedIcon from "@mui/icons-material/Verified";
import HeaderNavbarHOC from "../../HOC/HeaderNavbar";
import { trackEvent, EVENT_NAME } from "../../constant/MixpanelConstant";
import { AuthContext } from "../../Login/Protected";
import * as AxiosActions from "../../Actions/axiosActions";
import Pagination from "../../components/Common/Pagination";
import Config from "../../config";
import { Loader, Filter } from "../../components/Common";
import { Autocomplete, TextField } from "@mui/material";

function ApplicantsList() {
  const adminData = useContext(AuthContext);
  const [inputStatus, setInputStatus] = useState("");
  const [applicants, setApplicants] = useState([]);
  const [isFiltered, setIsFiltered] = useState(false);
  const [status, setStatus] = useState("");
  const [updateId, setUpdateId] = useState("");
  const [deleteId, setDeleteId] = useState("");
  const [applicantsNotes, setApplicantsNotes] = useState([]);
  const [fullReport, setFullReport] = useState([]);
  const [filterStatus, setFilterStatus] = useState("All");
  const [filterJobOpening, setFilterJobOpening] = useState("All");
  const [jobOpening, setJobOpening] = useState([]);
  const [count, setCount] = useState(0);
  const [filter, setFilter] = useState("");
  const [inputList, setInputList] = useState("");
  const [multipleInputStatus, setMultipleInputStatus] = useState("");
  const [multipleStatus, setMultipleStatus] = useState("Applied");
  const [filterEmail, setFilterEmail] = useState("");
  const [startDate, setStartDate] = useState("");
  const [filterDate, setFilterDate] = useState("all");
  const [endDate, setEndDate] = useState("");
  const [selectedinfo, setSelectedInfo] = useState({
    selectedID: [],
  });
  const [controller, setController] = useState({
    page: 0,
    rowsPerPage: 10,
    preventRefresh: false,
  });
  const isAllSelected =
    applicants.length > 0 &&
    selectedinfo.selectedID.length === applicants.length;
  const token = localStorage.getItem("access_token");
  console.log("Token", token);
  const [loading, setLoading] = useState(false);
  const [isDisable, setIsDisable] = useState(false);
  // renderdata(getdata from api)

  const renderdata = useCallback(async () => {
    console.log("adminData", adminData.adminData._id);
    setLoading(true);
    try {
      await AxiosActions.ApplicantsList({
        pageNo: controller.page,
        size: controller.rowsPerPage,
        token: token,
      })
        .then((data) => {
          console.log(data);
          setRenderState(data.data.data.rows, data.data.data.noRecords);
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (err) {
      console.log(err);
    }
  }, [adminData.adminData._id, controller.page, controller.rowsPerPage, token]);

  const setRenderState = (applicant, noOfRecords) => {
    setApplicants(applicant);
    setCount(noOfRecords);
  };

  const getJobOpening = () => {
    setLoading(true);
    AxiosActions.JobList({}).then((data) => {
      setJobOpening(data.data.data);
      setLoading(false);
    });
  };

  // Filter record

  const filterRecord = useCallback(async () => {
    trackEvent(EVENT_NAME.APPLICANT_LIST_FILTER, {
      data: "Filter list in applicant",
    });
    let pageNumber = controller.page;
    setLoading(true);

    if (!isFiltered) {
      pageNumber = 0;
      setIsFiltered(true);
      setController((prevController) => {
        return {
          ...prevController,
          page: 0,
          preventRefresh: true,
        };
      });
    }
    AxiosActions.ApplicantFilter({
      pageNo: pageNumber,
      size: controller.rowsPerPage,
      filter: !filterEmail || inputList ? 'name' : '',
      filterValue: inputList,
      status: filterStatus,
      job: filterJobOpening,
      emailValue: filterEmail,
      emailFilter: filterEmail ? 'email' : '',
      type: filterDate,
      startDate: startDate,
      endDate: endDate,
    })
      .then((data) => {
        setRenderState(data.data.data.records, data.data.data.noRecords);
        setLoading(false);
      })
      .catch((err) => {
        console.log("applicants/filtererr", err);
        toast.dismiss();
        toast.error('Something went wrong!');
      });
    setSelectedInfo({ selectedID: [] });
  }, [controller.page, controller.rowsPerPage, filterJobOpening, filterStatus, inputList, isFiltered, filterEmail, endDate, filterDate, startDate]);

  useEffect(() => {
    getJobOpening();
  }, []);

  useEffect(() => {

    if (isFiltered === true) {
      if (!controller.preventRefresh) filterRecord();
    } else {
      renderdata();
    }
  }, [controller, filterRecord, isFiltered, renderdata]);

  // New Pagination

  const handlePageChange = (event, newPage) => {
    setController({
      ...controller,
      page: newPage,
      preventRefresh: false,
    });
    setSelectedInfo({ selectedID: [] });
  };

  const handleChangeRowsPerPage1 = (event) => {
    setController({
      ...controller,
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0,
      preventRefresh: false,
    });

    setSelectedInfo({ selectedID: [] });
  };

  // Update status

  const changeStatus = (statusid, title) => {
    setIsDisable(true);
    trackEvent(EVENT_NAME.APPLICANT_CHANGE_STATUS, { data: status });
    console.log(statusid);
    const token = localStorage.getItem("access_token");
    AxiosActions.ApplicantStatus({
      applied_id: statusid,
      id: statusid,
      status: status,
      note: inputStatus,
      token: token,
      adminId: adminData.adminData._id,
    })
      .then((data) => {
        console.log("Apply Job Table", data);
        setIsDisable(false);
        renderdata();
      })
      .catch((err) => {
        setIsDisable(false);
        console.log(err);
        alert("Please update again");
      });
    setStatus("Applied");
    setInputStatus("");

    setSelectedInfo({ selectedID: [] });
  };

  //Download Resume

  const handleDownload = (link, id) => {
    trackEvent(EVENT_NAME.APPLICANT_DOWNLOAD, { data: link });
    if (link.includes("3elixir.s3.ap")) {
      window.open(link);
    } else {
      AxiosActions.openResume(id)
        .then((data) => {
          if (data.data.code === 200) {
            window.open(data?.data?.data?.url);
          }
        })
        .catch((err) => {
          toast.dismiss();
          toast.error('Resume unavailable. Please try later.');
        });
    }
  };

  //Reset Filter

  const reset = () => {
    trackEvent(EVENT_NAME.APPLICANT_LIST_RESET, {
      data: "Reset list in applicant",
    });
    setApplicants([]);
    setInputList("");
    setFilter("");
    setFilterStatus("All");
    setFilterJobOpening("All");
    setController({
      ...controller,
      page: 0,
      rowsPerPage: 20,
      preventRefresh: false,
    });
    setSelectedInfo({ selectedID: [] });
    setIsFiltered(false);
    setFilterEmail("");
    setStartDate("");
    setEndDate("");
    setFilterDate('all');
  };

  //Status Change

  const changeStatusValue = (event) => {
    setStatus(event.target.value);
  };

  const changeInputStatus = (event) => {
    setInputStatus(event.target.value);
  };

  //View Status

  const handleViewClick = (event, id) => {
    trackEvent(EVENT_NAME.APPLICANT_STATUS_VIEW, { data: id });
    AxiosActions.ApplicantNotesList({
      applied_id: id,
    })
      .then((data) => {
        console.log("view data", data.data.data);
        setApplicantsNotes(data.data.data);
      })
      .catch((err) => {
        alert("Please Check Again.");
      });
    event.preventDefault();
  };
  const handleDeleteClick = (event, id) => {
    setDeleteId(id);

    event.preventDefault();
  };

  //Delete Record

  const deleteRecord = (event, id) => {
    setIsDisable(true);
    trackEvent(EVENT_NAME.APPLICANT_DELETE, { data: id });
    console.log(id);
    AxiosActions.DeleteApplicant({
      _id: id,
      adminId: adminData.adminData._id,
    })
      .then((data) => {
        renderdata();
        setIsDisable(false);
      })
      .catch((err) => {
        alert("Please Delete Record Again.");
        setIsDisable(false);
      });

    event.preventDefault();
  };

  //View Full Report

  const handleViewReportClick = (event, id) => {
    trackEvent(EVENT_NAME.APPLICANT_VIEW, { data: id });
    const filterRecord = applicants.filter((item) => {
      if (id === item._id) {
        console.log(item);
        setStatus(item.status);
        return true;
      }
      return false;
    });
    setUpdateId(id);
    setFullReport(filterRecord);
  };
  //Select Multiple

  const handleCheckBoxChange = (e) => {
    const value = e.target.value;
    const { checked } = e.target;
    const list = [];
    const { selectedID } = selectedinfo;

    if (value === "all") {
      if (value === "all" && checked) {
        applicants.map((item) => {
          return list.push(item._id);
        });
        setSelectedInfo({
          selectedID: list,
        });
      } else {
        setSelectedInfo({
          selectedID: list.filter((e) => e !== value),
        });
      }
      return;
    } else if (checked) {
      setSelectedInfo({
        selectedID: [...selectedID, value],
      });
    } else {
      setSelectedInfo({
        selectedID: selectedID.filter((e) => e !== value),
      });
    }
  };

  //Delete Multiple

  const handleDeleteMultipleClick = (event) => {
    event.preventDefault();
  };
  const deleteAllRecord = (event) => {
    setIsDisable(true);
    trackEvent(EVENT_NAME.APPLICANT_DELETE_ALL, { data: "" });
    console.log("Delete All Records");
    AxiosActions.MultipleDeleteApplicants({
      _id: selectedinfo.selectedID,
      adminId: adminData._id,
    })
      .then((data) => {
        setIsDisable(false);
        renderdata();
        console.log("api/v1/applicants/deletemany", data);
      })
      .catch((err) => {
        setIsDisable(false);
        alert("Please Delete Record Again");
      });

    setSelectedInfo({ selectedID: [] });
    event.preventDefault();
  };

  //Multiple Status

  const handleStatusMultipleClick = (event) => {
    event.preventDefault();
  };

  const changeMultipleStatus = (event) => {
    setIsDisable(true);
    const token = localStorage.getItem("access_token");
    trackEvent(EVENT_NAME.APPLICANT_CHANGE_STATUS_ALL, { data: "" });
    AxiosActions.MultipleApplicantStatus({
      id: selectedinfo.selectedID,
      status: multipleStatus,
      token: token,
      data: selectedinfo.selectedID,
      note: multipleInputStatus,
      adminId: adminData._id,
    })
      .then((data) => {
        setIsDisable(false);
        renderdata();
        console.log("Change Many Status", data);
      })
      .catch((err) => {
        setIsDisable(false);
        alert("Please update again");
      });

    setMultipleStatus("Applied");
    setMultipleInputStatus("");
    setSelectedInfo({ selectedID: [] });
  };

  //Multiple Status Change

  const changeMultipleInputStatus = (event) => {
    setMultipleInputStatus(event.target.value);
  };

  const changeMultipleStatusValue = (event) => {
    setMultipleStatus(event.target.value);
  };

  return (
    <>
      {token === null ? (
        true((window.location.href = "/"))
      ) : (
        <main id="content" role="main" className="main">
          <div className="content container-fluid">
            <div className="page-header">
              <div className="row align-items-center">
                <div
                  className="col align-items-center"
                  style={{ alignItems: "center" }}
                >
                  <h1
                    className="page-header-title align-items-center"
                    style={{ alignSelf: "center" }}
                  >
                    Applicants
                  </h1>
                </div>
              </div>
            </div>

            <div className="card mb-3 mb-lg-5">
              <div className="card-header">
                <div className="row justify-content-between align-items-center flex-grow-1">
                  <div className="col-md">
                    <div className="d-flex justify-content-between align-items-center"></div>
                  </div>
                  <div className="col-auto">
                    <div className="row align-items-sm-center">
                      <Filter
                        status={true}
                        statusValue={filterStatus}
                        statusUpdate={setFilterStatus}
                        statusList={Config?.STATUS_LIST}
                        searchName={true}
                        searchNameValue={inputList}
                        searchNameUpdate={setInputList}
                        date={true}
                        dateValue={filterDate}
                        dateUpdate={setFilterDate}
                        startDateValue={startDate}
                        startDateUpdate={setStartDate}
                        endDateValue={endDate}
                        endDateUpdate={setEndDate}
                        searchEmail={true}
                        searchEmailValue={filterEmail}
                        searchEmailUpdate={setFilterEmail}
                        job={true}
                        jobValue={filterJobOpening}
                        jobUpdate={setFilterJobOpening}
                        jobList={jobOpening}
                        multipleSelect={selectedinfo?.selectedID?.length}
                        multipleDelete={true}
                        isDisable={isDisable}
                        handleDelete={handleDeleteMultipleClick}
                        deleteAllRecord={deleteAllRecord}
                        multipleStatus={true}
                        handleStatus={handleStatusMultipleClick}
                        changeMultipleStatusValue={changeMultipleStatusValue}
                        changeMultipleInputStatus={changeMultipleInputStatus}
                        multipleStatusList={Config?.STATUS_LIST}
                        changeMultipleStatus={changeMultipleStatus}
                        filterRecord={filterRecord}
                        resetRecord={reset}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="table-responsive datatable-custom">
                <table
                  id="datatable"
                  className="table table-borderless table-thead-bordered table-nowrap table-align-middle card-table"
                >
                  <thead className="thead-light">
                    <tr>
                      <th scope="col" className="table-column-pe-0">
                        <Checkbox
                          size="medium"
                          value="all"
                          onChange={handleCheckBoxChange}
                          checked={isAllSelected}
                        ></Checkbox>
                      </th>
                      <th>Date & Time</th>
                      <th>Name</th>

                      <th>Phone</th>
                      <th>Job Title</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>

                  <tbody>
                    {loading ? (
                      <tr>
                        <td colSpan={8} style={{ textAlign: "center" }}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <Loader />
                          </div>
                        </td>
                      </tr>
                    ) : (
                      <>
                        {" "}
                        {applicants.length === 0 && (
                          <tr>
                            <td colSpan={8} style={{ textAlign: "center" }}>
                              {" "}
                              <h3>No record found</h3>
                            </td>
                          </tr>
                        )}
                        {applicants.map((app, index) => {
                          return (
                            <tr key={"app" + index}>
                              <td className="table-column-pe-0">
                                <Checkbox
                                  key={index}
                                  size="medium"
                                  value={app._id}
                                  onChange={handleCheckBoxChange}
                                  checked={selectedinfo.selectedID.includes(
                                    app._id
                                  )}
                                ></Checkbox>
                              </td>
                              <td style={{ width: "5%" }}>
                                {moment(app.createdAt).format("DD-MM-YYYY h:mm A")}
                              </td>
                              <td
                                className="table-column-ps-0"
                                style={{ textWrap: "wrap" }}
                              >
                                <h5
                                  className="text-inherit mb-0"
                                  style={{ width: "200px" }}
                                >
                                  {app.name}
                                </h5>
                                {app.email}
                              </td>

                              <td>{app.phone}</td>
                              <td>
                                {app.job
                                  ? app.job.job_title
                                  : "Job not Mentioned"}
                              </td>
                              <td>
                                <span className="legend-indicator bg-success" />
                                {app.status}
                              </td>
                              <td>
                                &nbsp;
                                <button
                                  title="Download resume"
                                  className="icon icon-success icon-circle"
                                  onClick={() =>
                                    handleDownload(app.resume, app._id)
                                  }
                                >
                                  <i className="bi-download"></i>
                                </button>{" "}
                                &nbsp;
                                <button
                                  title="Edit applicant"
                                  type="button"
                                  className="icon icon-danger icon-circle"
                                  data-bs-toggle="modal"
                                  data-bs-target="#exampleModalLong"
                                  onClick={(event) =>
                                    handleViewReportClick(event, app._id)
                                  }
                                >
                                  <i className="bi-pen"></i>
                                </button>
                                &nbsp;
                                <button
                                  title="View applicant status"
                                  type="button"
                                  className="icon icon-warning icon-circle"
                                  data-bs-toggle="modal"
                                  data-bs-target="#view"
                                  onClick={(event) =>
                                    handleViewClick(event, app._id)
                                  }
                                >
                                  <i className="bi-eye"></i>
                                </button>
                                &nbsp;
                                <button
                                  title="Delete applicant"
                                  type="button"
                                  className="icon icon-info icon-circle"
                                  data-bs-toggle="modal"
                                  data-bs-target="#delete"
                                  onClick={(event) =>
                                    handleDeleteClick(event, app._id)
                                  }
                                >
                                  <i className="bi-trash"></i>
                                </button>
                                &nbsp;
                                <button
                                  title="View applicant"
                                  type="button"
                                  className="icon icon-dark icon-circle"
                                  data-bs-toggle="modal"
                                  data-bs-target="#viewpage"
                                  onClick={(event) =>
                                    handleViewReportClick(event, app._id)
                                  }
                                >
                                  <i className="bi-files"></i>
                                </button>
                                &nbsp;
                              </td>
                              <></>
                            </tr>
                          );
                        })}
                      </>
                    )}
                    {/* mapping */}
                  </tbody>
                </table>
              </div>
              <div
                className="modal fade"
                id="viewpage"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="exampleModalCenterTitle"
                aria-hidden="true"
              >
                <div
                  className="modal-dialog modal-dialog-centered"
                  role="document"
                >
                  <div className="modal-content">
                    <div className="modal-header">
                      <h1 className="modal-title" id="exampleModalLongTitle">
                        Report
                      </h1>

                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="modal-body">
                      {fullReport.map((item, index) => {
                        return (
                          <>
                            <h3 style={{ color: "red" }}>Basic Info</h3>
                            <div>
                              <table className="table table-align-middle">
                                <thead>
                                  <tr>
                                    <th scope="col"></th>
                                    <th scope="col"></th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>
                                      <h4>Source</h4>

                                      {item.source ? item.source : "-"}
                                    </td>
                                    <td>
                                      <h4>Name</h4>

                                      {item.name ? item.name : "-"}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <h4>Email</h4>
                                      {item.email ? item.email : "-"}
                                    </td>
                                    <td>
                                      <h4>Phone</h4>
                                      {item.phone ? item.phone : "-"}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <h4>Current Location</h4>
                                      {item.currentLocation
                                        ? item.currentLocation
                                        : "-"}
                                    </td>
                                    <td>
                                      <h4>City</h4>
                                      {item.city ? item.city : "-"}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <h4>Gender</h4>
                                      {item.gender ? item.gender : "-"}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>

                            <h3 style={{ color: "red" }}>Job informations</h3>
                            <div>
                              <table className="table table-align-middle">
                                <thead>
                                  <tr>
                                    <th scope="col"></th>
                                    <th scope="col"></th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>
                                      <h4>Qualification</h4>
                                      {item.qualification
                                        ? item.qualification
                                        : "-"}
                                    </td>
                                    <td>
                                      <h4>Experience</h4>
                                      {item.experience
                                        ? item.experience
                                        : "-"}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <h4>Job</h4>
                                      {item.job ? item.job.job_title : "-"}
                                    </td>
                                    <td>
                                      <h4>Current CTC</h4>
                                      {item.currentCTC
                                        ? item.currentCTC
                                        : "-"}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <h4>Expected CTC</h4>
                                      {item.expectedCTC
                                        ? item.expectedCTC
                                        : "-"}
                                    </td>
                                    <td>
                                      <h4>Offered CTC</h4>
                                      {item.offeredCTC
                                        ? item.offeredCTC
                                        : "-"}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <h4>Resume</h4>
                                      <button
                                        className="icon icon-success icon-circle"
                                        onClick={() =>
                                          handleDownload(
                                            item.resume,
                                            item._id
                                          )
                                        }
                                      >
                                        <i className="bi-download"></i>
                                      </button>
                                    </td>
                                    <td>
                                      <h4>Remark</h4>
                                      {item.remarks ? item.remarks : "-"}
                                    </td>
                                  </tr>

                                  <tr>
                                    <td>
                                      <h4>Status</h4>
                                      {item.status ? item.status : "-"}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </>
                        );
                      })}
                    </div>
                    <div className="modal-footer"></div>
                  </div>
                </div>
              </div>
              <div
                className="modal fade"
                id="exampleModalLong"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="exampleModalCenterTitle"
                aria-hidden="true"
              >
                <div
                  className="modal-dialog modal-dialog-centered"
                  role="document"
                >
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title" id="exampleModalLongTitle">
                        Status
                      </h5>

                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="modal-body">
                      {/* Status Modal */}
                      <div className="modal-content">
                        <table>
                          <tbody>
                            <tr>
                              <td>
                                {" "}
                                <label className="row-heading">
                                  Status :
                                </label>
                              </td>
                              <td>
                                {" "}
                                  <Autocomplete
                                   options={Config.STATUS_LIST || []}
                                   getOptionLabel={(option) => option.value || ""}
                                   value={
                                     Config.STATUS_LIST.find(
                                       (item) => item.value === status
                                     ) || null
                                   }
                                   onChange={(event, newValue) => {
                                     setStatus(
                                       newValue === null? "Select Status" : newValue.value
                                      );
                                    }}
                                   size="small"
                                   renderInput={(params) => 
                                    <TextField {...params}
                                    />}
                                    />
                              </td>
                            </tr>
                            <br />
                            <tr>
                              {" "}
                              <td>
                                {" "}
                                <label className="row-heading">
                                  Description :
                                </label>
                              </td>
                              <td>
                                <textarea
                                  className="form-control is-valid"
                                  placeholder=" Description"
                                  id="validationValidTextarea1"
                                  rows="4"
                                  onChange={(e) => changeInputStatus(e)}
                                ></textarea>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-white"
                        data-bs-dismiss="modal"
                      >
                        Close
                      </button>
                      <button
                        disabled={isDisable === true}
                        type="button"
                        className="btn btn-primary"
                        data-bs-dismiss="modal"
                        onClick={() => {
                          changeStatus(updateId);
                        }}
                      >
                        Save changes
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="modal fade"
                id="view"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="exampleModalCenterTitle"
                aria-hidden="true"
              >
                <div
                  className="modal-dialog modal-dialog-centered"
                  role="document"
                >
                  <div className="modal-content">
                    <div className="modal-header">
                      <h1 className="modal-title" id="exampleModalLongTitle">
                        Status
                      </h1>

                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="modal-body">
                      {applicantsNotes.length === 0 ? (
                        <Typography
                          sx={{ marginLeft: 30 }}
                          className="typograpy-status"
                          variant="body"
                        >
                          No Record Found
                        </Typography>
                      ) : (
                        applicantsNotes.map((item, index) => {
                          var date = new Date(item.createdAt).toLocaleString(
                            undefined,
                            {
                              timeZone: "Asia/Kolkata",
                            }
                          );
                          const date1 = date.split(",");
                          const date2 = date1[0].split("/");
                          const time = date1[1].split(" ");
                          const time1 = time[1].split(":");
                          return (
                            <Timeline
                              className="timeline-content"
                              key={index}
                              position="alternate"
                            >
                              <TimelineItem>
                                <TimelineOppositeContent
                                  sx={{ m: "auto 0" }}
                                  align="right"
                                  variant="body"
                                  color="secondary.main"
                                  className="status-timeline"
                                >
                                  {`${
                                    date2[1] + "/" + date2[0] + "/" + date2[2]
                                  }`}{" "}
                                  {`${time1[0] + ":" + time1[1]}`} {time[2]}
                                </TimelineOppositeContent>
                                <TimelineSeparator>
                                  <TimelineConnector
                                    sx={{
                                      bgcolor: "secondary.main",
                                    }}
                                  />
                                  <TimelineDot
                                    sx={{
                                      bgcolor: "#388e3c",
                                    }}
                                  >
                                    <VerifiedIcon />
                                  </TimelineDot>
                                  <TimelineConnector
                                    sx={{
                                      bgcolor: "secondary.main",
                                    }}
                                  />
                                </TimelineSeparator>
                                <TimelineContent
                                  variant="body"
                                  sx={{ py: "12px", px: 2 }}
                                >
                                  <div>
                                    <Chip
                                      color="secondary"
                                      className="chip-status"
                                      label={`${item.status}`}
                                    />
                                  </div>
                                  <div
                                    style={{
                                      marginTop: 5,
                                      marginLeft: 7,
                                    }}
                                  >
                                    <Typography
                                      className="typograpy-status"
                                      variant="div"
                                    >
                                      {item.note}
                                    </Typography>
                                  </div>
                                  <div
                                    style={{
                                      marginTop: 7,
                                      marginLeft: 7,
                                    }}
                                  >
                                    <Typography
                                      className="typograpy-status"
                                      variant="div"
                                    >
                                      Updated By -{" "}
                                      {item.userId && item.userId.username}
                                    </Typography>
                                  </div>
                                </TimelineContent>
                              </TimelineItem>
                            </Timeline>
                          );
                        })
                      )}
                    </div>
                    <div className="modal-footer"></div>
                  </div>
                </div>
              </div>
              <div
                className="modal fade"
                id="delete"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="exampleModalCenterTitle"
                aria-hidden="true"
              >
                <div
                  className="modal-dialog modal-dialog-centered"
                  role="document"
                >
                  <div className="modal-content">
                    <div className="modal-header">
                      <h1 className="modal-title" id="exampleModalLongTitle">
                        Delete
                      </h1>

                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="modal-body">
                      <label style={{ fontSize: 18, marginLeft: 60 }}>
                        “Are you sure, you want to delete the record?”
                      </label>
                    </div>
                    <div className="modal-footer">
                      <button
                        disabled={isDisable === true}
                        type="button"
                        className="btn btn-danger"
                        data-bs-dismiss="modal"
                        onClick={(event) => {
                          deleteRecord(event, deleteId);
                        }}
                      >
                        Yes
                      </button>
                      <button
                        type="button"
                        className="btn btn-primary"
                        data-bs-dismiss="modal"
                      >
                        No
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-footer">
                <Pagination
                  handlePageChange={handlePageChange}
                  controller={controller}
                  count={count}
                  handleChangeRowsPerPage1={handleChangeRowsPerPage1}
                />
              </div>
            </div>
          </div>
        </main>
      )}
    </>
  );
}

export default HeaderNavbarHOC(ApplicantsList);
