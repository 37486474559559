import React from 'react';
import moment from 'moment';
import { Checkbox } from '@mui/material';
import { Loader } from '../../components/Common';
import Config from '../../config';
import { employee } from '../../constant/Employee';
import PropTypes from 'prop-types';

const EmployeeTable = ({
  applicants,
  loading,
  selectedinfo,
  handleCheckBoxChange,
  isAllSelected,
  handleViewReportClick,
  handleDeleteClick,
  calculateDay,
}) => {
  return (
    <div className="table-responsive datatable-custom">
      <table
        id="datatable"
        className="table table-borderless table-thead-bordered table-nowrap table-align-middle card-table"
      >
        <thead className="thead-light">
          <tr>
            <th scope="col" className="table-column-pe-0">
              <Checkbox
                size="medium"
                value="all"
                onChange={handleCheckBoxChange}
                checked={isAllSelected}
              ></Checkbox>
            </th>
            <th>{employee.ID}</th>
            <th>{employee.EMPLOYEE_NAME}</th>
            <th>{employee.DATE_OF_JOINING}</th>
            <th>{employee.DATE_OF_LEAVING}</th>
            <th>{employee.CURRENT_STATUS}</th>
            <th>{employee.EMERGENCY_NAME}</th>
            <th>{employee.EMERGENCY_NUMBER}</th>
            <th>{employee.STATUS}</th>
            <th>{employee.ACTION}</th>
          </tr>
        </thead>
        <tbody>
          {loading ? (
            <tr>
              <td colSpan={8} style={{ textAlign: "center" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Loader />
                </div>
              </td>
            </tr>
          ) : (
            <>
              {applicants.length === 0 && (
                <tr>
                  <td colSpan={8} style={{ textAlign: "center" }}>
                    <h3>{employee.NO_RECORD_FOUND}</h3>
                  </td>
                </tr>
              )}
              {applicants.map((app, index) => {
                const empStatus = Config.EMPLOYEE_STATUS?.find(
                  (item) => item.value === app.status
                )?.name;
                return (
                  <tr key={"app" + index}>
                    <td className="table-column-pe-0">
                      <Checkbox
                        key={app._id}
                        size="medium"
                        value={app._id}
                        onChange={handleCheckBoxChange}
                        checked={selectedinfo.selectedID.includes(app._id)}
                      ></Checkbox>
                    </td>
                    <td>{app.empid}</td>
                    <td className="table-column-ps-1">
                      <h5 className="text-inherit mb-0">{app.employeeName}</h5>
                    </td>
                    <td style={{ width: "5%" }}>
                      {app.DateOfJoin != null
                        ? moment(app.DateOfJoin).format("DD-MM-YYYY")
                        : ""}
                    </td>
                    <td style={{ width: "5%" }}>
                      {app.DateOfExit != null
                        ? moment(app.DateOfExit).format("DD-MM-YYYY")
                        : "-"}
                    </td>
                    <td style={{ width: "5%" }}>
                      {app.DateOfJoin != null
                        ? calculateDay(
                            app.DateOfJoin,
                            app.DateOfExit != null ? app.DateOfExit : null
                          )
                        : "-"}
                    </td>
                    <td>{app.emergencyName || "-"}</td>
                    <td>{app.emergencyMobile || "-"}</td>
                    <td>{empStatus}</td>
                    <td>
                      <button
                        title="Edit employee"
                        type="button"
                        className="icon icon-danger icon-circle"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModalLong"
                        onClick={(event) => handleViewReportClick(event, app._id)}
                      >
                        <i className="bi-pen"></i>
                      </button>
                      &nbsp;
                      <button
                        title="Delete employee"
                        type="button"
                        className="icon icon-info icon-circle"
                        data-bs-toggle="modal"
                        data-bs-target="#delete"
                        onClick={(event) => handleDeleteClick(event, app._id)}
                      >
                        <i className="bi-trash"></i>
                      </button>
                      &nbsp;
                      <button
                        title="View employee"
                        type="button"
                        className="icon icon-dark icon-circle"
                        data-bs-toggle="modal"
                        data-bs-target="#viewpage"
                        onClick={(event) => handleViewReportClick(event, app._id)}
                      >
                        <i className="bi-eye"></i>
                      </button>
                    </td>
                  </tr>
                );
              })}
            </>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default EmployeeTable;

EmployeeTable.propTypes = {
  applicants: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  selectedinfo: PropTypes.object.isRequired,
  handleCheckBoxChange: PropTypes.func.isRequired,
  isAllSelected: PropTypes.bool.isRequired,
  handleViewReportClick: PropTypes.func.isRequired,
  handleDeleteClick: PropTypes.func.isRequired,
  calculateDay: PropTypes.func.isRequired,
};