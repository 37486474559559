import React from "react";
import TablePagination from "@mui/material/TablePagination";
import Config from "../../config";

function Pagination({
  handlePageChange,
  controller,
  count,
  handleChangeRowsPerPage1,
  }) {
  return (
    <TablePagination
      component="pagination"
      rowsPerPageOptions={Config?.ROWS_PER_PAGE_OPTIONS}
      onPageChange={handlePageChange}
      page={controller?.page}
      count={count}
      rowsPerPage={controller?.rowsPerPage}
      onRowsPerPageChange={handleChangeRowsPerPage1}
      labelRowsPerPage="Records:"
      labelDisplayedRows={() => {
        const start = controller?.page * controller?.rowsPerPage + 1; // Starting record for current page
        const end = Math.min((controller?.page + 1) * controller?.rowsPerPage, count); // Ending record for current page

        return `${start}-${end} of ${count}`;
      }}
      sx={{
        ".MuiTablePagination-toolbar": {
          alignItems: "baseline",
        },
      }}
    />
  );
}

export default Pagination;
