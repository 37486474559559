import Config from '../config/index'
const getClientStatus = (val,type) => {
    let data = Config.TRACKING_TOOLS_STATUS;
    if(type=='client'){
        data =Config.CLIENT_STATUS;
    } 
     if(type=='trackingtools'){
        data =Config.TRACKING_TOOLS_STATUS;

    }
    if(type=='jobs'){
        data =Config.JOBS_STATUS;

    }
    if(type=='salary'){
        data =Config.SALARY_STATUS;

    }
    if(type=='Expenses'){
        data =Config.EXPENSES_STATUS;

    }

    const temp =  data.filter((item)=>val==item.value);
   
        if(temp.length>0){
         return temp[0].name
        }
        return "-"
       
}

export   {
    getClientStatus
}