import React, { useState, useEffect, useContext, useMemo } from "react";
import Config from "../../config";
import Axios from "axios";
import HeaderNavbarHOC from "../../HOC/HeaderNavbar";
import AlertBox from "../../Alert";
import { trackEvent, EVENT_NAME } from "../../constant/MixpanelConstant";
import { AuthContext } from "../../Login/Protected";
import moment from "moment";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import Datepicker from "../../Datepicker";
import { HeaderTitle, Pagination, Filter } from "../../components/Common";
import View from "./View";
import Edit from "./Edit";
import Table from "./Table";
import { DATAUPDATESUCCESSFULLY } from "../../constant/Label";

function TaskList() {
  const adminData = useContext(AuthContext);
  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
  };
  const [loading, setLoading] = useState(true);
  const [startdate, setStartdate] = useState("");
  const [enddate, setEnddate] = useState("");
  const [filtertype, setFiltertype] = useState("all");
  const [openAlert, setOpenAlert] = useState(false);
  const [applicants, setApplicants] = useState([]);
  const [isFiltered, setIsFiltered] = useState(false);
  const [updateId, setUpdateId] = useState("");
  const [deleteId, setDeleteId] = useState("");
  const [fullReport, setFullReport] = useState([]);
  const [filterStatus, setFilterStatus] = useState("all");
  const [count, setCount] = useState(0);
  const [inputList, setInputList] = useState("");
  const [filterArr, setFilterArr] = useState([]);
  const [status, setStatus] = useState();
  const [updateproject, setUpdateproject] = useState("");
  const [updateemployee, setUpdateemployee] = useState("");
  const [updatesummary, setUpdatesummary] = useState("");
  const [updatedate, setUpdatedate] = useState("");
  const [isDisable, setIsDisable] = useState(false);
  const [clientId, setClientId] = useState("");
  const [getclient, setGetclient] = useState([]);
  const [empid, setEmpid] = useState("");
  const [getdata, setGetdata] = useState([]);
  const [selectedinfo, setSelectedinfo] = useState({
    selectedID: [],
  });
  const [controller, setController] = useState({
    page: 0,
    rowsPerPage: 10,
    preventRefresh: false,
  });

  // FOR SELECT ALL

  const isAllSelected =
    applicants.length > 0 &&
    selectedinfo.selectedID.length === applicants.length;
  const token = localStorage.getItem("access_token");

  // RENDER DATA

  const renderdata = async () => {
    try {
      setLoading(true);
      await Axios.post(Config.LOCALURL + "tasks/pagination", {
        page: controller.page,
        limit: controller.rowsPerPage,
        startDate: startdate,
        endDate: enddate,
        type: filtertype,
        status: filterStatus,
        clientId: clientId,
        empid: adminData.adminData.isAdmin
          ? empid
          : adminData.adminData.empid._id,
      })
        .then((data) => {
          if (data.status === 200) {
            setRenderState(data.data.results, data.data.count);
          }
          setLoading(false);
        })
        .catch((error) => {
          console.log("Catch Err in Pagination ", error);
        });
    } catch (err) {
      console.log("Catch err in render data", err);
    }
  };

  const setRenderState = (applicant, noOfRecords) => {
    setApplicants(applicant);
    setCount(noOfRecords);
  };
  const getClient = () => {
    Axios.get(Config.LOCALURL + "client/active")
      .then((response) => {
        if (response.status === 200) {
          setGetclient(response.data.data);
        }
      })
      .catch((error) => {
        console.log("Error in get Client", error);
      });
  };
  const getEmployeeList = () => {
    Axios.get(Config.LOCALURL + "employee/active")
      .then((response) => {
        if (response.status === 200) {
          setGetdata(response.data.data);
        }
      })
      .catch((error) => {
        console.log("Error in get employee list", error);
      });
  };
  useEffect(() => {
    getClient();
    getEmployeeList();
    if (isFiltered === true) {
      if (!controller.preventRefresh) filterRecord();
    } else {
      renderdata();
    }
  }, [controller, adminData.adminData]);

  // New Pagination

  const handlePageChange = (event, newPage) => {
    setController({
      ...controller,
      page: newPage,
      preventRefresh: false,
    });
    setSelectedinfo({ selectedID: [] });
  };

  const handleChangeRowsPerPage1 = (event) => {
    setController({
      ...controller,
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0,
      preventRefresh: false,
    });

    setSelectedinfo({ selectedID: [] });
  };

  // Filter record

  const filterRecord = async () => {
    trackEvent(EVENT_NAME.TASK_LIST_FILTER, { data: "" });
    if (!isFiltered) {
      setIsFiltered(true);
      setController((prevController) => {
        return {
          ...prevController,
          page: 0,
          preventRefresh: true,
        };
      });
    }
    renderdata();
    setSelectedinfo({ selectedID: [] });
  };

  // Update status
  const changeStatus = (statusid, title) => {
    setIsDisable(true);
    trackEvent(EVENT_NAME.TASK_EDIT, { data: statusid });
    Axios.post(Config.LOCALURL + "tasks/edit", {
      id: statusid,

      clientId: updateproject,
      empid: updateemployee,
      summary: updatesummary,
      date: updatedate,
      status: status,
      adminId: adminData.adminData._id,
    })
      .then((data) => {
        setOpenAlert(true);
        renderdata();
        setIsDisable(false);
      })
      .catch((err) => {
        console.log(err);
        alert("Please Delete Record Again.");
        setIsDisable(false);
      });

    setSelectedinfo({ selectedID: [] });
  };

  //Reset Filter

  const reset = () => {
    trackEvent(EVENT_NAME.TASK_RESET, { data: "" });
    setApplicants(filterArr);
    setInputList("");
    setFilterStatus("all");

    setFiltertype("all");

    setClientId("");
    setEmpid("");
    setController({
      ...controller,
      page: 0,
      rowsPerPage: 10,
      preventRefresh: false,
    });
    setSelectedinfo({ selectedID: [] });
    setIsFiltered(false);
  };

  const handleDeleteClick = (event, id) => {
    setDeleteId(id);

    event.preventDefault();
  };

  // Delete Record

  const deleteRecord = (event, id) => {
    setIsDisable(true);
    trackEvent(EVENT_NAME.TASK_DELETE, { data: id });
    Axios.post(Config.LOCALURL + "tasks/delete", {
      id: id,
      adminId: adminData.adminData._id,
    })
      .then((data) => {
        renderdata();
        setIsDisable(false);
      })
      .catch((err) => {
        console.log(err);
        setIsDisable(false);
        alert("Please Delete Record Again.");
      });

    event.preventDefault();
  };

  //View Full Report

  const handleViewReportClick = (event, id) => {
    trackEvent(EVENT_NAME.TASK_VIEW, { data: id });
    const filterRecord = applicants.filter((item) => {
      if (id === item._id) {
        setUpdateproject(item.clientId._id);
        setUpdateemployee(item.empid._id);
        setUpdatesummary(item.summary);
        setUpdatedate(moment(item.date).format("YYYY-MM-DD"));
        setStatus(item.status);
        return true;
      }
      return false;
    });

    setFullReport(filterRecord);
    setUpdateId(id);
  };

  //Select Multiple

  const handleCheckBoxChange = (e) => {
    const value = e.target.value;
    const { checked } = e.target;
    const list = [];
    const { selectedID } = selectedinfo;

    if (value === "all") {
      if (value === "all" && checked) {
        applicants.forEach((item) => {
          list.push(item._id);
        });
        setSelectedinfo({
          selectedID: list,
        });
      } else {
        setSelectedinfo({
          selectedID: list.filter((e) => e !== value),
        });
      }
    } else if (checked) {
      setSelectedinfo({
        selectedID: [...selectedID, value],
      });
    } else {
      setSelectedinfo({
        selectedID: selectedID.filter((e) => e !== value),
      });
    }
  };

  //Delete Multiple

  const handleDeleteMultipleClick = (event) => {
    event.preventDefault();
  };
  const deleteAllRecord = (event) => {
    setIsDisable(true);
    trackEvent(EVENT_NAME.TASK_DELETE_ALL, { data: "" });
    Axios.post(Config.LOCALURL + "tasks/delete/all", {
      id: selectedinfo.selectedID,
      adminId: adminData.adminData._id,
    })
      .then((data) => {
        renderdata();
        setIsDisable(false);
      })
      .catch((err) => {
        alert("Please Delete Record Again");
        setIsDisable(false);
      });

    setSelectedinfo({ selectedID: [] });
    event.preventDefault();
  };

  const results = [];

  for (const reacord of applicants) {
    if (
      reacord.empid &&
      !Object.hasOwn(results, reacord.empid.employeeName)
    ) {
      results[reacord.empid.employeeName] = {};
      for (const status of Config.TASK_STATUS) {
        results[reacord.empid.employeeName][status] = 0;
      }
    }
    if (reacord.empid) {
      results[reacord.empid.employeeName][reacord.status] += 1;
    }
  }

  const employeeStatus = [];
  for (const employeeName in results) {
    const statusCounts = results[employeeName];
    const employeeEntry = { employeeName, ...statusCounts };
    employeeStatus.push(employeeEntry);
  }

  const categories = useMemo(() => {
    return employeeStatus
      ? employeeStatus.map((entry) => entry.employeeName)
      : [];
  }, [employeeStatus]);

  const statusKeys = useMemo(() => {
    return employeeStatus && employeeStatus.length > 0
      ? Object.keys(employeeStatus[0]).filter((key) => key !== "employeeName")
      : [];
  }, [employeeStatus]);

  const seriesData = useMemo(() => {
    return statusKeys.map((status) => ({
      name: status,
      data: employeeStatus ? employeeStatus.map((entry) => entry[status]) : [],
    }));
  }, [statusKeys, employeeStatus]);
  return (
    <>
      <AlertBox
        open={openAlert}
        onClose={handleCloseAlert}
        message={DATAUPDATESUCCESSFULLY}
        title="Success"
        ok={false}
      />
      {token === null ? (
        true((window.location.href = "/"))
      ) : (
        <main id="content" role="main" className="main">
          <div className="content container-fluid">
            <HeaderTitle title="Tasks" />
              <div className="card card-bordered h-100">
                <div className="card-body">
                  {seriesData.length > 0 && (
                    <HighchartsReact
                      highcharts={Highcharts}
                      options={{
                        chart: {
                          type: "column",
                        },
                        title: {
                          text: "Task Count",
                          align: "center",
                        },
                        yAxis: {
                          title: {
                            text: "Number of count",
                          },
                        },

                        xAxis: {
                          title: {
                            text: "Employee",
                          },
                          categories: categories,
                          crosshair: true,
                          accessibility: {
                            rangeDescription: "Employee Name",
                          },
                        },

                        legend: {
                          layout: "vertical",
                          align: "right",
                          verticalAlign: "middle",
                        },

                        plotOptions: {
                          series: {
                            dataLabels: {
                              enabled: true,
                            },
                            label: {
                              connectorAllowed: false,
                            },
                          },
                        },
                        series: seriesData,
                        dataLabels: {
                          enabled: true,
                          rotation: -90,
                          color: "#FFFFFF",
                          align: "right",
                          format: "{point.y:.1f}",
                          y: 10,
                          style: {
                            fontSize: "13px",
                            fontFamily: "Verdana, sans-serif",
                          },
                        },
                        responsive: {
                          rules: [
                            {
                              condition: {
                                maxWidth: 500,
                              },
                              chartOptions: {
                                legend: {
                                  layout: "horizontal",
                                  align: "center",
                                  verticalAlign: "bottom",
                                },
                              },
                            },
                          ],
                        },
                      }}
                    />
                  )}
                </div>
              </div>
              <div className="card mb-3 mb-lg-5">
                <div className="card-header">
                  {adminData.adminData.isAdmin ? (
                    <Filter
                      status={true}
                      statusValue={filterStatus}
                      statusUpdate={setFilterStatus}
                      statusList={Config?.TASK_STATUS}
                      project={true}
                      projectValue={clientId}
                      projectUpdate={setClientId}
                      projectList={getclient}
                      date={true}
                      dateValue={filterStatus}
                      dateUpdate={setFilterStatus}
                      startDateValue={startdate}
                      startDateUpdate={setStartdate}
                      endDateValue={enddate}
                      endDateUpdate={setEnddate}
                      multipleSelect={selectedinfo?.selectedID?.length}
                      multipleDelete={true}
                      isDisable={isDisable}
                      handleDelete={handleDeleteMultipleClick}
                      deleteAllRecord={deleteAllRecord}
                      name={true}
                      nameValue={empid}
                      nameUpdate={setEmpid}
                      employeeList={getdata}
                      filterRecord={filterRecord}
                      resetRecord={reset}
                    />
                  ) : (
                    <Filter
                      status={true}
                      statusValue={filterStatus}
                      statusUpdate={setFilterStatus}
                      statusList={Config?.TASK_STATUS}
                      project={true}
                      projectValue={clientId}
                      projectUpdate={setClientId}
                      projectList={getclient}
                      date={true}
                      dateValue={filterStatus}
                      dateUpdate={setFilterStatus}
                      startDateValue={startdate}
                      startDateUpdate={setStartdate}
                      endDateValue={enddate}
                      endDateUpdate={setEnddate}
                      multipleSelect={selectedinfo?.selectedID?.length}
                      multipleDelete={true}
                      isDisable={isDisable}
                      handleDelete={handleDeleteMultipleClick}
                      deleteAllRecord={deleteAllRecord}
                      filterRecord={filterRecord}
                      resetRecord={reset}
                    />
                  )}
                </div>
                <div className="table-responsive datatable-custom">
                  <Table
                    handleCheckBoxChange={handleCheckBoxChange}
                    isAllSelected={isAllSelected}
                    loading={loading}
                    applicants={applicants}
                    selectedinfo={selectedinfo}
                    adminData={adminData}
                    handleViewReportClick={handleViewReportClick}
                    handleDeleteClick={handleDeleteClick}
                  />
                </div>
                <Edit
                  fullReport={fullReport}
                  setupdateproject={setUpdateproject}
                  updateproject={updateproject}
                  getclient={getclient}
                  setupdateemployee={setUpdateemployee}
                  updateemployee={updateemployee}
                  getdata={getdata}
                  setupdatesummary={setUpdatesummary}
                  updatesummary={updatesummary}
                  Datepicker={Datepicker}
                  setupdatedate={setUpdatedate}
                  updatedate={updatedate}
                  setstatus={setStatus}
                  isDisable={isDisable}
                  changeStatus={changeStatus}
                  status={status}
                  updateId={updateId}
                />
                <div
                  className="modal fade"
                  id="delete"
                  tabIndex="-1"
                  role="dialog"
                  aria-labelledby="exampleModalCenterTitle"
                  aria-hidden="true"
                >
                  <div
                    className="modal-dialog modal-dialog-centered"
                    role="document"
                  >
                    <div className="modal-content">
                      <div className="modal-header">
                        <h1 className="modal-title" id="exampleModalLongTitle">
                          Delete
                        </h1>

                        <button
                          type="button"
                          className="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        ></button>
                      </div>
                      <div className="modal-body">
                        <br></br>
                        <label
                        htmlFor="exampleFormControlTextarea1"
                          style={{
                            fontSize: 18,
                            marginLeft: 60,
                          }}
                        >
                          “Are you sure, you want to delete the record?”
                        </label>
                      </div>

                      <div className="modal-footer">
                        <button
                          type="button"
                          className="btn btn-danger"
                          data-bs-dismiss="modal"
                        >
                          No
                        </button>
                        <button
                          disabled={isDisable === true}
                          type="button"
                          className="btn btn-primary"
                          data-bs-dismiss="modal"
                          onClick={(event) => {
                            deleteRecord(event, deleteId);
                          }}
                        >
                          Yes
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <View fullReport={fullReport} />
                <div className="card-footer">
                  <Pagination
                    handlePageChange={handlePageChange}
                    controller={controller}
                    count={count}
                    handleChangeRowsPerPage1={handleChangeRowsPerPage1}
                  />
                </div>
              </div>
            
          </div>
          <div className="footer"></div>
        </main>
      )}
    </>
  );
}

export default HeaderNavbarHOC(TaskList);
