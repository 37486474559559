import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import HighchartsExporting from "highcharts/modules/exporting";
import HighchartsExportData from "highcharts/modules/export-data";
import highchartsAccessibility from "highcharts/modules/accessibility";
import moment from "moment";
highchartsAccessibility(Highcharts);
HighchartsExporting(Highcharts);
HighchartsExportData(Highcharts);

function LineChart({
  series,
  title,
  yAxisTitle,
  xAxisTitle,
  tickPixelInterval,
}) {
  return (
    <div>
      <HighchartsReact
        highcharts={Highcharts}
        options={{
          chart: {
            type: "line",
            zoomType: "x",
            events: {
              selection: function (event) {
                if (event && event.xAxis && event.xAxis[0]) {
                  var min = event?.xAxis[0].min;
                  var max = event?.xAxis[0].max;

                  const minMoment = moment(min);
                  const maxMoment = moment(max);

                  const differenceInDays = maxMoment.diff(minMoment, "days");
                  console.log("differenceInDays: ", differenceInDays);
                  if (differenceInDays <= 5) {
                    return false;
                  }
                }
              },
            },
          },
          exporting: {
            enabled: true,
            buttons: {
              contextButton: {
                menuItems: [
                  "viewFullscreen",
                  "viewData",
                  "downloadPNG",
                  "downloadPDF",
                  "downloadCSV",
                ],
              },
            },
          },
          title: {
            text: title,
            align: "center",
          },
          yAxis: {
            title: {
              text: yAxisTitle,
            },
          },
          xAxis: [
            {
              tickPixelInterval: tickPixelInterval,
              tickLength: 5,
              type: "datetime",
              // startOnTick: true,
              // endOnTick: true,
              dateTimeLabelFormats: {
                //   hour: '%H:%M',
                //   day: '%m/%d'
                day: "%e-%m-%Y",
              },
              tickColor: "black",
              title: {
                text: xAxisTitle,
              },
            },
          ],
          legend: {
            layout: "vertical",
            align: "right",
            verticalAlign: "middle",
          },
          plotOptions: {
            series: {
              connectNulls: true,

              label: {
                connectorAllowed: false,
              },
            },
          },
          series: series,
          responsive: {
            rules: [
              {
                condition: {
                  maxWidth: 500,
                },
                chartOptions: {
                  legend: {
                    layout: "horizontal",
                    align: "center",
                    verticalAlign: "bottom",
                  },
                },
              },
            ],
          },
          credits: {
            enabled: false,
          },
          data: {
            table: {
              header: true,
              //width: 1000 // Set the width of the data table view
            },
          },
        }}
      />
    </div>
  );
}

export default LineChart;
