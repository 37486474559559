import React, { useState, useContext, useEffect } from "react";
import moment from "moment";
import { Autocomplete, TextField } from "@mui/material";
import HeaderNavbarHOC from "../../HOC/HeaderNavbar";
import Config from "../../config";
import { AuthContext } from "../../Login/Protected";
import AlertBox from "../../Alert";
import { trackEvent, EVENT_NAME } from "../../constant/MixpanelConstant";
import * as AxiosActions from "../../Actions/axiosActions";
import Datepicker from "../../Datepicker";
import { leaves } from "../../constant/Leaves";
import BackgroundImage from "../../components/Common/CardImage";
import { DATAADDEDSUCCESSFULLY } from "../../constant/Label";

function LeavesAdd() {
  const [openAlert, setOpenAlert] = useState(false);
  const adminData = useContext(AuthContext);
  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
    setleavetype("Select leave type");
    setempid("Select employee name");
    setstatus("Select status");
    setFormvalue(initialvalue);
  };
  const [formdate, setformdate] = useState();
  const [todate, settodate] = useState();
  const [approverdate, setapproverdate] = useState();
  const [empid, setempid] = useState("Select employee name");
  const [getdata, setgetdata] = useState([]);
  const [status, setstatus] = useState("Select status");
  const [leavetype, setleavetype] = useState("Select leave type");
  const [errMsg, setErrMsg] = useState({
    fromdate: "",
    todate: "",
    reason: "",
    empid: "",
    leavetype: "",
    status: "",
  });
  const [isDisable, setIsDisable] = useState(false);

  const initialvalue = {
    reason: "",
    approve_date: "",
  };

  const [formvalue, setFormvalue] = useState(initialvalue);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormvalue({ ...formvalue, [name]: value });
  };

  const getEmployeeList = () => {
    AxiosActions.EmployeeList({})
      .then((response) => {
        console.log("getEmployeeList", response);
        if (response.status === 200) {
          setgetdata(response.data.data);
        }
      })
      .catch((error) => {
        console.log("Error in get employee list", error);
      });
  };
  useEffect(() => {
    getEmployeeList();
  }, []);
  // Hahdle submit

  function handleSubmit(e) {
    trackEvent(EVENT_NAME.LEAVES_ADD, { data: "" });
    e.preventDefault();
    let errObject = {
      fromdate: "",
      todate: "",
      reason: "",
      empid: "",
      leavetype: "",
      status: "",
    };
    let err = 0;
    if (adminData.adminData.isAdmin === true) {
      if (empid === "Select employee name") {
        err++;
        errObject.empid = "Select employee name";
      }
    }

    if (leavetype === "Select leave type") {
      err++;
      errObject.leavetype = "Select leave type";
    }
    if (status === "Select status") {
      err++;
      errObject.status = "Select status";
    }
    if (formvalue.reason === "") {
      err++;
      errObject.reason = "Please enter valid reason";
    }

    if (err !== 0) {
      console.log("has error", err, errObject);
      setErrMsg(errObject);
    } else {
      setIsDisable(true);
      trackEvent(EVENT_NAME.LEAVES_ADD, {
        data: adminData.adminData.isAdmin
          ? empid
          : adminData.adminData.empid && adminData.adminData.empid._id,
      });
      AxiosActions.LeaveAdd({
        empid: adminData.adminData.isAdmin
          ? empid
          : adminData.adminData.empid && adminData.adminData.empid._id,
        leave_type: leavetype,
        from_date: formdate,
        to_date: todate,
        status: status,
        approver: adminData.adminData.isAdmin ? adminData.adminData._id : null,
        approve_date: adminData.adminData.isAdmin ? approverdate : "",
        leave_reason: formvalue.reason,
        adminId: adminData.adminData._id,
      })
        .then((response) => {
          if (response.status === 200) {
            console.log("Tracking tools add response", response);
            if (response.data.success === true) {
              setIsDisable(false);
              setOpenAlert(true);
            }
          }
        })
        .catch((error) => {
          setIsDisable(false);
          console.log("Tracking tools error", error);
        });
    }
  }
  console.log(adminData);
  return (
    <>
      {" "}
      <AlertBox
        open={openAlert}
        onClose={handleCloseAlert}
        message={DATAADDEDSUCCESSFULLY}
        title="Success"
        ok={false}
      />
      <main id="content" role="main" className="main">
        <BackgroundImage/>
        <div
          className="content container-fluid"
          style={{ justifyItems: "center", maxWidth: "60rem" }}
        >
          <div className="page-header">
            <div className="row align-items-end">
              <div className="col-sm mb-2 mb-sm-0"></div>
            </div>
          </div>

          <div className="card w-70">
            <div className="card-header">
              <h1 className="card-title h1">{leaves.ADD_LEAVE_INFO}</h1>
            </div>

            <div className="card-body">
              {adminData.adminData.isAdmin ? (
                <>
                  {" "}
                  <div className="row mb-4">
                    <label
                      htmlFor="emailLabel"
                      className="col-sm-3 col-form-label form-label"
                      style={{ fontSize: 17 }}
                    >
                      {leaves.EMPLOYEE_NAME}
                    </label>
                    <div className="col-sm-9">
                      <Autocomplete
                        options={getdata}
                        getOptionLabel={(option) => option.employeeName}
                        value={
                          getdata.find((option) => option._id === empid ) || { employeeName: "Select employee"}
                        }
                        size="small"
                        renderInput={(params) => (
                          <TextField {...params} />
                        )}
                        onChange={(event, newValue) => {
                          setempid(
                            newValue === null
                              ? "Select employee name"
                              : newValue._id
                          );
                        }}
                      />
                      {errMsg.empid !== "" ? (
                        <label
                          className=" form-label"
                          style={{ fontSize: 15, color: "red" }}
                        >
                          {errMsg.empid}
                        </label>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </>
              ) : (
                <>
                  {" "}
                  <div className="row mb-4">
                    <label
                      htmlFor="emailLabel"
                      className="col-sm-3 col-form-label form-label"
                      style={{ fontSize: 17 }}
                    >
                      {leaves.EMPLOYEE_NAME}
                    </label>
                    <div className="col-sm-9">
                      <label
                        htmlFor="emailLabel"
                        className="col-sm-3 col-form-label form-label"
                        style={{ fontSize: 17 }}
                      >
                        {adminData.adminData.empid &&
                          adminData.adminData.empid.employeeName}
                      </label>
                    </div>
                  </div>
                </>
              )}

              <div className="row mb-4">
                <label
                  htmlFor="emailLabel"
                  className="col-sm-3 col-form-label form-label"
                  style={{ fontSize: 17 }}
                >
                  {leaves.LEAVE_TYPE}
                </label>
                <div className="col-sm-9">
                  <Autocomplete
                        options={Config.LEAVE_TYPE}
                        getOptionLabel={
                          (option) => option.name === "Select leave type" ? "Select leave type" : `${option.name} (${option.value})`
                        }
                        value={
                          Config.LEAVE_TYPE.find(
                            (option) => option.value === leavetype
                          )
                          || { name: "Select leave type", value: "Select leave type" }
                        }
                        size="small"
                        renderInput={(params) => (
                          <TextField {...params} />
                        )}
                        onChange={(event, newValue) => {
                          setleavetype(
                            newValue === null
                            ? "Select leave type"
                            : newValue.value
                          );
                        }}
                        />
                  {errMsg.leavetype !== "" ? (
                    <label
                      className=" form-label"
                      style={{ fontSize: 15, color: "red" }}
                    >
                      {errMsg.leavetype}
                    </label>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="row mb-4">
                <label
                  htmlFor="emailLabel"
                  className="col-sm-3 col-form-label form-label"
                  style={{ fontSize: 17 }}
                >
                  {leaves.FROM_DATE}
                </label>
                <div className="col-sm-9">
                  <Datepicker
                    onChange={(newDate) => {
                      setformdate(newDate);
                    }}
                    value={moment(formdate).format("YYYY-MM-DD")}
                  />
                </div>
              </div>
              <div className="row mb-4">
                <label
                  htmlFor="emailLabel"
                  className="col-sm-3 col-form-label form-label"
                  style={{ fontSize: 17 }}
                >
                  {leaves.TO_DATE}
                </label>
                <div className="col-sm-9">
                  <Datepicker
                    onChange={(newDate) => {
                      settodate(newDate);
                    }}
                    value={moment(todate).format("YYYY-MM-DD")}
                  />
                </div>
              </div>
              <div className="row mb-4">
                <label
                  htmlFor="emailLabel"
                  className="col-sm-3 col-form-label form-label"
                  style={{ fontSize: 17 }}
                >
                  {leaves.REASON}
                </label>
                <div className="col-sm-9">
                  <textarea
                    className="form-control"
                    placeholder="Leave reason"
                    id="validationValidTextarea1"
                    rows="2"
                    name="reason"
                    onChange={handleChange}
                    value={formvalue.reason}
                  ></textarea>
                  {errMsg.reason !== "" ? (
                    <label
                      className=" form-label"
                      style={{ fontSize: 15, color: "red" }}
                    >
                      {errMsg.reason}
                    </label>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="row mb-4">
                <label
                  htmlFor="emailLabel"
                  className="col-sm-3 col-form-label form-label"
                  style={{ fontSize: 17 }}
                >
                  {leaves.STATUS}
                </label>
                <div className="col-sm-9">
                  <Autocomplete
                        options={
                          adminData.adminData.isAdmin
                            ? Config.LEAVE_STATUS.filter((item) => item.value !== "Requested")
                            : Config.LEAVE_STATUS.filter((item) => item.value === "Requested")
                        }
                        getOptionLabel={(option) =>  option.name}
                        value={
                          Config.LEAVE_STATUS.find( (option) => option.value === status)  ||
                          {
                            name: "Select leave status" 
                          }
                        }
                        size="small"
                        renderInput={(params) => (
                          <TextField {...params} />
                        )}
                        onChange={(event, newValue) => {
                          setstatus(
                            newValue === null
                            ? "Select leave status"
                            : newValue.value
                          );
                        }}
                        />
                  {errMsg.status !== "" ? (
                    <label
                      className=" form-label"
                      style={{ fontSize: 15, color: "red" }}
                    >
                      {errMsg.status}
                    </label>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              {adminData.adminData.isAdmin ? (
                <>
                  <div className="row mb-4">
                    <label
                      htmlFor="emailLabel"
                      className="col-sm-3 col-form-label form-label"
                      style={{ fontSize: 17 }}
                    >
                      {leaves.APPROVER}
                    </label>
                    <div className="col-sm-9">
                      <label
                        htmlFor="emailLabel"
                        className="col-form-label form-label"
                        style={{ fontSize: 17 }}
                      >
                        {adminData.adminData.email}
                      </label>
                    </div>
                  </div>{" "}
                  <div className="row mb-4">
                    <label
                      htmlFor="emailLabel"
                      className="col-sm-3 col-form-label form-label"
                      style={{ fontSize: 17 }}
                    >
                      {leaves.APPROVER_DATE}
                    </label>
                    <div className="col-sm-9">
                      <Datepicker
                        onChange={(newDate) => {
                          setapproverdate(newDate);
                        }}
                        value={moment(approverdate).format("YYYY-MM-DD")}
                      />
                    </div>
                  </div>
                </>
              ) : (
                ""
              )}

              <div className="d-flex justify-content-end">
                <button
                  disabled={isDisable === true}
                  type="button"
                  className="btn btn-warning"
                  onClick={handleSubmit}
                >
                  {leaves.SAVE_CHANGES}
                </button>
              </div>
            </div>
          </div>
          <div></div>
          <br></br>
          <br></br>
        </div>
        <div className="footer">
          <div className="row justify-content-between align-items-center">
            <div className="col"></div>
            <div className="col-auto">
              <div className="d-flex justify-content-end"></div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default HeaderNavbarHOC(LeavesAdd);
