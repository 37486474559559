import React from "react";
import moment from "moment";
import Checkbox from "@mui/material/Checkbox";
import { expense } from "../../constant/Expence";

function Table({
  handleCheckBoxChange,
  isAllSelected,
  loading,
  Loader,
  applicants,
  selectedinfo,
  getClientStatus,
  handleViewReportClick,
  handleDeleteClick,
}) {
  return (
    <table
      id="datatable"
      className="table table-borderless table-thead-bordered table-nowrap table-align-middle card-table"
    >
      <thead className="thead-light">
        <tr>
          <th scope="col" className="table-column-pe-0">
            <Checkbox
              size="medium"
              value="all"
              onChange={handleCheckBoxChange}
              checked={isAllSelected}
            ></Checkbox>
          </th>

          <th>{expense.AMOUNT}</th>
          <th>{expense.DATE}</th>
          <th>{expense.MONTH}</th>
          <th>{expense.CATEGORY}</th>
          <th>{expense.ITEM}</th>
          <th>{expense.STATUS}</th>
          <th>{expense.ACTION}</th>
        </tr>
      </thead>
      <tbody>
        {loading ? (
          <tr>
            <td colSpan={8} style={{ textAlign: "center" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Loader />
              </div>
            </td>
          </tr>
        ) : (
          <>
            {" "}
            {applicants.length === 0 && (
              <tr>
                <td colSpan={9} style={{ textAlign: "center" }}>
                  <h3>No record found</h3>
                </td>
              </tr>
            )}
            {applicants.map((app, index) => {
              return (
                <tr key={"app" + index}>
                  <td className="table-column-pe-0">
                    <Checkbox
                      key={index}
                      size="medium"
                      value={app._id}
                      onChange={handleCheckBoxChange}
                      checked={selectedinfo.selectedID.includes(app._id)}
                    ></Checkbox>
                  </td>
                  <td>{app.netAmount}</td>{" "}
                  <td>{moment(app.date).format("DD-MM-YYYY")}</td>{" "}
                  <td>{app.month}</td>
                  <td>{app.category}</td> 
                  <td>
                    <div className="truncate" title={app.item}>
                      {app.item ?? "-"}
                    </div></td>
                  <td>{getClientStatus(app.status, "Expenses")}</td>
                  <td>
                    <button
                      title="Edit expense"
                      type="button"
                      className="icon icon-danger icon-circle"
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModalLong"
                      onClick={(event) => handleViewReportClick(event, app._id)}
                    >
                      <i className="bi-pen"></i>
                    </button>
                    &nbsp;
                    <button
                      title="Delete expense"
                      type="button"
                      className="icon icon-info icon-circle"
                      data-bs-toggle="modal"
                      data-bs-target="#delete"
                      onClick={(event) => handleDeleteClick(event, app._id)}
                    >
                      <i className="bi-trash"></i>
                    </button>
                    &nbsp;
                    <button
                      title="View expense"
                      type="button"
                      className="icon icon-dark icon-circle"
                      data-bs-toggle="modal"
                      data-bs-target="#viewpage"
                      onClick={(event) => handleViewReportClick(event, app._id)}
                    >
                      <i className="bi-eye"></i>
                    </button>
                  </td>
                  <></>
                </tr>
              );
            })}
          </>
        )}
      </tbody>
    </table>
  );
}

export default Table;
