import React, { useState, useEffect, useContext, useCallback } from "react";
import Axios from "axios";
import { toast } from "react-toastify";
import Slider from "react-slick";
import Modal from "react-modal";
import Timeline from "@mui/lab/Timeline";
import TimelineItem, { timelineItemClasses } from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import moment from "moment";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import { Avatar, Box, CircularProgress } from "@mui/material";
import HeaderNavbarHOC from "../../HOC/HeaderNavbar";
import Config from "../../config";
import { Filter } from "../../components/Common";
import { AuthContext } from "../../Login/Protected";
import * as AxiosActions from "../../Actions/axiosActions";
import {
  CONVERT_MILISECONDS_MINUTES,
  SampleNextArrow,
  SamplePrevArrow,
  STRING_AVATAR,
} from "../../constant/Daily";
import {
  add_notes,
  screenshot_large,
  screenshot_small,
} from "../../components/Common/SvgIcon";
import "./Daily.css";

function Daily() {
  const adminData = useContext(AuthContext);
  const [clientId, setClientId] = useState("");
  const [getclient, setGetclient] = useState([]);
  const [gettask, setGettask] = useState([]);
  const [taskId, setTaskId] = useState("");
  const [getdata, setGetdata] = useState([]);
  const [empid, setEmpid] = useState(adminData?.adminData?.empid?._id);
  const [startdate, setStartdate] = useState(moment().format("YYYY-MM-DD"));
  const [getEmpData, setGetEmpData] = useState([]);
  const [trackingData, setTrackingData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [taskImage, setTaskImage] = useState([]);

  const [loading, setLoading] = useState(false);

  const getEmployeeList = () => {
    AxiosActions.EmployeeList({})
      .then((response) => {
        if (response.status === 200) {
          setGetdata(response.data.data);
        }
      })
      .catch((error) => {
        console.log("Error in get employee list", error);
      });
  };

  const renderdata = useCallback(async () => {
    try {
      setLoading(true);
      await Axios.post(Config.LOCALURL + "time/attendance/pagination", {
        project: clientId || "",
        task: taskId,
        date: moment(startdate).format("YYYY-MM-DD"),
        empid: empid,
        timezone: moment.tz.guess(),
      })
        .then((data) => {
          if (data.data.code === 200) {
            setTrackingData(data.data.data);
          }
          setLoading(false);
        })
        .catch((error) => {
          console.log("Catch Err in Pagination ", error);
        });
    } catch (err) {
      console.log("Catch err in render data", err);
    }
  }, [clientId, startdate, taskId, empid]);

  const renderEmpData = useCallback(async () => {
    try {
      setLoading(true);
      await Axios.post(
        Config.LOCALURL + "time/attendance/date-wise/total-hour/count",
        {
          project: clientId || "",
          task: taskId,
          date: moment(startdate).format("YYYY-MM-DD"),
          empid: empid,
          timezone: moment.tz.guess(),
        }
      )
        .then((data) => {
          if (data.data.code === 200) {
            setGetEmpData(data.data.data);
          }
          setLoading(false);
        })
        .catch((error) => {
          console.log("Catch Err in Pagination ", error);
        });
    } catch (err) {
      console.log("Catch err in render data", err);
    }
  }, [clientId, startdate, taskId, empid]);

  const getClient = () => {
    Axios.get(Config.LOCALURL + "client/list")
      .then((response) => {
        if (response.status === 200) {
          setGetclient(response.data.data);
        }
      })
      .catch((error) => {
        console.log("Error in get Client", error);
      });
  };
  const getTask = useCallback(() => {
    Axios.post(Config.LOCALURL + "tasks/list/client-wise", { clientId: clientId })
      .then((response) => {
        if (response.status === 200) {
          setGettask(response.data.data);
        }
      })
      .catch((error) => {
        console.log("Error in get Client", error);
      });
  }, [clientId]);

  const getTimelineData = useCallback(() => {
    if (adminData.adminData.empid._id) {
      Axios.post(Config.LOCALURL + "time/attendance/pagination", {
        project: clientId || "",
        task: taskId,
        date: moment(startdate).format("YYYY-MM-DD"),
        empid: empid,
        timezone: moment.tz.guess(),
      })
        .then((response) => {
          if (response.status === 200) {
            setTrackingData(response.data.data);
          }
        })
        .catch((error) => {
          console.log("Error in get Timeline Data", error);
        });
    }
  }, [adminData.adminData.empid._id, clientId, empid, startdate, taskId]);

  const filterRecord = async () => {
    renderdata();
    renderEmpData();
  };

  const reset = () => {
    setClientId("");
    setTaskId("");
    setStartdate(moment().format("YYYY-MM-DD"));
    setEmpid(adminData.adminData.empid._id);
    renderdata();
    renderEmpData();
  };

  useEffect(() => {
    getEmployeeList();
    getClient();
    getTimelineData();
    renderdata();
    renderEmpData();
  }, [renderdata, renderEmpData, getTimelineData]);

  // Function to convert milliseconds to time
  const convertMillisecondsToTime = (milliseconds) => {
    const totalMinutes = Math.floor(milliseconds / (1000 * 60));
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;

    return hours || minutes ? `${hours}h ${minutes}m` : "-";
  };
  const timeString = convertMillisecondsToTime(getEmpData.totalHour);

  useEffect(() => {
    if (clientId) {
      getTask();
    }
  }, [clientId, getTask]);

  // Function to generate time slots (for example, every hour)
  const generateTimeSlots = () => {
    const timeRanges = Object.values(trackingData);
    return timeRanges;
  };

  const handleImageClick = (image) => {
    setIsModalOpen(true);
    setTaskImage(image);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      width: "45%",
      height: "45%",
      right: "auto",
      bottom: "auto",
      overflow: "visible",
      border: "none",
      display: "flex",
      padding: 0,
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      background: "none",
    },
  };

  const settings = {
    className: "gallery",
    dots: true,
    arrows: false,
    infinite: true,
    fade: true,
    autoplay: true,
    speed: 1000,
    adaptiveHeight: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    customPaging: function (i) {
      const imageSrc = taskImage[i];
      return (
        <a className="cursor-pointer">
          <img
            src={imageSrc}
            alt={`Image ${i + 1}`}
            style={{
              width: "65px",
              height: "65px",
              borderRadius: "8px",
              objectFit: "cover",
            }}
          />
        </a>
      );
    },
  };

  const handleScreenshotDelete = (screenshots) => {
    AxiosActions.screenshotDelete({
      screenshots: screenshots,
      adminId: adminData?.adminData?.empid?._id
    })
      .then((response) => {
        if (response.data.code === 200) {
          renderdata();
          renderEmpData();
          toast.dismiss();
          toast.success(response?.data?.message);
        } else {
          toast.dismiss();
          toast.error("Something went wrong!");
        }
      })
      .catch((error) => {
        console.log("Error in delete screenshot", error);
      });
  };

  {
    /* TODO: when add notes is required then below button used and call API.  */
  }
  // const handleAddNoteClick = (image) => {
  //   // Implement add note functionality here
  //   console.log("Add note clicked for image:", image);
  // };

  const renderTrackingData = () => {
    return Object.entries(trackingData)?.map(
      ([mainTimeRange, subRanges], index) => {
        const allItems = Object.entries(subRanges).flatMap(
          ([subRange, items]) => items.map((item) => ({ ...item, subRange }))
        );

        return (
          <TimelineItem key={`${mainTimeRange}-${index}`}>
            <TimelineSeparator>
              <TimelineDot sx={{ backgroundColor: '#333399' }}/>
                {index < Object.entries(trackingData)?.length - 1 && (
                  <TimelineConnector sx={{ backgroundColor: '#3333995e' }}/>
                )}
            </TimelineSeparator>
            <TimelineContent>
              <span className="activity-value">{mainTimeRange}</span>
              <div className="grid-container">
                {allItems.map((item, itemIndex) => (
                  <div
                    className="grid-item"
                    key={`${item.screenshots}-${itemIndex}`}
                  >
                    <div className="card card-bordered h-100 shadow-sm">
                      <div className="card-body">
                        <span className="d-block task-summary mb-1">
                          {item?.project?.clientName || "N/A"}
                        </span>
                        <span className="d-block task-summary-value">
                          {item?.task?.summary || "N/A"}
                        </span>
                        <div className="image-container">
                          <img
                            style={{ height: "auto" }}
                            className="card-img mt-3 cursor-pointer image-hover"
                            src={item?.screenshots?.[0] || ""}
                            alt="task-img"
                          />
                          <div className="card-img mt-3 cursor-pointer image-hover-options">
                            <button
                              onClick={() =>
                                handleImageClick(item?.screenshots)
                              }
                            >
                              {screenshot_large}
                            </button>
                            {/* TODO: when add notes is required then below button used and call API.  */}
                            {/* <button onClick={() => handleAddNoteClick(item?.screenshots?.[0])}>{add_notes}</button> */}
                          </div>
                        </div>
                        <div className="d-flex justify-content-between align-items-center">
                          <span className="d-block task-summary mt-3">
                            {item.subRange}
                          </span>
                          <span className="d-flex justify-content-between align-items-center gap-3">
                            <span
                              className="d-flex task-summary mt-3 cursor-pointer gap-1 align-items-center opacity-75"
                              onClick={() =>
                                handleImageClick(item?.screenshots)
                              }
                            >
                              {item?.screenshots?.length || 0}{" "}
                              {screenshot_small}
                            </span>
                            <span
                              className="cursor-pointer mt-3"
                              onClick={() => handleScreenshotDelete(item?.screenshots)}
                            >
                              <i className="bi-trash" style={{color : "#000000b0"}}></i>
                            </span>
                          </span>
                        </div>
                        <div className="d-flex justify-content-center align-items-center mt-4">
                          <span className="d-block task-summary">Active Time</span>
                          <span className="task-summary ms-2" style={{color: '#333399'}}>{CONVERT_MILISECONDS_MINUTES(item?.totalInterval)}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </TimelineContent>
          </TimelineItem>
        );
      }
    );
  };

  return (
    <main id="content" role="main" className="main">
      <div className="content container-fluid">
        <div className="card">
          <div className="card-body ">
            <h1 className="card-title" style={{ marginLeft: 20 }}>
              Screenshots
            </h1>
          </div>
          <div
            className="row justify-content-between align-items-center flex-grow-5 mb-4"
            style={{ marginLeft: 30 }}
          >
            <Filter
              task={true}
              taskValue={taskId}
              taskUpdate={setTaskId}
              taskList={gettask}
              project={true}
              projectValue={clientId}
              projectUpdate={setClientId}
              projectList={getclient}
              trackName={true}
              trackNameValue={empid}
              trackNameUpdate={setEmpid}
              employeeList={getdata}
              selectDate={true}
              selectDateValue={startdate}
              selectDateUpdate={setStartdate}
              filterRecord={filterRecord}
              resetRecord={reset}
            />
          </div>

          <Timeline
            sx={{
              [`& .${timelineItemClasses.root}:before`]: {
                flex: 0,
                padding: 0,
                marginLeft: 4,
              },
            }}
          >
            {loading && generateTimeSlots()?.length > 0 ? (
              <tr>
                <td
                  colSpan="6"
                  className="text-center d-flex justify-content-center align-item-center"
                >
                  <CircularProgress />
                </td>
              </tr>
            ) : generateTimeSlots()?.length === 0 ? (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                height="50vh"
              >
                <label className="card-subtitle">No record found</label>
              </Box>
            ) : (
              <div>
                <div
                  className="card"
                  style={{
                    marginLeft: 40,
                    marginRight: 40,
                    marginBottom: 30,
                  }}
                >
                  <div
                    className="card-body border rounded bg-white p-0"
                    style={{ border: "1px solid #333399" }}
                  >
                    <div className="table-responsive datatable-custom">
                      <table
                        id="datatable"
                        className="table table-borderless table-thead-bordered table-nowrap table-align-middle card-table"
                      >
                        <tbody>
                          <tr className="align-items-center d-flex justify-content-between p-3">
                            <td className="table-column-ps-0 p-0">
                              <a
                                className="d-flex align-items-center"
                                href="user-profile.html"
                              >
                                <div className="flex-shrink-0">
                                  <div className="avatar avatar-sm avatar-soft-primary avatar-circle">
                                    <Avatar {...STRING_AVATAR(getEmpData?.employeeEmail || getEmpData?.employeeName)} />
                                  </div>
                                </div>
                                <div className="flex-grow-1 ms-3">
                                  <h3 className="emp-name">
                                    {getEmpData.employeeName}
                                  </h3>
                                </div>
                              </a>
                            </td>
                            <td className="p-0">
                              <span className="activity-value d-block mb-1">
                                {moment(startdate).format("DD-MM-YYYY")}
                              </span>
                              <span className="activity-label">
                                {moment(startdate).format("dddd")}
                              </span>
                            </td>
                            <td className="p-0">
                              <span className="activity-value d-block mb-1">
                                {getEmpData.clockInAndOut}
                              </span>
                              <span className="activity-label">
                                Clock In/Out
                              </span>
                            </td>
                            <td className="p-0">
                              <span className="activity-value d-block mb-1">
                                {timeString}
                              </span>
                              <span className="activity-label">Total Time</span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                {renderTrackingData()}
              </div>
            )}
          </Timeline>
        </div>
      </div>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className="container">
          <button onClick={closeModal} className="close-button">
            <span className="position-relative" style={{ top: "-2px" }}>
              &times;
            </span>
          </button>
          <Slider {...settings}>
            {taskImage?.map((item, index) => (
              <div key={`${item}-${index}`}>
                <img
                  style={{
                    width: "100%",
                    height: "auto",
                    objectFit: "contain",
                  }}
                  src={item}
                  alt={`carousel-img-${index}`}
                />
              </div>
            ))}
          </Slider>
        </div>
      </Modal>
      <div className="footer"></div>
    </main>
  );
}

export default HeaderNavbarHOC(Daily);
