import React from "react";
import moment from "moment";
import { Autocomplete, TextField } from "@mui/material";
import Datepicker from "../../Datepicker";
import Config, { yearArray } from "../../config";
import { expense } from "../../constant/Expence";

function Edit({
  fullReport,
  setmonth,
  month,
  setyear,
  year,
  setItem,
  Item,
  setDescription,
  Description,
  setAmount,
  Amount,
  setdate,
  date,
  setCategory,
  Category,
  setStatus,
  status,
  isDisable,
  changeStatus,
  updateId,
}) {
  return (
    <div
      className="modal fade"
      id="exampleModalLong"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h2 className="modal-title" id="exampleModalLongTitle">
              {expense.EDIT_EXPENSES}
            </h2>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <div className="modal-content">
              <table>
                <tbody>
                  {fullReport.map((item, index) => {
                    return (
                      <>
                        <br></br>
                        <tr>
                          <td>
                            <h4>{expense.MONTH}</h4>{" "}
                            <Autocomplete
                              options={Config.SALARY_MONTH}
                              getOptionLabel={(option) => option}
                              value={month || "select month"}
                              onChange={(event, newValue) => {
                                setmonth(newValue);
                              }}
                              size="small"
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="outlined"
                                  autoComplete="off"
                                />
                              )}
                            />
                          </td>
                        </tr>
                        <br></br>
                        <tr>
                          <td>
                            <h4>{expense.YEAR}</h4>{" "}
                            <Autocomplete
                              options={yearArray}
                              getOptionLabel={(option) => option}
                              value={year || "select month"}
                              onChange={(event, newValue) => {
                                setyear(newValue);
                              }}
                              size="small"
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="outlined"
                                  autoComplete="off"
                                />
                              )}
                            />
                          </td>
                        </tr>
                        <br></br>

                        <tr>
                          <td>
                            <h4>{expense.ITEM}</h4>{" "}
                            <input
                              type="text"
                              className="form-control"
                              name="Item"
                              onChange={(event) => setItem(event.target.value)}
                              id="emailLabel"
                              placeholder="Item"
                              aria-label="Email"
                              value={Item}
                            />{" "}
                          </td>
                        </tr>
                        <br></br>
                        <tr>
                          <td>
                            <h4>{expense.DESCRIPTION}</h4>{" "}
                            <textarea
                              className="form-control"
                              placeholder="Description"
                              id="validationValidTextarea1"
                              rows="2"
                              name="Description"
                              onChange={(event) =>
                                setDescription(event.target.value)
                              }
                              value={Description}
                            ></textarea>
                          </td>
                        </tr>
                        <br></br>
                        <tr>
                          <td>
                            <h4>{expense.AMOUNT}</h4>{" "}
                            <input
                              type="number"
                              className="form-control"
                              name="Amount"
                              onChange={(event) =>
                                setAmount(event.target.value)
                              }
                              id="emailLabel"
                              placeholder="Amount"
                              aria-label="Email"
                              value={Amount}
                            />{" "}
                          </td>
                        </tr>
                        <br></br>
                        <tr>
                          <td>
                            <h4>{expense.DATE}</h4>{" "}
                            <Datepicker
                              onChange={(newDate) => {
                                setdate(newDate);
                              }}
                              value={moment(date).format("YYYY-MM-DD")}
                            />
                          </td>
                        </tr>
                        <br></br>
                        <tr>
                          <td>
                            <h4>{expense.CATEGORY}</h4>{" "}
                            <Autocomplete
                            options={Config.EXPENSES_CATEGORY}
                            getOptionLabel={(option) => option}
                            value={Category || "select category"}
                            onChange={(newValue) => {
                              setCategory(newValue.target.textContent);
                            }}
                            size="small"
                            renderInput={(params) => (
                              <TextField {...params} />
                            )}
                            />
                          </td>
                        </tr>
                        <br></br>
                        <tr>
                          <td>
                            <h4>{expense.STATUS}</h4>{" "}
                            <Autocomplete
                            options={Config.EXPENSES_STATUS}
                            getOptionLabel={(option) => option.name}
                            value={
                              Config.EXPENSES_STATUS.find(
                                (item) => item.value === status
                              ) || {name : "Select status"}
                            }
                            onChange={(event , newValue) => {
                              setStatus(newValue.value);
                            }}
                            size="small"
                            renderInput={(params) => (
                              <TextField {...params} />
                            )}
                            />                            
                          </td>
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-white"
              data-bs-dismiss="modal"
            >
              {expense.CLOSE}
            </button>
            <button
              disabled={isDisable === true}
              type="button"
              className="btn btn-primary"
              data-bs-dismiss="modal"
              onClick={() => {
                changeStatus(updateId);
              }}
            >
              {expense.SAVE_CHANGES}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Edit;
