import React, { useState, useEffect, useCallback } from "react";
import Config from "../../config";
import HeaderNavbarHOC from "../../HOC/HeaderNavbar";
import Useuser from "../../Contexct";
import { getClientStatus } from "../../services/common";
import { trackEvent, EVENT_NAME } from "../../constant/MixpanelConstant";
import AlertBox from "../../Alert";
import * as AxiosActions from "../../Actions/axiosActions";
import Edit from "./Edit";
import View from "./View";
import {
  HeaderTitle,
  AlertDelete,
  Pagination,
  Filter,
} from "../../components/Common";
import Table from "./Table";
import { DATAUPDATESUCCESSFULLY } from "../../constant/Label";

function JobsList() {
  const { adminData } = Useuser();
  const [openAlert, setOpenAlert] = useState(false);
  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
  };
  const [loading, setLoading] = useState(false);
  const [jobsid, setjobsid] = useState();
  const [jobstitle, setjobstitle] = useState();
  const [image, setimage] = useState();
  const [jobtype, setjobtype] = useState();
  const [Experience, setExperience] = useState();
  const [Location, setLocation] = useState();
  const [Time, setTime] = useState();
  const [Duties, setDuties] = useState();
  const [Status, setStatus] = useState();
  const [applicants, setApplicants] = useState([]);
  const [isFiltered, setIsFiltered] = useState(false);
  const [updateId, setUpdateId] = useState("");
  const [deleteId, setDeleteId] = useState("");
  const [fullReport, setFullReport] = useState([]);
  const [filterStatus, setFilterStatus] = useState("");
  const [count, setCount] = useState(0);
  const [inputList, setInputList] = useState("");
  const [selectedinfo, setSelectedInfo] = useState({
    selectedID: [],
  });
  const [controller, setController] = useState({
    page: 0,
    rowsPerPage: 10,
    preventRefresh: false,
  });
  const [isDisable, setIsDisable] = useState(false);
  const isAllSelected =
    applicants.length > 0 &&
    selectedinfo.selectedID.length === applicants.length;
  const token = localStorage.getItem("access_token");

  // renderdata(getdata from api)

  const renderdata = useCallback(async () => {
    try {
      setLoading(true);
      await AxiosActions.AllJobList({
        page: controller.page,
        limit: controller.rowsPerPage,
        status:
          filterStatus === "all"
            ? "all"
            : "" || filterStatus === "true"
            ? true
            : "" || filterStatus === "false"
            ? false
            : "",
        job_title: inputList,
      })
        .then((data) => {
          console.log("Productivity Data", data.data.data);
          if (data.status === 200) {
            setRenderState(data.data.data, data.data.totalRecords);
          }
          setLoading(false);
        })
        .catch((error) => {
          console.log("Productivity data error", error);
        });
    } catch (err) {
      console.log(err);
    }
  }, [controller.page, controller.rowsPerPage, filterStatus, inputList]);

  const setRenderState = (applicant, noOfRecords) => {
    setApplicants(applicant);
    setCount(noOfRecords);
  };

  const [Languages, setLanguages] = useState([{ Languages: "" }]);
  const [Requirments, setRequirments] = useState([{ Requirments: "" }]);
  const [Benefits, setBenefits] = useState([{ Benefits: "" }]);
  const [Role, setRole] = useState([{ Role: "" }]);
  let handleChangeLanguages = (i, e) => {
    let newLanguagesValues = [...Languages];
    newLanguagesValues[i][e.target.name] = e.target.value;
    setLanguages(newLanguagesValues);
  };

  let addLanguagesFields = () => {
    setLanguages([...Languages, { Languages: "" }]);
  };

  let removeLanguagesFields = (i) => {
    console.log("index DELETE", i);
    let newLanguagesValues = [...Languages];
    newLanguagesValues.splice(i, 1);
    setLanguages(newLanguagesValues);
  };
  let handleChangeRequirments = (i, e) => {
    let newRequirmentsValues = [...Requirments];
    newRequirmentsValues[i][e.target.name] = e.target.value;
    setRequirments(newRequirmentsValues);
  };

  let addRequirmentsFields = () => {
    setRequirments([...Requirments, { Requirments: "" }]);
  };

  let removeRequirmentsFields = (i) => {
    let newRequirmentsValues = [...Requirments];
    newRequirmentsValues.splice(i, 1);
    setRequirments(newRequirmentsValues);
  };

  let handleChangeBenefits = (i, e) => {
    let newBenefitsValues = [...Benefits];
    newBenefitsValues[i][e.target.name] = e.target.value;
    setBenefits(newBenefitsValues);
  };

  let addBenefitsFields = () => {
    setBenefits([...Benefits, { Benefits: "" }]);
  };

  let removeBenefitsFields = (i) => {
    let newBenefitsValues = [...Benefits];
    newBenefitsValues.splice(i, 1);
    setBenefits(newBenefitsValues);
  };

  let handleChangeRole = (i, e) => {
    let newRoleValues = [...Role];
    newRoleValues[i][e.target.name] = e.target.value;
    setRole(newRoleValues);
  };

  let addRoleFields = () => {
    setRole([...Role, { Role: "" }]);
  };

  let removeRoleFields = (i) => {
    let newRoleValues = [...Role];
    newRoleValues.splice(i, 1);
    setRole(newRoleValues);
  };

  // Filter record
  const filterRecord = useCallback(async () => {
    trackEvent(EVENT_NAME.JOB_LIST_FILTER, { data: "" });

    if (!isFiltered) {
      setIsFiltered(true);
      setController((prevController) => {
        return {
          ...prevController,
          page: 0,
          preventRefresh: true,
        };
      });
    }
    renderdata();
    setSelectedInfo({ selectedID: [] });
  }, [isFiltered, renderdata]);

  useEffect(() => {
    if (isFiltered === true) {
      if (!controller.preventRefresh) filterRecord();
    } else {
      renderdata();
    }
  }, [controller, filterRecord, isFiltered, renderdata]);

  // New Pagination

  const handlePageChange = (event, newPage) => {
    setController({
      ...controller,
      page: newPage,
      preventRefresh: false,
    });
    setSelectedInfo({ selectedID: [] });
  };

  const handleChangeRowsPerPage1 = (event) => {
    setController({
      ...controller,
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0,
      preventRefresh: false,
    });

    setSelectedInfo({ selectedID: [] });
  };

  // Update status

  const changeStatus = (statusid, title) => {
    setIsDisable(true);
    trackEvent(EVENT_NAME.JOB_EDIT, { data: jobsid });
    console.log(statusid);
    AxiosActions.EditJob({
      id: statusid,
      jobid: jobsid,
      image: image,
      job_title: jobstitle,
      job_type: jobtype,
      experience: Experience,
      location: Location,
      time: Time,
      Duties: Duties,
      status: Status,
      language: Languages,
      requirements: Requirments,
      role: Role,
      benefits: Benefits,
      adminId: adminData._id,
    })
      .then(async (data) => {
        console.log("Update data", data);
        await setOpenAlert(true);
        renderdata();
        setIsDisable(false);
      })
      .catch((err) => {
        setIsDisable(false);
        console.log("update error", err);
        alert("Please Delete Record Again.");
      });

    setSelectedInfo({ selectedID: [] });
  };

  //Reset Filter

  const reset = () => {
    setApplicants([]);
    setInputList("");
    setFilterStatus("");
    setController({
      ...controller,
      page: 0,
      rowsPerPage: 10,
      preventRefresh: false,
    });
    setSelectedInfo({ selectedID: [] });
    setIsFiltered(false);
  };

  const handleDeleteClick = (event, id) => {
    setDeleteId(id);

    event.preventDefault();
  };

  //Delete Record

  const deleteRecord = (event, id) => {
    setIsDisable(true);
    trackEvent(EVENT_NAME.JOB_DELETE, { data: id });
    console.log(id);
    AxiosActions.DeleteJob({
      id: id,
      adminId: adminData._id,
    })
      .then((data) => {
        renderdata();
        setIsDisable(false);
      })
      .catch((err) => {
        setIsDisable(false);
        console.log("delete error", err);
        alert("Please Delete Record Again.");
      });

    event.preventDefault();
  };

  //View Full Report

  const handleViewReportClick = (event, id) => {
    trackEvent(EVENT_NAME.JOB_VIEW, { data: id });
    const filterRecord = applicants.filter((applicantsItem) => {
      if (id === applicantsItem._id) {
        return true;
      }
      return false;
    });
    console.log("filer", filterRecord);
    filterRecord.forEach((item) => {
      setjobsid(item.id);
      setjobstitle(item.job_title);
      setimage(item.image);
      setjobtype(item.job_type);
      setExperience(item.experience);
      setLocation(item.location);
      setTime(item.time);
      setDuties(item.Duties);
      setStatus(item.status);
      setLanguages(item.language);
      setRequirments(item.requirements);
      setBenefits(item.benefits);

      setRole(item.roles);
    });
    setUpdateId(id);

    setFullReport(filterRecord);
    event.preventDefault();
  };
  console.log("benefits", Benefits);
  //Select Multiple

  const handleCheckBoxChange = (e) => {
    const value = e.target.value;
    const { checked } = e.target;
    const list = [];
    const { selectedID } = selectedinfo;

    if (value === "all") {
      if (value === "all" && checked) {
        applicants.map((item) => {
          return list.push(item._id);
        });
        setSelectedInfo({
          selectedID: list,
        });
      } else {
        setSelectedInfo({
          selectedID: list.filter((e) => e !== value),
        });
      }
      return;
    } else if (checked) {
      setSelectedInfo({
        selectedID: [...selectedID, value],
      });
    } else {
      setSelectedInfo({
        selectedID: selectedID.filter((e) => e !== value),
      });
    }
  };

  //Delete Multiple

  const handleDeleteMultipleClick = (event) => {
    event.preventDefault();
  };
  const deleteAllRecord = (event) => {
    setIsDisable(true);
    trackEvent(EVENT_NAME.JOB_DELETE_ALL, { data: "" });
    console.log("Delete All Records");
    AxiosActions.MultipleDeleteJob({
      id: selectedinfo.selectedID,
      adminId: adminData._id,
    })
      .then((data) => {
        renderdata();
        setIsDisable(false);
      })
      .catch((err) => {
        alert("Please Delete Record Again");
        setIsDisable(false);
      });

    setSelectedInfo({ selectedID: [] });
    event.preventDefault();
  };
  console.log("role", Role);
  return (
    <>
      <AlertBox
        open={openAlert}
        onClose={handleCloseAlert}
        message={DATAUPDATESUCCESSFULLY}
        title="Success"
        ok={false}
      />
      {token === null ? (
        true((window.location.href = "/"))
      ) : (
        <main id="content" role="main" className="main">
          <div className="content container-fluid">
            <HeaderTitle title="Jobs" />
            <div className="card mb-3 mb-lg-5">
              <div className="card-header">
                <Filter
                  searchJob={true}
                  searchJobValue={inputList}
                  searchJobUpdate={setInputList}
                  status={true}
                  statusValue={filterStatus}
                  statusUpdate={setFilterStatus}
                  statusList={Config?.JOBS_STATUS}
                  multipleSelect={selectedinfo?.selectedID?.length}
                  multipleDelete={true}
                  isDisable={isDisable}
                  handleDelete={handleDeleteMultipleClick}
                  deleteAllRecord={deleteAllRecord}
                  filterRecord={filterRecord}
                  resetRecord={reset}
                />
              </div>
              <div className="table-responsive datatable-custom">
                <Table
                  handleCheckBoxChange={handleCheckBoxChange}
                  isAllSelected={isAllSelected}
                  loading={loading}
                  applicants={applicants}
                  selectedinfo={selectedinfo}
                  getClientStatus={getClientStatus}
                  handleViewReportClick={handleViewReportClick}
                  handleDeleteClick={handleDeleteClick}
                />
              </div>
              <View fullReport={fullReport} getClientStatus={getClientStatus} />
              <AlertDelete
                isDisable={isDisable}
                deleteRecord={deleteRecord}
                deleteId={deleteId}
              />
              <Edit
                fullReport={fullReport}
                setjobsid={setjobsid}
                jobsid={jobsid}
                setjobstitle={setjobstitle}
                jobstitle={jobstitle}
                setimage={setimage}
                image={image}
                setjobtype={setjobtype}
                jobtype={jobtype}
                setExperience={setExperience}
                Experience={Experience}
                setLocation={setLocation}
                setTime={setTime}
                Time={Time}
                setDuties={setDuties}
                Duties={Duties}
                setStatus={setStatus}
                Status={Status}
                Config={Config}
                Languages={Languages}
                removeLanguagesFields={removeLanguagesFields}
                handleChangeLanguages={handleChangeLanguages}
                addLanguagesFields={addLanguagesFields}
                Requirments={Requirments}
                Benefits={Benefits}
                removeRequirmentsFields={removeRequirmentsFields}
                handleChangeRequirments={handleChangeRequirments}
                addRequirmentsFields={addRequirmentsFields}
                removeBenefitsFields={removeBenefitsFields}
                handleChangeBenefits={handleChangeBenefits}
                addBenefitsFields={addBenefitsFields}
                Role={Role}
                removeRoleFields={removeRoleFields}
                handleChangeRole={handleChangeRole}
                addRoleFields={addRoleFields}
                isDisable={isDisable}
                changeStatus={changeStatus}
                updateId={updateId}
              />
              <div className="card-footer">
                <Pagination
                  handlePageChange={handlePageChange}
                  controller={controller}
                  count={count}
                  handleChangeRowsPerPage1={handleChangeRowsPerPage1}
                />
              </div>
            </div>
          </div>
          <div className="footer"></div>
        </main>
      )}
    </>
  );
}

export default HeaderNavbarHOC(JobsList);
