import React, { useState, useContext, useEffect, useCallback } from "react";
import { toast } from "react-toastify";
import moment from "moment";
import { useStopwatch } from "react-timer-hook";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import { Autocomplete, TextField } from "@mui/material";
import axios from "axios";
import Config from "../../config";
import AlertBox from "../../Alert";
import { AuthContext } from "../../Login/Protected";
import * as AxiosActions from "../../../src/Actions/axiosActions";
import { CaptureTime } from "../../../src/components/Common/Tracking";
import BackgroundImage from "../../components/Common/CardImage";
import { DATAADDEDSUCCESSFULLY } from "../../constant/Label";

const TrackingToolsAdd = () => {
  const Navigate = useNavigate();
  const [openAlert, setOpenAlert] = useState(false);
  const adminData = useContext(AuthContext);
  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
  };
  const { seconds, minutes, hours, start, reset } = useStopwatch({
    autoStart: false,
  });
  const [startTimer, setStartTimer] = useState(false);
  const [getClient, setGetClient] = useState([]);
  const [getTask, setGetTask] = useState([]);
  const [clientId, setClientId] = useState("");
  const [taskId, setTaskId] = useState("");
  const [trackingId, setTrackingId] = useState("");
  const [todaySeconds, setTodaySeconds] = useState(0);
  const [todayMinutes, setTodayMinutes] = useState(0);
  const [todayHours, setTodayHours] = useState(0);
  const [weekSeconds, setWeekSeconds] = useState(0);
  const [weekMinutes, setWeekMinutes] = useState(0);
  const [weekHours, setWeekHours] = useState(0);
  const [taskSummary, setTaskSummary] = useState([]);
  const [isTaskSummary, setIsTaskSummary] = useState(false);
  const [intervalId, setIntervalId] = useState(0);
  const [isLeftSideCollapsed, setIsLeftSideCollapsed] = useState(false);
  const [selectedTask, setSelectedTask] = useState("todo");

  const getClientList = () => {
    AxiosActions.ProdutivityClientList({})
      .then((response) => {
        if (response.status == 200) {
          setGetClient(response.data.data);
        }
      })
      .catch((error) => {
        console.log("Error in get Client", error);
      });
  };

  useEffect(() => {
    getClientList();
  }, []);

  // Set Client ID for getting task
  const handleClient = (event) => {
    setClientId(event);
    getTaskList(event);
  };

  // Start Timer
  const handleStartTimer = async () => {
    if (clientId !== "" && taskId !== "") {
      if (window.electronAPI.captureScreen) {
        const screenshot = await window.electronAPI.captureScreen();
        if (screenshot) {
          AxiosActions.TrackingDetailsAdd({
            empid: adminData.adminData.empid._id,
            project: clientId,
            task: taskId,
            screenshot: null,
            captureTime: moment(),
            status: 'start',
          })
            .then((response) => {
              start();
              setStartTimer(true);
              setTrackingId(response.data.data._id);
              setIntervalId(setInterval(handleImageCapture, CaptureTime));
            })
            .catch((error) => {
              console.log("Tracking error", error);
            });
        }
      } else {
        toast.dismiss();
        toast.error('Electron API is not available');
      }
    } else {
      toast.dismiss();
      toast.error('Please select client and task');
    }
  };

  // Stop Timer
  const handleStopTimer = async () => {
    if (clientId !== "" && taskId !== "") {
      if (window.electronAPI.captureScreen) {
        const screenshot = await window.electronAPI.captureScreen();
        if (screenshot) {
          AxiosActions.TrackingDetailsAdd({
            _id: trackingId,
            empid: adminData.adminData.empid._id,
            project: clientId,
            task: taskId,
            screenshot: null,
            captureTime: moment(),
            status: 'end'
          })
            .then(async (response) => {
              reset(new Date(), false);
              setStartTimer(false);
              setIntervalId(clearInterval(intervalId));
              handleTodayTotal();
              handleWeekTotal();
              handleTaskSummary("todo");
            })
            .catch((error) => {
              console.log("Tracking error", error);
            });
        }
      }
    } else {
      toast.dismiss();
      toast.error('Please select client and task');
    }
  };

  // Image Capture
  const handleImageCapture = async () => {
    if (clientId !== "" && taskId !== "") {
      if (window.electronAPI.captureScreen) {
        const { thumbnailDataUrl, screenshotDataUrl } = await window.electronAPI.captureScreen();
        if (screenshotDataUrl || thumbnailDataUrl) {
          const timestamp = new Date().getTime();
          const uid = adminData.adminData.empid._id;
          const fileName = uid.slice(-4) + "_" + timestamp + "_" + 'screenshot.png';
          const imgURL = await handleUploadScreenshot(screenshotDataUrl, fileName);
          AxiosActions.TrackingDetailsAdd({
            empid: adminData.adminData.empid._id,
            project: clientId,
            task: taskId,
            screenshot: imgURL,
            captureTime: moment(),
            status: 'inprogress'
          })
            .then((response) => {})
            .catch((error) => {
              console.log("Tracking error", error);
            });
        } else {
          toast.dismiss();
          toast.error('Failed to capture screen');
        }
      } else {
        toast.dismiss();
        toast.error('Electron API is not available');
      }
    } else {
      toast.dismiss();
      toast.error('Please select client and task');
    }
  };

  // Function to upload the screenshot
  const handleUploadScreenshot = async (screenshot, fileName) => {
    const formData = new FormData();
    
    // Convert base64 screenshot to a Blob (this is optional, depending on how your server expects the data)
    const byteString = atob(screenshot.split(',')[1]);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const uintArray = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      uintArray[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([uintArray], { type: 'image/png' });

    formData.append('file', blob, fileName);
    formData.append('name', fileName);

    try {
      const response = await axios.post(`${Config.APIURL}time/attendance/upload/screenshot`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data', // Important for file upload
        },
      });
  
      if (response.data.code === 200) {
        return response.data.data;
      } else {
        toast.dismiss();
        toast.error('Failed to upload screenshot');
      }
    } catch (error) {
      console.error('Error uploading screenshot:', error);
    }
  };

  // Today Total
  const handleTodayTotal = useCallback(() => {
    AxiosActions.TodayTotal({
      empid: adminData.adminData.empid._id,
    })
      .then((response) => {
        if(response.data.code === 200 && response.data.data !== 'No Data') {
          let miliseconds = response.data.data;
          setTodaySeconds(Math.floor((miliseconds / 1000) % 60));
          setTodayMinutes(Math.floor((miliseconds / (1000 * 60)) % 60));
          setTodayHours(Math.floor((miliseconds / (1000 * 60 * 60)) % 24));
        } 
      })
      .catch((error) => {
        console.log("Tracking error", error);
      });
  }, [adminData.adminData.empid._id]);

  // Week Total
  const handleWeekTotal = useCallback(() => {
    AxiosActions.WeekTotal({
      empid: adminData.adminData.empid._id,
    })
      .then((response) => {
        if(response.data.code === 200 && response.data.data !== 'No Data') {
          let miliseconds = response.data.data;
          setWeekSeconds(Math.floor((miliseconds / 1000) % 60));
          setWeekMinutes(Math.floor((miliseconds / (1000 * 60)) % 60));
          setWeekHours(Math.floor((miliseconds / (1000 * 60 * 60)) % 24));
        }
      })
      .catch((error) => {
        console.log("Tracking error", error);
      });
  }, [adminData.adminData.empid._id]);

  // Task Summary
  const handleTaskSummary = useCallback((status) => {
    if (status === "todo") {
      AxiosActions.TaskSummary({
        empid: adminData.adminData.empid._id,
      })
        .then((response) => {
          if (response.data.code === 200 && response.data.data.length !== 0) {
            const combinedArray = Object.entries(response.data.data.count).map(
              ([id, countData]) => {
                const dataObject = response.data.data.data.find(
                  (obj) => obj.task._id === id
                );
                return {
                  ...dataObject,
                  seconds: Math.floor((countData.sum / 1000) % 60),
                  minutes: Math.floor((countData.sum / (1000 * 60)) % 60),
                  hours: Math.floor((countData.sum / (1000 * 60 * 60)) % 24),
                };
              }
            );
            setTaskSummary(combinedArray);
            setIsTaskSummary(true);
          } else {
            setTaskSummary([]);
            setIsTaskSummary(false);
          }
        })
        .catch((error) => {
          console.log("Tracking error", error);
        });
    } else if (status === "completed") {
      AxiosActions.CompletedTaskSummary({
        empid: adminData.adminData.empid._id,
      })
        .then((response) => {
          if (response.data.data.length !== 0) {
            const combinedArray = Object.entries(response.data.data.count).map(
              ([id, countData]) => {
                const dataObject = response.data.data.data.find(
                  (obj) => obj.task._id === id
                );
                return {
                  ...dataObject,
                  seconds: Math.floor((countData.sum / 1000) % 60),
                  minutes: Math.floor((countData.sum / (1000 * 60)) % 60),
                  hours: Math.floor((countData.sum / (1000 * 60 * 60)) % 24),
                };
              }
            );
            setTaskSummary(combinedArray);
            setIsTaskSummary(true);
          } else {
            setTaskSummary([]);
            setIsTaskSummary(false);
          }
        })
        .catch((error) => {
          console.log("Tracking error", error);
        });
    }
  }, [adminData.adminData.empid._id]);

  // Update Sattus
  const handleStatusUpdate = (taskid, taskstatus) => {
    if (taskstatus === "IN PROGRESS" || taskstatus === "TO DO") {
      AxiosActions.UpdateTaskStatus({
        _id: taskid,
        empid: adminData.adminData.empid._id,
      })
        .then((response) => {
          setIsTaskSummary(false);
          handleTaskSummary("todo");
        })
        .catch((error) => {
          console.log("Tracking error", error);
        });
    } else if (taskstatus === "DONE") {
      AxiosActions.UpdateTaskToDefault({
        _id: taskid,
        empid: adminData.adminData.empid._id,
      })
        .then((response) => {
          setIsTaskSummary(false);
          handleTaskSummary("todo");
        })
        .catch((error) => {
          console.log("Tracking error", error);
        });
    }
  };

  // Tap on back button
  const navigateHome = () => {
    Navigate("/dashboard");
  };

  const handleToggleLeftSide = () => {
    setIsLeftSideCollapsed(!isLeftSideCollapsed);
  };

  const getTaskList = useCallback((event) => {
    AxiosActions.EmployeeTask({
      clientId: event,
      empid: adminData.adminData.empid._id,
    })
      .then((response) => {
        if (response.status == 200) {
          setGetTask(response.data.data);
          setTimeout(() => {
            if (adminData) {
              handleTodayTotal();
              handleWeekTotal();
              handleTaskSummary("todo");
            }
          }, 5000);
        }
      })
      .catch((error) => {
        console.log("Error in get Client", error);
      });
  }, [adminData, handleTaskSummary, handleTodayTotal, handleWeekTotal]);

  useEffect(() => {
    handleTodayTotal();
    handleWeekTotal();
    handleTaskSummary(selectedTask);
  }, [handleTodayTotal, handleWeekTotal, handleTaskSummary, selectedTask]);

  return (
    <>
      {" "}
      <AlertBox
        open={openAlert}
        onClose={handleCloseAlert}
        message={DATAADDEDSUCCESSFULLY}
        title="Success"
        ok={false}
      />
      <main id="content" role="main" className="main">
        <BackgroundImage/>
        <div
          className="content container-fluid"
          style={{ justifyItems: "center", maxWidth: "60rem" }}
        >
          <div
            className="card-group"
            style={{
              display: "flex",
              flexDirection: "row",
              height: 550,
              backgroundColor: "#e6e6e6",
              width: isLeftSideCollapsed ? 450 : '100%',
              justifyItems: "center",
            }}
          >
            {!isLeftSideCollapsed && (
              <div
                id="mySidenav"
                className="card card-center"
                href="#"
                style={{
                  backgroundColor: "#e6e6e6",
                }}
              >
                <div className="card-body card-body-height">
                  <h1>
                    {" "}
                    <ArrowBackIcon onClick={navigateHome} /> My Task
                  </h1>
                  <div
                    className="btn-group"
                    role="group"
                    aria-label="Basic radio toggle button group"
                    style={{
                      backgroundColor: "white",
                      height: 40,
                      alignItems: "center",
                      marginTop: 10,
                    }}
                  >
                    <input
                      type="radio"
                      className="btn-check"
                      name="btnradio"
                      id="btnradio1"
                      autoComplete="off"
                      checked={selectedTask === "todo"}
                    />
                    <label
                      className="btn btn-outline-danger"
                      htmlFor="btnradio1"
                      style={{ height: 40, alignItems: "center", border: '1px solid #333399', background: selectedTask === 'todo' ? '#333399' : '' ,color: selectedTask === 'todo' ? 'white' : '#333399' }}
                      onClick={() => {setSelectedTask('todo'); handleTaskSummary("todo")}}
                    >
                      To-do
                    </label>
                    <input
                      type="radio"
                      className="btn-check"
                      name="btnradio"
                      id="btnradio2"
                      autoComplete="off"
                      checked={selectedTask === "completed"}
                    />
                    <label
                      className="btn btn-outline-danger"
                      htmlFor="btnradio2"
                      style={{ height: 40, alignItems: "baseline", border: '1px solid #333399', background: selectedTask === 'completed' ? '#333399' : '', color: selectedTask === 'completed' ? 'white' : '#333399' }}
                      onClick={() => {setSelectedTask('completed'); handleTaskSummary("completed")}}
                    >
                      Completed
                    </label>
                    <input
                      type="radio"
                      className="btn-check"
                      name="btnradio"
                      id="btnradio3"
                      autoComplete="off"
                    />
                  </div>

                  {isTaskSummary === false && taskSummary.length > 0 ? (
                    // Loader when isTaskSummary is false
                    <Box display="flex" justifyContent="center" alignItems="center" height="50vh">
                      <CircularProgress />
                    </Box>
                  ) : taskSummary.length === 0 ? (
                    <Box display="flex" justifyContent="center" alignItems="center" height="50vh">
                      <label className="card-subtitle">No record found</label>
                    </Box>
                  ) : (
                    // Display the list if taskSummary has items
                    taskSummary.map((item) => {
                      return (
                        <a
                          className="card card-hover-shadow border border-2"
                          style={{ marginTop: 10 }}
                        >
                          <div className="card-body">
                            <tr>
                              <td>
                                {" "}
                                <div className="form-check form-check-inline">
                                  <input
                                    type="checkbox"
                                    id="formInlineCheck1"
                                    className="form-check-input"
                                    onClick={() =>
                                      handleStatusUpdate(
                                        item.task._id,
                                        item.task.status
                                      )
                                    }
                                    disabled={startTimer === true}
                                  />
                                </div>
                              </td>
                              <td>
                                {" "}
                                <h2 className="card-title text-inherit fs-4">
                                  {item.task.summary}
                                </h2>
                                <div className="row align-items-center">
                                  <div className="col">
                                    <tr>
                                      <td>
                                        {" "}
                                        <h3 className="card-subtitle">
                                          {item.project.clientName}
                                        </h3>
                                      </td>{" "}
                                      <td>
                                        <h4 style={{ marginLeft: 100 }}>
                                          <span>
                                            {" "}
                                            {item.hours > 9
                                              ? item.hours
                                              : `0` + item.hours}{" "}
                                          </span>{" "}
                                          :
                                          <span>
                                            {" "}
                                            {item.minutes > 9
                                              ? item.minutes
                                              : `0` + item.minutes}{" "}
                                          </span>
                                          :
                                          <span>
                                            {" "}
                                            {item.seconds > 9
                                              ? item.seconds
                                              : `0` + item.seconds}{" "}
                                          </span>
                                        </h4>
                                      </td>
                                    </tr>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          </div>
                        </a>
                      );
                    })
                  )}
                </div>
              </div>
            )}
            
            {/* Right Side */}
            <div className="card">
              <div className="card-body">
                <a
                  className="card card-hover-shadow text-danger "
                  style={{border: '1px solid #333399' , backgroundColor : 'rgb(101 101 203 / 14%)'}}
                  href="#"
                >
                  <div className="card-body">
                    <h2 className="card-subtitle "
                      style={{color: 'rgb(84, 84, 88)'}}
                    >Current Session</h2>
                    <div className="row align-items-center ">
                      <div className="col-6">
                        <h2 className="card-title text-inherit">
                          <span> {hours > 9 ? hours : `0` + hours} </span> :
                          <span>
                            {" "}
                            {minutes > 9 ? minutes : `0` + minutes}{" "}
                          </span>
                          :
                          <span>
                            {" "}
                            {seconds > 9 ? seconds : `0` + seconds}{" "}
                          </span>
                        </h2>
                      </div>
                    </div>
                  </div>
                </a>
                <Autocomplete
                options={getClient || []}
                getOptionLabel={(option) => option.clientName || ""}
                style={{ marginTop: 20 }}
                value={getClient.find((item) => item._id === clientId) ||
                  { clientName: "select project" }
                }
                size="small"
                onChange={(event, newValue) => {
                  handleClient(newValue ? newValue._id : "");
                }}
                renderInput={(params) => <TextField {...params} />}
                disabled={startTimer === true}
                />
                
                <Autocomplete
                id="task-autocomplete"
                options={getTask}
                getOptionLabel={(option) => option.summary}
                style={{ marginTop: 20 }}
                value={getTask.find((task) => task._id === taskId) ||
                  { summary: "select task" }
                }
                size="small"
                onChange={(event, value) =>
                  setTaskId(value? value._id : "")
                }
                renderInput={(params) => <TextField {...params} />}
                disabled={startTimer === true}
                />
                {startTimer === false ? (
                  <button
                    type="button"
                    className="btn btn-danger"
                    style={{ marginTop: 10, width: '100%', fontSize: 18, background: '#333399', border: 'none' }}
                    onClick={handleStartTimer}
                  >
                    <i className="bi bi-play-fill"></i> Start Tracking
                  </button>
                ) : (
                  <button
                    type="button"
                    className="btn btn-danger"
                    style={{ marginTop: 10, width: '100%', fontSize: 18, background: '#333399', border: 'none' }}
                    onClick={handleStopTimer}
                  >
                    <i className="bi bi-play-fill"></i> Stop Tracking
                  </button>
                )}

                <a
                  className="card card-hover-shadow border border-secondary"
                  style={{ marginTop: 10 }}
                >
                  <div className="card-body">
                    <h3 className="card-subtitle">Today total</h3>
                    <div className="row align-items-center gx-2 mb-1">
                      <div
                        className="col-6"
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          width: "100%",
                          alignItems: "center",
                        }}
                      >
                        <div>
                          <h2 className="card-title text-inherit" id='timer'>
                            <span>
                              {" "}
                              {todayHours > 9
                                ? todayHours
                                : `0` + todayHours}{" "}
                            </span>
                            :
                            <span>
                              {" "}
                              {todayMinutes > 9
                                ? todayMinutes
                                : `0` + todayMinutes}{" "}
                            </span>
                            :
                            <span>
                              {" "}
                              {todaySeconds > 9
                                ? todaySeconds
                                : `0` + todaySeconds}{" "}
                            </span>
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
                <a
                  className="card card-hover-shadow border border-secondary "
                  style={{ marginTop: 10 }}
                >
                  <div className="card-body">
                    <h3 className="card-subtitle">Weekly total</h3>
                    <div className="row align-items-center gx-2 mb-1">
                      <div
                        className="col-6"
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          width: "100%",
                          alignItems: "center",
                        }}
                      >
                        <div>
                          <h2 className="card-title text-inherit">
                            <span>
                              {" "}
                              {weekHours > 9
                                ? weekHours
                                : `0` + weekHours}{" "}
                            </span>
                            :
                            <span>
                              {" "}
                              {weekMinutes > 9
                                ? weekMinutes
                                : `0` + weekMinutes}{" "}
                            </span>
                            :
                            <span>
                              {" "}
                              {weekSeconds > 9
                                ? weekSeconds
                                : `0` + weekSeconds}{" "}
                            </span>
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: 15,
                    justifyContent: "space-between",
                  }}
                >
                  <div>
                    <button
                      type="button"
                      className="btn btn-primary btn-icon btn-sm"
                      style={{ height: 25, width: 25 }}
                      onClick={handleToggleLeftSide}
                    >
                      {isLeftSideCollapsed ?
                        <i className="bi bi-chevron-double-right"></i>
                      :
                        <i className="bi bi-chevron-double-left"></i>
                      }
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br></br>
          <br></br>
        </div>
      </main>
    </>
  );
};

export default TrackingToolsAdd;
