import React from "react";
import { Autocomplete, TextField } from "@mui/material";
import Config from "../../config";
import {
  CLOSE,
  EDITWHATSAPP,
  PHONE,
  SAVECHANGES,
  SELECTTEMPLATE,
  TEMPLATE,
} from "../../constant/Label";

function Edit({
  handleChange,
  inputValue,
  errMsg,
  isDisable,
  changeStatus,
  updateId,
}) {
  return (
    <div
      className="modal fade"
      id="exampleModalLong"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h2 className="modal-title" id="exampleModalLongTitle">
              {EDITWHATSAPP}
            </h2>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <div className="modal-content">
              <table>
                <tbody>
                  <tr>
                    <td>
                      <h4>{PHONE}</h4>{" "}
                      <textarea
                        className="form-control"
                        placeholder="Phone"
                        id="validationValidTextarea1"
                        rows="2"
                        name="phone"
                        onChange={handleChange}
                        value={inputValue?.phone}
                      ></textarea>
                      {errMsg?.phone !== "" ? (
                        <label
                          className=" form-label"
                          style={{
                            fontSize: 15,
                            color: "red",
                          }}
                        >
                          {errMsg?.phone}
                        </label>
                      ) : (
                        ""
                      )}
                    </td>
                  </tr>

                  <br></br>
                  <tr>
                    <td>
                      <h4>{TEMPLATE}</h4>{" "}
                      <Autocomplete
                        name="template"
                        options={Config.TEMPLATE_TYPE || []}
                        getOptionLabel={(option) => option.value || ""}
                        value={
                          Config.TEMPLATE_TYPE.find(
                            (item) => item.value === inputValue?.template
                          ) || null
                        }
                        onChange={handleChange}
                        size="small"
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            autoComplete="off"
                          />
                        )}
                      />
                      {errMsg.template !== "" ? (
                        <label
                          className=" form-label"
                          style={{
                            fontSize: 15,
                            color: "red",
                          }}
                        >
                          {errMsg.template}
                        </label>
                      ) : (
                        ""
                      )}
                    </td>
                  </tr>

                  <br></br>
                  <tr>
                    <td>
                      {inputValue?.label?.map((labelItem, index) => (
                        <div className="row mb-4" key={index}>
                          <label
                            className="col-sm-3 col-form-label form-label"
                            style={{ fontSize: 17 }}
                          >
                            {`{{${index + 1}}}`}
                          </label>
                          <div className="col-sm-9">
                            <input
                              type="text"
                              className="form-control"
                              name={`label${index + 1}`}
                              onChange={handleChange}
                              id={`label${index + 1}`}
                              aria-label={labelItem?.type}
                              value={inputValue?.label[index]?.text}
                            />
                            {errMsg.labels[index] && (
                              <label
                                className="form-label"
                                style={{ fontSize: 15, color: "red" }}
                              >
                                {errMsg.labels[index]}
                              </label>
                            )}
                          </div>
                        </div>
                      ))}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-white"
              data-bs-dismiss="modal"
            >
              {CLOSE}
            </button>
            <button
              disabled={isDisable === true}
              type="button"
              className="btn btn-primary"
              data-bs-dismiss={inputValue?.phone === "" ? "" : "modal"}
              onClick={() => {
                changeStatus(updateId);
              }}
            >
              {SAVECHANGES}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Edit;
