import React, { useState, useContext } from "react";
import HeaderNavbarHOC from "../../HOC/HeaderNavbar";
import { AuthContext } from "../../Login/Protected";
import AlertBox from "../../Alert";
import Alert from "@mui/material/Alert";
import { trackEvent, EVENT_NAME } from "../../constant/MixpanelConstant";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import moment from "moment";
import * as AxiosActions from "../../Actions/axiosActions";
import { attendance } from "../../constant/Attendance";
import BackgroundImage from "../../components/Common/CardImage";
import { DATAADDEDSUCCESSFULLY } from "../../constant/Label";

function AttendanceAdd() {
  const [openAlert, setOpenAlert] = useState(false);
  const adminData = useContext(AuthContext);
  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
    
  };
  const [error, setError] = useState(false);
  const [inButtonColor, setInButtonColor] = useState("grey");
  const [outButtonColor, setOutButtonColor] = useState("grey");
const [type,setType]=useState("")
  const handleInButtonClick = () => {
    setInButtonColor("#333399");
    setOutButtonColor("grey");
    setType('in')
    setError(false)
  };

  const handleOutButtonClick = () => {
    setOutButtonColor("#333399");
    setInButtonColor("grey");
    setType('out')
    setError(false)
  };
  const [isDisable, setIsDisable] = useState(false);
  // Hahdle submit

  function handleSubmit(e) {
      setIsDisable(true);
      trackEvent(EVENT_NAME.ATTENDANCE_ADD, { data: type });
      AxiosActions.AttendanceAdd({
        empid:adminData.adminData.empid?._id,
        attendance_type:type,
        date:moment().format('YYYY-MM-DD  h:mm:ss a'),
        adminId:adminData.adminData._id})
        .then((response) => {
          if (response.status === 200) {
         
            if (response.data.success === true) {
              setIsDisable(false);
              setOpenAlert(true);
            }
          }else{
              setIsDisable(false);
              setError(true)
          }
        })
        .catch((error) => {
          setIsDisable(false);
          console.log("Tracking tools error", error);
        });
    }
  

  return (
    <>
      {" "}
      <AlertBox
        open={openAlert}
        onClose={handleCloseAlert}
        message={DATAADDEDSUCCESSFULLY}
        title="Success"
        ok={false}
      />
      <main id="content" role="main" className="main">
        <BackgroundImage/>
        <div
          className="content container-fluid"
          style={{ justifyItems: "center", maxWidth: "60rem" }}
        >
          <div className="page-header">
            <div className="row align-items-end">
              <div className="col-sm mb-2 mb-sm-0"></div>
            </div>
          </div>

          <div className="card w-50">
            <div className="card-header">
              <h1 className="card-title h1">{attendance.ATTENDANCE}</h1>
            </div>

            <div className="card-body">
              <div className="d-flex justify-content-center">
                <ButtonGroup size="large" sx={{ height: 60 }}>
                  <Button
                    variant="contained"
                    sx={{ width: 150, backgroundColor: inButtonColor }}
                    onClick={handleInButtonClick}
                  
                  >
                    {attendance.IN}
                  </Button>
                  <Button
                    variant="contained"
                    sx={{ width: 150, backgroundColor: outButtonColor }}
                    onClick={handleOutButtonClick}
                  
                  >
                    {attendance.OUT}
                  </Button>
                </ButtonGroup>
              </div>
              <div className="d-flex justify-content-center">
                <label
                  htmlFor="emailLabel"
                  style={{ fontSize: 30, marginTop: 50,color:'#000000' }}
                >
                  {attendance.HELLO}{" "}
                  {adminData.adminData.empid?.employeeName}
                  
                </label>
              </div>
              <div className="d-flex justify-content-center">
                <label
                  htmlFor="emailLabel"
                  style={{ fontSize: 25, marginTop: 40 }}
                >
                 {attendance.DATE} {moment().format('DD/MM/YYYY  h:mm:ss a')} 
                </label>
              </div>
              <div className="d-flex justify-content-center">
              {error === true && (
                                                <Alert
                                                  variant="outlined"
                                                  severity="error"
                                                  sx={{
                                                    height: 80,
                                                    width:500,
                                                    fontSize: 22,
                                                    marginTop:3
                                                  }}
                                                >
                                                 {attendance.ATTENDANCE_MSG}
                                                </Alert>
                                              )}
              </div>
             
              <div className="d-flex justify-content-center">
              <button
                  disabled={isDisable === true}
                  type="button"
                  className="btn btn-warning"
                  onClick={handleSubmit}
                  style={{ marginTop: 50 }}
                >
                  {attendance.SAVE_CHANGES}
                </button>
                </div>
            </div>
          </div>
          <div></div>
          <br></br>
          <br></br>
        </div>
        <div className="footer">
          <div className="row justify-content-between align-items-center">
            <div className="col"></div>
            <div className="col-auto">
              <div className="d-flex justify-content-end"></div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default HeaderNavbarHOC(AttendanceAdd);
