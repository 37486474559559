import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

function BarChart({
  categories,
  series,
  title,
  yAxisTitle,
  xAxisTitle,
  barWidth,
}) {
  return (
    <div>
      <HighchartsReact
        highcharts={Highcharts}
        options={{
          chart: {
            type: "column",
          },
          exporting: {
            enabled: true,
            buttons: {
              contextButton: {
                menuItems: [
                  "viewFullscreen",
                  "viewData",
                  "downloadPNG",
                  "downloadPDF",
                  "downloadCSV",
                ],
              },
            },
          },
          title: {
            text: title,
            align: "center",
          },
          yAxis: {
            title: {
              text: yAxisTitle,
            },
          },

          xAxis: {
            title: {
              text: xAxisTitle,
            },
            categories: categories,
            crosshair: true,
            accessibility: {
              rangeDescription: "Employee Name",
            },
          },

          legend: {
            layout: "vertical",
            align: "right",
            verticalAlign: "middle",
          },

          plotOptions: {
            series: {
              connectNulls: true,
              pointWidth: barWidth,
              dataLabels: {
                enabled: true,
              },
              label: {
                connectorAllowed: false,
              },
            },
          },
          series: series,

          dataLabels: {
            enabled: true,
            rotation: -90,
            color: "#FFFFFF",
            align: "right",
            format: "{point.y:.1f}",
            y: 10,
            style: {
              fontSize: "13px",
              fontFamily: "Verdana, sans-serif",
            },
          },
          responsive: {
            rules: [
              {
                condition: {
                  maxWidth: 500,
                },
                chartOptions: {
                  legend: {
                    layout: "horizontal",
                    align: "center",
                    verticalAlign: "bottom",
                  },
                },
              },
            ],
          },
        }}
      />
    </div>
  );
}

export default BarChart;
