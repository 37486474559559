export const SALARY = {
  CHART_TITLE: "Month Wise Salary",
  X_TITLE: "Month",
  Y_TITLE: "Amount",
};
export const EMPLOYEE_SALARY = {
  CHART_TITLE: "Employee Wise Salary",
  X_TITLE: "Employee Name",
  Y_TITLE: "Amount",
};
export const EXPENSES = {
  CHART_TITLE: "Expenses",
  X_TITLE: "Month",
  Y_TITLE: "Amount",
};

export const MIS_EXPENSES = {
  CHART_TITLE: "Miscellaneous Expenses",
  X_TITLE: "Month",
  Y_TITLE: "Amount",
};
