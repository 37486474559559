import React, { useState, useEffect } from "react";
import { Autocomplete, TextField } from "@mui/material";
import Config from "../../config";
import HeaderNavbarHOC from "../../HOC/HeaderNavbar";
import { trackEvent, EVENT_NAME } from "../../constant/MixpanelConstant";
import AlertBox from "../../Alert";
import * as AxiosActions from "../../Actions/axiosActions";
import {
  SAVECHANGES,
} from "../../constant/Label";
import BackgroundImage from "../../components/Common/CardImage";

function UserAdd() {
  const [openAlert, setOpenAlert] = useState(false);
  const [isDisable, setIsDisable] = useState(false);

  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
    setEmpId("Select employee name");
    setStatus("Select status");
    setFormvalue(initialvalue);
  };

  const [empId, setEmpId] = useState("Select employee name");
  const [status, setStatus] = useState("Select status");
  const [username, setUsername] = useState("");
  const [admin, setAdmin] = useState(Config.ADMIN_STATUS[0]); //isAdmin
  const [roles , setRoles] = useState("");
  const [getData, setGetData] = useState([]);
  const [errMsg, setErrMsg] = useState({
    name: "",
    password: "",
    empId: "",
    status: "",
    admin: "",
  });

  // get data form employee table

  const getEmployeeList = () => {
    AxiosActions.EmployeeList()
      .then((response) => {
        if (response.status === 200) {
          setGetData(response.data.data);
        }
      })
      .catch((error) => {
        console.log("Error in get employee list", error);
      });
  };

  useEffect(() => {
    getEmployeeList();
  }, []);

  // Update value and state

  const initialvalue = {
    name: "",
    password: "",
  };
  const [formvalue, setFormvalue] = useState(initialvalue);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormvalue({ ...formvalue, [name]: value });
  };

  // Hahdle submit

  function handleSubmit(e) {
    trackEvent(EVENT_NAME.USER_ADD, { data: "" });
    e.preventDefault();
    let errObject = {
      name: "",
      password: "",
      empId: "",
      status: "",
      admin: "",
    };
    let err = 0;
    if (empId === "Select employee name") {
      err++;
      errObject.empId = "Please select name";
    }

    if (status === "Select status") {
      err++;
      errObject.status = "Please Select valid  status";
    }
    if (formvalue.name === "") {
      err++;
      errObject.name = "Please enter valid username";
    }
    console.log('admin: ', admin)

    if (admin === "") {
      console.log('admin: ', admin)

      err++;
      errObject.admin = "Please enter valid is admin";
    }
    if (formvalue.password === "") {
      err++;
      errObject.password = "Please enter valid password";
    }

    if (err !== 0) {
      console.log("has error", err, errObject);
      setErrMsg(errObject);
    } else {
      setIsDisable(true);
      AxiosActions.UserAdd({
        name:
          admin === "true"
            ? username
            : getData?.find((ele) => ele._id === empId).employeeName,
        email: formvalue.name,
        password: formvalue.password,
        empid: empId,
        isAdmin: admin,
        role: roles,
        status: status,
      })
        .then((response) => {
          if (response.status === 200) {
            if (response.data.success === true) {
              setIsDisable(false);
              setOpenAlert(true);
            }
          }
        })
        .catch((error) => {
          setIsDisable(false);
          console.log("Employee Add Error", error);
        });
    }
  }
  return (
    <>
      {" "}
      <AlertBox
        open={openAlert}
        onClose={handleCloseAlert}
        message="Data Add SuccessFully"
        title="Success"
        ok={false}
      />
      <main id="content" role="main" className="main">
        <BackgroundImage/>
        <div
          className="content container-fluid"
          style={{ justifyItems: "center", maxWidth: "60rem" }}
        >
          <div className="page-header">
            <div className="row align-items-end">
              <div className="col-sm mb-2 mb-sm-0"></div>
            </div>
          </div>
          {/* title */}
          <div className="card w-50">
            <div className="card-header">
              <h1 className="card-title h1">User Information</h1>
            </div>

            <div className="card-body">
              <div className="row justify-content-center mb-4">
                <div className="col-sm-9">
                  <div className="input-group input-group-sm-vertical">
                    <TextField
                      fullWidth
                      id="email"
                      name="name"
                      label="User email"
                      value={formvalue.name}
                      onChange={handleChange}
                      size="small"
                      variant="outlined"
                    />
                  </div>{" "}
                  {errMsg.name !== "" ? (
                    <label
                      className=" form-label"
                      style={{ fontSize: 15, color: "red" }}
                    >
                      {errMsg.name}
                    </label>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="row justify-content-center mb-4">
                <div className="col-sm-9">
                  <div className="input-group input-group-sm-vertical">
                    <TextField
                      fullWidth
                      type="password"
                      id="password"
                      name="password"
                      label="User password"
                      value={formvalue.password}
                      onChange={handleChange}
                      variant="outlined"
                      size="small"
                    />
                  </div>{" "}
                  {errMsg.password !== "" ? (
                    <label
                      className=" form-label"
                      style={{ fontSize: 15, color: "red" }}
                    >
                      {errMsg.password}
                    </label>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="row justify-content-center mb-4">
                <div className="col-sm-9">
                  <Autocomplete
                    value={getData?.find(option => option._id === empId) || null}
                    onChange={(event, newValue) => {
                      if (newValue) {
                        setEmpId(newValue._id);
                      } else {
                        setEmpId("Select employee name");
                      }
                    }}
                    size="small"
                    options={getData}
                    getOptionLabel={(option) => option.employeeName}
                    isOptionEqualToValue={(option, value) => option.employeeName === value}
                    renderInput={(params) => <TextField {...params} label="Select employee name" variant="outlined" />}
                  />
                  {errMsg.empId !== "" ? (
                    <label
                      className=" form-label"
                      style={{ fontSize: 15, color: "red" }}
                    >
                      {errMsg.empId}
                    </label>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="row justify-content-center mb-4">
                <div className="col-sm-9">
                  <Autocomplete
                    value={admin || ""}
                    onChange={(event, newValue) => {
                      setAdmin(newValue);
                    }}
                    options={Config.ADMIN_STATUS}
                    getOptionLabel={(option) => option}
                    isOptionEqualToValue={(option, value) => option === value}
                    renderInput={(params) => (
                      <TextField {...params} label="Select Admin" variant="outlined" />
                    )}
                    size="small"
                  />
                  {errMsg.admin !== "" ? (
                    <label
                      className=" form-label"
                      style={{ fontSize: 15, color: "red" }}
                    >
                      {errMsg.admin}
                    </label>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              {admin === 'true' && (
                <div className="row justify-content-center mb-4">
                  <div className="col-sm-9">
                    <Autocomplete
                      value={Config.ADMIN_ROLES?.find(option => option.value === roles) || null}
                      onChange={(event, newValue) => {
                        if (newValue) {
                          setRoles(newValue.value);
                        } else {
                          setRoles("");
                        }
                      }}
                      options={Config.ADMIN_ROLES}
                      getOptionLabel={(option) => option.name}
                      isOptionEqualToValue={(option, value) => option.value === value}
                      renderInput={(params) => (
                        <TextField {...params} label="Select Roles" variant="outlined" />
                      )}
                      size="small"
                    />
                  </div>
                </div>
              )}

              <div className="row justify-content-center mb-4">
                <div className="col-sm-9">
                  <div className="input-group input-group-sm-vertical">
                    <TextField
                      name="name"
                      onChange={(e) => setUsername(e.target.value)}
                      id="username"
                      label="User Name"
                      value={admin === "true" ? username : ""}
                      disabled={admin !== "true"} // If admin, do not disable
                      fullWidth
                      variant="outlined"
                      size="small"
                    />
                  </div>{" "}
                  {errMsg.name !== "" ? (
                    <label
                      className=" form-label"
                      style={{ fontSize: 15, color: "red" }}
                    >
                      {errMsg.name}
                    </label>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="row justify-content-center mb-4">
                <div className="col-sm-9">
                  <Autocomplete
                    value={Config.USER_STATUS?.find(option => option.value === status) || null}
                    onChange={(event, newValue) => {
                      if (newValue) {
                        setStatus(newValue.value);
                      } else {
                        setStatus("");
                      }
                    }}
                    size="small"
                    options={Config.USER_STATUS}
                    getOptionLabel={(option) => option.name}
                    isOptionEqualToValue={(option, value) => option.value === value}
                    renderInput={(params) => (
                      <TextField {...params} label="Select status" variant="outlined" />
                    )}
                  />
                  {errMsg.status !== "" ? (
                    <label
                      className=" form-label"
                      style={{ fontSize: 15, color: "red" }}
                    >
                      {errMsg.status}
                    </label>
                  ) : (
                    ""
                  )}
                </div>
              </div>

              <div className="d-flex justify-content-end">
                <button
                  disabled={isDisable === true}
                  type="button"
                  className="btn btn-warning"
                  onClick={handleSubmit}
                >
                  {SAVECHANGES}
                </button>
              </div>
            </div>
          </div>
          <div></div>
          <br></br>
          <br></br>
        </div>
        <div className="footer">
          <div className="row justify-content-between align-items-center">
            <div className="col"></div>
            <div className="col-auto">
              <div className="d-flex justify-content-end"></div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default HeaderNavbarHOC(UserAdd);
