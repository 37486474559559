export const jobs = {
    ADD_A_JOBS_INFORMATION: "Add a Jobs Information",
    JOBS_ID: "Job Id",
    IMAGE: "Image",
    JOB_TITLE: "Job Title",
    JOB_TYPE: "Job Type",
    EXPERIENCE: "Experience",
    LOCATION: "Location",
    TIME: "Time",
    DUTIES: "Duties",
    STATUS: "Status",
    LANGUAGE: "Languages",
    REQUIREMENTS: "Requirements",
    BENEFITS: "Benefits",
    ROLE: "Role",
    UPLOAD: "Upload",
    VIEW_JOBS: "View jobs",
    SELECT_STATUS: "Select Status",
    EDIT_JOBS: "Edit Jobs",
    ACTION : "Action",
    NO_RECORD_FOUND : "No record found",
    JOBS_INFO : "Job info",

   
    //ACTION 
    SAVE_CHANGES: "Save changes",
    CLOSE: "Close",
}