import React from "react";
import moment from "moment";
import PropTypes from "prop-types";

const ViewEventModal = ({ formik }) => (
  <div
    className="modal fade bd-example-modal-xl"
    id="viewpage"
    tabIndex="-1"
    role="dialog"
    aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true"
  >
    <div className="modal-dialog modal-dialog-centered" role="document">
      <div className="modal-content">
        <div className="modal-header">
          <h1 className="modal-title" id="exampleModalLongTitle">
            View Event Details
          </h1>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div className="modal-body">
          <div>
            <h3 style={{ color: "red" }}>Activity Info</h3>
            <div>
              <table className="table table-align-middle">
                <thead>
                  <tr>
                    <th scope="col"></th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <h4>Activity Name</h4>
                      {formik.values.activityName}
                    </td>
                    <td>
                      <h4>Date and Time</h4>
                      {moment(formik.values.dateAndTime).format("DD MMM YYYY hh:mm A")}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h4>Description</h4>
                      {formik.values.description}
                    </td>
                    <td>
                      <h4>End Time</h4>
                      {formik.values.endTime}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h4>Event Type</h4>
                      {formik.values.eventType}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="modal-footer"></div>
      </div>
    </div>
  </div>
);

export default ViewEventModal;

ViewEventModal.propTypes = {
  formik: PropTypes.object.isRequired,
};
