import React from "react";
import Checkbox from "@mui/material/Checkbox";
import moment from "moment";
import {
  ACTION,
  CREATEDAT,
  NORECORDFOUND,
  PHONE,
  TEMPLATETYPE,
} from "../../constant/Label";
import Config from "../../config";

function Table({
  handleCheckBoxChange,
  isAllSelected,
  loading,
  applicants,
  selectedinfo,
  handleViewReportClick,
  handleDeleteClick,
}) {
  const getTemplateTypeName = (templateTypeValue) => {
    const templateType = Config?.TEMPLATE_TYPE?.find((type) => type?.value === templateTypeValue);
    return templateType ? templateType?.name : templateTypeValue;
  };
  
  return (
    <table
      id="datatable"
      className="table table-borderless table-thead-bordered table-nowrap table-align-middle card-table"
    >
      <thead className="thead-light">
        <tr>
          <th scope="col" className="table-column-pe-0">
            <Checkbox
              size="medium"
              value="all"
              onChange={handleCheckBoxChange}
              checked={isAllSelected}
            ></Checkbox>
          </th>

          <th>{PHONE}</th>
          <th>{TEMPLATETYPE}</th>
          <th>{CREATEDAT}</th>
          <th>{ACTION}</th>
        </tr>
      </thead>
      <tbody>
        {loading ? (
          <tr>
            <td colSpan={8} style={{ textAlign: "center" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <div className="spinner-border text-dark m-5" role="status">
                  <span className="sr-only"></span>
                </div>
              </div>
            </td>
          </tr>
        ) : (
          <>
            {" "}
            {applicants?.length === 0 && (
              <tr>
                <td colSpan={9} style={{ textAlign: "center" }}>
                  {" "}
                  <h3>{NORECORDFOUND}</h3>
                </td>
              </tr>
            )}
            {applicants?.map((app, index) => {
              const phoneNumbers = app.phoneNumber.split(",");
              const displayedPhoneNumbers = phoneNumbers.slice(0, 2);
              return (
                <tr key={"app" + index}>
                  <td className="table-column-pe-0">
                    <Checkbox
                      key={index}
                      size="medium"
                      value={app._id}
                      onChange={handleCheckBoxChange}
                      checked={selectedinfo?.selectedID.includes(app._id)}
                    ></Checkbox>
                  </td>
                  <td>
                    {displayedPhoneNumbers?.length > 1
                      ? `${displayedPhoneNumbers},...`
                      : displayedPhoneNumbers}
                  </td>
                  <td>{getTemplateTypeName(app?.messageType)}</td>
                  <td>{moment(app.createdAt).format("DD-MM-YYYY")}</td>{" "}
                  <td>
                    <button
                      title="Edit"
                      type="button"
                      className="icon icon-danger icon-circle"
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModalLong"
                      onClick={(event) => handleViewReportClick(event, app._id)}
                    >
                      <i className="bi-pen"></i>
                    </button>
                    &nbsp;
                    <button
                      title="Delete"
                      type="button"
                      className="icon icon-info icon-circle"
                      data-bs-toggle="modal"
                      data-bs-target="#delete"
                      onClick={(event) => handleDeleteClick(event, app._id)}
                    >
                      <i className="bi-trash"></i>
                    </button>
                    &nbsp;
                    <button
                    title="View"
                      type="button"
                      className="icon icon-dark icon-circle"
                      data-bs-toggle="modal"
                      data-bs-target="#viewpage"
                      onClick={(event) => handleViewReportClick(event, app._id)}
                    >
                      <i className="bi-eye"></i>
                    </button>
                  </td>
                </tr>
              );
            })}
          </>
        )}
      </tbody>
    </table>
  );
}

export default Table;
