import React from "react";
import Typography from "@mui/material/Typography";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import moment from "moment";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineDot from "@mui/lab/TimelineDot";
import VerifiedIcon from "@mui/icons-material/Verified";
import TimelineContent from "@mui/lab/TimelineContent";
import { productivity } from "../../constant/Productivity";

function NotesStatus({ addloading, notedata }) {
  return (
    <div
      className="modal fade"
      id="viewstatus"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title" id="exampleModalLongTitle">
              {productivity.NOTES_STATUS}
            </h1>

            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            {addloading ? (
              <>
                {" "}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <div className="spinner-border text-dark m-5" role="status">
                    <span className="sr-only"></span>
                  </div>
                </div>
              </>
            ) : (
              <>
                {" "}
                {notedata.length === 0 ? (
                  <Typography
                    sx={{ marginLeft: 25 }}
                    className="typograpy-status"
                    variant="body"
                  >
                    {productivity.NO_RECORD_FOUND}
                  </Typography>
                ) : (
                  notedata.map((item, index) => {
                    return (
                      <Timeline
                        className="timeline-content"
                        key={index}
                        position="alternate"
                      >
                        <TimelineItem>
                          <TimelineOppositeContent
                            sx={{ m: "auto 0" }}
                            align="right"
                            variant="body"
                            color="secondary.main"
                            className="status-timeline"
                          >
                            {moment(item.date).format("DD-MM-YYYY hh:mm:ss a")}
                            {moment(item.date).fromNow()}
                          </TimelineOppositeContent>
                          <TimelineSeparator>
                            <TimelineConnector
                              sx={{
                                bgcolor: "secondary.main",
                              }}
                            />
                            <TimelineDot
                              sx={{
                                bgcolor: "#388e3c",
                              }}
                            >
                              <VerifiedIcon />
                            </TimelineDot>
                            <TimelineConnector
                              sx={{
                                bgcolor: "secondary.main",
                              }}
                            />
                          </TimelineSeparator>
                          <TimelineContent
                            variant="body"
                            sx={{ py: "12px", px: 2 }}
                          >
                            <div></div>
                            <div
                              style={{
                                marginTop: 5,
                                marginLeft: 7,
                              }}
                            >
                              <Typography
                                className="typograpy-status"
                                variant="div"
                              >
                                Note:-{item.notes}
                              </Typography>
                            </div>
                            <div
                              style={{
                                marginTop: 7,
                                marginLeft: 7,
                              }}
                            >
                              <Typography
                                className="typograpy-status"
                                variant="div"
                              >
                                {productivity.UPDATED_BY}
                                {item.admin_id && item.admin_id.email}
                              </Typography>
                            </div>
                          </TimelineContent>
                        </TimelineItem>
                      </Timeline>
                    );
                  })
                )}
              </>
            )}
          </div>
          <div className="modal-footer"></div>
        </div>
      </div>
    </div>
  );
}

export default NotesStatus;
