export const attendance = {
    ATTENDANCE : 'Attendance',

    //ACTION 
    IN  : 'In',
    OUT : 'Out',
    
    CLOSE : 'Close',
    
    //GREETING
    HELLO : 'Hello',

    //MSG
    ATTENDANCE_MSG : 'Your Attendance is already exists',
    
    SAVE_CHANGES : 'Save changes',
    DATE : 'Date:',
    EDIT_TRACKINK_TOOLS : 'Edit tracking tools',
    NAME : 'Name:',
    TYPE : 'Type:',
    TIME : 'Time:',
    NO_RECORD_FOUND : 'No record found',
    ACTION : 'Action:',
}
export const attendanceActions = [
    { label: "In", value: "in" },
  { label: "Out", value: "out" },
]