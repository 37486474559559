import {  Outlet } from "react-router-dom";
import { useContext,  useState } from "react";
import { AuthContext } from "./Protected";

import React from "react";

const Auth = ({ allowedRoles }) => {
 


const adminData=useContext(AuthContext)

 

  


  return (
    <>
   {adminData.adminData.isAdmin ? (<Outlet></Outlet>):("")}
    </>
  );
};

export default Auth;
