import React from "react";
import moment from "moment";
import { expense } from "../../constant/Expence";

function View({
    fullReport,
    getClientStatus,
}) {
  return (
    <div
      className="modal fade bd-example-modal-xl"
      id="viewpage"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title" id="exampleModalLongTitle">
              {expense.VIEW_EXPENSES}
            </h1>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            {fullReport.map((item, index) => {
              return (
                <div key={index}>
                  <h3 style={{ color: "red" }}>{expense.EXPENSES_INFO}</h3>
                  <table className="table table-align-middle">
                    <thead>
                      <tr>
                        <th scope="col"></th>
                        <th scope="col"></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <h4>{expense.YEAR}</h4> {item.year}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <h4>{expense.MONTH}</h4>
                          {item.month}
                        </td>
                        <td>
                          <h4>{expense.CATEGORY}</h4>
                          {item.category}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <h4>{expense.AMOUNT}</h4>
                          {item.netAmount}
                        </td>
                        <td>
                          <h4>{expense.ITEM}</h4>
                          {item.item || "-"}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <h4>{expense.DATE}</h4>
                          {moment(item.date).format("DD-MM-YYYY")}
                        </td>
                        <td>
                          <h4>{expense.STATUS}</h4>
                          {getClientStatus(item.status, "salary")}
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={2}>
                          <h4>{expense.DESCRIPTION}</h4>
                          {item.description || "-"}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              );
            })}
          </div>
          <div className="modal-footer"></div>
        </div>
      </div>
    </div>
  );
}

export default View;
