export const customStyle = {
  heroStyle: {
    width: "1349px",
    position: "relative",
    left: "0px",
    top: "0px",
    zIndex: "999",
    opacity: "1",
  },
  streamTimeStyle: {
    color: "#8A8FA9",
    width: "fit-content",
    display: "flex",
    padding: "5px 9px 4px 2px",
    fontSize: "15px",
    borderRadius: "8px",
    marginBottom: "5px",
    backgroundColor: "#8A8FA933",
  },
  streamTimeImageStyle: {
    color: "#8A8FA9",
    padding: "3px",
    fontSize: "16px",
  },
  addUpdateCard: {
    boxShadow:
      "rgb(231 205 255) 0px 1px 4px 2px, rgba(0, 0, 0, 0.1) 5px 2px 15px -2px",
    backgroundColor: "#ffffff",
  },
  addUpdateFormInner: {
    paddingLeft: "23px",
    paddingTop: "13px",
  },
  blogShadowStyle: {
    boxShadow: "rgb(143 27 220 / 10%) 2px 2px 8px 4px",
    marginTop: "0",
    borderRadius: "10px",
    padding: "20px",
  },
  postsListStyle: {
    background: "rgb(253 253 253)",
    padding: "30px",
    boxShadow: "rgb(102 35 147 / 10%) 1px 7px 10px 6px",
  },
  eventDetailsCardStyle: {
    background: "#fff",
    boxShadow: "rgb(102 35 147 / 10%) 1px 7px 10px 6px",
  },
  selectTag: {
    color: "#8364e8",
    height: "35px",
    borderRadius: "20px",
    margin: "5px",
    padding: "0 12px",
    cursor: "pointer",
  },
  unselectTags: {
    color: "#fff",
    height: "35px",
    borderRadius: "25px",
    margin: "5px",
    padding: "19px 15px",
    backgroundColor: "#8364e8",
  },
  closeTagButton: {
    padding: "0px 8px",
    marginLeft: "5px",
  },
  success: {
    color: "#32CD32",
  },
  warning: {
    color: "#E9D502",
  },
};

export const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
};

export const focusedStyle = {
  borderColor: "#2196f3",
};

export const acceptStyle = {
  borderColor: "#00e676",
};

export const rejectStyle = {
  borderColor: "#ff1744",
};
