import React from "react";
import { Autocomplete, TextField } from "@mui/material";
import { jobs } from "../../constant/Jobs";

function Edit({
  fullReport,
  setjobsid,
  jobsid,
  setjobstitle,
  jobstitle,
  setimage,
  image,
  setjobtype,
  jobtype,
  setExperience,
  Experience,
  setLocation,
  setTime,
  Time,
  setDuties,
  Duties,
  setStatus,
  Status,
  Config,
  Languages,
  removeLanguagesFields,
  handleChangeLanguages,
  addLanguagesFields,
  Requirments,
  Benefits,
  removeRequirmentsFields,
  handleChangeRequirments,
  addRequirmentsFields,
  removeBenefitsFields,
  handleChangeBenefits,
  addBenefitsFields,
  Role,
  removeRoleFields,
  handleChangeRole,
  addRoleFields,
  isDisable,
  changeStatus,
  updateId,
}) {
  return (
    <div
      className="modal fade"
      id="exampleModalLong"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h2 className="modal-title" id="exampleModalLongTitle">
              {jobs.EDIT_JOBS}
            </h2>

            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <div className="modal-content">
              <table>
                <tbody>
                  {fullReport?.map((item, index) => {
                    return (
                      <>
                        <tr>
                          <td>
                            <h4>{jobs.JOBS_ID}</h4>
                            <input
                              type="text"
                              className="form-control"
                              name="jobsid"
                              id="firstNameLabel"
                              placeholder="jobid"
                              onChange={(event) =>
                                setjobsid(event.target.value)
                              }
                              value={jobsid}
                            />
                          </td>
                        </tr>
                        <br></br>
                        <tr>
                          <td>
                            <h4>{jobs.JOB_TITLE}</h4>
                            <input
                              type="text"
                              className="form-control"
                              name="jobstitle"
                              id="firstNameLabel"
                              placeholder="jobstitle"
                              onChange={(event) =>
                                setjobstitle(event.target.value)
                              }
                              value={jobstitle}
                            />
                          </td>
                        </tr>
                        <br></br>
                        <tr>
                          <td colSpan={2}>
                            <h4>{jobs.IMAGE}</h4>
                            <textarea
                              key={index}
                              className="form-control"
                              placeholder="image"
                              id="validationValidTextarea1"
                              rows="2"
                              name="image"
                              onChange={(event) => setimage(event.target.value)}
                              value={image}
                            ></textarea>
                          </td>
                        </tr>
                        <br></br>
                        <tr>
                          <td>
                            <h4>{jobs.JOB_TYPE}</h4>
                            <input
                              type="text"
                              className="form-control"
                              name="jobtype"
                              id="firstNameLabel"
                              placeholder="jobtype"
                              onChange={(event) =>
                                setjobtype(event.target.value)
                              }
                              value={jobtype}
                            />
                          </td>
                          <td>
                            <h4>{jobs.EXPERIENCE}</h4>
                            <input
                              type="text"
                              className="form-control"
                              name="Experience"
                              id="firstNameLabel"
                              placeholder="Experience"
                              onChange={(event) =>
                                setExperience(event.target.value)
                              }
                              value={Experience}
                            />
                          </td>
                        </tr>
                        <br></br>
                        <tr>
                          <td>
                            <h4>{jobs.LOCATION}</h4>
                            <input
                              type="text"
                              className="form-control"
                              name="Location"
                              id="firstNameLabel"
                              placeholder="Time"
                              onChange={(event) =>
                                setLocation(event.target.value)
                              }
                              value={Location}
                            />
                          </td>
                          <td>
                            <h4>{jobs.TIME}</h4>
                            <input
                              type="text"
                              className="form-control"
                              name="Time"
                              id="firstNameLabel"
                              placeholder="Time"
                              onChange={(event) => setTime(event.target.value)}
                              value={Time}
                            />
                          </td>
                        </tr>
                        <br></br>
                        <tr>
                          <td colSpan={2}>
                            <h4>{jobs.DUTIES}</h4>
                            <textarea
                              key={index}
                              className="form-control"
                              placeholder="Duties"
                              id="validationValidTextarea1"
                              rows="2"
                              name="Duties"
                              onChange={(event) =>
                                setDuties(event.target.value)
                              }
                              value={Duties}
                            ></textarea>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <h4 className="mt-4">{jobs.STATUS}</h4>
                            <Autocomplete
                              options={Config.JOBS_STATUS}
                              getOptionLabel={(option) => option.name}
                              value={
                                Config.JOBS_STATUS.find(
                                  (item) => item.value === Status
                                ) || {name : "Select status"}
                              }
                              onChange={(event, newValue) => {
                                console.log('newValue: ', newValue);
                                setStatus(newValue?.value);
                              }}
                              size="small"
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="outlined"
                                  autoComplete="off"
                                />
                              )}
                            />
                          </td>
                        </tr>
                        <br></br>
                        <tr>
                          <td colSpan={2}>
                            <h4>{jobs.LANGUAGE}</h4>
                            <div className="multiple-input1">
                              {Languages?.map((element, index) => (
                                <>
                                  {index === 1 ? (
                                    <button
                                      type="button"
                                      size="small"
                                      className="btn btn-danger btn-icon"
                                      onClick={() => removeLanguagesFields(0)}
                                    >
                                      <i className="bi bi-dash-circle"></i>
                                    </button>
                                  ) : null}

                                  <input
                                    key={index}
                                    type="text"
                                    className="form-control job-input"
                                    name="Languages"
                                    onChange={(e) =>
                                      handleChangeLanguages(index, e)
                                    }
                                    value={element.technologies}
                                    id="firstNameLabel"
                                    placeholder="Languages"
                                    aria-label="Your email"
                                  />
                                  {index ? (
                                    <button
                                      type="button"
                                      size="small"
                                      className="btn btn-danger btn-icon"
                                      onClick={() =>
                                        removeLanguagesFields(index)
                                      }
                                    >
                                      <i className="bi bi-dash-circle"></i>
                                    </button>
                                  ) : null}
                                </>
                              ))}
                              <button
                                type="button"
                                className="btn btn-info btn-icon btn-add"
                                onClick={() => addLanguagesFields()}
                              >
                                <i className="bi bi-plus-square"></i>
                              </button>
                            </div>
                          </td>
                        </tr>
                        <br></br>
                        <tr>
                          <td colSpan={2}>
                            <h4>{jobs.REQUIREMENTS}</h4>
                            <div className="multiple-input2">
                              {Requirments?.map((element, index) => (
                                <>
                                  {index === 1 ? (
                                    <button
                                      type="button"
                                      size="small"
                                      className="btn btn-danger btn-icon"
                                      onClick={() => removeRequirmentsFields(0)}
                                    >
                                      <i className="bi bi-dash-circle"></i>
                                    </button>
                                  ) : null}
                                  <textarea
                                    key={index}
                                    className="form-control job-input"
                                    placeholder="Requirments"
                                    id="validationValidTextarea1"
                                    rows="2"
                                    name="Requirments"
                                    onChange={(e) =>
                                      handleChangeRequirments(index, e)
                                    }
                                    value={element.description}
                                  ></textarea>
                                  {index ? (
                                    <button
                                      type="button"
                                      size="small"
                                      className="btn btn-danger btn-icon"
                                      onClick={() =>
                                        removeRequirmentsFields(index)
                                      }
                                    >
                                      <i className="bi bi-dash-circle"></i>
                                    </button>
                                  ) : null}
                                </>
                              ))}
                              <button
                                type="button"
                                className="btn btn-info btn-icon btn-add"
                                onClick={() => addRequirmentsFields()}
                              >
                                <i className="bi bi-plus-square"></i>
                              </button>
                            </div>
                          </td>
                        </tr>
                        <br></br>
                        <tr>
                          <td colSpan={2}>
                            <h4>{jobs.BENEFITS}</h4>
                            <div className="multiple-input2">
                              {Benefits?.map((element, index) => (
                                <>
                                  {index === 1 ? (
                                    <button
                                      type="button"
                                      size="small"
                                      className="btn btn-danger btn-icon"
                                      onClick={() => removeBenefitsFields(0)}
                                    >
                                      <i className="bi bi-dash-circle"></i>
                                    </button>
                                  ) : null}
                                  <textarea
                                    className="form-control job-input"
                                    placeholder="Benefits"
                                    id="validationValidTextarea1"
                                    rows="2"
                                    name="Benefits"
                                    onChange={(e) =>
                                      handleChangeBenefits(index, e)
                                    }
                                    value={element.description}
                                  ></textarea>
                                  {index ? (
                                    <button
                                      type="button"
                                      size="small"
                                      className="btn btn-danger btn-icon"
                                      onClick={() =>
                                        removeBenefitsFields(index)
                                      }
                                    >
                                      <i className="bi bi-dash-circle"></i>
                                    </button>
                                  ) : null}
                                </>
                              ))}
                              <button
                                type="button"
                                className="btn btn-info btn-icon btn-add"
                                onClick={() => addBenefitsFields()}
                              >
                                <i className="bi bi-plus-square"></i>
                              </button>
                            </div>
                          </td>
                        </tr>
                        <br></br>
                        <tr>
                          <td colSpan={2}>
                            <h4>{jobs.ROLE}</h4>
                            <div className="multiple-input2">
                              {Role?.map((element, index) => (
                                <>
                                  {index === 1 ? (
                                    <button
                                      type="button"
                                      size="small"
                                      className="btn btn-danger btn-icon"
                                      onClick={() => removeRoleFields(0)}
                                    >
                                      <i className="bi bi-dash-circle"></i>
                                    </button>
                                  ) : null}
                                  <textarea
                                    className="form-control job-input"
                                    placeholder="Role"
                                    id="validationValidTextarea1"
                                    rows="2"
                                    name="Role"
                                    onChange={(e) => handleChangeRole(index, e)}
                                    value={element.Role}
                                  ></textarea>
                                  {index ? (
                                    <button
                                      type="button"
                                      size="small"
                                      className="btn btn-danger btn-icon"
                                      onClick={() => removeRoleFields(index)}
                                    >
                                      <i className="bi bi-dash-circle"></i>
                                    </button>
                                  ) : null}
                                </>
                              ))}
                              <button
                                type="button"
                                className="btn btn-info btn-icon btn-add"
                                onClick={() => addRoleFields()}
                              >
                                <i className="bi bi-plus-square"></i>
                              </button>
                            </div>
                          </td>
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-white"
              data-bs-dismiss="modal"
            >
              {jobs.CLOSE}
            </button>
            <button
              disabled={isDisable === true}
              type="button"
              className="btn btn-primary"
              data-bs-dismiss="modal"
              onClick={() => {
                changeStatus(updateId);
              }}
            >
              {jobs.SAVE_CHANGES}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Edit;
