import React from "react";
import moment from "moment";
import PropTypes from "prop-types";
import { attendance, attendanceActions } from "../../constant/Attendance";
import { DateTimePicker } from "../../Datepicker";
import { Autocomplete, TextField } from "@mui/material";

function Edit({
  fullReport,
  setdate,
  date,
  setType,
  Type,
  isDisable,
  changeStatus,
  updateId,
}) {
  return (
    <div
      className="modal fade"
      id="exampleModalLong"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h2 className="modal-title" id="exampleModalLongTitle">
              {attendance.EDIT_TRACKINK_TOOLS}
            </h2>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <div className="modal-content">
              <table>
                <tbody>
                  {fullReport.map((item, index) => {
                    return (
                      <>
                        <tr>
                          <td>
                            <h4>{attendance.NAME}</h4>
                            {item.empid.employeeName}
                          </td>
                        </tr>
                        <br></br>
                        <tr>
                          <td>
                            <h4>{attendance.DATE}</h4>{" "}
                            <DateTimePicker
                              onChange={(DateandTime) => {
                                setdate(DateandTime);
                              }}
                              value={moment(date).toISOString()}
                            />
                          </td>
                        </tr>
                        <br></br>
                        <tr>
                          <td>
                            <h4>{attendance.TYPE}</h4>{" "}
                            <Autocomplete
                              options={attendanceActions}
                              getOptionLabel={
                                (option) => option.label
                              }
                              value={
                                attendanceActions.find(
                                  (option) => option.value === Type
                                )
                              }
                              size="small"
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                />
                              )}
                              onChange={(event, newValue) => {
                                setType(
                                  newValue === null
                                    ? ""
                                    : newValue.value
                                );
                              }}
                            />
                          </td>
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-white"
              data-bs-dismiss="modal"
            >
              {attendance.CLOSE}
            </button>
            <button
              disabled={isDisable === true}
              type="button"
              className="btn btn-primary"
              data-bs-dismiss="modal"
              onClick={() => {
                changeStatus(updateId);
              }}
            >
              {attendance.SAVE_CHANGES}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Edit;

Edit.propTypes = {
  fullReport: PropTypes.array.isRequired,
  setdate: PropTypes.func.isRequired,
  date: PropTypes.string.isRequired,
  setType: PropTypes.func.isRequired,
  Type: PropTypes.string.isRequired,
  isDisable: PropTypes.bool.isRequired,
  changeStatus: PropTypes.func.isRequired,
  updateId: PropTypes.number.isRequired,
}