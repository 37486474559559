import React from "react";
import PropTypes from "prop-types";
import { Autocomplete, TextField } from "@mui/material";
import Config from "../../config";
import {
  EDIT_USER,
  EMAIL,
  NAME,
  ROLE,
  STATUS,
  TYPE,
} from "../../constant/Label";

function Edit({
  fullReport,
  setusername,
  Username,
  setusertype,
  Usertype,
  setstatus,
  status,
  isDisable,
  changeStatus,
  updateId,
  roles,
  setRoles,
}) {
  const userType = [
    { label: "Admin", value: true },
    { label: "Employee", value: false },
  ];
  return (
    <div
      className="modal fade"
      id="exampleModalLong"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h2 className="modal-title" id="exampleModalLongTitle">
              {EDIT_USER}
            </h2>

            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <div className="modal-content">
              <table>
                <tbody>
                  {fullReport.map((item, index) => {
                    return (
                      <>
                        <tr>
                          <td>
                            <h4>{NAME}</h4>
                            {item.empid?.employeeName}
                          </td>
                        </tr>

                        <br></br>
                        <tr>
                          <td>
                            <h4>{EMAIL}</h4>
                            <input
                              type="text"
                              className="form-control"
                              name="Username"
                              id="firstNameLabel"
                              placeholder="Username"
                              disabled
                              onChange={(event) =>
                                setusername(event.target.value)
                              }
                              value={Username}
                            />
                          </td>
                        </tr>
                        <br></br>
                        <tr>
                          <td>
                            <h4>{TYPE}</h4>
                            <Autocomplete
                              key={userType?.value}
                              options={userType}
                              getOptionLabel={(option) => option.label}
                              size="small"
                              value={userType.find(
                                (option) => option.value === Usertype
                              )}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                />
                              )}
                              onChange={(event, newValue) => {
                                setusertype(newValue.value);
                              }}
                            />
                          </td>
                        </tr>
                        <br></br>
                        {Usertype.toString() === "true" ? (
                          <>
                            <tr>
                              <td>
                                <h4>{ROLE}</h4>
                                <Autocomplete
                              options={Config.ADMIN_ROLES}
                              getOptionLabel={(option) => option.name}
                              size="small"
                              value={
                                Config.ADMIN_ROLES.find(
                                  (item) => item.value === roles
                                ) || {name : "Select role"}
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                />
                              )}
                              onChange={(event, newValue) => {
                                setRoles(newValue.value);
                              }}
                            />
                              </td>
                            </tr>
                            <br></br>
                          </>
                        ) : (
                          ""
                        )}

                        <tr>
                          <td>
                            <h4>{STATUS}</h4>
                            <Autocomplete
                              options={Config.USER_STATUS}
                              getOptionLabel={(option) => option.name}
                              size="small"
                              value={
                                Config.USER_STATUS.find(
                                  (item) => item.value === status
                                ) || {name : "Select status"}
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                />
                              )}
                              onChange={(event, newValue) => {
                                setstatus(newValue.value);
                              }}
                            />
                          </td>
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-white"
              data-bs-dismiss="modal"
            >
              Close
            </button>
            <button
              disabled={isDisable === true}
              type="button"
              className="btn btn-primary"
              data-bs-dismiss="modal"
              onClick={() => {
                changeStatus(updateId);
              }}
            >
              Save changes
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Edit;

Edit.propTypes = {
  fullReport: PropTypes.array.isRequired,
  setusername: PropTypes.func.isRequired,
  Username: PropTypes.string.isRequired,
  setusertype: PropTypes.func.isRequired,
  Usertype: PropTypes.bool.isRequired,
  setstatus: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired,
  isDisable: PropTypes.bool.isRequired,
  changeStatus: PropTypes.func.isRequired,
  updateId: PropTypes.number.isRequired,
  roles: PropTypes.string.isRequired,
  setRoles: PropTypes.func.isRequired,
};
