import React from 'react';
import moment from 'moment';
import { employee } from '../../constant/Employee';
import PropTypes from 'prop-types';

function ViewEmployeeModal ({ fullReport, formik })  {
  return (
    <div
      className="modal fade bd-example-modal-xl"
      id="viewpage"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title" id="exampleModalLongTitle">
              {employee.VIEW_EMPLOYEE_DETAILS}
            </h1>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            {fullReport.map((item, index) => {
              return (
                <div key={item.id}>
                  <h3 style={{ color: "red" }}>{employee.PERSONAL_INFO}</h3>
                  <div>
                    <table className="table table-align-middle">
                      <thead>
                        <tr>
                          <th scope="col"></th>
                          <th scope="col"></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <h4>{employee.NAME}</h4>
                            {item.employeeName}
                          </td>
                          <td>
                            <h4>{employee.EMAIL}</h4>
                            {item.employeeEmail}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <h4>{employee.CONTACT_NUMBER}</h4>
                            {item.PrimaryContact}
                          </td>
                          <td>
                            <h4>{employee.CONTACT_NUMBER}</h4>
                            {item.SecondaryContact || "-"}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <h4>{employee.EMERGENCY_CONTACT_NAME}</h4>
                            {item.emergencyName || "-"}
                          </td>
                          <td>
                            <h4>{employee.EMERGENCY_CONTACT_NUMBER}</h4>
                            {item.emergencyMobile || "-"}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <h4>{employee.DATE_OF_BIRTH}</h4>
                            {moment(item.DateOfBirth).format("DD-MM-YYYY")}
                          </td>
                          <td>
                            <h4>{employee.QUALIFICATION}</h4>
                            {item.qualification || "-"}
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={1}>
                            <h4>{employee.ADDRESS}</h4>
                            {item.address}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <h4>{employee.BANK_NAME}</h4>
                            {item.bankName}
                          </td>
                          <td>
                            <h4>{employee.BANK_ACCOUNT_NUMBER}</h4>
                            {item.bankAccountNumber}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <h4>{employee.BANK_IFSC_CODE}</h4>
                            {item.bankIFSCCode}
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={2}>
                            <h4>{employee.DOCUMENT_NAME}</h4>
                            {item.DocumentsName.map((name) => (
                              <div key={name.id}>{name}</div>
                            ))}
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={2}>
                            <h4>{employee.DOCUMENT_PATH}</h4>
                            {item.DocumentsPath.map((path) =>
                              path ? (
                                <div key={path.id}>{path}</div>
                              ) : (
                                "No Document Uploaded"
                              )
                            )}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <br></br>
                  <br></br>
                  <h3 style={{ color: "red" }}>{employee.COMPANY_INFO}</h3>
                  <div>
                    <table className="table table-align-middle">
                      <thead>
                        <tr>
                          <th scope="col"></th>
                          <th scope="col"></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <h4>{employee.EMPLOYEE_ROLE}</h4>
                            {item.employeeRole}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <h4>{employee.DATE_OF_JOINING}</h4>
                            {moment(item.DateOfJoin).format("DD-MM-YYYY")}
                          </td>
                          <td>
                            <h4>{employee.DATE_OF_EXIT}</h4>
                            {item.DateOfExit == null
                              ? "-"
                              : moment(item.DateOfExit).format("DD-MM-YYYY")}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <h4>{employee.OFFERED_CTC}</h4>
                            {item.OfferCTC}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <h4>{employee.CONTRACT_PERIOD}</h4>
                            {item.ContractPeriod}
                          </td>
                          <td>
                            <h4>{employee.DURATION}</h4>
                            {formik.values.duration}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="modal-footer"></div>
        </div>
      </div>
    </div>
  );
};

export default ViewEmployeeModal;

ViewEmployeeModal.propTypes = {
  fullReport: PropTypes.arrayOf(PropTypes.object).isRequired,
  formik: PropTypes.object.isRequired,
};