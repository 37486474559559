import React, { useState, useContext,useEffect, useCallback } from "react";
import moment from "moment";
import { Autocomplete, TextField } from "@mui/material";
import HeaderNavbarHOC from "../../HOC/HeaderNavbar";
import Config from "../../config";
import * as AxiosActions from "../../Actions/axiosActions";
import { AuthContext } from "../../Login/Protected";
import AlertBox from "../../Alert";
import { trackEvent, EVENT_NAME } from "../../constant/MixpanelConstant";
import Datepicker from "../../Datepicker";
import BackgroundImage from "../../components/Common/CardImage";
import { DATAADDEDSUCCESSFULLY } from "../../constant/Label";

function TaskAdd() {
    const [empId, setEmpId] = useState("Select employee name");
  const [openAlert, setOpenAlert] = useState(false);
  const adminData = useContext(AuthContext);
  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
    setEmpId("Select employee name")
    setClientName("Select project")
    setStatus("Select status");
    setFormvalue(initialvalue);
  };
  const [date, setDate] = useState();
  const [clientName, setClientName] = useState("Select project");
  const [status, setStatus] = useState("Select status"); 
  const [errMsg, setErrMsg] = useState({
    name: "",
    status: "",
  });
  const [isDisable, setIsDisable] = useState(false);

  const initialvalue = {
    Summary:"",
  
  };

  const [formvalue, setFormvalue] = useState(initialvalue);
  const handleChange = (e) => {
    const {name, value } = e.target;
    setFormvalue({ ...formvalue, [name]: value });
  };

  const [getData, setGetData] = useState([]);
  const [getClient, setGetClient] = useState([]);
  const getClientList = () => {
    AxiosActions.ProdutivityClientList({})
      .then((response) => {
        if (response.status === 200) {
          setGetClient(response.data.data);
        }
      })
      .catch((error) => {
        console.log("Error in get Client", error);
      });
  };
  const getEmployeeList = () => {
    AxiosActions.EmployeeList(({}))
      .then((response) => {
        if (response.status === 200) {
          setGetData(response.data.data);
        }
      })
      .catch((error) => {
        console.log("Error in get employee list", error);
      });
  };

  const getLoginEmployee = useCallback(() => {
    if(adminData.adminData.empid){
        setEmpId(adminData.adminData.empid._id)
    }
  }, [adminData.adminData]);

  useEffect(() => {
    getLoginEmployee();
    getEmployeeList();
    getClientList();
  }, [adminData, getLoginEmployee]);
 

  // Hahdle submit

  function handleSubmit(e) {
    trackEvent(EVENT_NAME.TASK_ADD, { data: "" });
    e.preventDefault();
    let errObject = {
        Summary: "",
        status: "",
    };
    let err = 0;
    if (empId === "Select employee name") {
      err++;
      errObject.name = "Please select name";
    }
    if (clientName === "Select project") {
        err++;
        errObject.clientName = "Please select project";
      }
    if (status === "Select status") {
      err++;
      errObject.status = "Select status";
    }
    
    if (formvalue.Summary === "") {
        err++;
        errObject.Summary = "Please enter valid Summary";
      }
   
    if (err !== 0) {
      console.log("has error", err, errObject);
      setErrMsg(errObject);
    } else {
      setIsDisable(true);
      AxiosActions.TaskAdd({
        clientId:clientName,
        empid:empId,
        summary:formvalue.Summary,
        date:date,
        adminId:adminData.adminData._id,
        status:status
      
      })
        .then((response) => {
          if (response.status === 200) {
           
            if (response.data.success === true) {
              setIsDisable(false);
              setOpenAlert(true);
            }
          }
        })
        .catch((error) => {
          setIsDisable(false);
          console.log("Tracking tools error", error);
        });
    }
  }



  return (
    <>
      {" "}
      <AlertBox
        open={openAlert}
        onClose={handleCloseAlert}
        message={DATAADDEDSUCCESSFULLY}
        title="Success"
        ok={false}
      />
      <main id="content" role="main" className="main">
        <BackgroundImage/>
        <div
          className="content container-fluid"
          style={{ justifyItems: "center", maxWidth: "60rem" }}
        >
          <div className="page-header">
            <div className="row align-items-end">
              <div className="col-sm mb-2 mb-sm-0"></div>
            </div>
          </div>

          <div className="card w-70">
            <div className="card-header">
              <h1 className="card-title h1">Add Task</h1>
            </div>

            <div className="card-body">
            <div className="row mb-4">
                <label
                  htmlFor="clientNameLabel"
                  className="col-sm-3 col-form-label form-label"
                  style={{ fontSize: 17 }}
                >
                  Project
                </label>
                <div className="col-sm-9">
                  <Autocomplete
                    options={getClient}
                    getOptionLabel={
                      (option) => option.clientName
                    }
                    value={
                      getClient.find((option) => option._id === clientName ) || { clientName: "Select project"}
                    }
                    size="small"
                    renderInput={(params) => (
                      <TextField {...params} />
                    )}
                    onChange={
                      (event, newValue) => {
                        setClientName(newValue? newValue._id : null);
                      }
                    }
                  />
                  {errMsg.clientName !== "" ? (
                    <label
                      className=" form-label"
                      style={{ fontSize: 15, color: "red" }}
                    >
                      {errMsg.clientName}
                    </label>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="row mb-4">
                <label
                  htmlFor="employeeLabel"
                  className="col-sm-3 col-form-label form-label"
                  style={{ fontSize: 17 }}
                >
                 Employee
                </label>
                    {adminData.adminData.isAdmin ? (
                  <div className="col-sm-9">
                    <Autocomplete
                        options={getData}
                        getOptionLabel={
                          (option) => option.employeeName
                        }
                        value={
                          getData.find((option) => option._id === empId ) || { employeeName: "Select employee"}
                        }
                        size="small"
                        renderInput={(params) => (
                          <TextField {...params} />
                        )}
                        onChange={
                          (event, newValue) => {
                            setEmpId(newValue? newValue._id : null);
                          }
                        }
                      />
                    {errMsg.name !== "" ? (
                      <label
                        className=" form-label"
                        style={{ fontSize: 15, color: "red" }}
                      >
                        {errMsg.name}
                      </label>
                    ) : (
                      ""
                    )}
                  </div>
                ) : (
                  <div className="col-sm-9">
                    <label
                      className="col-sm-3 col-form-label form-label"
                      style={{ fontSize: 17 }}
                    >{adminData?.adminData?.empid?.employeeName}
                    </label>
                  </div>
                )}
              </div>
              <div className="row mb-4">
                <label
                  htmlFor="summaryLabel"
                  className="col-sm-3 col-form-label form-label"
                  style={{ fontSize: 17 }}
                >
                 Summary
                </label>
                <div className="col-sm-9">
                <textarea
                    className="form-control"
                    placeholder="Summary"
                    id="validationValidTextarea1"
                    rows="2"
                    name="Summary"
                    onChange={handleChange}
                    value={formvalue.Summary}
                  ></textarea>
                  {errMsg.Summary !== "" ? (
                    <label
                      className=" form-label"
                      style={{ fontSize: 15, color: "red" }}
                    >
                      {errMsg.Summary}
                    </label>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="row mb-4">
                    <label
                      htmlFor="emailLabel"
                      className="col-sm-3 col-form-label form-label"
                      style={{ fontSize: 17 }}
                    >
                     Date
                    </label>
                    <div className="col-sm-9">
                    <Datepicker onChange={(newDate) => {setDate(newDate)}} value={moment(date).format('YYYY-MM-DD')}/>
                    </div>
                  </div>
              <div className="row mb-4">
                <label
                  htmlFor="emailLabel"
                  className="col-sm-3 col-form-label form-label"
                  style={{ fontSize: 17 }}
                >
                 Status
                </label>
                <div className="col-sm-9">
                  <Autocomplete
                  options={Config.TASK_STATUS}
                  getOptionLabel={
                    (option) => option
                  }
                  value={
                    Config.TASK_STATUS.find((option) => option === status) || "Select status"
                  }
                  size="small"
                  renderInput={(params) => (
                    <TextField {...params} 
                    />
                  )}
                  onChange={
                    (event, newValue) => {
                      setStatus(newValue);
                    }
                  }
                />
                  {errMsg.status !== "" ? (
                    <label
                      className=" form-label"
                      style={{ fontSize: 15, color: "red" }}
                    >
                      {errMsg.status}
                    </label>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="d-flex justify-content-end">
                <button
                  disabled={isDisable === true}
                  type="button"
                  className="btn btn-warning"
                  onClick={handleSubmit}
                >
                  Save changes
                </button>
              </div>
            </div>
          </div>
          <div></div>
          <br></br>
          <br></br>
        </div>
        <div className="footer">
          <div className="row justify-content-between align-items-center">
            <div className="col"></div>
            <div className="col-auto">
              <div className="d-flex justify-content-end"></div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default HeaderNavbarHOC(TaskAdd);
