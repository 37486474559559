import React, { useState } from "react";
import { Autocomplete, TextField } from "@mui/material";
import HeaderNavbarHOC from "../../HOC/HeaderNavbar";
import Config from "../../config";
import AlertBox from "../../Alert";
import { trackEvent, EVENT_NAME } from "../../constant/MixpanelConstant";
import * as AxiosActions from "../../Actions/axiosActions";
import {
  ADDWHATSAPP,
  DATAADDEDSUCCESSFULLY,
  LABLENOTEMPTY,
  PHONE,
  SAVECHANGES,
  SELECTTEMPLATE,
  SUCCESS,
  TEMPLATETYPE,
} from "../../constant/Label";
import BackgroundImage from "../../components/Common/CardImage";

function WhatsappAdd() {
  const [openAlert, setOpenAlert] = useState(false);
  const [isDisable, setIsDisable] = useState(false);
  const currentDate = new Date();
  const currentMonth = Config.SALARY_MONTH[currentDate.getMonth()];
  const currentYear = currentDate.getFullYear().toString();

  const initialvalue = {
    phone: "",
    template: "appointment",
    label: [
      { type: "text", text: "" },
      { type: "text", text: "" },
      { type: "text", text: "" },
      { type: "text", text: "" },
      { type: "text", text: "" },
    ],
  };
  const [formValue, setFormValue] = useState(initialvalue);

  const [errMsg, setErrMsg] = useState({
    phone: "",
    template: "",
    labels: formValue?.label?.map(() => ""),
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "phone") {
      const sanitizedValue = value.replace(/[^\d,\s\n]+/g, "");
      setFormValue((prevFormValue) => ({
        ...prevFormValue,
        phone: sanitizedValue,
      }));
    } else if (name.startsWith("label")) {
      const index = parseInt(name.slice(-1), 10) - 1;
      setFormValue((prevFormValue) => ({
        ...prevFormValue,
        label: prevFormValue.label.map((item, i) =>
          i === index ? { ...item, text: value } : item
        ),
      }));
    } else {
      setFormValue((prevFormValue) => ({
        ...prevFormValue,
        [name]: value,
      }));
    }
  };

  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
    setFormValue(initialvalue);
  };

  // Handle submit
  function handleSubmit(e) {
    e.preventDefault();

    const phoneNumbersArray = formValue?.phone
      ?.split(/\n/)
      .map((number) => number.replace(/\s+/g, "").replace(/,+$/, ""))
      .filter(Boolean);

    const formattedMobileString = phoneNumbersArray.join(",");

    const isValid = formattedMobileString
      .split(",")
      .every((number) => /^91\d{10}$/.test(number));

    let errObject = {
      phone: "",
      template: "",
      labels: formValue?.label?.map((labelItem) =>
        labelItem?.text.trim() === "" ? LABLENOTEMPTY : ""
      ),
    };
    let err = 0;
    if (errObject?.labels.some((labelError) => labelError !== "")) {
      err++;
    }
    if (!isValid) {
      err++;
      errObject.phone = "Phone number must be 10 digit";
    }
    if (formValue?.template === "") {
      err++;
      errObject.template = "Select template";
    }
    if (err !== 0) {
      setErrMsg(errObject);
    } else {
      setErrMsg({
        phone: "",
        template: "",
        labels: formValue?.label?.map(() => ""),
      });

      trackEvent(EVENT_NAME.WHATSAPP_ADD, {
        data: {
          messageType: formValue?.template,
          phoneNumber: formattedMobileString,
          templateParameters: formValue?.label,
          month: currentMonth,
          year: Number(currentYear),
        },
      });

      setIsDisable(true);
      AxiosActions.WhatsAppMarketingAdd({
        messageType: formValue?.template,
        phoneNumber: formattedMobileString,
        templateParameters: formValue?.label,
        month: currentMonth,
        year: Number(currentYear),
      })
        .then((response) => {
          if (response.status === 200) {
            setIsDisable(false);
            setOpenAlert(true);
          }
        })
        .catch((error) => {
          setIsDisable(false);
          console.log("WhatsApp add error", error);
        });
    }
  }

  return (
    <>
      <AlertBox
        open={openAlert}
        onClose={handleCloseAlert}
        message={DATAADDEDSUCCESSFULLY}
        title={SUCCESS}
        ok={false}
      />
      <main id="content" role="main" className="main">
        <BackgroundImage/>
        <div
          className="content container-fluid"
          style={{ justifyItems: "center", maxWidth: "60rem" }}
        >
          <div className="page-header">
            <div className="row align-items-end">
              <div className="col-sm mb-2 mb-sm-0"></div>
            </div>
          </div>

          <div className="card w-70">
            <div className="card-header">
              <h1 className="card-title h1">{ADDWHATSAPP}</h1>
            </div>

            <div className="card-body">
              <div className="row mb-4">
                <label
                  className="col-sm-3 col-form-label form-label"
                  style={{ fontSize: 17 }}
                >
                  {PHONE}
                </label>
                <div className="col-sm-9">
                  <textarea
                    className="form-control"
                    placeholder="Phone"
                    id="validationValidTextarea1"
                    rows="2"
                    name="phone"
                    value={formValue?.phone}
                    onChange={handleChange}
                  ></textarea>
                  {errMsg.phone !== "" ? (
                    <label
                      className=" form-label"
                      style={{ fontSize: 15, color: "red" }}
                    >
                      {errMsg.phone}
                    </label>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="row mb-4">
                <label
                  htmlFor="templateLabel"
                  className="col-sm-3 col-form-label form-label"
                  style={{ fontSize: 17 }}
                >
                  {TEMPLATETYPE}
                </label>
                <div className="col-sm-9">
                  <Autocomplete
                    name = 'template'
                    options={Config.TEMPLATE_TYPE || []}
                    getOptionLabel={(option) => option.value || ""}
                    value={Config.TEMPLATE_TYPE.find((item) => item.value === formValue?.template) || null}
                    onChange={
                      (event, newValue) => {
                        setFormValue((prevFormValue) => ({
                          ...prevFormValue,
                          template: newValue?.value || "",
                        }));
                      }
                    }
                    size="small"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        autoComplete="off"
                      />
                    )}
                  />
                  {errMsg.template !== "" ? (
                    <label
                      className=" form-label"
                      style={{ fontSize: 15, color: "red" }}
                    >
                      {errMsg.template}
                    </label>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              {formValue?.label?.map((labelItem, index) => (
                <div className="row mb-4" key={index}>
                  <label
                    className="col-sm-3 col-form-label form-label"
                    style={{ fontSize: 17 }}
                  >
                    {`{{${index + 1}}}`}
                  </label>
                  <div className="col-sm-9">
                    <input
                      type="text"
                      className="form-control"
                      name={`label${index + 1}`}
                      onChange={handleChange}
                      id={`label${index + 1}`}
                      aria-label={labelItem?.type}
                      value={formValue?.label[index]?.text}
                    />
                    {errMsg.labels[index] && (
                      <label
                        className="form-label"
                        style={{ fontSize: 15, color: "red" }}
                      >
                        {errMsg.labels[index]}
                      </label>
                    )}
                  </div>
                </div>
              ))}
              <div className="d-flex justify-content-end">
                <button
                  disabled={isDisable === true}
                  type="button"
                  className="btn btn-warning"
                  onClick={handleSubmit}
                >
                  {SAVECHANGES}
                </button>
              </div>
            </div>
          </div>
          <div></div>
          <br></br>
          <br></br>
        </div>
        <div className="footer">
          <div className="row justify-content-between align-items-center">
            <div className="col"></div>
            <div className="col-auto">
              <div className="d-flex justify-content-end"></div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default HeaderNavbarHOC(WhatsappAdd);
