import React, { useState, useEffect, useCallback } from "react";
import HeaderNavbarHOC from "../../HOC/HeaderNavbar";
import AlertBox from "../../Alert";
import { trackEvent, EVENT_NAME } from "../../constant/MixpanelConstant";
import * as AxiosActions from "../../Actions/axiosActions";
import Datepicker from "../../Datepicker";
import moment from "moment";
import { numberToWords } from "number-to-words";
import Config, { yearArray } from "../../config";
import { Autocomplete, TextField } from "@mui/material";
import BackgroundImage from "../../components/Common/CardImage";
import { DATAADDEDSUCCESSFULLY } from "../../constant/Label";

function SalaryAdd() {
  const [openAlert, setOpenAlert] = useState(false);
  const [date, setdate] = useState(moment(new Date));
  const [getdata, setgetdata] = useState([]);
  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
    setstatus("Select status");
    setFormvalue(initialvalue);
    setBasic("");
    setTotalAddition("");
    setTotalDeduction("");
    setNetAmount("");
  };
  const getEmployeeList = () => {
    AxiosActions.EmployeeList({})
      .then((response) => {
        console.log("getEmployeeList", response);
        if (response.status === 200) {
          setgetdata(response.data.data);
        }
      })
      .catch((error) => {
        console.log("Error in get employee list", error);
      });
  };

  useEffect(() => {
    getEmployeeList();
  }, []);

  const currentDate = new Date();
  const currentMonth = Config.SALARY_MONTH[currentDate.getMonth()];
  const currentYear = currentDate.getFullYear().toString();


  const [empid, setempid] = useState("Select employee name");
  const [month, setmonth] = useState(currentMonth);
  const [year, setyear] = useState(currentYear);
  const [status, setstatus] = useState("Select status");
  const [errMsg, setErrMsg] = useState({
    name: "",
  });
  const [isDisable, setIsDisable] = useState(false);
  const initialvalue = {
    month: month,
    year: year,
    total_days: "30",
    Days: "",
    HRA: "0",
    Conveyance: "0",
    PF: "0",
    esi: "0",
    ProfessionTax: "0",
    Amount: "",
    Mode: "",
    bank: "",
    Cheque: "",
  };
  const [formvalue, setFormvalue] = useState(initialvalue);
  const [salary, setSalary] = useState("");
  const [basic, setBasic] = useState("");
  const [TotalAddition, setTotalAddition] = useState("");
  const [TotalDeduction, setTotalDeduction] = useState("");
  const [NetAmount, setNetAmount] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "month") {
      setmonth(value);
    } else if (name === "year") {
      setyear(value);
    }
    setFormvalue({ ...formvalue, [name]: value });
    if (formvalue.total_days !== "") {
      console.log("enter");
      if (empid !== "Select employee name") {
        getdata.filter((data) => {
          if (data._id === empid) {
            setSalary(data.OfferCTC);
            return true;
          }
          return false;
        });
      }
    }
  };
  const handlebasic = useCallback(() => {
    const basicSalary =
      (parseInt(salary) / parseInt(formvalue.total_days)) *
      parseInt(formvalue.Days);
    if (basicSalary) {
      setBasic(parseInt(basicSalary));
    }
  }, [formvalue.Days, formvalue.total_days, salary]);

  const handleTotalAddition = useCallback(() => {
    const totalAddition =
      parseInt(basic) +
      parseInt(formvalue.HRA) +
      parseInt(formvalue.Conveyance);
    if (!isNaN(totalAddition)) {
      setTotalAddition(totalAddition);
    }
  }, [basic, formvalue.HRA, formvalue.Conveyance]);

  const handleTotalDeduction = useCallback(() => {
    const totalDeduction =
      parseInt(formvalue.PF) +
      parseInt(formvalue.esi) +
      parseInt(formvalue.ProfessionTax);
    if (!isNaN(totalDeduction)) {
      setTotalDeduction(totalDeduction);
    }
  }, [formvalue.PF, formvalue.esi, formvalue.ProfessionTax]);

  const handleNetAmount = useCallback(() => {
    const totalAddition = parseInt(TotalAddition);
    const totalDeduction = parseInt(TotalDeduction);

    if (!isNaN(totalAddition) && !isNaN(totalDeduction)) {
      const netAmount = totalAddition - totalDeduction;
      setNetAmount(netAmount);

      if (!isNaN(netAmount)) {
        const amountInWords = numberToWords.toWords(netAmount);
        setFormvalue((prevFormvalue) => ({ ...prevFormvalue, Amount: amountInWords }));
      } else {
        setFormvalue((prevFormvalue) => ({ ...prevFormvalue, Amount: "" }));
      }
    } else {
      setFormvalue((prevFormvalue) => ({ ...prevFormvalue, Amount: "" }));
    }
  }, [TotalAddition, TotalDeduction]);

  useEffect(() => {
    handlebasic();
  }, [formvalue.Days, formvalue.total_days, salary]);

  useEffect(() => {
    handleTotalAddition();
  }, [basic, formvalue.HRA, formvalue.Conveyance]);

  useEffect(() => {
    handleTotalDeduction();
  }, [formvalue.PF, formvalue.esi, formvalue.ProfessionTax]);

  useEffect(() => {
    handleNetAmount();
  }, [TotalAddition, TotalDeduction]);

  // Hahdle submit
  function handleSubmit(e) {
    trackEvent(EVENT_NAME.TRACKING_TOOLS_ADD, { data: "" });
    e.preventDefault();
    let errObject = {
      name: "",
      status: "",
    };
    let err = 0;

    if (empid === "Select employee name") {
      err++;
      errObject.name = "Select employee name";
    }
    if (err !== 0) {
      console.log("has error", err, errObject);
      setErrMsg(errObject);
    } else {
      setIsDisable(true);
      AxiosActions.SalaryAdd({
        empid: empid,
        month: month,
        year: formvalue.year,

        totalDays: formvalue.total_days,

        days: formvalue.Days,
        basicSalary: basic,
        hra: formvalue.HRA,
        conveyanceAllowance: formvalue.Conveyance,
        totalAddition: TotalAddition,

        pf: formvalue.PF,
        employees_State_Insurance: formvalue.esi,
        professionTax: formvalue.ProfessionTax,
        totalDeduction: TotalDeduction,
        netAmount: NetAmount,

        salary_Amt_In_Words: formvalue.Amount,
        mode: formvalue.Mode,
        bank_Name: formvalue.bank,
        status: status === "true" ? true : false,
        date: date,
        cheque_No: formvalue.Cheque,
      })
        .then((response) => {
          if (response.status === 200) {
            console.log("SalaryAdd responce", response);
            if (response.data.success === true) {
              setIsDisable(false);
              setOpenAlert(true);
            }
          }
        })
        .catch((error) => {
          setIsDisable(false);
          console.log("SalaryAdd error", error);
        });
    }
  }

  return (
    <>
      {" "}
      <AlertBox
        open={openAlert}
        onClose={handleCloseAlert}
        message={DATAADDEDSUCCESSFULLY}
        title="Success"
        ok={false}
      />
      <main id="content" role="main" className="main">
        <BackgroundImage/>
        <div
          className="content container-fluid"
          style={{ justifyItems: "center", maxWidth: "60rem" }}
        >
          <div className="page-header">
            <div className="row align-items-end">
              <div className="col-sm mb-2 mb-sm-0"></div>
            </div>
          </div>

          <div className="card w-70">
            <div className="card-header">
              <h1 className="card-title h1">Add Salary</h1>
            </div>

            <div className="card-body">
              <div className="row mb-4">
                <label
                  htmlFor="employeeNameLabel"
                  className="col-sm-3 col-form-label form-label"
                  style={{ fontSize: 17 }}
                >
                  Employee Name
                </label>
                <div className="col-sm-9">
                  <Autocomplete
                    options={getdata}
                    getOptionLabel={(option) => option.employeeName}
                    value={
                      getdata.find((option) => option._id === empid) || {
                        employeeName: "Select employee",
                      }
                    }
                    size="small"
                    renderInput={(params) => <TextField {...params} />}
                    onChange={(event, newValue) => {
                      setempid(
                        newValue === null
                          ? "Select employee name"
                          : newValue._id
                      );
                    }}
                  />
                  {errMsg.name !== "" ? (
                    <label
                      className=" form-label"
                      style={{ fontSize: 15, color: "red" }}
                    >
                      {errMsg.name}
                    </label>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="row mb-4">
                <label
                  htmlFor="monthLabel"
                  className="col-sm-3 col-form-label form-label"
                  style={{ fontSize: 17 }}
                >
                  Month
                </label>
                <div className="col-sm-9">
                  <Autocomplete
                    options={Config.SALARY_MONTH}
                    getOptionLabel={(option) => option}
                    value={
                      Config.SALARY_MONTH.find((option) => option === month) ||
                      "Select Month"
                    }
                    size="small"
                    renderInput={(params) => <TextField {...params} />}
                    onChange={(event, newValue) => {
                      setmonth(newValue);
                      handleChange(event);
                    }}
                  />
                  <br></br>
                  <Autocomplete
                    options={yearArray} // An array of year options
                    getOptionLabel={(option) => option.toString()} // Ensure the label is a string
                    value={
                      formvalue?.year ||
                      Config.SALARY_YEAR.find((option) => option === year) ||
                      "Select Year"
                    }
                    size="small"
                    renderInput={(params) => <TextField {...params} />}
                    onChange={(event, newValue) => {
                      if (newValue) {
                        setyear(newValue);
                        handleChange({
                          target: { name: "year", value: newValue },
                        });
                      }
                    }}
                  />
                </div>
              </div>
              <div className="row mb-4">
                <label
                  htmlFor="totalDaysLabel"
                  className="col-sm-3 col-form-label form-label"
                  style={{ fontSize: 17 }}
                >
                  Total Days
                </label>
                <div className="col-sm-9">
                  <input
                    type="number"
                    className="form-control"
                    name="total_days"
                    onChange={(newValue) => handleChange(newValue)}
                    id="totalDaysLabel"
                    placeholder="Total Days"
                    aria-label="Email"
                    value={formvalue.total_days}
                    defaultValue={30}
                  />{" "}
                </div>
              </div>

              <div className="row mb-4">
                <label
                  htmlFor="daysThLabel"
                  className="col-sm-3 col-form-label form-label"
                  style={{ fontSize: 17 }}
                >
                  Days(30)
                </label>
                <div className="col-sm-9">
                  <input
                    type="number"
                    className="form-control"
                    name="Days"
                    onChange={(newValue) => handleChange(newValue)}
                    id="daysThLabel"
                    placeholder="Days"
                    aria-label="Email"
                    value={formvalue.Days}
                  />{" "}
                </div>
              </div>
              <div className="row mb-4">
                <label
                  htmlFor="basicDALabel"
                  className="col-sm-3 col-form-label form-label"
                  style={{ fontSize: 17 }}
                >
                  Basic/DA
                </label>
                <div className="col-sm-9">
                  <input
                    type="number"
                    className="form-control"
                    name="Basic"
                    onChange={(event) => setBasic(event.target.value)}
                    id="basicDALabel"
                    placeholder="Basic/DA"
                    aria-label="Email"
                    value={basic}
                  />{" "}
                </div>
              </div>
              <div className="row mb-4">
                <label
                  htmlFor="hraLabel"
                  className="col-sm-3 col-form-label form-label"
                  style={{ fontSize: 17 }}
                >
                  HRA
                </label>
                <div className="col-sm-9">
                  <input
                    type="number"
                    className="form-control"
                    name="HRA"
                    onChange={(newValue) => handleChange(newValue)}
                    id="hraLabel"
                    placeholder="HRA"
                    aria-label="Email"
                    value={formvalue.HRA}
                  />{" "}
                </div>
              </div>
              <div className="row mb-4">
                <label
                  htmlFor="conveyanceLabel"
                  className="col-sm-3 col-form-label form-label"
                  style={{ fontSize: 17 }}
                >
                  Conveyance
                </label>
                <div className="col-sm-9">
                  <input
                    type="number"
                    className="form-control"
                    name="Conveyance"
                    onChange={(newValue) => handleChange(newValue)}
                    id="conveyanceLabel"
                    placeholder="Conveyance"
                    aria-label="Email"
                    value={formvalue.Conveyance}
                  />{" "}
                </div>
              </div>
              <div className="row mb-4">
                <label
                  htmlFor="totalAdditionLabel"
                  className="col-sm-3 col-form-label form-label"
                  style={{ fontSize: 17 }}
                >
                  Total Addition
                </label>
                <div className="col-sm-9">
                  <input
                    type="number"
                    className="form-control"
                    name="TotalAddition"
                    onClick={handleTotalAddition}
                    id="totalAdditionLabel"
                    placeholder="Total Addition"
                    aria-label="Email"
                    value={TotalAddition}
                    readOnly
                  />{" "}
                </div>
              </div>
              <div className="row mb-4">
                <label
                  htmlFor="pfLabel"
                  className="col-sm-3 col-form-label form-label"
                  style={{ fontSize: 17 }}
                >
                  P.F
                </label>
                <div className="col-sm-9">
                  <input
                    type="number"
                    className="form-control"
                    name="PF"
                    onChange={(newValue) => handleChange(newValue)}
                    id="pfLabel"
                    placeholder="P.F"
                    aria-label="Email"
                    value={formvalue.PF}
                  />{" "}
                </div>
              </div>
              <div className="row mb-4">
                <label
                  htmlFor="esiLabel"
                  className="col-sm-3 col-form-label form-label"
                  style={{ fontSize: 17 }}
                >
                  E.S.I
                </label>
                <div className="col-sm-9">
                  <input
                    type="number"
                    className="form-control"
                    name="esi"
                    onChange={(newValue) => handleChange(newValue)}
                    id="esiLabel"
                    placeholder="E.S.I"
                    aria-label="Email"
                    value={formvalue.esi}
                  />{" "}
                </div>
              </div>
              <div className="row mb-4">
                <label
                  htmlFor="taxLabel"
                  className="col-sm-3 col-form-label form-label"
                  style={{ fontSize: 17 }}
                >
                  Profession Tax
                </label>
                <div className="col-sm-9">
                  <input
                    type="number"
                    className="form-control"
                    name="ProfessionTax"
                    onChange={(newValue) => handleChange(newValue)}
                    id="taxLabel"
                    placeholder="Profession Tax"
                    aria-label="Email"
                    value={formvalue.ProfessionTax}
                  />{" "}
                </div>
              </div>
              <div className="row mb-4">
                <label
                  htmlFor="deductionLabel"
                  className="col-sm-3 col-form-label form-label"
                  style={{ fontSize: 17 }}
                >
                  Total Deduction
                </label>
                <div className="col-sm-9">
                  <input
                    type="number"
                    className="form-control"
                    name="TotalDeduction"
                    onClick={handleTotalDeduction}
                    id="deductionLabel"
                    placeholder="Total Deduction"
                    aria-label="Email"
                    value={TotalDeduction}
                  />{" "}
                </div>
              </div>
              <div className="row mb-4">
                <label
                  htmlFor="amountLabel"
                  className="col-sm-3 col-form-label form-label"
                  style={{ fontSize: 17 }}
                >
                  Net Amount
                </label>
                <div className="col-sm-9">
                  <input
                    type="number"
                    className="form-control"
                    name="NetAmount"
                    onClick={handleNetAmount}
                    id="amountLabel"
                    placeholder="Net Amount"
                    aria-label="Email"
                    value={NetAmount}
                  />{" "}
                </div>
              </div>
              <div className="row mb-4">
                <label
                  htmlFor="amtwordsLabel"
                  className="col-sm-3 col-form-label form-label"
                  style={{ fontSize: 17 }}
                >
                  Amount in words
                </label>
                <div className="col-sm-9">
                  <input
                    type="text"
                    className="form-control"
                    name="Amount"
                    onChange={(newValue) => handleChange(newValue)}
                    id="amtwordsLabel"
                    placeholder="Amount in words"
                    aria-label="Email"
                    value={formvalue.Amount}
                  />{" "}
                </div>
              </div>
              <div className="row mb-4">
                <label
                  htmlFor="modeLabel"
                  className="col-sm-3 col-form-label form-label"
                  style={{ fontSize: 17 }}
                >
                  Mode
                </label>
                <div className="col-sm-9">
                  <input
                    type="text"
                    className="form-control"
                    name="Mode"
                    onChange={(newValue) => handleChange(newValue)}
                    id="modeLabel"
                    placeholder="Mode"
                    aria-label="Email"
                    value={formvalue.Mode}
                  />{" "}
                </div>
              </div>
              <div className="row mb-4">
                <label
                  htmlFor="nameOfBankLabel"
                  className="col-sm-3 col-form-label form-label"
                  style={{ fontSize: 17 }}
                >
                  Name of Bank
                </label>
                <div className="col-sm-9">
                  <input
                    type="text"
                    className="form-control"
                    name="bank"
                    onChange={(newValue) => handleChange(newValue)}
                    id="nameOfBankLabel"
                    placeholder="Name of Bank"
                    aria-label="Email"
                    value={formvalue.bank}
                  />{" "}
                </div>
              </div>
              <div className="row mb-4">
                <label
                  htmlFor="ddNoLabel"
                  className="col-sm-3 col-form-label form-label"
                  style={{ fontSize: 17 }}
                >
                  Cheque/DD no
                </label>
                <div className="col-sm-9">
                  <input
                    type="text"
                    className="form-control"
                    name="Cheque"
                    onChange={(newValue) => handleChange(newValue)}
                    id="ddNoLabel"
                    placeholder="Cheque/DD no"
                    aria-label="Email"
                    value={formvalue.Cheque}
                  />{" "}
                </div>
              </div>
              <div className="row mb-4">
                <label
                  htmlFor="dateLabel"
                  className="col-sm-3 col-form-label form-label"
                  style={{ fontSize: 17 }}
                >
                  Date
                </label>
                <div className="col-sm-9">
                  <Datepicker
                    onChange={(newDate) => {
                      setdate(newDate);
                    }}
                    value={date}
                  />
                </div>
              </div>
              <div className="row mb-4">
                <label
                  htmlFor="statusLabel"
                  className="col-sm-3 col-form-label form-label"
                  style={{ fontSize: 17 }}
                >
                  Status
                </label>
                <div className="col-sm-9">
                  <Autocomplete
                    options={Config.SALARY_STATUS} 
                    getOptionLabel={(option) => option.name || ""}
                    value={
                      Config.SALARY_STATUS.find(
                        (option) => option.value === status
                      ) || {name : "Select Status"}
                    }
                    size="small"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                      />
                    )}
                    onChange={(event, newValue) => {
                      setstatus(newValue ? newValue.value : "Select status");
                    }}
                  />

                  {errMsg.status !== "" ? (
                    <label
                      className=" form-label"
                      style={{ fontSize: 15, color: "red" }}
                    >
                      {errMsg.status}
                    </label>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="d-flex justify-content-end">
                <button
                  disabled={isDisable === true}
                  type="button"
                  className="btn btn-warning"
                  onClick={handleSubmit}
                >
                  Save changes
                </button>
              </div>
            </div>
          </div>
          <div></div>
          <br></br>
          <br></br>
        </div>
        <div className="footer">
          <div className="row justify-content-between align-items-center">
            <div className="col"></div>
            <div className="col-auto">
              <div className="d-flex justify-content-end"></div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default HeaderNavbarHOC(SalaryAdd);
