import * as React from "react";
import moment from "moment";
import PropTypes from "prop-types";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import InputAdornment from "@mui/material/InputAdornment";
import DateRangeIcon from "@mui/icons-material/DateRange"; // Import the date icon from MUI
import { MobileDateTimePicker  } from '@mui/x-date-pickers/MobileDateTimePicker';
import { MultiInputTimeRangeField } from "@mui/x-date-pickers-pro";

export default function Datepicker({ value, onChange }) {
  const datePickerContainerStyle = {
    border: "1px solid rgb(0 0 0 / 23%)",
    // width: "195px", // Set the border color to light gray
    width: "100%", // Set the border color to light gray
    height: "43px", // Adjust the height value as per your requirement
    display: "flex",
    alignItems: "center",
    justifyContent: "left",
    borderRadius: "4px",
    // padding: "1px",
    overflow: "hidden",
    // Add padding around the component
  };

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <div style={datePickerContainerStyle} className="date-picker">
        <MobileDatePicker
          variant="static"
          value={moment.utc(value).tz("Asia/Kolkata")}
          onChange={onChange}
          renderInput={(props) => <input {...props} />}
          slotProps={{
            textField: {
              InputProps: {
                endAdornment: (
                  <InputAdornment position="end" className="cursor-pointer">
                    <DateRangeIcon />
                  </InputAdornment>
                ),
                style: { color: "rgb(0 0 0 / 66%)" }, // Change input text color
              },
            },
          }}
          sx={{ border: "none", width: "100%" }}
          inputStyle={{ border: "none" }} // Remove the border from the input
          format="DD-MM-YYYY"
        />
      </div>
    </LocalizationProvider>
  );
}

export function DateTimePicker({ value,onChange }) {
  const datePickerContainerStyle = {
    border: "1px solid rgb(0 0 0 / 23%)",
    borderRadius: "4px",
    width: "100%",
    height: "43px",
    display: "flex",
    alignItems: "center",
    justifyContent: "left",
    overflow: "hidden",
  };
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <div style={datePickerContainerStyle} className="date-picker">
      <MobileDateTimePicker
        value={moment(value)}
        onChange={onChange}
        renderInput={(props) => <input {...props} />}
        slotProps={{
          textField: {
            InputProps: {
              endAdornment: (
                <InputAdornment position="end" className="cursor-pointer">
                  <DateRangeIcon />
                </InputAdornment>
              ),
              style: { color: "rgb(0 0 0 / 66%)" }, // Change input text color
            },
          },
        }}
        sx={{ border: "none", width: "100%" }}
        inputStyle={{ border: "none" }}
        format="DD-MM-YYYY h:mm A"
      />
      </div>
    </LocalizationProvider>
  );
}

export function EventDateTimePicker({value,onChange}) {
  const datePickerContainerStyle = {
    border: "1px solid rgb(0 0 0 / 23%)",
    borderRadius: "4px",
    width: "100%",
    height: "43px",
    display: "flex",
    alignItems: "center",
    justifyContent: "left",
    overflow: "hidden",
  };
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <div style={datePickerContainerStyle} className="date-picker">
      <MobileDateTimePicker
        value={moment(value)}
        onChange={
          (newValue) => onChange(newValue.toISOString())
        }
        label = "select date and time"
        renderInput={(props) => <input {...props} />}
        format="DD-MM-YYYY hh:mm A"
        slotProps={{
          textField: {
            InputProps: {
              endAdornment: (
                <InputAdornment position="end" className="cursor-pointer">
                  <DateRangeIcon />
                </InputAdornment>
              ),
              style: { color: "rgb(0 0 0 / 66%)" }, // Change input text color
            },
          },
        }}
        sx={{ border: "none", width: "100%" }}
        inputStyle={{ border: "none" }}
      />
      </div>
    </LocalizationProvider>
  );
}

export function ReminderDateTimePicker({value,onChange}) {
  const datePickerContainerStyle = {
    border: "1px solid rgb(0 0 0 / 23%)",
    borderRadius: "4px",
    width: "100%",
    height: "43px",
    display: "flex",
    alignItems: "center",
    justifyContent: "left",
    overflow: "hidden",
  };

  const minDate = moment().add(1, "hours");

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <div style={datePickerContainerStyle} className="date-picker">
      <MobileDateTimePicker
        defaultValue={moment.utc(value).tz("Asia/Kolkata")}
        onChange={(newValue) => onChange(newValue.format("YYYY-MM-DDTHH:mm"))}
        minDateTime={minDate}
        renderInput={(props) => <input {...props} />}
        slotProps={{
          textField: {
            InputProps: {
              endAdornment: (
                <InputAdornment position="end" className="cursor-pointer">
                  <DateRangeIcon />
                </InputAdornment>
              ),
              style: { color: "rgb(0 0 0 / 66%)" }
            },
          },
        }}
        sx={{ border: "none", width: "100%" }}
        inputStyle={{ border: "none" }}
        format="DD-MM-YYYY h:mm A"
      />
      </div>
    </LocalizationProvider>
  );
}

export function TimeDurationProvider({ onChange,value }) {
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
        <MultiInputTimeRangeField
          format="HH:mm"
          value={value}
          onChange={(newValue) => {
            onChange(newValue);
          }}
          slotProps={{
            textField: ({ position }) => ({
              label: position === 'start' ? 'From' : 'To',
              size : 'small',
            }),
          }}
        />
    </LocalizationProvider>
  );
}

DateTimePicker.propTypes = {
  date: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

Datepicker.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

ReminderDateTimePicker.propTypes = {
  onChange: PropTypes.func.isRequired,
};

TimeDurationProvider.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.array.isRequired,
};