import React, { useState, useEffect } from "react";
import moment from "moment";
import { Autocomplete, TextField } from "@mui/material";
import HeaderNavbarHOC from "../../HOC/HeaderNavbar";
import AlertBox from "../../Alert";
import { trackEvent, EVENT_NAME } from "../../constant/MixpanelConstant";
import * as AxiosActions from "../../Actions/axiosActions";
import Datepicker from "../../Datepicker";
import Config, { yearArray } from "../../config";
import { expense } from "../../constant/Expence";
import BackgroundImage from "../../components/Common/CardImage";
import { DATAADDEDSUCCESSFULLY } from "../../constant/Label";

function ExpensesAdd() {
  const [openAlert, setOpenAlert] = useState(false);
  const [date, setdate] = useState();
  const [getdata, setgetdata] = useState([]);
  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
    setstatus(Config.EXPENSES_STATUS[0].value);
    setFormvalue(initialvalue);
  };
  const getEmployeeList = () => {
    AxiosActions.EmployeeList({})
      .then((response) => {
        console.log("getEmployeeList", response);
        if (response.status === 200) {
          setgetdata(response.data.data);
        }
      })
      .catch((error) => {
        console.log("Error in get employee list", error);
      });
  };

  useEffect(() => {
    getEmployeeList();
  }, []);
  const currentDate = new Date();
  const currentMonth = Config.SALARY_MONTH[currentDate.getMonth()];
  const currentYear = currentDate.getFullYear().toString();

  const [month, setmonth] = useState(currentMonth);
  const [year, setyear] = useState(currentYear);
  const [status, setstatus] = useState(Config.EXPENSES_STATUS[0].value);

  const [errMsg, setErrMsg] = useState({
    name: "",
  });
  const [isDisable, setIsDisable] = useState(false);

  const initialvalue = {
    month: month,
    Item: "",
    Amount: "0",
    Description: "",
    year: year,
  };

  const [formvalue, setFormvalue] = useState(initialvalue);
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "month") {
      setmonth(value);
    } else if (name === "year") {
      setyear(value);
    }
    setFormvalue({ ...formvalue, [name]: value });
  };
  // Hahdle submit

  function handleSubmit(e) {
    trackEvent(EVENT_NAME.TRACKING_TOOLS_ADD, { data: "" });
    e.preventDefault();
    let errObject = {
      name: "",
      status: "",
      month: "",
      Item: "",
      Amount: "",

      year: "",
    };
    let err = 0;

    if (status === "Select status") {
      err++;
      errObject.status = "Select status";
    }

    if (formvalue.Amount === "") {
      err++;
      errObject.Amount = "Enter Amount";
    }
    if (formvalue.Item === "") {
      err++;
      errObject.Item = "Enter Item";
    }
    if (month === "Select Month") {
      err++;
      errObject.month = "Select Month";
    }
    if (year === "Select Year") {
      err++;
      errObject.year = "Select Year";
    }
    if (err !== 0) {
      console.log("has error", err, errObject);
      setErrMsg(errObject);
    } else {
      setIsDisable(true);
      AxiosActions.ExpensesAdd({
        month: formvalue.month,
        status: status,
        item: formvalue.Item,
        description: formvalue.Description,

        netAmount: Number(formvalue.Amount),
        date: date,
        year: Number(formvalue.year),
      })
        .then((response) => {
          if (response.status === 200) {
            console.log("ExpensesAdd responce", response);
            if (response.data.success === true) {
              setIsDisable(false);
              setOpenAlert(true);
            }
          }
        })
        .catch((error) => {
          setIsDisable(false);
          console.log("ExpensesAdd error", error);
        });
    }
  }

  return (
    <>
      {" "}
      <AlertBox
        open={openAlert}
        onClose={handleCloseAlert}
        message={DATAADDEDSUCCESSFULLY}
        title="Success"
        ok={false}
      />
      <main id="content" role="main" className="main">
        <BackgroundImage/>
        <div
          className="content container-fluid"
          style={{ justifyItems: "center", maxWidth: "60rem" }}
        >
          <div className="page-header">
            <div className="row align-items-end">
              <div className="col-sm mb-2 mb-sm-0"></div>
            </div>
          </div>

          <div className="card w-70">
            <div className="card-header">
              <h1 className="card-title h1">{expense.ADD_EXPENSES}</h1>
            </div>

            <div className="card-body">
              <div className="row mb-4">
                <label
                  htmlFor="emailLabel"
                  className="col-sm-3 col-form-label form-label"
                  style={{ fontSize: 17 }}
                >
                  {expense.MONTH}
                </label>
                <div className="col-sm-9">
                  <Autocomplete
                    options={Config.SALARY_MONTH}
                    getOptionLabel={(option) => option}
                    value={formvalue?.month || "select month"} 
                    onChange={(event, newValue) => {
                      setmonth(newValue);
                      handleChange({
                        target: {
                          name: "month",
                          value: newValue,
                        },
                      }); 
                    }}
                    size="small"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        autoComplete="off" 
                      />
                    )}
                  />
                  {errMsg.month !== "" ? (
                    <label
                      className=" form-label"
                      style={{ fontSize: 15, color: "red" }}
                    >
                      {errMsg.month}
                    </label>
                  ) : (
                    ""
                  )}
                  <br></br>
                </div>
              </div>
              <div className="row mb-4">
                <label
                  htmlFor="emailLabel"
                  className="col-sm-3 col-form-label form-label"
                  style={{ fontSize: 17 }}
                >
                  {expense.YEAR}
                </label>
                <div className="col-sm-9">
                  <Autocomplete
                    options={yearArray}
                    getOptionLabel={(option) => option}
                    value={formvalue?.year || "select month"} 
                    onChange={(event, newValue) => {
                      setyear(newValue);
                      handleChange({
                        target: {
                          name: "year",
                          value: newValue,
                        },
                      }); 
                    }}
                    size="small"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        autoComplete="off" 
                      />
                    )}
                  />
                  {errMsg.year !== "" ? (
                    <label
                      className=" form-label"
                      style={{ fontSize: 15, color: "red" }}
                    >
                      {errMsg.year}
                    </label>
                  ) : (
                    ""
                  )}
                  <br></br>
                </div>
              </div>
              <div className="row mb-4">
                <label
                  htmlFor="emailLabel"
                  className="col-sm-3 col-form-label form-label"
                  style={{ fontSize: 17 }}
                >
                  {expense.ITEM}
                </label>
                <div className="col-sm-9">
                  <input
                    type="text"
                    className="form-control"
                    name="Item"
                    onChange={handleChange}
                    id="emailLabel"
                    placeholder="Item"
                    aria-label="Email"
                    value={formvalue.Item}
                  />{" "}
                  {errMsg.Item !== "" ? (
                    <label
                      className=" form-label"
                      style={{ fontSize: 15, color: "red" }}
                    >
                      {errMsg.Item}
                    </label>
                  ) : (
                    ""
                  )}
                  <br></br>
                </div>
              </div>
              <div className="row mb-4">
                <label
                  htmlFor="emailLabel"
                  className="col-sm-3 col-form-label form-label"
                  style={{ fontSize: 17 }}
                >
                  {expense.AMOUNT}
                </label>
                <div className="col-sm-9">
                  <input
                    type="number"
                    className="form-control"
                    name="Amount"
                    onChange={handleChange}
                    id="emailLabel"
                    placeholder="Amount"
                    aria-label="Email"
                    value={formvalue.Amount}
                  />{" "}
                  {errMsg.Amount !== "" ? (
                    <label
                      className=" form-label"
                      style={{ fontSize: 15, color: "red" }}
                    >
                      {errMsg.Amount}
                    </label>
                  ) : (
                    ""
                  )}
                  <br></br>
                </div>
              </div>
              <div className="row mb-4">
                <label
                  htmlFor="emailLabel"
                  className="col-sm-3 col-form-label form-label"
                  style={{ fontSize: 17 }}
                >
                  {expense.DESCRIPTION}
                </label>
                <div className="col-sm-9">
                  <textarea
                    className="form-control"
                    placeholder="Description"
                    id="validationValidTextarea1"
                    rows="2"
                    name="Description"
                    onChange={handleChange}
                    value={formvalue.Description}
                  ></textarea>
                </div>
              </div>

              <div className="row mb-4">
                <label
                  htmlFor="emailLabel"
                  className="col-sm-3 col-form-label form-label"
                  style={{ fontSize: 17 }}
                >
                  {expense.DATE}
                </label>
                <div className="col-sm-9">
                  <Datepicker
                    onChange={(newDate) => {
                      setdate(newDate);
                    }}
                    value={moment(date).format("YYYY-MM-DD")}
                  />
                </div>
              </div>

              <div className="row mb-4">
                <label
                  htmlFor="emailLabel"
                  className="col-sm-3 col-form-label form-label"
                  style={{ fontSize: 17 }}
                >
                  {expense.STATUS}
                </label>
                <div className="col-sm-9">
                  <Autocomplete
                  options={Config.EXPENSES_STATUS}
                  getOptionLabel={(option) => option.name}
                  value={
                    Config.EXPENSES_STATUS.find(
                      (item) => item.value === status
                    ) || null
                  }
                  onChange={(event, newValue) => {
                    setstatus(
                      newValue === null? "Select status" : newValue.value
                    );
                  }}
                  size="small"
                  renderInput={(params) => (
                    <TextField
                    {...params}
                    variant="outlined"
                    autoComplete="off"
                    />
                  )}
                  />
                  {errMsg.status !== "" ? (
                    <label
                      className=" form-label"
                      style={{ fontSize: 15, color: "red" }}
                    >
                      {errMsg.status}
                    </label>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="d-flex justify-content-end">
                <button
                  disabled={isDisable === true}
                  type="button"
                  className="btn btn-warning"
                  onClick={handleSubmit}
                >
                  {expense.SAVE_CHANGES}
                </button>
              </div>
            </div>
          </div>
          <div></div>
          <br></br>
          <br></br>
        </div>
        <div className="footer">
          <div className="row justify-content-between align-items-center">
            <div className="col"></div>
            <div className="col-auto">
              <div className="d-flex justify-content-end"></div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default HeaderNavbarHOC(ExpensesAdd);
