import React, { useState, useEffect, useContext } from "react";
import Config from "../../config";
import AlertBox from "../../Alert";
import HeaderNavbarHOC from "../../HOC/HeaderNavbar";
import { AuthContext } from "../../Login/Protected";
import { trackEvent, EVENT_NAME } from "../../constant/MixpanelConstant";
import { useAuth0 } from "@auth0/auth0-react";
import * as AxiosActions from "../../Actions/axiosActions";
import { HeaderTitle,Filter } from "../../components/Common";
import Table from "./Table";
import Edit from "./Edit";
import { DATAUPDATESUCCESSFULLY } from "../../constant/Label";

function Userlist() {
  const [openAlert, setOpenAlert] = useState(false);
  const adminData = useContext(AuthContext);
  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
  };
  const [filterStatus, setFilterStatus] = useState("all");
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [confirm, setConfirm] = useState("");
  const [changePasswordId, setChangePasswordId] = useState("");
  const [filteruser, setFilteruser] = useState("all");

  const [applicants, setApplicants] = useState([]);
  const [isFiltered, setIsFiltered] = useState(false);
  const [updateId, setUpdateId] = useState("");
  const [deleteId, setDeleteId] = useState("");
  const [fullReport, setFullReport] = useState([]);

  const [count, setCount] = useState(0);


  const [username, setUsername] = useState("");
  const [usertype, setUsertype] = useState("");
  const [status, setStatus] = useState("");
  const [roles , setRoles] = useState("");
  const [selectedinfo, setSelectedinfo] = useState({
    selectedID: [],
  });
  const [controller, setController] = useState({
    page: 0,
    rowsPerPage: 10,
    preventRefresh: false,
  });
  const [isDisable, setIsDisable] = useState(false);
  const { user } = useAuth0();

  const token = localStorage.getItem("access_token");

  // renderdata(getdata from api)

  const renderdata = async () => {
    try {
      setLoading(true);
      await AxiosActions.UserList({ isAdmin: filteruser, status: filterStatus })
        .then((data) => {
          if (data.status === 200) {
            setRenderState(data.data.data, data.data.count);
          }
          setLoading(false);
        })
        .catch((error) => {
          console.log("Productivity data error", error);
        });
    } catch (err) {
      console.log(err);
    }
  };
  console.log("filteruser", filteruser);
  const setRenderState = (applicant, noOfRecords) => {
    setApplicants(applicant);
    setCount(noOfRecords);
  };

  useEffect(() => {
    if (isFiltered === true) {
      if (!controller.preventRefresh) filterRecord();
    } else {
      renderdata();
    }
  }, [controller]);

  // Filter record

  const filterRecord = async () => {
    trackEvent(EVENT_NAME.USER_LIST_FILTER, { data: "" });
    if (!isFiltered) {
      controller.page = 0;
      setIsFiltered(true);
      setController((prevController) => {
        return {
          ...prevController,
          page: 0,
          preventRefresh: true,
        };
      });
    }
    renderdata();
    setSelectedinfo({ selectedID: [] });
  };

  // Update status

  const changeStatus = (statusid, title) => {
    setIsDisable(true);
    trackEvent(EVENT_NAME.USER_EDIT, { data: statusid });
    AxiosActions.EditUser({
      id: statusid,
      isAdmin: usertype,
      status: status,
      role: roles,
    })
      .then(async (data) => {
        if (data.status === 200) {
          setOpenAlert(true);
          setIsDisable(false);
          renderdata();
        }
      })
      .catch((err) => {
        console.log("update error", err);
        setIsDisable(false);
        alert("Please Delete Record Again.");
      });
  };

  //Reset Filter

  const reset = () => {
    trackEvent(EVENT_NAME.USER_RESET, { data: "" });
    setFilteruser("all");
    setFilterStatus("all");
    setController({
      ...controller,
      page: 0,
      rowsPerPage: 10,
      preventRefresh: false,
    });
    setSelectedinfo({ selectedID: [] });
    setIsFiltered(false);
  };

  const handleDeleteClick = (event, id) => {
    setDeleteId(id);

    event.preventDefault();
  };

  //Delete Record

  const deleteRecord = (event, id) => {
    setIsDisable(true);
    trackEvent(EVENT_NAME.USER_DELETE, { data: id });
    console.log(id);
    AxiosActions.DeleteUser({
      id: id,
      adminId: adminData._id,
    })
      .then((data) => {
        setIsDisable(false);
        renderdata();
      })
      .catch((err) => {
        setIsDisable(false);
        console.log("delete error", err);
        alert("Please Delete Record Again.");
      });

    event.preventDefault();
  };

  //View Full Report

  const handleViewReportClick = (event, id) => {
    trackEvent(EVENT_NAME.USER_VIEW, { data: id });
    const filterRecord = applicants.filter((applicantsItem) => {
      if (id === applicantsItem._id) {
        setUsername(applicantsItem.email);

        setStatus(applicantsItem.status);
        setUsertype(applicantsItem.isAdmin);
        setRoles(applicantsItem.role)
        return true;
      }
      return false;
    });
    setUpdateId(id);
    setChangePasswordId(id);
    setFullReport(filterRecord);
    event.preventDefault();
  };

  const changePassword = (id, title) => {
    trackEvent(EVENT_NAME.CHANGE_PASSWORD, { data: id });
    if (password !== confirm) {
      setError(true);
    } else {
      setIsDisable(true);
      AxiosActions.UserChangePassword({
        id: id,
        password: password,
      })
        .then(async (data) => {
          if (data.status === 200) {
            setOpenAlert(true);
            setIsDisable(false);
            renderdata();
          }
        })
        .catch((err) => {
          setIsDisable(false);
          console.log("update error", err);
          alert("Please Delete Record Again.");
        });
      setError(false);
    }
  };

  return (
    <>
      {token === null ? (
        true((window.location.href = "/"))
      ) : (
        <>
          <AlertBox
            open={openAlert}
            onClose={handleCloseAlert}
            message={DATAUPDATESUCCESSFULLY}
            title="Success"
            ok={false}
          />
          <main id="content" role="main" className="main">
            <div className="content container-fluid">
              <HeaderTitle title="Users" />
                <div className="card mb-3 mb-lg-5">
                  <div className="card-header">
                    <Filter
                      status={true}
                      statusValue={filterStatus}
                      statusUpdate={setFilterStatus}
                      statusList={Config?.USER_STATUS}
                      userType={true}
                      userTypeValue={filteruser}
                      userTypeUpdate={setFilteruser}
                      userTypeList={Config?.USER_TYPE}
                      filterRecord={filterRecord}
                      resetRecord={reset}
                    />
                  </div>
                  <div className="table-responsive datatable-custom">
                    <Table
                      loading={loading}
                      applicants={applicants}
                      handleViewReportClick={handleViewReportClick}
                      fullReport={fullReport}
                      setusername={setUsername}
                      Username={username}
                      setusertype={setUsertype}
                      Usertype={usertype}
                      roles={roles}
                      setRoles={setRoles}
                      setstatus={setStatus}
                      status={status}
                      isDisable={isDisable}
                      changeStatus={changeStatus}
                      updateId={updateId}
                      handleDeleteClick={handleDeleteClick}
                      deleteRecord={deleteRecord}
                      deleteId={deleteId}
                      setpassword={setPassword}
                      setConfirm={setConfirm}
                      setError={setError}
                      Password={password}
                      Confirm={confirm}
                      error={error}
                      changePassword={changePassword}
                      ChangePasswordId={changePasswordId}
                    />
                  </div>
                  <Edit
                    fullReport={fullReport}
                    setusername={setUsername}
                    Username={username}
                    setusertype={setUsertype}
                    Usertype={usertype}
                    setstatus={setStatus}
                    status={status}
                    setRoles={setRoles}
                    roles={roles}
                    isDisable={isDisable}
                    changeStatus={changeStatus}
                    updateId={updateId}
                  />

                  <div className="card-footer"></div>
                </div>
            </div>
            <div className="footer"></div>
          </main>
        </>
      )}
    </>
  );
}

export default HeaderNavbarHOC(Userlist);
