import React from "react";
import Checkbox from "@mui/material/Checkbox";
import { AlertDelete } from "../../components/Common";
import Edit from "./Edit";
import View from "./View";

function Table({
  handleCheckBoxChange,
  isAllSelected,
  loading,
  applicants,
  selectedinfo,
  handleClick,
  changeStatusValue,
  changeInputStatus,
  isDisable,
  changeStatus,
  updateId,
  handleViewClick,
  applicantsNotes,
  handleDeleteClick,
  deleteRecord,
  deleteId,
}) {
  return (
    <table
      id="datatable"
      className="table table-borderless table-thead-bordered  table-align-middle card-table"
    >
      <thead className="thead-light">
        <tr>
          <th scope="col" className="table-column-pe-0">
            <Checkbox
              size="medium"
              value="all"
              onChange={handleCheckBoxChange}
              checked={isAllSelected}
            ></Checkbox>
          </th>
          <th>Date & Time</th>
          <th>Name</th>

          <th>Instant Message</th>
          <th>Details</th>

          <th>Status</th>
          <th>Action</th>
        </tr>
      </thead>
      {loading ? (
        <tr>
          <td colSpan={8} style={{ textAlign: "center" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div className="spinner-border text-dark m-5" role="status">
                <span className="sr-only"></span>
              </div>
            </div>
          </td>
        </tr>
      ) : (
        <>
          {applicants.length === 0 ? (
            <tbody>
              {applicants.length === 0 && (
                <tr>
                  <td colSpan={7} style={{ textAlign: "center" }}>
                    <h3>No record found</h3>
                  </td>
                </tr>
              )}
            </tbody>
          ) : (
            <tbody>
              {applicants.map((app, index) => {
                var date = new Date(app.createdAt).toLocaleString(undefined, {
                  timeZone: "Asia/Kolkata",
                });
                const date1 = date.split(",");
                const date2 = date1[0].split("/");
                const time = date1[1].split(" ");
                const time1 = time[1].split(":");
                return (
                  <tr key={"app" + index}>
                    <td className="table-column-pe-0">
                      <Checkbox
                        key={index}
                        size="medium"
                        value={app._id}
                        onChange={handleCheckBoxChange}
                        checked={selectedinfo.selectedID.includes(app._id)}
                      ></Checkbox>
                    </td>
                    <td style={{ width: "5%" }}>
                      {" "}
                      {`${date2[1] + "/" + date2[0] + "/" + date2[2]}`}{" "}
                      {`${time1[0] + ":" + time1[1]}`} {time[2]}
                    </td>
                    <td className="table-column-ps-0" style={{ width: "5%" }}>
                      <h5 className="text-inherit mb-0">{app.name}</h5>
                      {app.email}
                    </td>

                    <td className="table-column-ps-0" style={{ width: "15%" }}>
                      {app.instantmessage}
                    </td>
                    <td className="table-column-ps-0" style={{ width: "25%" }}>
                      {app.details}
                    </td>

                    <td>
                      <span className="legend-indicator bg-success" />
                      {app.status}
                    </td>
                    <td>
                      &nbsp; &nbsp;
                      <button
                        type="button"
                        className="icon icon-danger icon-circle"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModalLong"
                        onClick={(event) => handleClick(event, app._id)}
                      >
                        <i className="bi-pen"></i>
                      </button>
                      <Edit
                        changeStatusValue={changeStatusValue}
                        changeInputStatus={changeInputStatus}
                        isDisable={isDisable}
                        changeStatus={changeStatus}
                        updateId={updateId}
                      />
                      &nbsp;
                      <button
                        type="button"
                        className="icon icon-warning icon-circle"
                        data-bs-toggle="modal"
                        data-bs-target="#view"
                        onClick={(event) => handleViewClick(event, app._id)}
                      >
                        <i className="bi-eye"></i>
                      </button>
                      <View applicantsNotes={applicantsNotes} />
                      &nbsp;
                      <button
                        type="button"
                        className="icon icon-info icon-circle"
                        data-bs-toggle="modal"
                        data-bs-target="#delete"
                        onClick={(event) => handleDeleteClick(event, app._id)}
                      >
                        <i className="bi-trash"></i>
                      </button>
                      <AlertDelete
                        isDisable={isDisable}
                        deleteRecord={deleteRecord}
                        deleteId={deleteId}
                      />
                      &nbsp; &nbsp;
                    </td>
                  </tr>
                );
              })}
            </tbody>
          )}
        </>
      )}
    </table>
  );
}

export default Table;
