import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import axios from "axios";
import { toast } from "react-toastify";
import { TextField, Autocomplete } from "@mui/material";
import moment from "moment";
import Datepicker from "../../Datepicker";
import Config from "../../config";
import AlertBox from "../../Alert";
import HeaderNavbarHOC from "../../HOC/HeaderNavbar";
import Useuser from "../../Contexct";
import * as AxiosActions from "../../Actions/axiosActions";
import { EVENT_NAME, trackEvent } from "../../constant/MixpanelConstant";
import { employee } from "../../constant/Employee";
import BackgroundImage from "../../components/Common/CardImage";

function EmployeeAdd() {
  const { adminData } = Useuser();
  const [documents, setDocuments] = useState([]);
  const [openAlert, setOpenAlert] = useState(false);
  const [isDisable, setIsDisable] = useState(false);

  useEffect(() => {
    setDocuments(Config.DOCUMENT);
  }, []);

  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
    formik.resetForm();
    setDocuments("");
  };

  const handleFileInput = (e) => {
    const allowedExtensions = ["jpg", "png", "jpeg", "pdf"];
    const files = Array.from(e.target.files);
    const validFiles = files.filter((file) => {
      const extension = file.name.split(".").pop().toLowerCase();
      return allowedExtensions.includes(extension);
    });

    if (validFiles.length !== files.length) {
      toast.error("Invalid File. Only jpg, png, jpeg, and pdf are allowed.");
      return;
    }

    validFiles.forEach((file) => {
      const timestamp = new Date().getTime();
      const fileName = `${timestamp}_${file.name}`;
      const formData = new FormData();
      formData.append("file", file);
      formData.append("name", fileName);

      axios
        .post(`${Config.APIURL}user/file/upload`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          if (response.data.code === 200) {
            formik.setFieldValue("documentsPath", [
              ...formik.values.documentsPath,
              `${response.data.data}${fileName}`,
            ]);
          }
        })
        .catch((error) => {
          console.log("file upload getting response error", error);
        });
    });
    formik.setFieldValue("selectedFiles", [
      ...formik.values.selectedFiles,
      ...files,
    ]);
  };

  const handleRemoveFile = (index) => {
    formik.setFieldValue(
      "selectedFiles",
      formik.values.selectedFiles.filter((_, i) => i !== index)
    );
  };

  const formik = useFormik({
    initialValues: {
      employeeName: "",
      status: Config.EMPLOYEE_STATUS[0].value,
      empid: "",
      email: "",
      primaryContact: "",
      secondaryContact: "",
      emergencyName: "",
      emergencyMobile: "",
      address: "",
      qualification: "",
      bankName: "",
      bankAccountNumber: "",
      bankIfscCode: "",
      employeeRole: "",
      joining: "",
      exit: "",
      dob: "",
      documentsName: [],
      documentsPath: [],
      selectedFiles: [],
      offerCTC: "",
      contractPeriod: "",
    },
    validate: (values) => {
      const errors = {};
      if (!values.employeeName) {
        errors.employeeName = "Employee name is required";
      }
      return errors;
    },
    onSubmit: (values) => {
      trackEvent(EVENT_NAME.EMPLOYEE_ADD, { data: values.employeeName });
      setIsDisable(true);
      AxiosActions.EmployeeAdd({
        empId: values.empid,
        employeeName: values.employeeName,
        status: values.status,
        employeeEmail: values.email,
        dateOfBirth: values.dob,
        address: values.address,
        qualification: values.qualification,
        bankName: values.bankName,
        bankAccountNumber: values.bankAccountNumber,
        bankIfscCode: values.bankIfscCode,
        documentsName: values.documentsName || [],
        documentsPath: values.documentsPath || [],
        employeeRole: values.employeeRole,
        dateOfJoin: values.joining,
        dateOfExit: values.exit,
        offerCTC: values.offerCTC,
        contractPeriod: values.contractPeriod,
        primaryContact: values.primaryContact,
        secondaryContact: values.secondaryContact,
        adminId: adminData._id,
        emergencyName: values.emergencyName,
        emergencyMobile: values.emergencyMobile,
      })
        .then((response) => {
          if (response.status === 200) {
            if (response.data.success === true) {
              setOpenAlert(true);
              setIsDisable(false);
              formik.resetForm();
            }
          }
        })
        .catch((error) => {
          setIsDisable(false);
          console.log("Post data error", error);
        });
        formik.setFieldValue("joining", "");
        formik.setFieldValue("exit", "");
        formik.setFieldValue("dob", "");
    },
  });

  return (
    <>
      <AlertBox
        open={openAlert}
        onClose={handleCloseAlert}
        message="Data Add SuccessFully"
        title="Success"
        ok={false}
      />

      <main id="content" role="main" className="main">
        <BackgroundImage/>
        <div
          className="content container-fluid"
          style={{ justifyItems: "center", maxWidth: "60rem" }}
        >
          <div className="page-header">
            <div className="row align-items-end">
              <div className="col-sm mb-2 mb-sm-0"></div>
            </div>
          </div>
          {/* Employee Form  */}
          <div className="card w-70">
            <div className="card-header">
              <h1 className="card-title h1">
                {employee.EMPLOYEE_PERSONAL_INFO}
              </h1>
            </div>
            <div className="card-body">
              <div className="card-body pt-0">
                <div className="d-flex flex-md-nowrap flex-wrap align-items-center gap-2 mb-2">
                  <div className="col-md-6 col-12">
                    <TextField
                      fullWidth
                      id="employeeName"
                      name="employeeName"
                      label="Employee name"
                      value={formik.values.employeeName}
                      onChange={formik.handleChange}
                      variant="outlined"
                    />
                    {formik.errors.employeeName &&
                      formik.touched.employeeName && (
                        <div className="form-error" style={{ color: "red" }}>
                          {formik.errors.employeeName}
                        </div>
                      )}
                  </div>
                  <div className="col-md-6 col-12">
                    <Autocomplete
                      value={Config.EMPLOYEE_STATUS.find(
                        (status) => status.value === formik.values.status
                      )}
                      onChange={(event, newValue) =>
                        formik.setFieldValue("status", newValue?.value || "")
                      }
                      options={Config.EMPLOYEE_STATUS}
                      getOptionLabel={(option) => option.name}
                      isOptionEqualToValue={(option, value) =>
                        option.value === value
                      }
                      renderInput={(params) => (
                        <TextField {...params} label="Select status" />
                      )}
                    />
                  </div>
                </div>
                <div className="d-flex flex-md-nowrap flex-wrap align-items-center gap-2 mb-4">
                  <div className="col-md-6 col-12">
                    <TextField
                      fullWidth
                      id="empid"
                      name="empid"
                      label="Employee ID"
                      value={formik.values.empid}
                      onChange={formik.handleChange}
                      variant="outlined"
                    />
                  </div>
                  <div className="col-md-6 col-12">
                    <TextField
                      fullWidth
                      id="email"
                      name="email"
                      label="Employee email"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      variant="outlined"
                    />
                  </div>
                </div>

                <div className="mb-4">
                  <div className="border p-3">
                    <label
                      htmlFor="bankDetail"
                      className="col-form-label pt-0 form-label"
                      style={{ fontSize: 17 }}
                    >
                      {employee.EMPLOYEE_CONTACT}
                    </label>
                    <div className="d-flex flex-md-nowrap flex-wrap align-items-center gap-2 mb-2">
                      <div className="col-md-6 col-12">
                        <TextField
                          fullWidth
                          id="primaryContact"
                          name="primaryContact"
                          label="Primary contact"
                          value={formik.values.primaryContact}
                          onChange={formik.handleChange}
                          variant="outlined"
                        />
                      </div>
                      <div className="col-md-6 col-12">
                        <TextField
                          fullWidth
                          id="secondaryContact"
                          name="secondaryContact"
                          label="Secondary contact"
                          value={formik.values.secondaryContact}
                          onChange={formik.handleChange}
                          variant="outlined"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mb-4">
                  <div className="border p-3">
                    <label
                      htmlFor="bankDetail"
                      className="col-form-label pt-0 form-label"
                      style={{ fontSize: 17 }}
                    >
                      {employee.EMERGENCY_CONTACT}
                    </label>
                    <div className="d-flex flex-md-nowrap flex-wrap align-items-center gap-2 mb-2">
                      <div className="col-md-6 col-12">
                        <TextField
                          fullWidth
                          id="emergencyName"
                          name="emergencyName"
                          label="Emergency Name"
                          value={formik.values.emergencyName}
                          onChange={formik.handleChange}
                          variant="outlined"
                        />
                      </div>
                      <div className="col-md-6 col-12">
                        <TextField
                          fullWidth
                          id="emergencyMobile"
                          name="emergencyMobile"
                          label="Emergency Mobile"
                          value={formik.values.emergencyMobile}
                          onChange={formik.handleChange}
                          variant="outlined"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mb-4">
                  <TextField
                    fullWidth
                    id="address"
                    name="address"
                    label="Address"
                    value={formik.values.address}
                    onChange={formik.handleChange}
                    variant="outlined"
                    multiline
                  />
                </div>
                <div className="d-flex flex-md-nowrap flex-wrap align-items-center gap-2 mb-4">
                  <div className="col-md-6 col-12 mb-4">
                    <label
                      className="col-form-label pt-0 form-label"
                      style={{ fontSize: 17 }}
                    >
                      {employee.DATE_OF_BIRTH}
                    </label>
                    <div className="input-group input-group-sm-vertical">
                      <Datepicker
                        onChange={(newDate) => {
                          formik.setFieldValue("dob", newDate);
                        }}
                        value={moment(formik.values.dob).format("YYYY-MM-DD")}
                      />
                    </div>
                  </div>
                  <div className="col-md-6 col-12">
                    <TextField
                      fullWidth
                      id="qualification"
                      name="qualification"
                      label="Qualification"
                      value={formik.values.qualification}
                      onChange={formik.handleChange}
                      variant="outlined"
                    />
                  </div>
                </div>
                <div className="mb-4">
                  <div className="border p-3">
                    <label
                      htmlFor="bankDetail"
                      className="col-form-label pt-0 form-label"
                      style={{ fontSize: 17 }}
                    >
                      {employee.BANK_DETAILS}
                    </label>
                    <div className="mb-2">
                      <TextField
                        fullWidth
                        id="bankName"
                        name="bankName"
                        label="Bank name"
                        value={formik.values.bankName}
                        onChange={formik.handleChange}
                        variant="outlined"
                      />
                    </div>
                    <div className="mb-2">
                      <TextField
                        fullWidth
                        id="bankAccountNumber"
                        name="bankAccountNumber"
                        label="Bank account number"
                        value={formik.values.bankAccountNumber}
                        onChange={formik.handleChange}
                        variant="outlined"
                      />
                    </div>
                    <div className="mb-0">
                      <TextField
                        fullWidth
                        id="bankIfscCode"
                        name="bankIfscCode"
                        label="Bank IFSC code"
                        value={formik.values.bankIfscCode}
                        onChange={formik.handleChange}
                        variant="outlined"
                      />
                    </div>
                  </div>
                </div>
                <div className="mb-4">
                  <div className="border p-3">
                    <label
                      htmlFor="empDocument"
                      className="col-form-label pt-0 form-label"
                      style={{ fontSize: 17 }}
                    >
                      {employee.EMPLOYEE_DOCUMENT}
                    </label>
                    <Autocomplete
                      multiple
                      id="tags-outlined"
                      options={documents}
                      getOptionLabel={(option) => option}
                      onChange={(event, newValue) => {
                        formik.setFieldValue("documentsName", newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={employee.SELECT_DOCUMENT}
                          placeholder=""
                        />
                      )}
                    />
                    <br></br>
                    <input
                      multiple
                      type="file"
                      className="form-control"
                      name="file"
                      onChange={handleFileInput}
                      id="file"
                    />
                    <div className="col-sm-9">
                      {formik.values.selectedFiles.length > 0 && (
                        <ul className="me-5">
                          {formik.values.selectedFiles.map((file, index) => (
                            <li
                              key={file.name}
                              className="d-flex justify-content-between align-items-center"
                            >
                              {file.name}
                              <button
                                className="btn"
                                type="button"
                                onClick={() => handleRemoveFile(index)}
                              >
                                x
                              </button>
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>
                    <div className="col-sm-9"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div></div>
          <br></br>
          <br></br>
          <div className="card w-70">
            <div className="card-header">
              <h1 className="card-title h1">{employee.COMPANY_INFO}</h1>
            </div>
            <div className="card-body">
              <div className="card-body pt-0">
                <div className="mb-4">
                  <TextField
                    fullWidth
                    id="employeeRole"
                    name="employeeRole"
                    label="Employee Role/Job Position"
                    value={formik.values.employeeRole}
                    onChange={formik.handleChange}
                    variant="outlined"
                  />
                </div>
                <div className="d-flex flex-md-nowrap flex-wrap align-items-center gap-2 mb-4 border p-3">
                  <div className="col-md-6 col-12">
                    <label
                      className="col-form-label pt-0 form-label"
                      style={{ fontSize: 16 }}
                    >
                      {employee.DATE_OF_JOINING}
                    </label>
                    <Datepicker
                      onChange={(newDate) => {
                        formik.setFieldValue("joining", newDate);
                      }}
                      value={moment(formik.values.joining).format("YYYY-MM-DD")}
                    />
                  </div>
                  <div className="col-md-6 col-12">
                    <label
                      className="col-form-label pt-0 form-label"
                      style={{ fontSize: 16 }}
                    >
                      {employee.DATE_OF_EXIT}
                    </label>
                    <Datepicker
                      onChange={(newDate) => {
                        formik.setFieldValue("exit", newDate);
                      }}
                      value={moment(formik.values.exit).format("YYYY-MM-DD")}
                    />
                  </div>
                </div>

                <div className="d-flex flex-md-nowrap flex-wrap align-items-center gap-2 mb-4 border p-3">
                  <div className="col-md-6 col-12">
                    <div className="input-group">
                      <input
                        type="number"
                        className="form-control"
                        name="offerCTC"
                        onChange={formik.handleChange}
                        value={formik.values.offerCTC}
                        id="firstNameLabel"
                        placeholder="Offered CTC"
                        aria-label="Qualification"
                      />
                      <span className="input-group-text" id="basic-addon2">
                        {employee.PER_MONTH}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-6 col-12">
                    <div className="input-group">
                      <input
                        type="number"
                        className="form-control"
                        name="contractPeriod"
                        onChange={formik.handleChange}
                        value={formik.values.contractPeriod}
                        id="firstNameLabel"
                        placeholder="Contract Period"
                        aria-label="Your first name"
                        aria-describedby="basic-addon2"
                      />{" "}
                      <span className="input-group-text" id="basic-addon2">
                        {employee.MONTH}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-end">
                  <button
                    disabled={isDisable}
                    type="button"
                    className="btn btn-warning"
                    onClick={formik.handleSubmit}
                  >
                    {employee.UPLOAD}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer">
          <div className="row justify-content-between align-items-center">
            <div className="col"></div>
            <div className="col-auto">
              <div className="d-flex justify-content-end"></div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default HeaderNavbarHOC(EmployeeAdd);