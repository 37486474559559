export const getInitials = (name) => {
  const nameParts = name?.split(" ");
  if(nameParts) {
    const firstNameInitial = nameParts[0].charAt(0).toUpperCase();
    const lastNameInitial = nameParts[1]
    ? nameParts[1].charAt(0).toUpperCase()
    : "";
    return firstNameInitial + lastNameInitial;
  }
};

export const SampleNextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        background: "#333399",
        borderRadius: "50%",
        right: "10px",
        zIndex: 1000,
      }}
      onClick={onClick}
    />
  );
};

export const SamplePrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        background: "#333399",
        borderRadius: "50%",
        left: "10px",
        zIndex: 1000,
      }}
      onClick={onClick}
    />
  );
};

// Function to convert milliseconds to time
export const CONVERT_MILISECONDS_MINUTES = (milliseconds) => {
  const totalMinutes = Math.floor(milliseconds / (1000 * 60));
  const minutes = totalMinutes % 60;

  return minutes ? `${minutes} Minutes` : "-";
};

function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string?.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

export function STRING_AVATAR(email) {
  return {
    sx: {
      bgcolor: stringToColor(email),
    },
    children: email?.split(' ')?.length > 1 ? `${email?.split(' ')[0][0]}${email?.split(' ')[1][0]}` : `${email?.split(' ')[0][0]}`,
  };
}