import React, { useState, useEffect, useMemo } from "react";
import { toast } from "react-toastify";
import moment from "moment";
import { numberToWords } from "number-to-words";
import Config from "../../config";
import {
  BarChart,
  Loader,
  Filter,
  AlertDelete,
  Pagination,
  HeaderTitle,
} from "../../components/Common";
import HeaderNavbarHOC from "../../HOC/HeaderNavbar";
import Useuser from "../../Contexct";
import AlertBox from "../../Alert";
import { trackEvent, EVENT_NAME } from "../../constant/MixpanelConstant";
import { getClientStatus } from "../../services/common";
import * as AxiosActions from "../../Actions/axiosActions";
import { SALARY } from "../../constant/Chart";
import View from "./View";
import Edit from "./Edit";
import Table from "./Table";
import { DATAUPDATESUCCESSFULLY } from "../../constant/Label";

function SalaryList() {
  const { adminData } = Useuser();
  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
  };
  const [loading, setLoading] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [applicants, setApplicants] = useState([]);
  const [isFiltered, setIsFiltered] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [getData, setGetData] = useState([]);
  const [endDate, setEndDate] = useState("");
  const [empId, setEmpId] = useState("");
  const [updateId, setUpdateId] = useState("");
  const [deleteId, setDeleteId] = useState("");
  const [fullReport, setFullReport] = useState([]);
  const [filterDate, setFilterDate] = useState("all");
  const [filterMonth, setFilterMonth] = useState("all");
  const [filterYear, setFilterYear] = useState("all");
  const [filterStatus, setFilterStatus] = useState("all");
  const [count, setCount] = useState(0);
  const [status, setStatus] = useState("");
  const [isDisable, setIsDisable] = useState(false);
  const [chartData, setChartData] = useState({});
  const [selectedinfo, setSelectedInfo] = useState({
    selectedID: [],
  });
  const [controller, setController] = useState({
    page: 0,
    rowsPerPage: 10,
    preventRefresh: false,
  });
  const [date, setdate] = useState();
  const [month, setmonth] = useState("");
  const [year, setyear] = useState("");
  const [total_days, settotal_days] = useState("");
  const [Days, setDays] = useState("");
  const [Basic, setBasic] = useState("");
  const [HRA, setHRA] = useState("");
  const [Conveyance, setConveyance] = useState("");
  const [TotalAddition, setTotalAddition] = useState("");
  const [PF, setPF] = useState("");
  const [esi, setesi] = useState("");
  const [ProfessionTax, setProfessionTax] = useState("");
  const [TotalDeduction, setTotalDeduction] = useState("");
  const [NetAmount, setNetAmount] = useState("");
  const [Amount, setAmount] = useState("");
  const [Mode, setMode] = useState("");
  const [bank, setbank] = useState("");
  const [Cheque, setCheque] = useState("");
  const [filterYearChart, setFilterYearChart] = useState(moment().year());
  const [monthChart, setMonthChart] = useState("all");
  const [empIdChart, setEmpIdChart] = useState("");


  // FOR SELECT ALL
  const isAllSelected =
    applicants.length > 0 &&
    selectedinfo.selectedID.length === applicants.length;
  const token = localStorage.getItem("access_token");

  // RENDER DATA
  const renderdata = async () => {
    try {
      setLoading(true);
      AxiosActions.SalaryList({
        page: controller.page,
        limit: controller.rowsPerPage,
        empid: empId,
        year: Number(filterYear) ? Number(filterYear) : filterYear,
        type: filterDate,
        month: filterMonth,
        category: "Salary",
        status:
          filterStatus === "all"
            ? "all"
            : "" || filterStatus === "true"
            ? true
            : "" || filterStatus === "false"
            ? false
            : "",
      })
        .then((data) => {
          if (data.status === 200) {
            console.log("render data", data.data);
            setRenderState(data.data.results, data.data.count);
          }
          setLoading(false);
        })
        .catch((error) => {
          console.log("Catch Err in Pagination ", error);
        });
    } catch (err) {
      console.log("Catch err in render data", err);
    }
  };

  const setRenderState = (applicant, noOfRecords) => {
    setApplicants(applicant);
    setCount(noOfRecords);
  };

  // RENDER Chart DATA
  const renderChartData = async (filterYearChart, monthChart,empIdChart) => {
    try {
      setLoading(true);
      AxiosActions.SalaryChartList({
        page: 0,
        limit: 10,
        empid: empIdChart,
        year: parseInt(filterYearChart),
        type: "all",
        month: monthChart,
        category: "Salary",
        status: "all",
      })
        .then((data) => {
          if (data.status === 200) {
            console.log("render chart data", data.data);
            if (data.data?.count > 0) {
              setChartData(data?.data?.totalExpense);
            }
          }
          setLoading(false);
        })
        .catch((error) => {
          console.log("Catch Err chart data ", error);
        });
    } catch (err) {
      console.log("Catch err in render chart data", err);
    }
  };

  const getEmployeeList = () => {
    AxiosActions.EmployeeList({})
      .then((response) => {
        console.log("getEmployeeList", response);
        if (response.status == 200) {
          setGetData(response.data.data);
        }
      })
      .catch((error) => {
        console.log("Error in get employee list", error);
      });
  };

  useEffect(() => {
    getEmployeeList();
    renderChartData(filterYearChart, monthChart,empIdChart);
    if (isFiltered === true) {
      if (!controller.preventRefresh) filterRecord();
    } else {
      renderdata();
    }
  }, [controller]);

  // New Pagination
  const handlePageChange = (event, newPage) => {
    getEmployeeList();
    setController({
      ...controller,
      page: newPage,
      preventRefresh: false,
    });
    setSelectedInfo({ selectedID: [] });
  };

  const handleChangeRowsPerPage1 = (event) => {
    setController({
      ...controller,
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0,
      preventRefresh: false,
    });

    setSelectedInfo({ selectedID: [] });
  };

  // Filter record

  const filterRecord = async () => {
    trackEvent(EVENT_NAME.TRACKING_TOOLS_LIST_FILTER, { data: "" });
    if (!isFiltered) {
      setIsFiltered(true);
      setController((prevController) => {
        return {
          ...prevController,
          page: 0,
          preventRefresh: true,
        };
      });
    }
    renderdata();
    setSelectedInfo({ selectedID: [] });
  };

  const handleSalaryPdfDownload = (id) => {
    console.log('id handleSalaryPdfDownload: ', id);
      // Perform a fetch request to the API
      fetch(`${Config.APIURL}employee/salaryslip/pdf`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ id: id }),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(`Failed to fetch PDF: ${response.statusText}`);
          }
          return response.blob(); // Convert the response to a Blob
        })
        .then((blob) => {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = `salary-report-${id}.pdf`;
          document.body.appendChild(a);
          a.click();
          a.remove();
          window.URL.revokeObjectURL(url);
        })
        .catch((error) => {
          console.error('Error fetching PDF:', error); // Log detailed error
          toast.error('Failed to download the PDF. Please try again.');
        });
      
  };
  

  
  const handleFilterStatusChange = (event) => {
    setFilterStatus(event.target.value);
  };

  useEffect(() => {
    handleTotalAddition();
  }, [Basic, HRA, Conveyance]);

  useEffect(() => {
    handleTotalDeduction();
  }, [Basic, TotalAddition, PF, esi, ProfessionTax]);

  useEffect(() => {
    handleNetAmount();
  }, [Basic, TotalAddition, TotalDeduction]);

  function handleTotalAddition() {
    const TotalAddition =
      parseInt(Basic) + parseInt(HRA) + parseInt(Conveyance);
    setTotalAddition(TotalAddition);
  }

  function handleTotalDeduction() {
    const TotalDeduction =
      parseInt(PF) + parseInt(esi) + parseInt(ProfessionTax);
    setTotalDeduction(TotalDeduction);
  }
  function handleNetAmount() {
    const totalAddition = parseInt(TotalAddition);
    const totalDeduction = parseInt(TotalDeduction);

    if (Number.isFinite(totalAddition) && Number.isFinite(totalDeduction)) {
      const netAmount = totalAddition - totalDeduction;
      setNetAmount(netAmount);

      if (Number.isFinite(netAmount)) {
        const amountInWords = numberToWords.toWords(netAmount);
        setAmount(amountInWords);
      } else {
        setAmount("");
      }
    } else {
      setAmount("");
    }
  }

  // Update status
  const changeStatus = (statusid, title) => {
    setIsDisable(true);
    trackEvent(EVENT_NAME.TRACKING_TOOLS_EDIT, { data: statusid });
    AxiosActions.EditSalary({
      id: statusid,
      status: status === "true" ? true : false,

      month: month,
      year: year,

      totalDays: total_days,

      days: Days,
      basicSalary: Basic,
      hra: HRA,
      conveyanceAllowance: Conveyance,
      totalAddition: TotalAddition,

      pf: PF,
      employees_State_Insurance: esi,
      professionTax: ProfessionTax,
      totalDeduction: TotalDeduction,
      netAmount: NetAmount,

      salary_Amt_In_Words: Amount,
      mode: Mode,
      bank_Name: bank,
      date: date,
      cheque_No: Cheque,
      status: status,
    })
      .then((data) => {
        console.log(data);
        setOpenAlert(true);
        renderdata();
        renderChartData(filterYearChart , monthChart,empIdChart);
        setIsDisable(false);
      })
      .catch((err) => {
        console.log(err);
        alert("Please Delete Record Again.");
        setIsDisable(false);
      });

    setSelectedInfo({ selectedID: [] });
  };

  //Reset Filter
  const reset = () => {
    trackEvent(EVENT_NAME.TRACKING_TOOLS_RESET, { data: "" });
    setApplicants([]);
    setFilterStatus("all");
    setFilterYear("all");
    setFilterMonth("all");
    setFilterDate("all");
    setFilterStatus("all");
    setController({
      ...controller,
      page: 0,
      rowsPerPage: 10,
      preventRefresh: false,
    });
    setSelectedInfo({ selectedID: [] });
    setIsFiltered(false);
  };

  const handleDeleteClick = (event, id) => {
    setDeleteId(id);

    event.preventDefault();
  };

  // Delete Record
  const deleteRecord = (event, id) => {
    setIsDisable(true);
    trackEvent(EVENT_NAME.TRACKING_TOOLS_DELETE, { data: id });
    AxiosActions.DeleteSalary({
      id: id,
      adminId: adminData._id,
    })
      .then((data) => {
        renderdata();
        renderChartData(filterYearChart,monthChart,empIdChart);
        setIsDisable(false);
      })
      .catch((err) => {
        console.log(err);
        setIsDisable(false);
        alert("Please Delete Record Again.");
      });

    event.preventDefault();
  };

  //View Full Report
  const handleViewReportClick = (event, id) => {
    trackEvent(EVENT_NAME.TRACKING_TOOLS_VIEW, { data: id });
    const filterRecord = applicants.filter((item) => {
      if (id === item._id) {
        setdate(moment(item.date).format("YYYY-MM-DD"));
        setmonth(item.month);
        setyear(item.year);
        settotal_days(item.totalDays);
        setDays(item.days);
        setBasic(item.basicSalary);
        setHRA(item.hra);
        setConveyance(item.conveyanceAllowance);
        setTotalAddition(item.totalAddition);
        setPF(item.pf);
        setesi(item.employees_State_Insurance);
        setProfessionTax(item.professionTax);
        setTotalDeduction(item.totalDeduction);
        setNetAmount(item.netAmount);
        setAmount(item.salary_Amt_In_Words);
        setMode(item.mode);
        setbank(item.bank_Name);
        setCheque(item.cheque_No);
        setStatus(item.status);
        return true;
      }
    });

    setFullReport(filterRecord);
    setUpdateId(id);
  };

  //Select Multiple
  const handleCheckBoxChange = (e) => {
    const value = e.target.value;
    const { checked } = e.target;
    const list = [];
    const { selectedID } = selectedinfo;

    if (value === "all") {
      if (value === "all" && checked) {
        applicants.map((item) => {
          list.push(item._id);
        });
        setSelectedInfo({
          selectedID: list,
        });
      } else {
        setSelectedInfo({
          selectedID: list.filter((e) => e !== value),
        });
      }
      return;
    } else if (checked) {
      setSelectedInfo({
        selectedID: [...selectedID, value],
      });
    } else {
      setSelectedInfo({
        selectedID: selectedID.filter((e) => e !== value),
      });
    }
  };

  //Delete Multiple
  const handleDeleteMultipleClick = (event) => {
    event.preventDefault();
  };
  const deleteAllRecord = (event) => {
    setIsDisable(true);
    trackEvent(EVENT_NAME.TRACKING_TOOLS_DELETE_ALL, { data: "" });
    console.log("Delete All Records");
    AxiosActions.MultipleDeleteSalary({
      id: selectedinfo.selectedID,
      adminId: adminData._id,
    })
      .then((data) => {
        renderdata();
        setIsDisable(false);
      })
      .catch((err) => {
        alert("Please Delete Record Again");
        setIsDisable(false);
      });

    setSelectedInfo({ selectedID: [] });
    event.preventDefault();
  };

  //Chart Filter
  const filterChartRecord = async () => {
    renderChartData(filterYearChart, monthChart,empIdChart);
  };
  // Reset Chart Filter
  const resetChartRecord = async () => {
    setFilterYearChart(moment().year());
    setMonthChart("all");
    setEmpIdChart("")
    renderChartData(moment().year(), "all","");
  };

  // Creates a series that chart names and chart data
  const seriesChartData = useMemo(() => {
    if (Object.keys(chartData).length === 0) {
      return [];
    } else {
      return Config.SALARY_MONTH.map((month, index) => ({
        name: month,
        data: Config.SALARY_MONTH.map((month, i) => {
          if (i === index) {
            return chartData[month];
          } else {
            return null;
          }
        }),
      }));
    }
  }, [chartData]);

  return (
    <>
      <AlertBox
        open={openAlert}
        onClose={handleCloseAlert}
        message={DATAUPDATESUCCESSFULLY}
        title="Success"
        ok={false}
      />
      {token === null ? (
        true((window.location.href = "/"))
      ) : (
        <main id="content" role="main" className="main">
          <div className="content container-fluid">
            <HeaderTitle title="Salary" />

            <Filter
              year={true}
              yearValue={filterYearChart}
              yearUpdate={setFilterYearChart}
              month={true}
              monthValue={monthChart}
              monthUpdate={setMonthChart}
              name={true}
              nameValue={empIdChart}
              nameUpdate={setEmpIdChart}
              employeeList={getData}
              filterRecord={filterChartRecord}
              resetRecord={resetChartRecord}
            />
            <div className="card card-bordered h-100">
              <div className="card-body">
                {seriesChartData.length > 0 ? (
                  <BarChart
                    categories={Config.SALARY_MONTH}
                    series={seriesChartData}
                    title={SALARY.CHART_TITLE}
                    yAxisTitle={SALARY.Y_TITLE}
                    xAxisTitle={SALARY.X_TITLE}
                    barWidth={60}
                  />
                ) : (
                  <Loader />
                )}
              </div>
            </div>
            <br></br>
            <>
              <div className="card mb-3 mb-lg-5">
                <div className="card-header">
                  <Filter
                    year={true}
                    yearValue={filterYear}
                    yearUpdate={setFilterYear}
                    month={true}
                    monthValue={filterMonth}
                    monthUpdate={setFilterMonth}
                    status={true}
                    statusValue={filterStatus}
                    statusUpdate={setFilterStatus}
                    statusList={Config?.SALARY_STATUS}
                    date={true}
                    dateValue={filterDate}
                    dateUpdate={setFilterDate}
                    startDateValue={startDate}
                    startDateUpdate={setStartDate}
                    endDateValue={endDate}
                    endDateUpdate={setEndDate}
                    name={true}
                    nameValue={empId}
                    nameUpdate={setEmpId}
                    employeeList={getData}
                    multipleSelect={selectedinfo?.selectedID?.length}
                    multipleDelete={true}
                    isDisable={isDisable}
                    handleDelete={handleDeleteMultipleClick}
                    deleteAllRecord={deleteAllRecord}
                    filterRecord={filterRecord}
                    resetRecord={reset}
                  />
                </div>

                <div className="table-responsive datatable-custom">
                  <Table
                    handleCheckBoxChange={handleCheckBoxChange}
                    isAllSelected={isAllSelected}
                    loading={loading}
                    Loader={Loader}
                    applicants={applicants}
                    selectedinfo={selectedinfo}
                    getClientStatus={getClientStatus}
                    handleViewReportClick={handleViewReportClick}
                    handleDeleteClick={handleDeleteClick}
                    handleSalaryPdfDownload={handleSalaryPdfDownload}
                  />
                </div>
                <Edit
                  fullReport={fullReport}
                  setmonth={setmonth}
                  month={month}
                  setyear={setyear}
                  year={year}
                  settotal_days={settotal_days}
                  total_days={total_days}
                  setDays={setDays}
                  Days={Days}
                  setBasic={setBasic}
                  Basic={Basic}
                  setHRA={setHRA}
                  HRA={HRA}
                  setConveyance={setConveyance}
                  Conveyance={Conveyance}
                  setTotalAddition={setTotalAddition}
                  TotalAddition={TotalAddition}
                  setPF={setPF}
                  PF={PF}
                  setesi={setesi}
                  esi={esi}
                  setProfessionTax={setProfessionTax}
                  ProfessionTax={ProfessionTax}
                  setTotalDeduction={setTotalDeduction}
                  TotalDeduction={TotalDeduction}
                  setNetAmount={setNetAmount}
                  NetAmount={NetAmount}
                  setAmount={setAmount}
                  Amount={Amount}
                  setMode={setMode}
                  Mode={Mode}
                  setbank={setbank}
                  bank={bank}
                  setCheque={setCheque}
                  Cheque={Cheque}
                  setdate={setdate}
                  date={date}
                  setStatus={setStatus}
                  status={status}
                  isDisable={isDisable}
                  changeStatus={changeStatus}
                  updateId={updateId}
                />
                <AlertDelete
                  isDisable={isDisable}
                  deleteRecord={deleteRecord}
                  deleteId={deleteId}
                />
                <View
                  fullReport={fullReport}
                  getClientStatus={getClientStatus}
                />
                <div className="card-footer">
                  <Pagination
                    handlePageChange={handlePageChange}
                    controller={controller}
                    count={count}
                    handleChangeRowsPerPage1={handleChangeRowsPerPage1}
                  />
                </div>
              </div>
            </>
          </div>
          <div className="footer"></div>
        </main>
      )}
    </>
  );
}

export default HeaderNavbarHOC(SalaryList);
