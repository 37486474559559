import React, { useRef } from "react";
import PropTypes from "prop-types";
import { Autocomplete, TextField } from "@mui/material";
import Config from "../../config";
import { reminder } from "../../constant/reminder";

function Edit({
  fullReport,
  setname,
  selectedValues,
  handleSelectChange,
  getdata,
  setdatetime,
  datetime,
  name,
  status,
  setdec,
  dec,
  setstatus,
  isDisable,
  changeStatus,
  updateId,
}) {
  const dateInputRef = useRef(null);

  return (
    <div
      className="modal fade"
      id="exampleModalLong"
      tabIndex="-1"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h2 className="modal-title" id="exampleModalLongTitle">
              Edit Reminder
            </h2>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <div className="modal-content">
              <table>
                <tbody>
                  {fullReport.map((item, index) => {
                    return (
                      <>
                        <tr>
                          <td>
                            <h4>{reminder.REMINDER_NAME}</h4>{" "}
                            <input
                              type="text"
                              className="form-control"
                              name="acthours"
                              id="firstNameLabel"
                              placeholder="acthours"
                              onChange={(event) => setname(event.target.value)}
                              value={name}
                            />
                          </td>
                        </tr>

                        <br></br>
                        <tr>
                          <td>
                            <h4>{reminder.EMPLOYEE_NAME}</h4>{" "}
                            <Autocomplete
                              multiple
                              limitTags={2}
                              id="multiple-limit-tags"
                              options={getdata}
                              getOptionLabel={(option) =>
                                option.employeeName || ""
                              }
                              onChange={handleSelectChange}
                              value={getdata.filter((emp) =>
                                selectedValues.includes(emp._id)
                              )}
                              isOptionEqualToValue={(option, value) =>
                                option._id === value._id
                              }
                              renderInput={(params) => (
                                <TextField {...params} size="small" />
                              )}
                            />
                          </td>
                        </tr>

                        <br></br>
                        <tr>
                          <td>
                            <h4>{reminder.DATE_AND_TIME}</h4>{" "}
                            <input
                              type="datetime-local"
                              ref={dateInputRef}
                              className="form-control cursor-pointer"
                              name="acthours"
                              id="firstNameLabel"
                              placeholder="acthours"
                              onChange={(event) =>
                                setdatetime(event.target.value)
                              }
                              value={datetime}
                              onClick={() => {
                                if (dateInputRef.current) {
                                  dateInputRef.current?.showPicker();
                                }
                              }}
                              min={new Date().toISOString().slice(0, 16)}
                            />
                          </td>
                        </tr>

                        <br></br>
                        <tr>
                          <td>
                            <h4>{reminder.DESCRIPTION}</h4>{" "}
                            <textarea
                              className="form-control"
                              placeholder="Description"
                              id="validationValidTextarea1"
                              rows="2"
                              name="notes"
                              onChange={(event) => setdec(event.target.value)}
                              value={dec}
                            ></textarea>
                          </td>
                        </tr>

                        <br></br>
                        <tr>
                          <td>
                            <h4>{reminder.STATUS}</h4>{" "}
                            <Autocomplete
                              options={Config.REMINDER_STATUS}
                              name="status"
                              getOptionLabel={(option) => option.name}
                              value={
                                Config.REMINDER_STATUS.find(
                                  (option) => option.value === status
                                ) || { name: "Select status" }
                              }
                              size="small"
                              renderInput={(params) => (
                                <TextField {...params} />
                              )}
                              onChange={(event, newValue) => {
                                console.log('newValue: ', newValue)
                                setstatus(
                                  newValue === null
                                  ? "Select status"
                                  : newValue.value
                                );
                              }}
                              />
                          </td>
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-white"
              data-bs-dismiss="modal"
            >
              {reminder.CLOSE}
            </button>
            <button
              disabled={isDisable === true}
              type="button"
              className="btn btn-primary"
              data-bs-dismiss="modal"
              onClick={() => {
                changeStatus(updateId);
              }}
            >
              {reminder.SAVE_CHANGES}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

Edit.propTypes = {
  fullReport: PropTypes.array,
  setname: PropTypes.func,
  selectedValues: PropTypes.array,
  handleSelectChange: PropTypes.func,
  getdata: PropTypes.array,
  setdatetime: PropTypes.func,
  datetime: PropTypes.string,
  name: PropTypes.string,
  status: PropTypes.string,
  setdec: PropTypes.func,
  dec: PropTypes.string,
  setstatus: PropTypes.func,
  isDisable: PropTypes.bool,
  changeStatus: PropTypes.func,
  updateId: PropTypes.string,
};

export default Edit;
