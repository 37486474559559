import React from "react";
import Checkbox from "@mui/material/Checkbox";
import moment from "moment";
import { productivity } from "../../constant/Productivity";

function Table({
  handleCheckBoxChange,
  isAllSelected,
  loading,
  applicants,
  selectedinfo,
  adminData,
  handleViewReportClick,
  handleDeleteClick,
  Viewnote,
}) {
  return (
    <table
      id="datatable"
      className="table table-borderless table-thead-bordered table-nowrap table-align-middle card-table"
    >
      <thead className="thead-light">
        <tr>
          <th scope="col" className="table-column-pe-0">
            <Checkbox
              size="medium"
              value="all"
              onChange={handleCheckBoxChange}
              checked={isAllSelected}
            ></Checkbox>
          </th>
          <th>{productivity.DATE}</th>
          <th>{productivity.EMPLOYEE_NAME}</th>
          <th>{productivity.CLIENT_NAME}</th>
          <th>{productivity.TRAKING_TOOLS}</th>
          <th>{productivity.TYPE_OF_HOURS}</th>
          <th>{productivity.ESTIMATED_HOURS}</th>
          <th>{productivity.ACTUAL_HOURS}</th>
          <th>{productivity.STATUS}</th>
          <th>{productivity.ACTION}</th>
        </tr>
      </thead>
      <tbody>
        {loading ? (
          <tr>
            <td colSpan={9} style={{ textAlign: "center" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <div className="spinner-border text-dark m-5" role="status">
                  <span className="sr-only"></span>
                </div>
              </div>
            </td>
          </tr>
        ) : (
          <>
            {applicants.length === 0 && (
              <tr>
                <td colSpan={9} style={{ textAlign: "center" }}>
                  <h3>{productivity.NO_RECORD_FOUND}</h3>
                </td>
              </tr>
            )}
            {applicants.map((app, index) => {
              return (
                <tr key={"app" + index}>
                  <td className="table-column-pe-0">
                    <Checkbox
                      key={index}
                      size="medium"
                      value={app._id}
                      onChange={handleCheckBoxChange}
                      checked={selectedinfo.selectedID.includes(app._id)}
                    ></Checkbox>
                  </td>
                  <td style={{ width: "5%" }}>
                    {moment(app.date).format("DD-MM-YYYY")}
                  </td>
                  <td>
                    <h5>{app.empid && app.empid.employeeName}</h5>
                  </td>

                  <td>{app.clientId && app.clientId.clientName}</td>
                  <td>
                    {app.trackingToolId && app.trackingToolId.trackingToolName}
                  </td>
                  {app.typeOfHours === true ? (
                    <td>{productivity.PRODUCTIVITY_HOURS}</td>
                  ) : (
                    <td>{productivity.NON_PRODUCTIVITY_HOURS}</td>
                  )}
                  <td>{app.estimatedHours}</td>
                  <td>{app.actualHours}</td>
                  <td>{app.status}</td>

                  <td>
                    {adminData.adminData.isAdmin ? (
                      <>
                        <button
                          title="Edit Productivity"
                          type="button"
                          className="icon icon-danger icon-circle"
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModalLong"
                          onClick={(event) =>
                            handleViewReportClick(event, app._id)
                          }
                        >
                          <i className="bi-pen"></i>
                        </button>
                        &nbsp;
                        <button
                          title="Delete Productivity"
                          type="button"
                          className="icon icon-info icon-circle"
                          data-bs-toggle="modal"
                          data-bs-target="#delete"
                          onClick={(event) => handleDeleteClick(event, app._id)}
                        >
                          <i className="bi-trash"></i>
                        </button>
                        &nbsp;
                        <button
                          title="Add Notes"
                          type="button"
                          className="icon icon-success icon-circle"
                          data-bs-toggle="modal"
                          data-bs-target="#status"
                          onClick={(event) =>
                            handleViewReportClick(event, app._id)
                          }
                        >
                          <i className="bi-pencil"></i>
                        </button>
                        &nbsp;
                        <button
                          title="View Notes"
                          type="button"
                          className="icon icon-warning icon-circle"
                          data-bs-toggle="modal"
                          data-bs-target="#viewstatus"
                          onClick={(event) => Viewnote(event, app._id)}
                        >
                          <i className="bi-calendar-event"></i>
                        </button>
                      </>
                    ) : (
                      ""
                    )}
                    &nbsp;
                    <button
                      title="View Productivity"
                      type="button"
                      className="icon icon-dark icon-circle"
                      data-bs-toggle="modal"
                      data-bs-target="#viewpage"
                      onClick={(event) => handleViewReportClick(event, app._id)}
                    >
                      <i className="bi-eye"></i>
                    </button>
                    &nbsp;
                  </td>
                </tr>
              );
            })}
          </>
        )}
      </tbody>
    </table>
  );
}

export default Table;
