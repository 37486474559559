import React from "react";
import Alert from "@mui/material/Alert";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
function AlertBox({ open, message, onClose, title, ok }) {
  return (
    <Dialog
      open={open}
      scroll="body"
      fullWidth
      className="dialog"
      sx={{ height: 500, marginTop: 2, alignItems: "center" }}
      keepMounted
    >
      <div className="row-heading"><br></br>
        <Typography
          variant="body"
          sx={{
            marginLeft: 3,
            fontSize: 22,
          }}
        >
          {title}
        </Typography>
        <CloseIcon
          className="close-icon"
          fontSize="small"
          onClick={onClose}
          sx={{
            marginLeft: 58,
            marginTop: 1,
          }}
        />
      </div>
      <div>
        <Alert
          sx={{
            height: 100,
            fontSize: 18,
            alignItems: "center",
            backgroundColor: "white",
            color: "black",
          }}
        >
          {message}
        </Alert>
      </div>
      <div className="modal-footer">
      {ok===true ? (
                           <Button
                          className="filter-button"
                          variant="contained"
                         sx={{ minWidth: 100 ,marginLeft:1,marginRight:1}}
                          size="small"
                        
                          onClick={onClose}
                        >
                          ok
                        </Button>):("")}
                        <Button
                          className="filter-button"
                          variant="contained"
                          sx={{  minWidth: 100,marginRight:1 }}
                          size="small"
                        
                          onClick={onClose}
                        >
                          Close
                        </Button>
                       
                      </div>
                      <br></br>
                    
    </Dialog>
  );
}

export default AlertBox;
