import React, { useState, useEffect, useContext } from "react";
import Config from "../../config";
import HeaderNavbarHOC from "../../HOC/HeaderNavbar";
import AlertBox from "../../Alert";
import { trackEvent, EVENT_NAME } from "../../constant/MixpanelConstant";
import moment from "moment";
import { AuthContext } from "../../Login/Protected";
import * as AxiosActions from "../../Actions/axiosActions";
import { AlertDelete, HeaderTitle, Pagination, Filter } from "../../components/Common";
import Table from "./Table";
import Edit from "./Edit";
import View from "./View";
import { DATAUPDATESUCCESSFULLY } from "../../constant/Label";

function LeavesList() {
  const adminData = useContext(AuthContext);
  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
  };
  const [getdata, setgetdata] = useState([]);
  const [empid, setempid] = useState("");
  const [loading, setLoading] = useState(false);
  const [startdate, setstartdate] = useState("");
  const [enddate, setenddate] = useState("");
  const [openAlert, setOpenAlert] = useState(false);
  const [applicants, setApplicants] = useState([]);
  const [isFiltered, setIsFiltered] = useState(false);
  const [updateId, setUpdateId] = useState("");
  const [deleteId, setDeleteId] = useState("");
  const [fullReport, setFullReport] = useState([]);
  const [filterStatus, setFilterStatus] = useState("all");
  const [filterMonth, setFilterMonth] = useState("all");
  const [filterYear, setFilterYear] = useState("all");
  const [filtertype, setFiltertype] = useState("all");
  const [leavetype, setleavetype] = useState("");
  const [count, setCount] = useState(0);
  const [inputList, setInputList] = useState("");
  const [filterArr, setFilterArr] = useState([]);
  const [status, setstatus] = useState();
  const [leave_type, setleave_type] = useState("");
  const [fromdate, setfromdate] = useState("");
  const [todate, settodate] = useState("");
  const [approver, setapprover] = useState("");
  const [approverdate, setapproverdate] = useState("");
  const [reason, setreason] = useState("");
  const [isDisable, setIsDisable] = useState(false);
  const [selectedinfo, setSelectedInfo] = useState({
    selectedID: [],
  });
  const [controller, setController] = useState({
    page: 0,
    rowsPerPage: 10,
    preventRefresh: false,
  });

  // FOR SELECT ALL

  const isAllSelected =
    applicants.length > 0 &&
    selectedinfo.selectedID.length === applicants.length;
  const token = localStorage.getItem("access_token");

  // RENDER DATA

  const renderdata = async () => {
    try {
      setLoading(true);
      AxiosActions.LeaveList({
        page: controller.page,
        limit: controller.rowsPerPage,
        from_date: startdate,
        to_date: enddate,
        type: filtertype,
        leave_type: leavetype,
        status: filterStatus,
        empid: adminData.adminData.isAdmin
          ? empid
          : adminData.adminData.empid._id,
        year: filterYear,
        month: filterMonth,
      })
        .then((data) => {
          if (data.status === 200) {
            setRenderState(data.data.results, data.data.count);
          }
          setLoading(false);
        })
        .catch((error) => {
          console.log("Catch Err in Pagination ", error);
        });
    } catch (err) {
      console.log("Catch err in render data", err);
    }
  };

  const setRenderState = (applicant, noOfRecords) => {
    setApplicants(applicant);
    setCount(noOfRecords);
  };

  const getEmployeeList = () => {
    AxiosActions.EmployeeList({})
      .then((response) => {
        console.log("getEmployeeList", response);
        if (response.status == 200) {
          setgetdata(response.data.data);
        }
      })
      .catch((error) => {
        console.log("Error in get employee list", error);
      });
  };

  useEffect(() => {
    getEmployeeList();
    if (isFiltered === true) {
      if (!controller.preventRefresh) filterRecord();
    } else {
      renderdata();
    }
  }, [controller, adminData.adminData]);

  // New Pagination

  const handlePageChange = (event, newPage) => {
    setController({
      ...controller,
      page: newPage,
      preventRefresh: false,
    });
    setSelectedInfo({ selectedID: [] });
  };

  const handleChangeRowsPerPage1 = (event) => {
    setController({
      ...controller,
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0,
      preventRefresh: false,
    });

    setSelectedInfo({ selectedID: [] });
  };

  // Filter record

  const filterRecord = async () => {
    trackEvent(EVENT_NAME.LEAVES_LIST_FILTER, { data: "" });
    if (!isFiltered) {
      setIsFiltered(true);
      setController((prevController) => {
        return {
          ...prevController,
          page: 0,
          preventRefresh: true,
        };
      });
    }
    renderdata();
    setSelectedInfo({ selectedID: [] });
  };

  const handleFilterStatusChange = (event) => {
    setFilterStatus(event.target.value);
  };

  // Update status
  const changeStatus = (statusid, title) => {
    setIsDisable(true);
    trackEvent(EVENT_NAME.LEAVES_EDIT, { data: statusid });
    AxiosActions.EditLeave({
      id: statusid,
      leave_type: leave_type,
      from_date: fromdate,
      to_date: todate,
      status: status,
      approver: approver,
      approve_date: approverdate,
      leave_reason: reason,
      adminId: adminData.adminData._id,
    })
      .then((data) => {
        console.log(data);
        setOpenAlert(true);
        renderdata();
        setIsDisable(false);
      })
      .catch((err) => {
        console.log(err);
        alert("Please Delete Record Again.");
        setIsDisable(false);
      });

    setSelectedInfo({ selectedID: [] });
  };

  //Reset Filter

  const reset = () => {
    trackEvent(EVENT_NAME.LEAVES_RESET, { data: "" });
    setApplicants(filterArr);
    setInputList("");
    setFilterStatus("");
    setFilterYear("all");
    setFilterMonth("all");
    setFiltertype("all");
    setController({
      ...controller,
      page: 0,
      rowsPerPage: 10,
      preventRefresh: false,
    });
    setSelectedInfo({ selectedID: [] });
    setIsFiltered(false);
    setleavetype('');
    setempid('');
  };

  const handleDeleteClick = (event, id) => {
    console.log(id);
    setDeleteId(id);

    event.preventDefault();
  };

  // Delete Record

  const deleteRecord = (event, id) => {
    setIsDisable(true);
    trackEvent(EVENT_NAME.LEAVES_DELETE, { data: id });
    console.log(id);
    AxiosActions.DeleteLeave({
      id: id,
      adminId: adminData._id,
    })
      .then((data) => {
        renderdata();
        setIsDisable(false);
      })
      .catch((err) => {
        console.log(err);
        setIsDisable(false);
        alert("Please Delete Record Again.");
      });

    event.preventDefault();
  };

  //View Full Report

  const handleViewReportClick = (event, id) => {
    trackEvent(EVENT_NAME.LEAVES_VIEW, { data: id });
    const filterRecord = applicants.filter((item) => {
      if (id === item._id) {
        console.log(item.leave_type);
        setleave_type(item.leave_type);
        setfromdate(moment(item.from_date).format("YYYY-MM-DD"));
        settodate(moment(item.to_date).format("YYYY-MM-DD"));
        setreason(item.leave_reason);
        setstatus(item.status);
        setapproverdate(moment(item.approve_date).format("YYYY-MM-DD"));
        return true;
      }
    });

    setFullReport(filterRecord);
    setUpdateId(id);
  };

  //Select Multiple

  const handleCheckBoxChange = (e) => {
    const value = e.target.value;
    const { checked } = e.target;
    const list = [];
    const { selectedID } = selectedinfo;

    if (value === "all") {
      if (value === "all" && checked) {
        applicants.map((item) => {
          list.push(item._id);
        });
        setSelectedInfo({
          selectedID: list,
        });
      } else {
        setSelectedInfo({
          selectedID: list.filter((e) => e !== value),
        });
      }
      return;
    } else if (checked) {
      setSelectedInfo({
        selectedID: [...selectedID, value],
      });
    } else {
      setSelectedInfo({
        selectedID: selectedID.filter((e) => e !== value),
      });
    }
  };

  //Delete Multiple

  const handleDeleteMultipleClick = (event) => {
    event.preventDefault();
  };
  const deleteAllRecord = (event) => {
    setIsDisable(true);
    trackEvent(EVENT_NAME.LEAVES_DELETE_ALL, { data: "" });
    console.log("Delete All Records");
    AxiosActions.MultipleDeleteLeave({
      id: selectedinfo.selectedID,
      adminId: adminData._id,
    })
      .then((data) => {
        renderdata();
        setIsDisable(false);
      })
      .catch((err) => {
        alert("Please Delete Record Again");
        setIsDisable(false);
      });

    setSelectedInfo({ selectedID: [] });
    event.preventDefault();
  };

  const changeFilter = (event) => {
    setInputList(event.target.value);
  };

  return (
    <>
      <AlertBox
        open={openAlert}
        onClose={handleCloseAlert}
        message={DATAUPDATESUCCESSFULLY}
        title="Success"
        ok={false}
      />
      {token === null ? (
        true((window.location.href = "/"))
      ) : (
        <main id="content" role="main" className="main">
          <div className="content container-fluid">
            <HeaderTitle title="Leaves" />

            <div className="card mb-3 mb-lg-5">
              <div className="card-header">
                {adminData.adminData.isAdmin ? (
                  <Filter
                    year={true}
                    yearValue={filterYear}
                    yearUpdate={setFilterYear}
                    month={true}
                    monthValue={filterMonth}
                    monthUpdate={setFilterMonth}
                    status={true}
                    statusValue={filterStatus}
                    statusUpdate={setFilterStatus}
                    statusList={Config?.LEAVE_STATUS}
                    leaveType={true}
                    leaveTypeValue={leavetype}
                    leaveTypeUpdate={setleavetype}
                    leaveTypeList={Config?.LEAVE_TYPE}
                    date={true}
                    dateValue={filtertype}
                    dateUpdate={setFiltertype}
                    startDateValue={startdate}
                    startDateUpdate={setstartdate}
                    endDateValue={enddate}
                    endDateUpdate={setenddate}
                    multipleSelect={selectedinfo?.selectedID?.length}
                    multipleDelete={true}
                    isDisable={isDisable}
                    handleDelete={handleDeleteMultipleClick}
                    deleteAllRecord={deleteAllRecord}
                    name={true}
                    nameValue={empid}
                    nameUpdate={setempid}
                    employeeList={getdata}
                    filterRecord={filterRecord}
                    resetRecord={reset}
                  />
                ) : (
                  <Filter
                    status={true}
                    statusValue={filterStatus}
                    statusUpdate={setFilterStatus}
                    statusList={Config?.LEAVE_STATUS}
                    leaveType={true}
                    leaveTypeValue={leavetype}
                    leaveTypeUpdate={setleavetype}
                    leaveTypeList={Config?.LEAVE_TYPE}
                    date={true}
                    dateValue={filtertype}
                    dateUpdate={setFiltertype}
                    startDateValue={startdate}
                    startDateUpdate={setstartdate}
                    endDateValue={enddate}
                    endDateUpdate={setenddate}
                    filterRecord={filterRecord}
                    resetRecord={reset}
                  />
                )}
              </div>
              <div className="table-responsive datatable-custom">
                <Table 
                  handleCheckBoxChange={handleCheckBoxChange}
                  isAllSelected={isAllSelected}
                  loading={loading}
                  applicants={applicants}
                  selectedinfo={selectedinfo}
                  adminData={adminData}
                  handleViewReportClick={handleViewReportClick}
                  handleDeleteClick={handleDeleteClick}
                />
              </div>
              <Edit 
                fullReport={fullReport}
                setleave_type={setleave_type}
                leave_type={leave_type}
                setfromdate={setfromdate}
                fromdate={fromdate}
                settodate={settodate}
                todate={todate}
                setreason={setreason}
                reason={reason}
                setstatus={setstatus}
                status={status}
                adminData={adminData}
                setapproverdate={setapproverdate}
                approverdate={approverdate}
                isDisable={isDisable}
                changeStatus={changeStatus}
                updateId={updateId}
              />
              <AlertDelete
                isDisable={isDisable}
                deleteRecord={deleteRecord}
                deleteId={deleteId}
              />
              <View fullReport={fullReport} />
              <div className="card-footer">
                <Pagination
                  handlePageChange={handlePageChange}
                  controller={controller}
                  count={count}
                  handleChangeRowsPerPage1={handleChangeRowsPerPage1}
                />
              </div>
            </div>
          </div>
          <div className="footer"></div>
        </main>
      )}
    </>
  );
}

export default HeaderNavbarHOC(LeavesList);
