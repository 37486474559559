import React, { useState, useEffect } from "react";
import moment from "moment";
import { useLocation } from "react-router-dom";
import Config from "../../config";
import * as AxiosActions from "../../Actions/axiosActions";
import { EVENT_NAME, trackEvent } from "../../constant/MixpanelConstant";
import HeaderNavbarHOC from "../../HOC/HeaderNavbar";
import { Filter, Pagination } from "../../components/Common";
import {
  COMPANY_NAME,
  DATE,
  EMAIL,
  NORECORDFOUND,
  NOTE,
  POSITIONS,
  STATUS,
  TEMPLATE,
  TIME,
  TYPE,
} from "../../constant/Label";

function NotesList() {
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [filterMonth, setFilterMonth] = useState("all");
  const [filterYear, setFilterYear] = useState("all");
  const [filterDate, setFilterDate] = useState("all");
  const [applicants, setApplicants] = useState([]);
  const [isFiltered, setIsFiltered] = useState(false);
  const [filterStatus, setFilterStatus] = useState("all");
  const [count, setCount] = useState(0);
  const { state } = useLocation();

  const [controller, setController] = useState({
    page: 0,
    rowsPerPage: 10,
    preventRefresh: false,
  });

  const token = localStorage.getItem("access_token");

  // RENDER DATA

  const renderdata = async () => {
    try {
      setLoading(true);
      AxiosActions.EmailMarketingNotesList({
        marketingReference: state?._id,
        page: controller.page,
        limit: controller.rowsPerPage,
        year: Number(filterYear) ? Number(filterYear) : filterYear,
        type: filterDate,
        month: filterMonth,
        noteType: filterStatus,
        startDate: startDate,
        endDate: endDate,
        updatedAt: false,
        createdAt: true,
      })
        .then((data) => {
          if (data?.status === 200) {
            if (data?.data?.data?.resultData) {
              setApplicants(data?.data?.data?.resultData);
              setCount(data?.data?.data?.count);
            }
          }
          setLoading(false);
        })
        .catch((error) => {});
    } catch (err) {}
  };

  useEffect(() => {
    if (isFiltered === true) {
      if (!controller.preventRefresh) filterRecord();
    } else {
      renderdata();
    }
  }, [controller]);

  // New Pagination

  const handlePageChange = (event, newPage) => {
    setController({
      ...controller,
      page: newPage,
      preventRefresh: false,
    });
  };

  const handleChangeRowsPerPage1 = (event) => {
    setController({
      ...controller,
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0,
      preventRefresh: false,
    });
  };

  // Filter record

  const filterRecord = async () => {
    trackEvent(EVENT_NAME.EMAIL_MARKETING_FILTER_NOTE_LIST, { data: "" });
    setIsFiltered(true);
    if (!isFiltered) {
      let data = {
        page: 0,
        rowsPerPage: 10,
        preventRefresh: false,
      };
      setController(data);
    }
    renderdata();
  };

  const getStatusName = (statusValue) => {
    const statusType = Config?.EMAIL_STATUS?.find(
      (type) => type?.value === statusValue
    );
    return statusType ? statusType?.name : statusValue;
  };

  const reset = () => {
    trackEvent(EVENT_NAME.EMAIL_MARKETING_RESET_NOTE_LIST, { data: "" });
    setFilterStatus("all");
    setController({
      ...controller,
      page: 0,
      rowsPerPage: 10,
      preventRefresh: false,
    });
    setIsFiltered(false);
    setFilterDate("all");
    setStartDate("");
    setEndDate("");
    setFilterMonth("all");
    setFilterYear("all");
  };

  // // TODO: if status badge color change with status name than use this otherwise remove.
  // const statusColorMap = {
  //   pending: "badge bg-warning p-2 m-2",
  //   rejected: "badge bg-danger p-2 m-2",
  //   Failed: "badge bg-danger p-2 m-2",
  //   contacted: "badge bg-info p-2 m-2",
  //   sent: "badge bg-primary p-2 m-2"
  // };

  // const getStatusColorClass = (statusValue) => {
  //   const colorClass = statusColorMap[statusValue?.toLowerCase()];
  //   return colorClass || "badge bg-secondary p-2 m-2";
  // };

  return (
    <>
      {token === null ? (
        true((window.location.href = "/"))
      ) : (
        <main id="content" role="main" className="main">
          <div className="content container-fluid">
            <div className="page-header">
              <div className="container">
                <div className="row">
                  <div className="col-sm">
                    <h2 className="mb-0">{state?.name}</h2>
                    <h4 className="mb-0 mt-2">{state?.email}</h4>
                  </div>
                  <div className="col-sm">
                    <h4 className="mb-0">
                      {state?.emailType && `${TYPE} : ${state?.emailType}`}
                    </h4>
                    <h4 className="mb-0 mt-2">
                      {state?.status && `${STATUS} : ${state?.status}`}
                    </h4>
                  </div>
                  <div className="col-sm">
                    <h4 className="mb-0">
                      {state?.companyName &&
                        `${COMPANY_NAME} : ${state?.companyName}`}
                    </h4>
                    <h4 className="mb-0 mt-2">
                      {state?.positions && `${POSITIONS} : ${state?.positions}`}
                    </h4>
                  </div>
                </div>
              </div>
            </div>

            <div className="card mb-3 mb-lg-5">
              <div className="card-header">
                <Filter
                  year={true}
                  yearValue={filterYear}
                  yearUpdate={setFilterYear}
                  month={true}
                  monthValue={filterMonth}
                  monthUpdate={setFilterMonth}
                  status={true}
                  statusValue={filterStatus}
                  statusUpdate={setFilterStatus}
                  statusList={Config?.EMAIL_STATUS}
                  date={true}
                  dateValue={filterDate}
                  dateUpdate={setFilterDate}
                  startDateValue={startDate}
                  startDateUpdate={setStartDate}
                  endDateValue={endDate}
                  endDateUpdate={setEndDate}
                  filterRecord={filterRecord}
                  resetRecord={reset}
                />
              </div>
              <div className="card mb-3 mb-lg-5">
                <table
                  id="datatable"
                  className="table table-borderless table-thead-bordered table-nowrap table-align-middle card-table"
                >
                  <thead className="thead-light">
                    <tr>
                      <th>{STATUS}</th>
                      <th>{EMAIL}</th>
                      <th>{NOTE}</th>
                      <th>{TEMPLATE}</th>
                      <th>{DATE}</th>
                      <th>{TIME}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading ? (
                      <tr>
                        <td colSpan={8} style={{ textAlign: "center" }}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <div
                              className="spinner-border text-dark m-5"
                              role="status"
                            >
                              <span className="sr-only"></span>
                            </div>
                          </div>
                        </td>
                      </tr>
                    ) : (
                      <>
                        {applicants?.length === 0 && (
                          <tr>
                            <td colSpan={9} style={{ textAlign: "center" }}>
                              <h3>{NORECORDFOUND}</h3>
                            </td>
                          </tr>
                        )}
                        {applicants?.map((app, index) => {
                          return (
                            <tr key={index}>
                              <td
                                 //className={getStatusColorClass(app?.noteType)} // if status badge color change with status name than use this otherwise remove.
                                className="badge bg-secondary p-2 m-2"
                              >
                                {getStatusName(app?.noteType)}
                              </td>
                              <td>{app.emailAddress || "-"}</td>

                              <td>{app.message}</td>
                              <td>{app.template || "-"}</td>

                              <td>
                                {moment(app.createdAt).format("D MMM YYYY")}
                              </td>
                              <td>
                                {moment(app.createdAt).format("h:mm:ss A")}
                              </td>
                            </tr>
                          );
                        })}
                      </>
                    )}
                  </tbody>
                </table>
              </div>
              <div className="card-footer">
                <Pagination
                  handlePageChange={handlePageChange}
                  controller={controller}
                  count={count}
                  handleChangeRowsPerPage1={handleChangeRowsPerPage1}
                />
              </div>
            </div>
          </div>
          <div className="footer"></div>
        </main>
      )}
    </>
  );
}

export default HeaderNavbarHOC(NotesList);
