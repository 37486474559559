import React from "react";
import Edit from "./Edit";
import { AlertDelete } from "../../components/Common";
import ResetPassword from "./ResetPassword";
import PropTypes from "prop-types";

function Table({
  loading,
  applicants,
  handleViewReportClick,
  fullReport,
  setusername,
  Username,
  setusertype,
  Usertype,
  roles,
  setRoles,
  setstatus,
  status,
  isDisable,
  changeStatus,
  updateId,
  handleDeleteClick,
  deleteRecord,
  deleteId,
  setpassword,
  setConfirm,
  setError,
  Password,
  Confirm,
  error,
  changePassword,
  ChangePasswordId,
}) {
  return (
    <table
      id="datatable"
      className="table table-borderless table-thead-bordered table-nowrap table-align-middle card-table"
    >
      <thead className="thead-light">
        <tr>
          <th>Username</th>
          <th>Employee Name</th>
          <th>User</th>
          <th>Role</th>
          <th>Status</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        {loading ? (
          <tr>
            <td colSpan={8} style={{ textAlign: "center" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <status className="spinner-border text-dark m-5">
                  <span className="sr-only"></span>
                </status>
              </div>
            </td>
          </tr>
        ) : (
          <>
            {applicants.length === 0 && (
              <tr>
                <td colSpan={5} style={{ textAlign: "center" }}>
                  <h3>No record found</h3>
                </td>
              </tr>
            )}
            {applicants.map((app, index) => {
              return (
                <tr key={"app" + index}>
                  <td>
                    <h5>{app.email}</h5>
                  </td>
                  <td>{app?.empid?.employeeName ?? '-'}</td>
                  <td>{app.isAdmin ? "Admin" : "Employee"}</td>
                  <td>{app.isAdmin ? app.role : "-"}</td>
                  <td>{app.status}</td>

                  <td>
                    <button
                      title="Edit user"
                      type="button"
                      className="icon icon-danger icon-circle"
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModalLong"
                      onClick={(event) => handleViewReportClick(event, app._id)}
                    >
                      <i className="bi-pen"></i>
                    </button>
                    <Edit
                      fullReport={fullReport}
                      setusername={setusername}
                      Username={Username}
                      setusertype={setusertype}
                      Usertype={Usertype}
                      roles={roles}
                      setRoles={setRoles}
                      setstatus={setstatus}
                      status={status}
                      isDisable={isDisable}
                      changeStatus={changeStatus}
                      updateId={updateId}
                    />
                    &nbsp;
                    <button
                      title="Delete user"
                      type="button"
                      className="icon icon-info icon-circle"
                      data-bs-toggle="modal"
                      data-bs-target="#delete"
                      onClick={(event) => handleDeleteClick(event, app._id)}
                    >
                      <i className="bi-trash"></i>
                    </button>
                    <AlertDelete
                      isDisable={isDisable}
                      deleteRecord={deleteRecord}
                      deleteId={deleteId}
                    />
                    &nbsp;
                    <button
                      title="Change password"
                      type="button"
                      className="icon icon-dark icon-circle"
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModalLong1"
                      onClick={(event) => handleViewReportClick(event, app._id)}
                    >
                      <i className="bi bi-lock"></i>
                    </button>
                    <ResetPassword
                      setpassword={setpassword}
                      setConfirm={setConfirm}
                      setError={setError}
                      fullReport={fullReport}
                      Password={Password}
                      Confirm={Confirm}
                      error={error}
                      isDisable={isDisable}
                      changePassword={changePassword}
                      ChangePasswordId={ChangePasswordId}
                    />
                  </td>
                  <></>
                </tr>
              );
            })}
          </>
        )}
      </tbody>
    </table>
  );
}

export default Table;

Table.propTypes = {
  loading: PropTypes.bool.isRequired,
  applicants: PropTypes.array.isRequired,
  handleViewReportClick: PropTypes.func.isRequired,
  fullReport: PropTypes.object.isRequired,
  setusername: PropTypes.func.isRequired,
  Username: PropTypes.string.isRequired,
  setusertype: PropTypes.func.isRequired,
  Usertype: PropTypes.string.isRequired,
  roles: PropTypes.array.isRequired,
  setRoles: PropTypes.func.isRequired,
  setstatus: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired,
  isDisable: PropTypes.bool.isRequired,
  changeStatus: PropTypes.func.isRequired,
  updateId: PropTypes.string.isRequired,
  handleDeleteClick: PropTypes.func.isRequired,
  deleteRecord: PropTypes.func.isRequired,
  deleteId: PropTypes.string.isRequired,
  setpassword: PropTypes.func.isRequired,
  setConfirm: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
  Password: PropTypes.string.isRequired,
  Confirm: PropTypes.string.isRequired,
  error: PropTypes.string.isRequired,
  changePassword: PropTypes.func.isRequired,
  ChangePasswordId: PropTypes.string.isRequired,
};