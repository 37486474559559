import React from "react";
import moment from "moment";
import Datepicker from "../../Datepicker";
import Config, { yearArray } from "../../config";
import { Autocomplete, TextField } from "@mui/material";

function Edit({
  fullReport,
  setmonth,
  month,
  setyear,
  year,
  settotal_days,
  total_days,
  setDays,
  Days,
  setBasic,
  Basic,
  setHRA,
  HRA,
  setConveyance,
  Conveyance,
  setTotalAddition,
  TotalAddition,
  setPF,
  PF,
  setesi,
  esi,
  setProfessionTax,
  ProfessionTax,
  setTotalDeduction,
  TotalDeduction,
  setNetAmount,
  NetAmount,
  setAmount,
  Amount,
  setMode,
  Mode,
  setbank,
  bank,
  setCheque,
  Cheque,
  setdate,
  date,
  setStatus,
  status,
  isDisable,
  changeStatus,
  updateId,
}) {
  return (
    <div
      className="modal fade"
      id="exampleModalLong"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h2 className="modal-title" id="exampleModalLongTitle">
              Edit Salary
            </h2>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <div className="modal-content">
              <table>
                <tbody>
                  {fullReport.map((item, index) => {
                    return (
                      <>
                        <tr>
                          <td>
                            <h4>Employee name</h4> {item.empid.employeeName}
                          </td>
                        </tr>

                        <br></br>
                        <tr>
                          <td>
                            <h4>Month</h4>{" "}
                            <Autocomplete
                            options={Config.SALARY_MONTH}
                            getOptionLabel={(option) => option}
                            value={month || "select month"}
                            onChange={(event, newValue) => {
                              setmonth(newValue);
                            }}
                            size="small"
                            renderInput={(params) => (
                              <TextField {...params} variant="outlined" autoComplete="off" />
                            )}
                            />
                          </td>
                        </tr>

                        <br></br>
                        <tr>
                          <td>
                            <h4>Year</h4>{" "}
                            <Autocomplete 
                            options={yearArray}
                            getOptionLabel={(option) => option}
                            value={year || "select year"}
                            onChange={(event, newValue) => {
                              setyear(newValue);
                            }}
                            size="small"
                            renderInput={(params) => (
                              <TextField {...params} variant="outlined" autoComplete="off" />
                            )}
                            />
                          </td>
                        </tr>

                        <br></br>
                        <tr>
                          <td>
                            <h4>Total Days</h4>{" "}
                            <input
                              type="number"
                              className="form-control"
                              name="total_days"
                              onChange={(event) =>
                                settotal_days(event.target.value)
                              }
                              value={total_days}
                              id="emailLabel"
                              placeholder="Total Days"
                              aria-label="Email"
                            />{" "}
                          </td>
                        </tr>

                        <br></br>
                        <tr>
                          <td>
                            <h4>Days(30)</h4>{" "}
                            <input
                              type="number"
                              className="form-control"
                              name="Days"
                              onChange={(event) => setDays(event.target.value)}
                              value={Days}
                              id="emailLabel"
                              placeholder="Days"
                              aria-label="Email"
                            />{" "}
                          </td>
                        </tr>

                        <br></br>
                        <tr>
                          <td>
                            <h4>Basic/DA</h4>{" "}
                            <input
                              type="number"
                              className="form-control"
                              name="Basic"
                              onChange={(event) => setBasic(event.target.value)}
                              value={Basic}
                              id="emailLabel"
                              placeholder="Basic/DA"
                              aria-label="Email"
                            />{" "}
                          </td>
                        </tr>

                        <br></br>
                        <tr>
                          <td>
                            <h4>HRA</h4>{" "}
                            <input
                              type="number"
                              className="form-control"
                              name="HRA"
                              onChange={(event) => setHRA(event.target.value)}
                              value={HRA}
                              id="emailLabel"
                              placeholder="HRA"
                              aria-label="Email"
                            />{" "}
                          </td>
                        </tr>

                        <br></br>
                        <tr>
                          <td>
                            <h4>Conveyance</h4>{" "}
                            <input
                              type="number"
                              className="form-control"
                              name="Conveyance"
                              onChange={(event) =>
                                setConveyance(event.target.value)
                              }
                              value={Conveyance}
                              id="emailLabel"
                              placeholder="Conveyance"
                              aria-label="Email"
                            />{" "}
                          </td>
                        </tr>

                        <br></br>
                        <tr>
                          <td>
                            <h4>Total Addition</h4>{" "}
                            <input
                              type="number"
                              className="form-control"
                              name="TotalAddition"
                              onChange={(event) =>
                                setTotalAddition(event.target.value)
                              }
                              value={TotalAddition}
                              id="emailLabel"
                              placeholder="Total Addition"
                              aria-label="Email"
                            />{" "}
                          </td>
                        </tr>

                        <br></br>
                        <tr>
                          <td>
                            <h4>P.F</h4>{" "}
                            <input
                              type="number"
                              className="form-control"
                              name="PF"
                              onChange={(event) => setPF(event.target.value)}
                              value={PF}
                              id="emailLabel"
                              placeholder="P.F"
                              aria-label="Email"
                            />{" "}
                          </td>
                        </tr>

                        <br></br>
                        <tr>
                          <td>
                            <h4>E.S.I</h4>{" "}
                            <input
                              type="number"
                              className="form-control"
                              name="esi"
                              onChange={(event) => setesi(event.target.value)}
                              value={esi}
                              id="emailLabel"
                              placeholder="E.S.I"
                              aria-label="Email"
                            />{" "}
                          </td>
                        </tr>

                        <br></br>
                        <tr>
                          <td>
                            <h4>Profession Tax</h4>{" "}
                            <input
                              type="number"
                              className="form-control"
                              name="ProfessionTax"
                              onChange={(event) =>
                                setProfessionTax(event.target.value)
                              }
                              value={ProfessionTax}
                              id="emailLabel"
                              placeholder="Profession Tax"
                              aria-label="Email"
                            />{" "}
                          </td>
                        </tr>

                        <br></br>
                        <tr>
                          <td>
                            <h4>Total Deduction</h4>{" "}
                            <input
                              type="number"
                              className="form-control"
                              name="TotalDeduction"
                              onChange={(event) =>
                                setTotalDeduction(event.target.value)
                              }
                              value={TotalDeduction}
                              id="emailLabel"
                              placeholder="Total Deduction"
                              aria-label="Email"
                            />{" "}
                          </td>
                        </tr>

                        <br></br>
                        <tr>
                          <td>
                            <h4>Net Amount</h4>{" "}
                            <input
                              type="number"
                              className="form-control"
                              name="NetAmount"
                              id="emailLabel"
                              placeholder="Net Amount"
                              aria-label="Email"
                              onChange={(event) =>
                                setNetAmount(event.target.value)
                              }
                              value={NetAmount}
                            />{" "}
                          </td>
                        </tr>

                        <br></br>
                        <tr>
                          <td>
                            <h4>Amount in words</h4>{" "}
                            <input
                              type="text"
                              className="form-control"
                              name="Amount"
                              onChange={(event) =>
                                setAmount(event.target.value)
                              }
                              value={Amount}
                              id="emailLabel"
                              placeholder="Amount in words"
                              aria-label="Email"
                            />{" "}
                          </td>
                        </tr>

                        <br></br>
                        <tr>
                          <td>
                            <h4>Mode</h4>{" "}
                            <input
                              type="text"
                              className="form-control"
                              name="Mode"
                              onChange={(event) => setMode(event.target.value)}
                              value={Mode}
                              id="emailLabel"
                              placeholder="Mode"
                              aria-label="Email"
                            />{" "}
                          </td>
                        </tr>

                        <br></br>
                        <tr>
                          <td>
                            <h4>Name of Bank</h4>{" "}
                            <input
                              type="text"
                              className="form-control"
                              name="bank"
                              onChange={(event) => setbank(event.target.value)}
                              id="emailLabel"
                              placeholder="Name of Bank"
                              aria-label="Email"
                              value={bank}
                            />{" "}
                          </td>
                        </tr>

                        <br></br>
                        <tr>
                          <td>
                            <h4>Cheque/DD no</h4>{" "}
                            <input
                              type="text"
                              className="form-control"
                              name="Cheque"
                              onChange={(event) =>
                                setCheque(event.target.value)
                              }
                              id="emailLabel"
                              placeholder="Cheque/DD no"
                              aria-label="Email"
                              value={Cheque}
                            />{" "}
                          </td>
                        </tr>

                        <br></br>
                        <tr>
                          <td>
                            <h4>Date</h4>{" "}
                            <Datepicker
                              onChange={(newDate) => {
                                setdate(newDate);
                              }}
                              value={moment(date).format("YYYY-MM-DD")}
                            />
                          </td>
                        </tr>

                        <br></br>
                        <tr>
                          <td>
                            <h4>Status</h4>{" "}
                            <Autocomplete
                            options={Config.SALARY_STATUS}
                            getOptionLabel={(option) => option.name}
                            value={
                              Config.SALARY_STATUS.find(
                                (option) => option.value === status
                              ) || {name : "Select Status"}
                            }
                            onChange={(event, newValue) => {
                              setStatus(newValue?.value);
                            }}
                            size="small"
                            renderInput={(params) => (
                              <TextField {...params} />
                            )}
                            />
                          </td>
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-white"
              data-bs-dismiss="modal"
            >
              Close
            </button>
            <button
              disabled={isDisable === true}
              type="button"
              className="btn btn-primary"
              data-bs-dismiss="modal"
              onClick={() => {
                changeStatus(updateId);
              }}
            >
              Save changes
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Edit;
