export const ROUTES = {
  DASHBOARD: "/dashboard",
  CONTACTS: "/contacts",
  QUOTES: "/quotes",
  APPLICANT: {
    ADD: "/applicant/add",
    LIST: "/applicant/list",
    IMPORT: "/applicant/import",
  },
  EMPLOYEE: {
    ADD: "/employee/add",
    LIST: "/employee/list",
  },
  USER: {
    ADD: "/user/add",
    LIST: "/user/list",
  },
  ATTENDANCE: {
    ADD: "/attendance/add",
    LIST: "/attendance/list",
    SHEET: "/AttendenceSheet",
  },
  CLIENT: {
    ADD: "/client/add",
    LIST: "/client/list",
  },
  TRACKING_TOOLS: {
    ADD: "/trackingtools/add",
    LIST: "/trackingtools/list",
  },
  LEAVES: {
    ADD: "/leaves/add",
    LIST: "/leaves/list",
  },
  SALARY: {
    ADD: "/salary/add",
    LIST: "/salary/list",
  },
  EXPENSES: {
    ADD: "/expenses/add",
    LIST: "/expenses/list",
  },
  REMINDER: {
    ADD: "/Reminder/add",
    LIST: "/Reminder/list",
    SHEET: "/Reminder/sheet",
  },
  HISTORY: {
    LIST: "/history/list",
  },
  TASKS: {
    ADD: "/tasks/add",
    LIST: "/tasks/list",
  },
  TRACK: {
    ADD: "/track/add",
    DAILY: "/daily",
    SHEET: "/timesheet",
  },
  PRODUCTIVITY: {
    ADD: "/productivity/add",
    LIST: "/productivity/list",
  },
  SUMMARY: {
    EMPLOYEE: "/summary/employee",
    CLIENT: "/summary/client",
    TRACKING_TOOLS: "/summary/trackingtools",
    SALARY: "/summary/salary",
  },
  JOBS: {
    ADD: "/jobs/add",
    LIST: "/jobs/list",
  },
  EMAIL: {
    ADD: "/email/add",
    LIST: "/email/list",
    NOTE:"/notes/list",
    CSV: "/email/csv-import",
  },
  WHATSAPP: {
    ADD: "/whatsapp/add",
    LIST: "/whatsapp/list",
    CSV: "/whatsapp/csv-import",
  },
  EVENT : {
    ADD: "/event/add",
    LIST: "/event/list",
  }
};
