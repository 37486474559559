import moment from "moment";
import React from "react";
import PropTypes from "prop-types";

function View({ fullReport }) {
  return (
    <div
      className="modal fade bd-example-modal-xl"
      id="viewpage"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title" id="exampleModalLongTitle">
              View task
            </h1>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            {fullReport.map((item) => {
              return (
                <div key={item.id}>
                  <h3 style={{ color: "red" }}>Task information</h3>
                  <table className="table table-align-middle">
                    <thead>
                      <tr>
                        <th scope="col"></th>
                        <th scope="col"></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <h4>Project Name</h4>
                          {item.clientId?.clientName}
                        </td>
                        <td>
                          <h4>Employee Name</h4>
                          {item.empid?.employeeName}
                        </td>
                      </tr>

                      <tr>
                        <td colSpan={2}>
                          <h4>Summary</h4>
                          {item.summary}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <h4>Date</h4>
                          {moment(item.date).format("DD-MM-YYYY")}
                        </td>
                        <td>
                          <h4>Status</h4>
                          {item.status}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              );
            })}
          </div>
          <div className="modal-footer"></div>
        </div>
      </div>
    </div>
  );
}

export default View;

View.propTypes = {
  fullReport: PropTypes.array.isRequired,
};
