import React from "react";
import { contact } from "../../constant/Contact";

function Report({ fullReport, app, handleDownload }) {
  return (
    <div
      className="modal fade"
      id="viewpage"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title" id="exampleModalLongTitle">
            {contact.REPORT}
            </h1>

            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            {fullReport.map((item, index) => {
              return (
                <>
                  <h3 style={{ color: "red" }}>{contact.BASIC_INFO}</h3>
                  <div>
                    <table className="table table-align-middle">
                      <thead>
                        <tr>
                          <th scope="col"></th>
                          <th scope="col"></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <h4>{contact.SOURCE}</h4>

                            {item.source ? item.source : "-"}
                          </td>
                          <td>
                            <h4>{contact.NAME}</h4>

                            {item.name ? item.name : "-"}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <h4>{contact.EMAIL}</h4>
                            {item.email ? item.email : "-"}
                          </td>
                          <td>
                            <h4>{contact.PHONE}</h4>
                            {item.phone ? item.phone : "-"}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <h4>{contact.CURRENT_LOACTION}</h4>
                            {item.currentLocation ? item.currentLocation : "-"}
                          </td>
                          <td>
                            <h4>{contact.CITY}</h4>
                            {item.city ? item.city : "-"}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <h4>{contact.GENDER}</h4>
                            {item.gender ? item.gender : "-"}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <h3 style={{ color: "red" }}>Job Info</h3>
                  <div>
                    <table className="table table-align-middle">
                      <thead>
                        <tr>
                          <th scope="col"></th>
                          <th scope="col"></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <h4>{contact.QUALIFICTION}</h4>
                            {item.qualification ? item.qualification : "-"}
                          </td>
                          <td>
                            <h4>{contact.EXPERIENCE}</h4>
                            {item.experience ? item.experience : "-"}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <h4>{contact.JOB}</h4>
                            {app.job ? app.job.job_title : "-"}
                          </td>
                          <td>
                            <h4>{contact.CURRENT_CTC}</h4>
                            {item.currentCTC ? item.currentCTC : "-"}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <h4>{contact.EXPECTED_CTC}</h4>
                            {item.expectedCTC ? item.expectedCTC : "-"}
                          </td>
                          <td>
                            <h4>{contact.OFFERED_CTC}</h4>
                            {item.offeredCTC ? item.offeredCTC : "-"}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <h4>{contact.RESUME}</h4>
                            <button
                              className="icon icon-success icon-circle"
                              onClick={() => handleDownload(app.resume)}
                            >
                              <i className="bi-download"></i>
                            </button>
                          </td>
                          <td>
                            <h4>{contact.REMARK}</h4>
                            {item.remarks ? item.remarks : "-"}
                          </td>
                        </tr>

                        <tr>
                          <td>
                            <h4>{contact.STATUS}</h4>
                            {item.status ? item.status : "-"}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </>
              );
            })}
          </div>
          <div className="modal-footer"></div>
        </div>
      </div>
    </div>
  );
}

export default Report;
