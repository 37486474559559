import React from "react";

function HeaderTitle({ title, name, email }) {
  return (
    <div className="page-header">
      <div className="row align-items-center">
        <div
          className="col align-items-center"
          style={{ alignItems: "center" }}
        >
          <h1
            className="page-header-title align-items-center"
            style={{ alignSelf: "center" }}
          >
            {title}
          </h1>
          <p className="mb-0">{name}</p>
          <p className="mb-0">{email}</p>
        </div>
      </div>
    </div>
  );
}

export default HeaderTitle;
