import React from "react";
import { useNavigate } from "react-router-dom";

function PageNotFound() {
  const navigate = useNavigate();
  return (
    <>
      <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
        <img src="https://admin.3elixir.com/assets/img/error/pageNotFound.png" alt="page not found" />
      </div>
      <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
        <button
          type="button"
          className="btn btn-outline-primary"
          onClick={() => navigate('/dashboard')}
        >
          Back To Home
        </button>
      </div>
    </>
  );
}

export default PageNotFound;
