export const client = {
    ADD_CLIENT_INFO: 'Add client information',
    CLIENT_NAME : 'Client name',
    DATE : 'Date',
    STATUS : 'Status',
    EDIT_CLIENT : 'Edit client',
    ACTION : 'Action',
    NO_RECORD_FOUND : 'No record found',
    VIEW_CLIENT : 'View client',
    CLIENT_INFO : 'Client information',
    

    //ACTION 
    SAVE_CHANGES : 'Save changes',
    CLOSE : 'Close',

}