import React from "react";
import Alert from "@mui/material/Alert";
import PropTypes from "prop-types";

function ResetPassword({
  setpassword,
  setConfirm,
  setError,
  fullReport,
  Password,
  Confirm,
  error,
  isDisable,
  changePassword,
  ChangePasswordId,
}) {
  return (
    <div
      className="modal fade"
      id="exampleModalLong1"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h2 className="modal-title" id="exampleModalLongTitle">
              Reset Password
            </h2>

            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => {
                setpassword("");
                setConfirm("");
                setError(false);
              }}
            ></button>
          </div>
          <div className="modal-body">
            <div className="modal-content">
              <table>
                <tbody>
                  {fullReport.map((item, index) => {
                    return (
                      <>
                        <tr>
                          <td>
                            <h4>New password</h4>
                            <input
                              type="password"
                              className="form-control"
                              name="Username"
                              id="firstNameLabel"
                              placeholder="New password"
                              onChange={(event) =>
                                setpassword(event.target.value)
                              }
                              value={Password}
                            />
                          </td>
                        </tr>
                        <br></br>

                        <tr>
                          <td>
                            <h4>Confirm password</h4>
                            <input
                              type="password"
                              className="form-control"
                              name="Username"
                              id="firstNameLabel"
                              placeholder="Confirm password"
                              onChange={(event) =>
                                setConfirm(event.target.value)
                              }
                              value={Confirm}
                            />
                          </td>
                        </tr>
                      </>
                    );
                  })}
                  <br></br>
                  {error === true && (
                    <Alert
                      variant="outlined"
                      severity="error"
                      sx={{
                        height: 80,
                        fontSize: 16,
                      }}
                    >
                      'New password and confirm password does not match!'
                    </Alert>
                  )}
                </tbody>
              </table>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-white"
              data-bs-dismiss="modal"
              onClick={() => {
                setpassword("");
                setConfirm("");
                setError(false);
              }}
            >
              Close
            </button>
            <button
              disabled={isDisable === true}
              type="button"
              className="btn btn-primary"
              onClick={() => {
                changePassword(ChangePasswordId);
              }}
            >
              Save changes
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ResetPassword;

ResetPassword.propTypes = {
  setpassword: PropTypes.func.isRequired,
  setConfirm: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
  fullReport: PropTypes.array.isRequired,
  Password: PropTypes.string.isRequired,
  Confirm: PropTypes.string.isRequired,
  error: PropTypes.bool.isRequired,
  isDisable: PropTypes.bool.isRequired,
  changePassword: PropTypes.func.isRequired,
  ChangePasswordId: PropTypes.string.isRequired,
};
