import './App.css';
import Login from './Login';
function App() {
  return (
    <>

      <Login></Login>


    </>
  );
}

export default App;
