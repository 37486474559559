import React from "react";
import { Autocomplete, TextField } from "@mui/material";
import Config, { yearArray } from "../../config";
import { COUNTRY, FILTER_LABEL, RESEND_EMAIL, TEMPLATE } from "../../constant/Label";

function Filter({
  TrackingTool,
  TrackingToolValue,
  TrackingToolUpdate,
  TrackingToolList,
  project,
  projectValue,
  projectUpdate,
  projectList,
  task,
  taskValue,
  taskUpdate,
  taskList,
  year,
  yearValue,
  yearUpdate,
  month,
  monthValue,
  monthUpdate,
  status,
  country,
  statusValue,
  countryValue,
  statusUpdate,
  countryUpdate,
  statusList,
  countryList,
  hours,
  hoursValue,
  hoursUpdate,
  hoursList,
  userType,
  userTypeValue,
  userTypeUpdate,
  userTypeList,
  leaveType,
  leaveTypeValue,
  leaveTypeUpdate,
  leaveTypeList,
  type,
  typeValue,
  typeUpdate,
  date,
  dateValue,
  dateUpdate,
  startDateValue,
  startDateUpdate,
  endDateValue,
  endDateUpdate,
  selectDate,
  selectDateValue,
  selectDateUpdate,
  category,
  categoryValue,
  categoryUpdate,
  name,
  nameValue,
  nameUpdate,
  trackName,
  trackNameValue,
  trackNameUpdate,
  employeeList,
  searchJob,
  searchJobValue,
  searchJobUpdate,
  searchName,
  searchNameValue,
  searchNameUpdate,
  searchEmail,
  searchEmailValue,
  searchEmailUpdate,
  searchPhone,
  searchPhoneValue,
  searchPhoneUpdate,
  job,
  jobValue,
  jobUpdate,
  jobList,
  template,
  templateValue,
  templateUpdate,
  templateList,
  multipleSelect,
  isDisable,
  multipleDelete,
  multipleResend,
  handleDelete,
  handleMultiResend,
  setTemplateFormValue,
  templateFormValue,
  deleteAllRecord,
  multipleStatus,
  handleStatus,
  changeMultipleStatusValue,
  changeMultipleInputStatus,
  multipleStatusList,
  changeMultipleStatus,
  filterRecord,
  resetRecord,
}) {
  return (
    <div className="container-fluid filter">
      <div className="row d-flex justify-content-end align-items-center">
        {searchJob && (
          <>
            <div className="p-2 col-auto text-secondary">
              {FILTER_LABEL.JOB_TITLE}
            </div>
            <div className="p-2 col-auto">
              <div className="tom-select-custom tom-select-custom-end">
                <input
                  type="search"
                  className="form-control"
                  name="search"
                  value={searchJobValue}
                  onChange={(event) => searchJobUpdate(event.target.value)}
                  id="firstNameLabel"
                  placeholder="Name"
                  aria-label="Your first name"
                />
              </div>
            </div>
          </>
        )}
        {searchName && (
          <>
            <div className="p-2 col-auto text-secondary">
              {FILTER_LABEL.NAME}
            </div>
            <div className="p-2 col-auto">
              <div className="tom-select-custom tom-select-custom-end">
                <input
                  type="search"
                  className="form-control"
                  name="search"
                  value={searchNameValue}
                  onChange={(event) => searchNameUpdate(event.target.value)}
                  id="firstNameLabel"
                  placeholder="Name"
                  aria-label="Your first name"
                />
              </div>
            </div>
          </>
        )}
        {searchEmail && (
          <>
            <div className="p-2 col-auto text-secondary">
              {FILTER_LABEL.EMAIL}
            </div>
            <div className="p-2 col-auto">
              <div className="tom-select-custom tom-select-custom-end">
                <input
                  type="search"
                  className="form-control"
                  name="search"
                  value={searchEmailValue}
                  onChange={(event) => searchEmailUpdate(event.target.value)}
                  id="firstNameLabel"
                  placeholder="Email"
                  aria-label="Email"
                />
              </div>
            </div>
          </>
        )}
        {searchPhone && (
          <>
            <div className="p-2 col-auto text-secondary">
              {FILTER_LABEL.PHONE}
            </div>
            <div className="p-2 col-auto">
              <div className="tom-select-custom tom-select-custom-end">
                <input
                  type="search"
                  className="form-control"
                  name="search"
                  value={searchPhoneValue}
                  onChange={(event) => searchPhoneUpdate(event.target.value)}
                  id="firstNameLabel"
                  placeholder="Phone"
                  aria-label="Phone"
                />
              </div>
            </div>
          </>
        )}
        {TrackingTool && (
          <>
            <div className="p-2 col-auto text-secondary">
              {FILTER_LABEL.TRACKING}
            </div>
            <div className="p-2 col-auto">
              <div className="tom-select-custom tom-select-custom-end">
                <select
                  className="form-select"
                  autoComplete="off"
                  value={TrackingToolValue}
                  onChange={(event) => TrackingToolUpdate(event.target.value)}
                >
                  <option value="all">{FILTER_LABEL.ALL}</option>

                  {TrackingToolList.map((item, index) => {
                    return (
                      <option value={item._id} key={index}>
                        {item.trackingToolName}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
          </>
        )}
        {project && (
          <div className="p-2 col-3">
            <Autocomplete
              options={[
                { value: '', label: FILTER_LABEL.SELECT_PROJECT },
                ...projectList.map((item) => ({
                  value: item._id,
                  label: item.clientName,
                })),
              ]}
              getOptionLabel={(option) => option.label}
              value={
                projectValue === ''
                  ? { value: '', label: FILTER_LABEL.SELECT_PROJECT }
                  : projectList
                      .filter((item) => item._id === projectValue)
                      .map((item) => ({
                        value: item._id,
                        label: item.clientName,
                      }))[0]
              }
              onChange={(event, newValue) => {
                projectUpdate(newValue ? newValue.value : '');
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  size="small"
                />
              )}
            />
          </div>
        )}

        {task && (
          <div className="p-2 col-3">
            <Autocomplete
              options={[
                { value: '', label: FILTER_LABEL.SELECT_TASK },
                ...taskList.map((item) => ({
                  value: item._id,
                  label: item.summary,
                })),
              ]}
              getOptionLabel={(option) => option.label}
              value={
                taskValue === ''
                  ? { value: '', label: FILTER_LABEL.SELECT_TASK }
                  : taskList
                      .filter((item) => item._id === taskValue)
                      .map((item) => ({
                        value: item._id,
                        label: item.summary,
                      }))[0]
              }
              onChange={(event, newValue) => {
                taskUpdate(newValue ? newValue.value : '');
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  size="small"
                />
              )}
            />
          </div>
        )}
        {name && (
          <>
            <div className="p-2 col-auto text-secondary">
              {FILTER_LABEL.NAME}
            </div>
            <div className="p-2 col-auto">
              <div className="tom-select-custom tom-select-custom-end">
                <select
                  className="form-select"
                  autoComplete="off"
                  value={nameValue}
                  onChange={(event) => nameUpdate(event.target.value)}
                >
                  <option value="all">{FILTER_LABEL.ALL}</option>
                  {employeeList?.map((item, index) => {
                    return (
                      <option value={item._id} key={index}>
                        {item.employeeName}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
          </>
        )}
        {trackName && (
          <>
            <div className="p-2 col-auto">
              <div className="tom-select-custom tom-select-custom-end">
                <select
                  className="form-select"
                  autoComplete="off"
                  value={trackNameValue}
                  onChange={(event) => trackNameUpdate(event.target.value)}
                >
                  {employeeList?.map((item, index) => {
                    return (
                      <option value={item._id} key={index}>
                        {item.employeeName}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
          </>
        )}
        {hours && (
          <>
            <div className="p-2 col-auto text-secondary">
              {FILTER_LABEL.HOURS}
            </div>
            <div className="p-2 col-auto">
              <div className="tom-select-custom tom-select-custom-end">
                <select
                  className="form-select"
                  autoComplete="off"
                  value={hoursValue}
                  onChange={(event) => hoursUpdate(event.target.value)}
                >
                  <option value="all">{FILTER_LABEL.ALL}</option>
                  {hoursList.map((item, index) => (
                    <option value={item.value} key={index}>
                      {" "}
                      {item.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </>
        )}
        {template && (
          <>
            <div className="p-2 col-auto text-secondary">
              {FILTER_LABEL.TEMPLATE_TYPE}
            </div>
            <div className="p-2 col-auto">
              <div className="tom-select-custom tom-select-custom-end">
                <select
                  className=" form-select "
                  data-target-column-index={5}
                  data-target-table="datatable"
                  autoComplete="off"
                  value={templateValue}
                  onChange={(event) => templateUpdate(event.target.value)}
                >
                  <option value="All">All</option>
                  {templateList?.map((item, index) => (
                    <option value={item.value} key={index}>
                      {item.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </>
        )}
        {selectDate && (
            <div className="p-2 col-auto">
              <div className="tom-select-custom tom-select-custom-end">
                <input
                  type="date"
                  className="form-control cursor-pointer"
                  name="StartDate"
                  id="firstNameLabel"
                  placeholder="StartDate"
                  aria-label="StartDate"
                  onChange={(event) => selectDateUpdate(event.target.value)}
                  onFocus={(event) => event.target.showPicker()}
                  value={selectDateValue}
                />  
              </div>
            </div>
        )}

        {date && (
          <>
            <div className="p-2 col-auto text-secondary">
              {FILTER_LABEL.DATE}
            </div>
            <div className="p-2 col-auto">
              <div className="tom-select-custom tom-select-custom-end">
                <select
                  className="form-select"
                  autoComplete="off"
                  value={dateValue}
                  onChange={(event) => dateUpdate(event.target.value)}
                >
                  <option value="all">{FILTER_LABEL.ALL}</option>
                  {Config.DATE_CATEGORY.map((item, index) => (
                    <option value={item.value} key={index}>
                      {" "}
                      {item.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </>
        )}
        {dateValue === "customize" && (
          <>
            <div className="p-2 col-auto text-secondary">
              {FILTER_LABEL.START_DATE}
            </div>
            <div className="p-2 col-auto">
              <div className="tom-select-custom tom-select-custom-end">
                <input
                  type="date"
                  className="form-control"
                  name="StartDate"
                  id="firstNameLabel"
                  placeholder="StartDate"
                  aria-label="StartDate"
                  onChange={(event) => startDateUpdate(event.target.value)}
                  value={startDateValue}
                />
              </div>
            </div>

            <div className="p-2 col-auto text-secondary">
              {FILTER_LABEL.END_DATE}
            </div>
            <div className="p-2 col-auto">
              <div className="tom-select-custom tom-select-custom-end">
                <input
                  type="date"
                  className="form-control"
                  name="EndDate"
                  id="firstNameLabel"
                  placeholder="EndDate"
                  aria-label="EndDate"
                  onChange={(event) => endDateUpdate(event.target.value)}
                  value={endDateValue}
                  min={startDateValue}
                />
              </div>
            </div>
          </>
        )}
        {status && (
          <>
            <div className="p-2 col-auto text-secondary">
              {FILTER_LABEL.STATUS}
            </div>
            <div className="p-2 col-auto">
              <div className="tom-select-custom tom-select-custom-end">
                <select
                  className="form-select"
                  autoComplete="off"
                  value={statusValue}
                  onChange={(event) => statusUpdate(event.target.value)}
                >
                  <option value="all">{FILTER_LABEL.ALL}</option>
                  {statusList?.name === undefined &&
                    statusList?.value === undefined}

                  {statusList?.map((item, index) => {
                    return item.name === undefined &&
                      item.value === undefined ? (
                      <option value={item} key={index}>
                        {" "}
                        {item}
                      </option>
                    ) : (
                      <option value={item.value} key={index}>
                        {" "}
                        {item.name}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
          </>
        )}
        {country && (
          <>
            <div className="p-2 col-auto text-secondary">
              {COUNTRY}
            </div>
            <div className="p-2 col-auto">
              <div className="tom-select-custom tom-select-custom-end">
                <select
                  className="form-select"
                  autoComplete="off"
                  value={countryValue}
                  onChange={(event) => countryUpdate(event.target.value)}
                >
                  <option value="all">{FILTER_LABEL.ALL}</option>
                  {countryList.name === undefined &&
                    countryList.value === undefined}

                  {countryList?.map((item, index) => {
                    return item.name === undefined &&
                      item.timeZone === undefined ? (
                      <option value={item} key={index}>
                        {" "}
                        {item}
                      </option>
                    ) : (
                      <option value={item.timeZone} key={index}>
                        {" "}
                        {item.name}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
          </>
        )}
        {userType && (
          <>
            <div className="p-2 col-auto text-secondary">
              {FILTER_LABEL.USER_TYPE}
            </div>
            <div className="p-2 col-auto">
              <div className="tom-select-custom tom-select-custom-end">
                <select
                  className="form-select"
                  autoComplete="off"
                  value={userTypeValue}
                  onChange={(event) => userTypeUpdate(event.target.value)}
                >
                  <option value="all">{FILTER_LABEL.ALL}</option>
                  {userTypeList?.map((item, index) => (
                    <option value={item.value} key={index}>
                      {" "}
                      {item.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </>
        )}
        {leaveType && (
          <>
            <div className="p-2 col-auto text-secondary">
              {FILTER_LABEL.LEAVE_TYPE}
            </div>
            <div className="p-2 col-auto">
              <div className="tom-select-custom tom-select-custom-end">
                <select
                  className="form-select"
                  autoComplete="off"
                  value={leaveTypeValue}
                  onChange={(event) => leaveTypeUpdate(event.target.value)}
                >
                  <option value="all">{FILTER_LABEL.ALL}</option>
                  {leaveTypeList?.map((item, index) => (
                    <option value={item.value} key={index}>
                      {" "}
                      {item.name} ({item.value})
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </>
        )}
        {type && (
          <>
            <div className="p-2 col-auto text-secondary">
              {FILTER_LABEL.USER_TYPE}
            </div>
            <div className="p-2 col-auto">
              <div className="tom-select-custom tom-select-custom-end">
                <select
                  className="form-select"
                  autoComplete="off"
                  value={typeValue}
                  onChange={(event) => typeUpdate(event.target.value)}
                >
                  <option value="all">All</option>
                  <option value="in">In</option>
                  <option value="out">Out</option>
                </select>
              </div>
            </div>
          </>
        )}

        {job && (
          <>
            <div className="p-2 col-auto text-secondary">
              {FILTER_LABEL.JOB_TITLE}
            </div>
            <div className="p-2 col-auto">
              <div className="tom-select-custom tom-select-custom-end">
                <select
                  className=" form-select "
                  data-target-column-index={5}
                  data-target-table="datatable"
                  autoComplete="off"
                  value={jobValue}
                  onChange={(event) => jobUpdate(event.target.value)}
                >
                  <option value="All">All</option>
                  {jobList?.map((item, index) => (
                    <option value={item.job_title} key={index}>
                      {item.job_title}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </>
        )}
        {category && (
          <>
            <div className="p-2 col-auto text-secondary">
              {FILTER_LABEL.CATEGORY}
            </div>
            <div className="p-2 col-auto">
              <div className="tom-select-custom tom-select-custom-end">
                <select
                  className="form-select"
                  autoComplete="off"
                  value={categoryValue}
                  onChange={(event) => categoryUpdate(event.target.value)}
                >
                  <option value="all">{FILTER_LABEL.ALL}</option>
                  {Config.EXPENSES_CATEGORY?.map((item, index) => (
                    <option value={item} key={index}>
                      {" "}
                      {item}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </>
        )}
        {month && (
          <>
            <div className="p-2 col-auto text-secondary">
              {FILTER_LABEL.MONTH}
            </div>
            <div className="p-2 col-auto">
              <div className="tom-select-custom tom-select-custom-end">
                <select
                  className="form-select"
                  data-target-column-index={6}
                  autoComplete="off"
                  value={monthValue}
                  onChange={(event) => monthUpdate(event.target.value)}
                >
                  <option value="all">{FILTER_LABEL.ALL}</option>
                  {Config.SALARY_MONTH.map((item, index) => (
                    <option value={item} key={index}>
                      {" "}
                      {item}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </>
        )}
        {year && (
          <>
            <div className="p-2 col-auto text-secondary">
              {FILTER_LABEL.YEAR}
            </div>
            <div className="p-2 col-auto">
              <div className="tom-select-custom tom-select-custom-end">
                <select
                  className="form-select"
                  data-target-column-index={6}
                  autoComplete="off"
                  value={yearValue}
                  onChange={(event) => yearUpdate(event.target.value)}
                >
                  {yearValue === "all" && (
                    <option value="all">{FILTER_LABEL.ALL}</option>
                  )}
                  {yearArray.map((item, index) => {
                    return (
                      <option value={item} key={index}>
                        {item}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
          </>
        )}

        <div className="p-2 col-auto">
          <button
            type="button"
            className="btn btn-info"
            onClick={() => filterRecord()}
          >
            {FILTER_LABEL.FILTER}
          </button>
        </div>
        <div className="p-2 col-auto">
          <button
            type="button"
            className="btn btn-warning"
            onClick={() => {
              resetRecord();
            }}
          >
            {FILTER_LABEL.RESET}
          </button>
        </div>
        {multipleSelect && multipleSelect !== 0 ? (
          <>
            {multipleDelete && (
              <div className="p-2 col-auto">
                <div className="col-sm-auto">
                  <div className="row align-items-center">
                    <div className="col">
                      <button
                        type="button"
                        className="btn btn-danger"
                        data-bs-toggle="modal"
                        data-bs-target="#deleteall"
                        onClick={(event) => handleDelete(event)}
                      >
                        {FILTER_LABEL.DELETE}{" "}
                        <span className="py-1 px-2 text-bg-light rounded-circle fw-semibold">
                          {multipleSelect}
                        </span>
                      </button>
                      <div
                        className="modal fade"
                        id="deleteall"
                        tabIndex="-1"
                        role="dialog"
                        aria-labelledby="exampleModalCenterTitle"
                        aria-hidden="true"
                      >
                        <div
                          className="modal-dialog modal-dialog-centered"
                          role="document"
                        >
                          <div className="modal-content">
                            <div className="modal-header">
                              <h1
                                className="modal-title"
                                id="exampleModalLongTitle"
                              >
                                {FILTER_LABEL.DELETE}
                              </h1>

                              <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                              ></button>
                            </div>
                            <div className="modal-body">
                              <label
                                style={{
                                  fontSize: 18,
                                  marginLeft: 20,
                                }}
                              >
                                “Are you sure, you want to delete the multiple
                                record?”
                              </label>
                            </div>
                            <div className="modal-footer">
                              <button
                                disabled={isDisable === true}
                                type="button"
                                className="btn btn-danger"
                                data-bs-dismiss="modal"
                                onClick={(event) => deleteAllRecord(event)}
                              >
                                {FILTER_LABEL.YES}
                              </button>
                              <button
                                type="button"
                                className="btn btn-primary"
                                data-bs-dismiss="modal"
                              >
                                {FILTER_LABEL.NO}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {multipleResend && (
              <div className="p-2 col-auto">
                <div className="col-sm-auto">
                  <div className="row align-items-center">
                    <div className="col">
                      <button
                        type="button"
                        className="btn btn-success"
                        data-bs-toggle="modal"
                        data-bs-target="#resendMulti"
                        onClick={(event) => event.preventDefault()}
                      >
                        {FILTER_LABEL.RESEND}{" "}
                        <span className="py-1 px-2 text-bg-light rounded-circle fw-semibold">
                          {multipleSelect}
                        </span>
                      </button>
                      <div
                        className="modal fade"
                        id="resendMulti"
                        tabIndex="-1"
                        role="dialog"
                        aria-labelledby="exampleModalCenterTitle"
                        aria-hidden="true"
                      >
                        <div
                          className="modal-dialog modal-dialog-centered"
                          role="document"
                        >
                          <div className="modal-content">
                            <div className="modal-header">
                              <h1
                                className="modal-title"
                                id="exampleModalLongTitle"
                              >
                                {FILTER_LABEL.RESEND}
                              </h1>

                              <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                              ></button>
                            </div>
                            {/* <div className="modal-body">
                              <label
                                style={{
                                  fontSize: 18,
                                  marginLeft: 20,
                                }}
                              >
                                {RESEND_EMAIL} <span className="text-dark fw-semibold">{multipleSelect}</span> email ?
                              </label>
                            </div> */}
                            <div className="modal-body">
                              <div className="card-body">
                                <label
                                  className="mb-4"
                                  style={{
                                    fontSize: 18,
                                  }}
                                >
                                  {RESEND_EMAIL}{" "}
                                  <span className="text-dark fw-semibold">
                                    {multipleSelect}
                                  </span>{" "}
                                  email ?
                                </label>

                                <div className="d-flex align-items-center">
                                  <label
                                    htmlFor="statusLabel"
                                    className="col-sm-3 col-form-label form-label"
                                    style={{ fontSize: 17 }}
                                  >
                                    {TEMPLATE}
                                  </label>
                                  <select
                                    className="form-select"
                                    autoComplete="off"
                                    name="emailType"
                                    onChange={(e) =>
                                      setTemplateFormValue(e.target.value)
                                    }
                                    value={templateFormValue}
                                  >
                                    {Config.EMAIL_TYPE.map((item, index) => (
                                      <option value={item.value} key={index}>
                                        {" "}
                                        {item.name}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              </div>
                            </div>
                            <div className="modal-footer">
                              <button
                                disabled={isDisable === true}
                                type="button"
                                className="btn btn-danger"
                                data-bs-dismiss="modal"
                                onClick={(event) => handleMultiResend(event)}
                              >
                                {FILTER_LABEL.YES}
                              </button>
                              <button
                                type="button"
                                className="btn btn-primary"
                                data-bs-dismiss="modal"
                              >
                                {FILTER_LABEL.NO}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {multipleStatus && (
              <div className="p-2 col-auto">
                <div className="col-sm-auto">
                  <div className="row align-items-center">
                    <div className="col">
                      <button
                        type="button"
                        className="btn btn-success"
                        data-bs-toggle="modal"
                        data-bs-target="#statusall"
                        onClick={(event) => handleStatus(event)}
                      >
                        {FILTER_LABEL.STATUS}
                      </button>

                      <div
                        className="modal fade"
                        id="statusall"
                        tabIndex="-1"
                        role="dialog"
                        aria-labelledby="exampleModalCenterTitle"
                        aria-hidden="true"
                      >
                        <div
                          className="modal-dialog modal-dialog-centered"
                          role="document"
                        >
                          <div className="modal-content">
                            <div className="modal-header">
                              <h5
                                className="modal-title"
                                id="exampleModalLongTitle"
                              >
                                {FILTER_LABEL.STATUS}
                              </h5>

                              <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                              ></button>
                            </div>
                            <div className="modal-body">
                              <div className="modal-content">
                                <table>
                                  <tbody>
                                    <tr>
                                      <td>
                                        {" "}
                                        <label className="row-heading">
                                          {FILTER_LABEL.STATUS} :
                                        </label>
                                      </td>
                                      <td>
                                        {" "}
                                        <select
                                          className="form-select form-select-sm "
                                          style={{ width: "70%" }}
                                          onChange={changeMultipleStatusValue}
                                        >
                                          {multipleStatusList?.map(
                                            (item, index) => (
                                              <option
                                                value={item.value}
                                                key={index}
                                              >
                                                {item.name}
                                              </option>
                                            )
                                          )}
                                        </select>
                                      </td>
                                    </tr>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <tr>
                                      {" "}
                                      <td>
                                        {" "}
                                        <label className="row-heading">
                                          Description :
                                        </label>
                                      </td>
                                      <td>
                                        <textarea
                                          className="form-control is-valid"
                                          placeholder="Description"
                                          id="validationValidTextarea1"
                                          rows="4"
                                          onChange={(e) =>
                                            changeMultipleInputStatus(e)
                                          }
                                        ></textarea>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                            <div className="modal-footer">
                              <button
                                type="button"
                                className="btn btn-white"
                                data-bs-dismiss="modal"
                              >
                                Close
                              </button>
                              <button
                                disabled={isDisable === true}
                                type="button"
                                className="btn btn-primary"
                                data-bs-dismiss="modal"
                                onClick={(event) =>
                                  changeMultipleStatus(event)
                                }
                              >
                                Save changes
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default Filter;
