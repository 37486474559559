import React from "react";
import moment from "moment";
import { TextField, Autocomplete } from "@mui/material";
import Config from "../../config";
import { productivity } from "../../constant/Productivity";

function Edit({
  fullReport,
  changeclient,
  clientName,
  getclient,
  settaskId,
  taskId,
  gettask,
  settrackingTool,
  trackingTool,
  TrackingTools,
  sethourstype,
  hourstype,
  setestimatedhours,
  estimatedhours,
  setactualhours,
  actualhours,
  setstatus,
  status,
  setnote,
  note,
  isDisable,
  changeStatus,
  updateId,
}) {
  return (
    <div
      className="modal fade"
      id="exampleModalLong"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h2 className="modal-title" id="exampleModalLongTitle">
              {productivity.EDIT_PRODUCTIVITY}
            </h2>

            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <div className="modal-content">
              <table>
                <tbody>
                  {fullReport.map((item, index) => {
                    return (
                      <>
                        <tr>
                          <td>
                            <h4>{productivity.NAME}</h4>
                            {item.empid.employeeName}
                          </td>
                        </tr>
                        <br></br>
                        <tr>
                          <td>
                            <h4>{productivity.DATE}</h4>
                            {moment(item.date).format("DD-MM-YYYY")}
                          </td>
                        </tr>
                        <br></br>
                        <tr>
                          <td>
                            <h4>{productivity.CLIENT_NAME}</h4>
                            <Autocomplete
                              options={getclient || []}
                              getOptionLabel={(option) =>
                                option.clientName || ""
                              }
                              value={
                                getclient.find(
                                  (item) => item._id === clientName
                                ) || null
                              }
                              onChange={(event, newValue) => {
                                changeclient(newValue ? newValue._id : "");
                              }}
                              size="small"
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="outlined"
                                  autoComplete="off"
                                />
                              )}
                            />
                          </td>
                        </tr>
                        <br></br>
                        <tr>
                          <td>
                            <Autocomplete
                              options={gettask || []}
                              style={{ marginTop: "10px" }}
                              getOptionLabel={(option) => option.summary || ""}
                              value={
                                gettask.find((item) => item._id === taskId) ||
                                null
                              }
                              onChange={(event, newValue) => {
                                settaskId(newValue ? newValue._id : "");
                              }}
                              size="small"
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Select Task"
                                  variant="outlined"
                                  autoComplete="off"
                                />
                              )}
                            />
                          </td>
                        </tr>
                        <br></br>
                        <tr>
                          <td>
                            <h4>{productivity.TRAKING_TOOLS}</h4>
                            <Autocomplete
                              options={TrackingTools || []}
                              getOptionLabel={(option) =>
                                option.trackingToolName || ""
                              }
                              value={
                                TrackingTools.find(
                                  (item) => item._id === trackingTool
                                ) || {
                                  trackingToolName: "Select tracking tool",
                                }
                              }
                              onChange={(event, newValue) => {
                                settrackingTool(newValue ? newValue._id : "");
                              }}
                              size="small"
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="outlined"
                                  autoComplete="off"
                                />
                              )}
                            />
                          </td>
                        </tr>
                        <br></br>
                        <tr>
                          <td>
                            <h4>{productivity.TYPE_OF_HOURS}</h4>
                            <Autocomplete
                              options={Config.PRODDUCTIVITY_HOURS_TYPE}
                              getOptionLabel={(option) => option.name}
                              value={
                                Config.PRODDUCTIVITY_HOURS_TYPE.find(
                                  (item) => item.value === hourstype
                                ) || { name: "Select hours type" }
                              }
                              onChange={(event, newValue) => {
                                sethourstype(newValue ? newValue.value : "");
                              }}
                              size="small"
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="outlined"
                                  autoComplete="off"
                                />
                              )}
                            />
                          </td>
                        </tr>
                        <br></br>
                        <tr>
                          <td>
                            <h4>{productivity.ESTIMATED_HOURS}</h4>
                            <input
                              type="text"
                              className="form-control"
                              name="acthours"
                              id="firstNameLabel"
                              placeholder="acthours"
                              onChange={(event) =>
                                setestimatedhours(event.target.value)
                              }
                              value={estimatedhours}
                            />
                          </td>
                        </tr>
                        <br></br>
                        <tr>
                          <td>
                            <h4>{productivity.ACTUAL_HOURS}</h4>
                            <input
                              type="text"
                              className="form-control"
                              name="acthours"
                              id="firstNameLabel"
                              placeholder="acthours"
                              onChange={(event) =>
                                setactualhours(event.target.value)
                              }
                              value={actualhours}
                            />
                          </td>
                        </tr>
                        <br></br>
                        <tr>
                          <td>
                            <h4>{productivity.STATUS}</h4>
                            <Autocomplete
                              options={Config.PRODDUCTIVITY_STATUS}
                              getOptionLabel={(option) => option}
                              value={
                                Config.PRODDUCTIVITY_STATUS.find(
                                  (item) => item === status
                                ) || "Select status"
                              }
                              onChange={(event, newValue) => {
                                setstatus(newValue);
                              }}
                              size="small"
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="outlined"
                                  autoComplete="off"
                                />
                              )}
                            />
                          </td>
                        </tr>{" "}
                        <br></br>
                        <tr>
                          <td>
                            <h4>{productivity.NOTES}</h4>
                            <textarea
                              className="form-control"
                              placeholder="Notes"
                              id="validationValidTextarea1"
                              rows="2"
                              name="note"
                              onChange={(event) => setnote(event.target.value)}
                              value={note}
                            ></textarea>
                          </td>
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-white"
              data-bs-dismiss="modal"
            >
              {productivity.CLOSE}
            </button>
            <button
              disabled={isDisable === true}
              type="button"
              className="btn btn-primary"
              data-bs-dismiss="modal"
              onClick={() => {
                changeStatus(updateId);
              }}
            >
              {productivity.SAVE_CHANGES}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Edit;
