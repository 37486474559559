import React, { useState } from "react";
import AWS from "aws-sdk";
import { Autocomplete, CircularProgress, TextField } from "@mui/material";
import Config from "../../config";
import HeaderNavbarHOC from "../../HOC/HeaderNavbar";
import AlertBox from "../../Alert";
import Useuser from "../../Contexct";
import { trackEvent, EVENT_NAME } from "../../constant/MixpanelConstant";
import * as AxiosActions from "../../Actions/axiosActions";
import { jobs } from "../../constant/Jobs";
import BackgroundImage from "../../components/Common/CardImage";

function JobAdd() {
  const [status, setstatus] = useState("Select Status");
  const [errMsg, setErrMsg] = useState({
    jobsid: "",
    image: "",
    jobstitle: "",
    jobtype: "",
    Experience: "",
    Location: "",
    Time: "",
    Duties: "",
    Languages: "",
    Requirments: "",
    Benefits: "",
    status: "",
  });
  const { adminData } = Useuser();
  const [openAlert, setOpenAlert] = useState(false);
  const [Languages, setLanguages] = useState([{ Languages: "" }]);
  const [Requirments, setRequirments] = useState([{ Requirments: "" }]);
  const [Benefits, setBenefits] = useState([{ Benefits: "" }]);
  const [Role, setRole] = useState([{ Role: "" }]);
  const [isDisable, setIsDisable] = useState(false);
  const [isFileUpload, setIsFileUpload] = useState(false);
  const [fileUploadLoading, setFileUploadLoading] = useState(false);
  const [fileUrl, setFileurl] = useState(false);
  let tempUrl = [];
  const S3_BUCKET = "3elixir";

  AWS.config.update({
    accessKeyId: "AKIA5DBQO6R4ZQBBKMX5",
    secretAccessKey: "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",
  });

  //File Upload
  const fileUpload = (e) => {
    let tempUpload = [];
    let images = e.target.files;
    let allowedExtensions = ["jpg", "png", "jpeg"];
    for (let i = 0; i < images.length; i++) {
      setIsFileUpload(false);
      let extension = images[i].name
        .substr(images[i].name.lastIndexOf(".") + 1)
        .toLowerCase();
      if (allowedExtensions.indexOf(extension) !== -1) {
        setFileUploadLoading(true);
        tempUpload.push(images[i]);
        setIsFileUpload(tempUpload);
        const s3 = new AWS.S3();
        let fileName = images[i].name;
        const uploadParams = {
          ACL: "public-read",
          Bucket: S3_BUCKET,
          Key: fileName,
          Body: images[i],
        };
        s3.upload(uploadParams, (err, data) => {
          if (err) {
            console.log("Error uploading file:", err);
          } else {
            const completeurl =
              "https://3elixir.s3.ap-south-1.amazonaws.com/" + data.Key;
            console.log("File uploaded successfully:", completeurl);
            tempUrl.push(completeurl);
            setTimeout(async () => {
              await setFileurl(tempUrl);
              await setIsFileUpload(true);
              await setFileUploadLoading(false);
            }, 2000);
          }
        });
      } else {
        console.log("Please upload jpg, png, jpeg file only.");
      }
    }
  };
  let handleChangeLanguages = (i, e) => {
    let newLanguagesValues = [...Languages];
    newLanguagesValues[i][e.target.name] = e.target.value;
    setLanguages(newLanguagesValues);
  };

  let addLanguagesFields = () => {
    setLanguages([...Languages, { Languages: "" }]);
  };

  let removeLanguagesFields = (i) => {
    console.log("index DELETE", i);
    let newLanguagesValues = [...Languages];
    newLanguagesValues.splice(i, 1);
    setLanguages(newLanguagesValues);
  };

  let handleChangeRequirments = (i, e) => {
    let newRequirmentsValues = [...Requirments];
    newRequirmentsValues[i][e.target.name] = e.target.value;
    setRequirments(newRequirmentsValues);
  };

  let addRequirmentsFields = () => {
    setRequirments([...Requirments, { Requirments: "" }]);
  };

  let removeRequirmentsFields = (i) => {
    let newRequirmentsValues = [...Requirments];
    newRequirmentsValues.splice(i, 1);
    setRequirments(newRequirmentsValues);
  };

  let handleChangeBenefits = (i, e) => {
    let newBenefitsValues = [...Benefits];
    newBenefitsValues[i][e.target.name] = e.target.value;
    setBenefits(newBenefitsValues);
  };

  let addBenefitsFields = () => {
    setBenefits([...Benefits, { Benefits: "" }]);
  };

  let removeBenefitsFields = (i) => {
    let newBenefitsValues = [...Benefits];
    newBenefitsValues.splice(i, 1);
    setBenefits(newBenefitsValues);
  };

  let handleChangeRole = (i, e) => {
    let newRoleValues = [...Role];
    newRoleValues[i][e.target.name] = e.target.value;
    setRole(newRoleValues);
  };

  let addRoleFields = () => {
    setRole([...Role, { Role: "" }]);
  };

  let removeRoleFields = (i) => {
    let newRoleValues = [...Role];
    newRoleValues.splice(i, 1);
    setRole(newRoleValues);
  };

  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setFileurl(false);
    setIsFileUpload(false);
    setOpenAlert(false);
    setFormvalue(initialvalue);
    setstatus("Select Status");
    setLanguages([{ Languages: "" }]);
    setRequirments([{ Requirments: "" }]);
    setBenefits([{ Benefits: "" }]);
    setRole([{ Role: "" }]);
  };
  // Handle Input Value

  const initialvalue = {
    jobsid: "",
    image: "",
    jobstitle: "",
    jobtype: "",
    Experience: "",
    Location: "",
    Time: "",
    Duties: "",
  };
  const [formvalue, setFormvalue] = useState(initialvalue);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormvalue({ ...formvalue, [name]: value });
  };

  // Handle Submit

  function handleSubmit(e) {
    trackEvent(EVENT_NAME.JOB_ADD, { data: formvalue.jobsid });
    e.preventDefault();
    let errObject = {
      jobsid: "",
      image: "",
      jobstitle: "",
      jobtype: "",
      Experience: "",
      Location: "",
      Time: "",
      Duties: "",
      Languages: "",
      Requirments: "",
      Benefits: "",
      status: "",
    };
    let err = 0;

    // VALIDATION

    if (formvalue.jobsid === "") {
      err++;
      errObject.jobsid = "Please Enter Valid Jobs id";
    }
    if (formvalue.jobstitle === "") {
      err++;
      errObject.jobstitle = "Please Enter Valid Jobs title";
    }
    if (formvalue.jobtype === "") {
      err++;
      errObject.jobtype = "Please Enter Valid Jobs type";
    }
    if (formvalue.Experience === "") {
      err++;
      errObject.Experience = "Please Enter Valid Experience";
    }
    if (formvalue.Location === "") {
      err++;
      errObject.Location = "Please Enter Valid Location";
    }
    if (formvalue.Time === "") {
      err++;
      errObject.Time = "Please Enter Valid Time";
    }
    if (formvalue.Duties === "") {
      err++;
      errObject.Duties = "Please Enter Valid Duties";
    }

    if (status === "Select Status") {
      err++;
      errObject.status = "Please Enter Valid Status";
    }
    if (err !== 0) {
      console.log("has error", err, errObject);
      setErrMsg(errObject);
    } else {
      setIsDisable(true);
      AxiosActions.JobAdd({
        jobid: formvalue.jobsid,
        image: fileUrl,
        job_title: formvalue.jobstitle,
        job_type: formvalue.jobtype,
        experience: formvalue.Experience,
        location: formvalue.Location,
        time: formvalue.Time,
        Duties: formvalue.Duties,
        status: status === "inactive" ? false : true,
        language: Languages,
        requirements: Requirments,
        role: Role,
        benefits: Benefits,
        adminId: adminData._id,
      }).catch((error) => {
        setIsDisable(false);
        console.log("Post data error", error);
      });
    }
  }
  return (
    <>
      <AlertBox
        open={openAlert}
        onClose={handleCloseAlert}
        message="Data Add SuccessFully"
        title="Success"
        ok={false}
      />

      <main id="content" role="main" className="main">
        <BackgroundImage/>
        <div
          className="content container-fluid"
          style={{ justifyItems: "center", maxWidth: "60rem" }}
        >
          <div className="page-header">
            <div className="row align-items-end">
              <div className="col-sm mb-2 mb-sm-0"></div>
            </div>
          </div>
          {/* Employee Form  */}

          <div className="card w-70">
            <div className="card-header">
              <h1 className="card-title h1">{jobs.ADD_A_JOBS_INFORMATION}</h1>
            </div>
            <div className="card-body">
              <div className="row mb-4">
                <label
                  className="col-sm-3 col-form-label form-label"
                  style={{ fontSize: 16 }}
                >
                  {jobs.JOBS_ID}
                </label>
                <div className="col-sm-9">
                  <input
                    type="text"
                    className="form-control"
                    name="jobsid"
                    onChange={handleChange}
                    value={formvalue.jobsid}
                    id="firstNameLabel"
                    placeholder="Jobs Id"
                    aria-label="Your first name"
                  />
                  {errMsg.jobsid !== "" ? (
                    <label
                      className=" form-label"
                      style={{ fontSize: 15, color: "red" }}
                    >
                      {errMsg.jobsid}
                    </label>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="row mb-4">
                <label
                  className="col-sm-3 col-form-label form-label"
                  style={{ fontSize: 16 }}
                >
                  {jobs.IMAGE}
                </label>
                <div className="col-sm-9">
                  <input
                    type="file"
                    className="form-control"
                    name="image"
                    onChange={(e) => fileUpload(e)}
                    // value={formvalue.image}
                    id="file"
                  />
                  {fileUploadLoading === true && (
                    <CircularProgress
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignSelf: "center",
                        flexDirection: "row",
                        width: "100%",
                        marginTop: 10,
                      }}
                    />
                  )}
                  {isFileUpload === true &&
                    fileUrl.map((item) => {
                      return (
                        <img
                          style={{ marginTop: 10 }}
                          width="200"
                          height="200"
                          src={item}
                          alt={formvalue.jobstitle}
                        />
                      );
                    })}
                </div>
              </div>
              <div className="row mb-4">
                <label
                  className="col-sm-3 col-form-label form-label"
                  style={{ fontSize: 16 }}
                >
                  {jobs.JOB_TITLE}
                </label>
                <div className="col-sm-9">
                  <input
                    type="text"
                    className="form-control"
                    name="jobstitle"
                    onChange={handleChange}
                    value={formvalue.jobstitle}
                    id="firstNameLabel"
                    placeholder="Job Title"
                    aria-label="Your email"
                  />
                  {errMsg.jobstitle !== "" ? (
                    <label
                      className=" form-label"
                      style={{ fontSize: 15, color: "red" }}
                    >
                      {errMsg.jobstitle}
                    </label>
                  ) : (
                    ""
                  )}
                </div>
              </div>

              <div className="row mb-4">
                <label
                  className="col-sm-3 col-form-label form-label"
                  style={{ fontSize: 16 }}
                >
                  {jobs.JOB_TYPE}
                </label>
                <div className="col-sm-9">
                  <input
                    type="text"
                    className="form-control"
                    name="jobtype"
                    onChange={handleChange}
                    value={formvalue.jobtype}
                    id="firstNameLabel"
                    placeholder="Job Type"
                    aria-label="Your email"
                  />
                  {errMsg.jobtype !== "" ? (
                    <label
                      className=" form-label"
                      style={{ fontSize: 15, color: "red" }}
                    >
                      {errMsg.jobtype}
                    </label>
                  ) : (
                    ""
                  )}
                </div>
              </div>

              <div className="row mb-4">
                <label
                  className="col-sm-3 col-form-label form-label"
                  style={{ fontSize: 16 }}
                >
                  {jobs.EXPERIENCE}
                </label>
                <div className="col-sm-9">
                  <input
                    type="text"
                    className="form-control"
                    name="Experience"
                    onChange={handleChange}
                    value={formvalue.Experience}
                    id="firstNameLabel"
                    placeholder="Experience"
                    aria-label="Your email"
                  />
                  {errMsg.Experience !== "" ? (
                    <label
                      className=" form-label"
                      style={{ fontSize: 15, color: "red" }}
                    >
                      {errMsg.Experience}
                    </label>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="row mb-4">
                <label
                  className="col-sm-3 col-form-label form-label"
                  style={{ fontSize: 16 }}
                >
                  {jobs.LOCATION}
                </label>
                <div className="col-sm-9">
                  <input
                    type="text"
                    className="form-control"
                    name="Location"
                    onChange={handleChange}
                    value={formvalue.Location}
                    id="firstNameLabel"
                    placeholder="Location"
                    aria-label="Your email"
                  />
                  {errMsg.Location !== "" ? (
                    <label
                      className=" form-label"
                      style={{ fontSize: 15, color: "red" }}
                    >
                      {errMsg.Location}
                    </label>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="row mb-4">
                <label
                  className="col-sm-3 col-form-label form-label"
                  style={{ fontSize: 16 }}
                >
                  {jobs.TIME}
                </label>
                <div className="col-sm-9">
                  <input
                    type="text"
                    className="form-control"
                    name="Time"
                    onChange={handleChange}
                    value={formvalue.Time}
                    id="firstNameLabel"
                    placeholder="Time"
                    aria-label="Your email"
                  />
                  {errMsg.Time !== "" ? (
                    <label
                      className=" form-label"
                      style={{ fontSize: 15, color: "red" }}
                    >
                      {errMsg.Time}
                    </label>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="row mb-4">
                <label
                  className="col-sm-3 col-form-label form-label"
                  style={{ fontSize: 16 }}
                >
                  {jobs.DUTIES}
                </label>
                <div className="col-sm-9">
                  <input
                    type="text"
                    className="form-control"
                    name="Duties"
                    onChange={handleChange}
                    value={formvalue.Duties}
                    id="firstNameLabel"
                    placeholder="Duties"
                    aria-label="Your email"
                  />
                  {errMsg.Duties !== "" ? (
                    <label
                      className=" form-label"
                      style={{ fontSize: 15, color: "red" }}
                    >
                      {errMsg.Duties}
                    </label>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="row mb-4">
                <label
                  className="col-sm-3 col-form-label form-label"
                  style={{ fontSize: 16 }}
                >
                  {jobs.STATUS}
                </label>
                <div className="col-sm-9">
                  <Autocomplete
                    options={Config.EMPLOYEE_STATUS}
                    getOptionLabel={(option) => option.name}
                    value={
                      Config.EMPLOYEE_STATUS.find(
                        (item) => item.name === status
                      ) || null
                    }
                    onChange={
                      (newValue) =>{ setstatus(newValue.target.textContent)
                      }
                      
                    }
                    size="small"
                    renderInput={(params) => (
                      <TextField {...params} variant="outlined" autoComplete="off" />
                    )}
                  />      
                  {errMsg.status !== "" ? (
                    <label
                      className=" form-label"
                      style={{ fontSize: 15, color: "red" }}
                    >
                      {errMsg.status}
                    </label>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="row mb-2">
                <label
                  className="col-sm-3 col-form-label form-label"
                  style={{ fontSize: 16 }}
                >
                  {jobs.LANGUAGE}
                </label>
                <div className="col-sm-9 multiple-input1">
                  {Languages.map((element, index) => (
                    <>
                        {index === 1 ? (
                          <button
                            type="button"
                            size="small"
                            className="btn btn-danger btn-icon"
                            onClick={() => removeLanguagesFields(0)}
                          >
                            <i className="bi bi-dash-circle"></i>
                          </button>
                        ) : null}

                      <input
                        key={index}
                        type="text"
                        className="form-control job-input"
                        name="Languages"
                        onChange={(e) => handleChangeLanguages(index, e)}
                        value={element.Languages}
                        id="firstNameLabel"
                        placeholder="Languages"
                        aria-label="Your email"
                      />
                      {index ? (
                          <button
                            type="button"
                            size="small"
                            className="btn btn-danger btn-icon"
                            onClick={() => removeLanguagesFields(0)}
                          >
                            <i className="bi bi-dash-circle"></i>
                          </button>
                        ) : null}
                    
                    </>
                  ))}
                     <button
                         type="button"
                         className="btn btn-info btn-icon btn-add"
                         onClick={() => addLanguagesFields()}
                       >
                         <i className="bi bi-plus-square"></i>
                       </button>
                </div>
              </div>
              <div className="row mb-2">
                <label
                  className="col-sm-3 col-form-label form-label"
                  style={{ fontSize: 16 }}
                >
                  {jobs.REQUIREMENTS}
                </label>
                <div className="col-sm-9 multiple-input2">
                  {Requirments.map((element, index) => (
                    <>
                        {index === 1 ? (
                          <button
                            type="button"
                            size="small"
                            className="btn btn-danger btn-icon"
                            onClick={() => removeRequirmentsFields(0)}
                          >
                            <i className="bi bi-dash-circle"></i>
                          </button>
                        ) : null}
                      <textarea
                        key={index}
                        className="form-control job-input"
                        placeholder="Requirments"
                        id="validationValidTextarea1"
                        rows="2"
                        name="Requirments"
                        onChange={(e) => handleChangeRequirments(index, e)}
                        value={element.Requirments}
                      ></textarea>
                        {index ? (
                          <button
                            type="button"
                            size="small"
                            className="btn btn-danger btn-icon"
                            onClick={() => removeRequirmentsFields(index)}
                          >
                            <i className="bi bi-dash-circle"></i>
                          </button>
                        ) : null}
                    </>
                  ))}
                  <button
                  type="button"
                  className="btn btn-info btn-icon btn-add"
                  onClick={() => addRequirmentsFields()}
                >
                  <i className="bi bi-plus-square"></i>
                </button>
                </div>
              </div>
              <div className="row mb-4">
                <label
                  className="col-sm-3 col-form-label form-label"
                  style={{ fontSize: 16 }}
                >
                  Benefits
                </label>
                <div className="col-sm-9 multiple-input2">
                  {Benefits.map((element, index) => (
                    <>
                        {index === 1 ? (
                          <button
                            type="button"
                            size="small"
                            className="btn btn-danger btn-icon"
                            onClick={() => removeBenefitsFields(0)}
                          >
                            <i className="bi bi-dash-circle"></i>
                          </button>
                        ) : null}
                      <textarea
                        className="form-control job-input"
                        placeholder="Benefits"
                        id="validationValidTextarea1"
                        rows="2"
                        name="Benefits"
                        onChange={(e) => handleChangeBenefits(index, e)}
                        value={element.Benefits}
                      ></textarea>
                        {index ? (
                          <button
                            type="button"
                            size="small"
                            className="btn btn-danger btn-icon"
                            onClick={() => removeBenefitsFields(index)}
                          >
                            <i className="bi bi-dash-circle"></i>
                          </button>
                        ) : null}
                    </>
                  ))}
                  <button
                  type="button"
                  className="btn btn-info btn-icon btn-add"
                  onClick={() => addBenefitsFields()}
                >
                  <i className="bi bi-plus-square"></i>
                </button>
                </div>
              </div>
              <div className="row mb-4">
                <label
                  className="col-sm-3 col-form-label form-label"
                  style={{ fontSize: 16 }}
                >
                  {jobs.ROLE}
                </label>
                <div className="col-sm-9 multiple-input2">
                  {Role.map((element, index) => (
                    <>
                    {index === 1 ? (
                          <button
                            type="button"
                            size="small"
                            className="btn btn-danger btn-icon"
                            onClick={() => removeRoleFields(0)}
                          >
                            <i className="bi bi-dash-circle"></i>
                          </button>
                        ) : null}
                      <textarea
                        className="form-control job-input"
                        placeholder="Role"
                        id="validationValidTextarea1"
                        rows="2"
                        name="Role"
                        onChange={(e) => handleChangeRole(index, e)}
                        value={element.Role}
                      ></textarea>
                        {index ? (
                          <button
                            type="button"
                            size="small"
                            className="btn btn-danger btn-icon"
                            onClick={() => removeRoleFields(index)}
                          >
                            <i className="bi bi-dash-circle"></i>
                          </button>
                        ) : null}
                    </>
                  ))}
                  <button
                  type="button"
                  className="btn btn-info btn-icon btn-add"
                  onClick={() => addRoleFields()}
                >
                  <i className="bi bi-plus-square"></i>
                </button>
                </div>
              </div>
              <div className="d-flex justify-content-end">
                <button
                  disabled={isDisable === true}
                  type="button"
                  className="btn btn-warning"
                  onClick={handleSubmit}
                >
                  {jobs.UPLOAD}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="footer">
          <div className="row justify-content-between align-items-center">
            <div className="col"></div>
            <div className="col-auto">
              <div className="d-flex justify-content-end"></div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default HeaderNavbarHOC(JobAdd);
