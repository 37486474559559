import React from "react";
import { PHONE, TEMPLATEPARAMETERS, TEMPLATETYPE,
  VIEWWHATSAPPMARKETING, WHATSAPPINFO } from "../../constant/Label";

function View({ fullReport }) {
  return (
    <div
      className="modal fade bd-example-modal-xl"
      id="viewpage"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title" id="exampleModalLongTitle">
              {VIEWWHATSAPPMARKETING}
            </h1>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            {fullReport?.map((item, index) => {
              return (
                <div key={index}>
                  <h3 style={{ color: "red" }}>{WHATSAPPINFO}</h3>
                  <table className="table table-align-middle">
                    <thead>
                      <tr>
                        <th scope="col"></th>
                        <th scope="col"></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <h4>{PHONE}</h4>
                          {item?.phoneNumber || "-"}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <h4>{TEMPLATETYPE}</h4>
                          {item?.messageType || "-"}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <h4>{TEMPLATEPARAMETERS}</h4>
                          {item?.templateParameters?.map((param, index) => (
                            <div key={index}>{param?.text || "-"}</div>
                          ))}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              );
            })}
          </div>
          <div className="modal-footer"></div>
        </div>
      </div>
    </div>
  );
}

export default View;
