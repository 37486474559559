import React from 'react';
import ReactDOM from 'react-dom/client';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './index.css';
import App from './route';
import reportWebVitals from './reportWebVitals';
import mixpanel from 'mixpanel-browser';
import { Auth0Provider } from '@auth0/auth0-react';

if (window.location.hostname !== 'localhost') {
  mixpanel.init('a769f48df9b308c6751f11a6791d7e72', { debug: true });
} else {
  // after testing is done, put any wrong key here
  mixpanel.init('a769f48df9b308c6751f11a6791d7e72', { debug: true });
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Auth0Provider
      domain="dev-jmk8xg02lxrn4mkb.us.auth0.com"
      clientId="Nh6qozRmRzTuTdAoZKR0JSeUkRSBYW5c"
      authorizationParams={{
        redirect_uri: window.location.origin + '/dashboard'
      }}
    >
      <App />
      <ToastContainer />
    </Auth0Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
