import React, { useState, useContext } from "react";
import { Autocomplete, TextField } from "@mui/material";
import HeaderNavbarHOC from "../../HOC/HeaderNavbar";
import Config from "../../config";
import { AuthContext } from "../../Login/Protected";
import AlertBox from "../../Alert";
import { trackEvent, EVENT_NAME } from "../../constant/MixpanelConstant";
import * as AxiosActions from "../../Actions/axiosActions";
import moment from "moment";
import Datepicker from "../../Datepicker";
import BackgroundImage from "../../components/Common/CardImage";
import { DATAADDEDSUCCESSFULLY } from "../../constant/Label";

function ClientAdd() {
  const [openAlert, setOpenAlert] = useState(false);
  const adminData = useContext(AuthContext);
  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
    setStatus("Select status");
    setFormvalue(initialvalue);
  };

  const [date, setDate] = useState();
  const [status, setStatus] = useState("Select status");
  const [errMsg, setErrMsg] = useState({
    date: "",
    clientName: "",
    status: "",
  });

  const initialvalue = {
    date: "",
    clientName: "",
  };

  const [formvalue, setFormvalue] = useState(initialvalue);
  const [isDisable, setIsDisable] = useState(false);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormvalue({ ...formvalue, [name]: value });
  };

  // Hahdle submit

  function handleSubmit(e) {
    trackEvent(EVENT_NAME.CLIENT_ADD, { data: "" });
    e.preventDefault();
    let errObject = {
      clientName: "",
      status: "",
    };
    let err = 0;

    if (status === "Select status") {
      err++;
      errObject.status = "Select status";
    }

    if (formvalue.clientName === "") {
      err++;
      errObject.clientName = "Please enter valid client name";
    }

    if (err !== 0) {
      console.log("has error", err, errObject);
      setErrMsg(errObject);
    } else {
      setIsDisable(true);
      AxiosActions.ClientAdd({
        clientName: formvalue.clientName,
        status: status === "true" ? true : false,
        date: date,
      })
        .then((response) => {
          if (response.status === 200) {
            if (response.data.success === true) {
              setIsDisable(false);
              setOpenAlert(true);
            }
          }
        })
        .catch((error) => {
          setIsDisable(false);
          console.log("client Add Error", error);
        });
    }
  }

  return (
    <>
      {" "}
      <AlertBox
        open={openAlert}
        onClose={handleCloseAlert}
        message={DATAADDEDSUCCESSFULLY}
        title="Success"
        ok={false}
      />
      <main id="content" role="main" className="main">
        <BackgroundImage/>
        <div
          className="content container-fluid"
          style={{ justifyItems: "center", maxWidth: "60rem" }}
        >
          <div className="page-header">
            <div className="row align-items-end">
              <div className="col-sm mb-2 mb-sm-0"></div>
            </div>
          </div>

          <div className="card">
            <div className="card-header">
              <h1 className="card-title h1">Add client information</h1>
            </div>

            <div className="card-body">
              <div className="row mb-4">
                <label
                  htmlFor="emailLabel"
                  className="col-sm-3 col-form-label form-label"
                  style={{ fontSize: 17 }}
                >
                  Client name
                </label>
                <div className="col-sm-9">
                  <input
                    type="text"
                    className="form-control"
                    name="clientName"
                    onChange={handleChange}
                    id="emailLabel"
                    placeholder="Client name"
                    aria-label="Email"
                    value={formvalue.clientName}
                  />{" "}
                  {errMsg.clientName !== "" ? (
                    <label
                      className=" form-label"
                      style={{ fontSize: 15, color: "red" }}
                    >
                      {errMsg.clientName}
                    </label>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="row mb-4">
                <label
                  htmlFor="emailLabel"
                  className="col-sm-3 col-form-label form-label"
                  style={{ fontSize: 17 }}
                >
                  Date
                </label>
                <div className="col-sm-9">
                  <Datepicker
                    onChange={(newDate) => {
                      setDate(newDate);
                    }}
                    value={moment(date).format("YYYY-MM-DD")}
                  />
                  {errMsg.date !== "" ? (
                    <label
                      className=" form-label"
                      style={{ fontSize: 15, color: "red" }}
                    >
                      {errMsg.date}
                    </label>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="row mb-4">
                <label
                  htmlFor="emailLabel"
                  className="col-sm-3 col-form-label form-label"
                  style={{ fontSize: 17 }}
                >
                  Status
                </label>
                <div className="col-sm-9">
                  <Autocomplete
                    options={Config.CLIENT_STATUS}
                    getOptionLabel={(option) => option.name}
                    value={Config.CLIENT_STATUS.find(
                      (option) => option.value === status
                    ) || { name: "Select status" }
                     }
                    size="small"
                    renderInput={(params) => 
                    <TextField {...params}
                    />}
                    onChange={(event, newValue) => {
                      setStatus(
                        newValue === null ? "Select status" : newValue.value
                      );
                    }}
                    />
                  {errMsg.status !== "" ? (
                    <label
                      className=" form-label"
                      style={{ fontSize: 15, color: "red" }}
                    >
                      {errMsg.status}
                    </label>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="d-flex justify-content-end">
                <button
                  disabled={isDisable === true}
                  type="button"
                  className="btn btn-warning"
                  onClick={handleSubmit}
                >
                  Save changes
                </button>
              </div>
            </div>
          </div>
          <div></div>
          <br></br>
          <br></br>
        </div>
        <div className="footer">
          <div className="row justify-content-between align-items-center">
            <div className="col"></div>
            <div className="col-auto">
              <div className="d-flex justify-content-end"></div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default HeaderNavbarHOC(ClientAdd);
