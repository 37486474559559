import React from "react";
import { Typography, TextField } from "@mui/material";
import PropTypes from "prop-types";

function ModalContent({ selectedDate, selectedDateDetails, inTime , outTime }) {
  return (
    <div style={{ textAlign: "center", padding: 10 }}>
      <Typography variant="h6">Details for {selectedDate}</Typography>
      {selectedDateDetails ? (
        <div>
          <TextField
            label="Date"
            fullWidth
            value={selectedDateDetails.date}
            InputProps={{
              readOnly: true,
            }}
            margin="normal"
          />
          <TextField
            label="In Time"
            fullWidth
            value={inTime}
            InputProps={{
              readOnly: true,
            }}
            margin="normal"
          />
          <TextField
            label="Out Time"
            fullWidth
            value={outTime}
            InputProps={{
              readOnly: true,
            }}
            margin="normal"
          />
          <TextField
            label="Total Hours"
            fullWidth
            value={`${selectedDateDetails.totalHours.toFixed(2)} hour`}
            InputProps={{
              readOnly: true,
            }}
            margin="normal"
          />
        </div>
      ) : (
        <Typography style={{ padding: 10 }}>No Attendance Found</Typography>
      )}
    </div>
  );
}

export default ModalContent;

ModalContent.propTypes = {
  selectedDate: PropTypes.string.isRequired,
  selectedDateDetails: PropTypes.object,
};
