export const SAMPLE_CSV_HEADERS = [
  "source",
  "name",
  "email",
  "phone",
  "gender",
  "qualification",
  "experience",
  "job",
  "currentLocation",
  "city",
  "state",
  "currentCTC",
  "expectedCTC",
  "offeredCTC",
  "remarks",
  "status",
  "resume",
];

export const SAMPLE_CSV_DATA = [
  "LinkedIn",
  "John Doe",
  "johndoe@example.com",
  "1234567890",
  "Male",
  "Bachelor's Degree",
  "5 years",
  "Software Engineer",
  "Adajan",
  "Surat",
  "Gujarat",
  "0",
  "0",
  "0",
  "Strong candidate",
  "Interview Scheduled",
  "Resume_Link.docx",
];

export const CSV_TABLE_HEADERS = [
  "Source",
  "Name",
  "Email",
  "Mobile No",
  "Gender",
  "Qualification",
  "Experience",
  "Job",
  "Current Location",
  "City",
  "State",
  "Current CTC",
  "Expected CTC",
  "Offered CTC",
  "Remarks",
  "Status",
  "Resume",
];
