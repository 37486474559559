import React,{ useState, useEffect,useContext } from "react";
import HeaderNavbarHOC from "../HOC/HeaderNavbar";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import Config from "../config";
import { AuthContext } from "../Login/Protected";
function Home() {
  const { user, isAuthenticated, isLoading } = useAuth0();
  const [loading, setLoading] = useState(false);
  const [employeecount, setemployeecount] = useState("");
  const [productivity,setproductivity]=useState()
  const [usercount, setusercount] = useState("");
  const adminData = useContext(AuthContext);
  const token = localStorage.getItem("access_token");
  console.log(token);
  useEffect(() => {
    getemployeeCount();
    getuserCount();
  //  productivitycount();
  }, [adminData.adminData]);
  if (isLoading) {
    return <div>Loading ...</div>;
  }

  const getemployeeCount = () => {
    Axios.post(Config.APIURL + "employee/count")
      .then((response) => {
        console.log("getEmployeeList", response.data.data);
        if (response.status == 200) {
          setemployeecount(response.data.data);
        }
      })
      .catch((error) => {
        console.log("Error in get employee list", error);
      });
  };

  const getuserCount = () => {
    Axios.post(Config.APIURL + "user/count")
      .then((response) => {
        console.log("getEmployeeList", response.data.data);
        if (response.status == 200) {
          setusercount(response.data.data);
        }
      })
      .catch((error) => {
        console.log("Error in get employee list", error);
      });
  };
  // const productivitycount = () => {
  //   Axios.post(Config.APIURL + "employee/productivity/count",{empid:adminData.adminData.empid && adminData.adminData.empid._id})
    
  //     .then((response) => {
  //       console.log("productivitycount", response);
  //       if (response.status == 200) {
  //         setproductivity(response.data.productivityDataList[0]);
  //       }
  //     })
  //     .catch((error) => {
  //       console.log("Error in get employee list", error);
  //     });
  // };
 
  return (
    <>
      {loading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100vh"
        >
          <CircularProgress />
        </Box>
      ) : (
        ""
      )}
      {isAuthenticated === false ? (
        (window.location.href = "/")
      ) : (
        <main id="content" role="main" className="main">
          {/* Content */}
          <div className="content container-fluid">
            {/* Page Header */}
            <div className="page-header">
              <div className="row align-items-center">
                <div className="col">
                  <h1 className="page-header-title">Dashboard</h1>
                </div>
              </div>
              {/* End Row */}
            </div>
            {/* End Page Header */}
            {/* Stats */}
            <div className="row">
              {adminData.adminData.isAdmin ? (
                <>
                  {" "}
                  <div className="col-sm-6 col-lg-3 mb-3 mb-lg-5">
                    {/* Card */}
                    <a className="card card-hover-shadow h-100" href="#">
                      <div className="card-body">
                        <h3 className="card-subtitle">Total Employee</h3>
                        <div className="row align-items-center gx-2 mb-1">
                          <div className="col-6">
                            <h2 className="card-title text-inherit">
                              {employeecount}
                            </h2>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                  <div className="col-sm-6 col-lg-3 mb-3 mb-lg-5">
                    {/* Card */}
                    <a className="card card-hover-shadow h-100" href="#">
                      <div className="card-body">
                        <h3 className="card-subtitle">Total User</h3>
                        <div className="row align-items-center gx-2 mb-1">
                          <div className="col-6">
                            <h2 className="card-title text-inherit">
                              {usercount}
                            </h2>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                </>
              ) : (
                ""
              )}

              <div className="col-sm-6 col-lg-3 mb-3 mb-lg-5">
                {/* Card */}
                <a className="card card-hover-shadow h-100" href="#">
                  <div className="card-body">
                    <h3 className="card-subtitle">Total Productivity Hours</h3>
                    <div className="row align-items-center gx-2 mb-1">
                      <div className="col-6">
                        <h2 className="card-title text-inherit">05</h2>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
              <div className="col-sm-6 col-lg-3 mb-3 mb-lg-5">
                {/* Card */}
                <a className="card card-hover-shadow h-100" href="#">
                  <div className="card-body">
                    <h3 className="card-subtitle">
                      Total Non Productivity Hours
                    </h3>
                    <div className="row align-items-center gx-2 mb-1">
                      <div className="col-6">
                        <h2 className="card-title text-inherit">05</h2>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </main>
      )}
    </>
  );
}

// HeaderNavbarHOC(Home)
export default HeaderNavbarHOC(Home);
