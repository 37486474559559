import React, { useState, useEffect } from "react";
import Config from "../../config";
import HeaderNavbarHOC from "../../HOC/HeaderNavbar";
import { trackEvent, EVENT_NAME } from "../../constant/MixpanelConstant";
import * as AxiosActions from "../../Actions/axiosActions";
import { HeaderTitle, Pagination } from "../../components/Common";
import Table from "./Table";
import { history } from "../../constant/History";

function History() {
  const [startdate, setstartdate] = useState("");
  const [enddate, setenddate] = useState("");
  const [empid, setempid] = useState("");
  const [getdata, setgetdata] = useState([]);
  const [applicants, setApplicants] = useState([]);
  const [isFiltered, setIsFiltered] = useState(false);
  const [filterStatus, setFilterStatus] = useState("all");
  const [count, setCount] = useState(0);
  const [filterArr, setFilterArr] = useState([]);
  const [controller, setController] = useState({
    page: 0,
    rowsPerPage: 10,
    preventRefresh: false,
  });

  const token = localStorage.getItem("access_token");

  // renderdata(getdata from api)

  const renderdata = async () => {
    try {
      await AxiosActions.HistoryList({
        page: controller.page,
        limit: controller.rowsPerPage,
        type: filterStatus,
        startDate: startdate,
        endDate: enddate,
        adminId: "",
        targetField: "all",
        action: "add",
      })
        .then((data) => {
          console.log("Productivity Data", data.data);
          if (data.status === 200) {
            setRenderState(data.data.results, data.data.count);
          }
        })
        .catch((error) => {
          console.log("Productivity data error", error);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const setRenderState = (applicant, noOfRecords) => {
    setApplicants(applicant);
    setCount(noOfRecords);
  };

  const getadminList = () => {
    //Testing Pending
    AxiosActions.UserList({isAdmin: "all"})
      .then((response) => {
        console.log("getadminList", response.data.data);
        if (response.status == 200) {
          setgetdata(response.data.data);
        }
      })
      .catch((error) => {
        console.log("Error in get employee list", error);
      });
  };

  useEffect(() => {
    getadminList();
    if (isFiltered === true) {
      if (!controller.preventRefresh) filterRecord();
    } else {
      renderdata();
    }
  }, [controller]);

  // New Pagination

  const handlePageChange = (event, newPage) => {
    setController({
      ...controller,
      page: newPage,
      preventRefresh: false,
    });
  };

  const handleChangeRowsPerPage1 = (event) => {
    setController({
      ...controller,
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0,
      preventRefresh: false,
    });
  };

  // Filter record

  const filterRecord = async () => {
    trackEvent(EVENT_NAME.EMPLOYEE_SUMMARY_FILTER, {
      data: "reset list of client summary",
    });
    let pageNumber = controller.page;

    if (!isFiltered) {
      pageNumber = 0;
      setIsFiltered(true);
      setController((prevController) => {
        return {
          ...prevController,
          page: 0,
          preventRefresh: true,
        };
      });
    }
    renderdata();
  };

  const reset = () => {
    trackEvent(EVENT_NAME.EMPLOYEE_SUMMARY_RESET, { data: "" });
    setApplicants(filterArr);
    setempid("");
    setFilterStatus("all");
    setController({
      ...controller,
      page: 0,
      rowsPerPage: 10,
      preventRefresh: false,
    });

    setIsFiltered(false);
  };
  return (
    <>
      {token === null ? (
        true((window.location.href = "/"))
      ) : (
        <main id="content" role="main" className="main">
          <div className="content container-fluid">
            <HeaderTitle title="History" />

            <>
              <div className="card mb-3 mb-lg-5">
                <div className="card-header">
                  <div className="row justify-content-between align-items-center flex-grow-1">
                    <div className="col-md">
                      <div className="d-flex justify-content-between align-items-center"></div>
                    </div>
                    <div className="col-auto">
                      <div className="row align-items-sm-center">
                        <div className="col-sm-auto">
                          <div className="row align-items-center gx-0">
                            <div className="col">
                              <span className="text-secondary me-2">{history.ADMIN}</span>
                            </div>
                            {/* name drop down */}
                            <div className="col-auto">
                              <div className="tom-select-custom tom-select-custom-end">
                                <select
                                  className="form-select"
                                  autoComplete="off"
                                  name="Name"
                                  onChange={(event) =>
                                    setempid(event.target.value)
                                  }
                                  value={empid}
                                >
                                  <option value="" selected>
                                  {history.ALL}
                                  </option>

                                  {getdata.map((item, index) => {
                                    if (item.status == "active") {
                                      return (
                                        <option value={item._id}>
                                          {item.email}
                                        </option>
                                      );
                                    }
                                  })}
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-auto">
                          <div className="row align-items-center gx-0">
                            <div className="col">
                              <span className="text-secondary me-2">
                                {history.ACTION}
                              </span>
                            </div>
                            {/* name drop down */}
                            <div className="col-auto">
                              <div className="tom-select-custom tom-select-custom-end">
                                <select
                                  className="form-select"
                                  autoComplete="off"
                                  name="Name"
                                  onChange={(event) =>
                                    setempid(event.target.value)
                                  }
                                  value={empid}
                                >
                                  <option value="" selected>
                                  {history.ALL}
                                  </option>

                                  {Config.HISTORY_ACTION.map((item, index) => {
                                    return <option value={item}>{item}</option>;
                                  })}
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-auto">
                          <div className="row align-items-center gx-0">
                            <div className="col">
                              <span className="text-secondary me-2">
                                {history.TARGET_FIELD}
                              </span>
                            </div>
                            {/* name drop down */}
                            <div className="col-auto">
                              <div className="tom-select-custom tom-select-custom-end">
                                <select
                                  className="form-select"
                                  autoComplete="off"
                                  name="Name"
                                  onChange={(event) =>
                                    setempid(event.target.value)
                                  }
                                  value={empid}
                                >
                                  <option value="" selected>
                                  {history.ALL}
                                  </option>

                                  {Config.HISTORY_TRAGETED_FILEDS.map(
                                    (item, index) => {
                                      return (
                                        <option value={item}>{item}</option>
                                      );
                                    }
                                  )}
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-auto">
                          <div className="row align-items-center gx-0">
                            <div className="col">
                              <span className="text-secondary me-2">{history.DATE}:</span>
                            </div>
                            {/* date drop down */}
                            <div className="col-auto">
                              <div className="tom-select-custom tom-select-custom-end">
                                <select
                                  className="js-select js-datatable-filter form-select form-select-sm form-select-borderless"
                                  data-target-column-index={6}
                                  data-target-table="datatable"
                                  autoComplete="off"
                                  value={filterStatus || "all"}
                                  onChange={(event) =>
                                    setFilterStatus(event.target.value)
                                  }
                                >
                                  <option value="all" selected="">
                                    {history.ALL}
                                  </option>
                                  <option value="customize"> {history.CUSTOMIZE}</option>
                                  <option value="today"> {history.TODAY}</option>
                                  <option value="yesterday"> {history.YESTERDAY}</option>
                                  <option value="last_week"> {history.LAST_WEEK}</option>
                                  <option value="current_week">
                                    {history.CURRENT_WEEK}
                                  </option>
                                  <option value="last_month">{history.LAST_MONTH}</option>
                                  <option value="this_month">{history.THIS_MONTH}</option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                        {filterStatus === "customize" ? (
                          <>
                            {" "}
                            <div className="col-sm-auto">
                              <div className="row align-items-center gx-0">
                                <div className="col">
                                  <span className="text-secondary me-2">
                                    {history.START_DATE}
                                  </span>
                                </div>
                                <div className="col-auto">
                                  <div className="tom-select-custom tom-select-custom-end">
                                    <input
                                      type="date"
                                      className="form-control"
                                      name="StartDate"
                                      onChange={(event) =>
                                        setstartdate(event.target.value)
                                      }
                                      value={startdate}
                                      id="firstNameLabel"
                                      placeholder="StartDate"
                                      aria-label="StartDate"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-auto">
                              <div className="row align-items-center gx-0">
                                <div className="col">
                                  <span className="text-secondary me-2">
                                    {history.END_DATE}
                                  </span>
                                </div>
                                <div className="col-auto">
                                  <div className="tom-select-custom tom-select-custom-end">
                                    <input
                                      type="date"
                                      className="form-control"
                                      name="EndDate"
                                      id="firstNameLabel"
                                      placeholder="EndDate"
                                      aria-label="EndDate"
                                      onChange={(event) =>
                                        setenddate(event.target.value)
                                      }
                                      value={enddate}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        ) : (
                          ""
                        )}

                        <div className="col-sm-auto">
                          <div className="row align-items-center pe-0">
                            <div className="col">
                              <button
                                type="button"
                                className="btn btn-danger"
                                onClick={() => filterRecord()}
                              >
                                {history.FILTER}
                              </button>
                            </div>
                          </div>
                        </div>

                        <div className="col-sm-auto">
                          <div className="row align-items-center gx-0 me-0">
                            <div className="col">
                              <button
                                type="button"
                                className="btn btn-warning"
                                onClick={reset}
                              >
                                {history.RESET}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="table-responsive datatable-custom">
                  <Table applicants={applicants} />
                </div>

                <div className="card-footer">
                  <Pagination
                    handlePageChange={handlePageChange}
                    controller={controller}
                    count={count}
                    handleChangeRowsPerPage1={handleChangeRowsPerPage1}
                  />
                </div>
              </div>
            </>
          </div>
          
        </main>
      )}
    </>
  );
}

export default HeaderNavbarHOC(History);
