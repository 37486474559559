import React from "react";
import moment from "moment";
import { reminder } from "../../constant/reminder";

function View({ fullReport }) {
  return (
    <div
      className="modal fade bd-example-modal-xl"
      id="viewpage"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title" id="exampleModalLongTitle">
              {reminder.VIEW_REMINDER}
            </h1>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            {fullReport?.map((item, index) => {
              return (
                <div key={index}>
                  <h3 style={{ color: "red" }}>Reminder information</h3>
                  <table className="table table-align-middle">
                    <thead>
                      <tr>
                        <th scope="col"></th>
                        <th scope="col"></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <h4>{reminder.REMINDER_NAME}</h4>
                          {item.remainderName}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <h4>{reminder.EMPLOYEE_NAME}</h4>
                          {item.empid.map((item, index) => {
                            return (
                              <React.Fragment key={index}>
                                {item.employeeName}
                                <br />
                              </React.Fragment>
                            );
                          })}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <h4>{reminder.DATE_AND_TIME}</h4>
                          {moment
                            .utc(item.date)
                            .format("DD-MM-YYYY hh:mm:ss a")}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <h4>{reminder.DESCRIPTION}</h4>
                          {item.description}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <h4>{reminder.STATUS}</h4>
                          {item.status}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              );
            })}
          </div>
          <div className="modal-footer"></div>
        </div>
      </div>
    </div>
  );
}

export default View;
