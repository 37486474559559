import React, { useState, useEffect, useRef, useContext } from "react";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import HeaderNavbarHOC from "../../HOC/HeaderNavbar";
import Config from "../../config";
import AlertBox from "../../Alert";
import { trackEvent, EVENT_NAME } from "../../constant/MixpanelConstant";
import * as AxiosActions from "../../Actions/axiosActions";
import { Autocomplete, TextField } from "@mui/material";
import { reminder } from "../../constant/reminder";
import { AuthContext } from "../../Login/Protected";
import { ReminderDateTimePicker } from "../../Datepicker";
import BackgroundImage from "../../components/Common/CardImage";
import { DATAADDEDSUCCESSFULLY } from "../../constant/Label";

function ReminderAdd() {
  const adminData = useContext(AuthContext);

  const [openAlert, setOpenAlert] = useState(false);
  const [getData, setGetData] = useState([]);

  const getEmployeeList = () => {
    AxiosActions.EmployeeList({})
      .then((response) => {
        if (response.status === 200) {
          setGetData(response.data.data);
        }
      })
      .catch((error) => {
        console.log("Error in get employee list", error);
      });
  };

  useEffect(() => {
    getEmployeeList();
  }, []);

  const formik = useFormik({
    initialValues: {
      name: "",
      employee: [],
      date: "",
      Description: "",
      status: "Select status",
    },
    validate: (values) => {
      const errors = {};

      // Manual validation for each field
      if (!values.name) {
        errors.name = "Please enter valid tracking tools name";
      }
      if (values?.employee?.length === 0) {
        errors.employee = "Select employee";
      }
      if (!values.date) {
        errors.date = "Please enter valid date and time";
      }
      if (!values.Description) {
        errors.Description = "Please enter valid description";
      }
      if (!values.status || values.status === "Select status") {
        errors.status = "Select status";
      }

      return errors;
    },
    onSubmit: (values) => {
      trackEvent(EVENT_NAME.TRACKING_TOOLS_ADD, { data: "" });
      AxiosActions.ReminderAdd({
        adminId: adminData.adminData._id,
        empid: values.employee,
        status: values.status,
        description: values.Description,
        remainderName: values.name,
        date: values.date,
      })
        .then((response) => {
          if (response.status === 200 && response.data.success) {
            setOpenAlert(true);
          }
        })
        .catch((error) => {
          console.log("Tracking tools error", error);
          const errorMessage = error || "Something went wrong!";
          toast.dismiss();
          toast.error(errorMessage);
        });
    },
  });

  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
    formik.resetForm();
  };

  const handleSelectChange = (event, values) => {
    formik.setFieldValue(
      "employee",
      values.map((item) => item._id)
    );
  };

  return (
    <>
      <AlertBox
        open={openAlert}
        onClose={handleCloseAlert}
        message={DATAADDEDSUCCESSFULLY}
        title="Success"
        ok={false}
      />
      <main id="content" role="main" className="main">
        <BackgroundImage/>
        <div
          className="content container-fluid"
          style={{ justifyItems: "center", maxWidth: "60rem" }}
        >
          <div className="page-header">
            <div className="row align-items-end">
              <div className="col-sm mb-2 mb-sm-0"></div>
            </div>
          </div>

          <div className="card w-50">
            <div className="card-header">
              <h1 className="card-title h1">{reminder.ADD_REMINDER_INFO}</h1>
            </div>

            <div className="card-body">
              <form className="card-body pt-0" onSubmit={formik.handleSubmit}>
                <div className="mb-4">
                  <TextField
                    fullWidth
                    name="name"
                    label="Reminder name"
                    onChange={formik.handleChange}
                    value={formik.values.name}
                    variant="outlined"
                    size="small"
                  />
                  {formik.errors.name && formik.touched.name && (
                    <div className="form-error" style={{ color: "red" }}>
                      {formik.errors.name}
                    </div>
                  )}
                </div>
                <div className="row mb-4">
                  <Autocomplete
                    multiple
                    limitTags={2}
                    id="multiple-limit-tags"
                    options={getData}
                    getOptionLabel={(option) => option.employeeName || ""}
                    onChange={handleSelectChange}
                    size="small"
                    value={getData?.filter((emp) =>
                      formik.values?.employee.includes(emp?._id)
                    )}
                    isOptionEqualToValue={(option, value) =>
                      option._id === value._id
                    }
                    renderInput={(params) => (
                      <TextField {...params} label="Select employee" />
                    )}
                  />
                  {formik.errors.employee && formik.touched.employee && (
                    <div className="form-error" style={{ color: "red" }}>
                      {formik.errors.employee}
                    </div>
                  )}
                </div>
                <div className="row mb-4 flex-column">
                  <div>
                    <ReminderDateTimePicker
                      name="date"
                      value={formik.values.date}
                      onChange={(value) => formik.setFieldValue("date", value)}
                    />
                    {formik.errors.date && formik.touched.date && (
                      <div className="form-error" style={{ color: "red" }}>
                        {formik.errors.date}
                      </div>
                    )}
                  </div>
                </div>
                <div className="mb-4">
                  <TextField
                    fullWidth
                    name="Description"
                    label="Description"
                    onChange={formik.handleChange}
                    value={formik.values.Description}
                    variant="outlined"
                    size="small"
                    multiline
                  />
                  {formik.errors.Description && formik.touched.Description && (
                    <div className="form-error" style={{ color: "red" }}>
                      {formik.errors.Description}
                    </div>
                  )}
                </div>
                <div className="mb-4">
                  <Autocomplete
                    options={Config.REMINDER_STATUS}
                    name="status"
                    getOptionLabel={(option) => option.name}
                    value={
                      Config.REMINDER_STATUS.find(
                        (status) => status.value === formik.values.status
                      ) || { name : "Select status" }                
                    }
                    size="small"
                    renderInput={(params) => <TextField {...params} />}
                    onChange={(event, newValue) => {
                      formik.setFieldValue(
                        "status",
                        newValue === null ? "Select status" : newValue.value
                      );
                    }
                    }
                  />
                  {formik.errors.status && formik.touched.status && (
                    <div className="form-error" style={{ color: "red" }}>
                      {formik.errors.status}
                    </div>
                  )}
                </div>

                <div className="d-flex justify-content-end">
                  <button type="submit" className="btn btn-warning">
                    {reminder.SAVE_CHANGES}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default HeaderNavbarHOC(ReminderAdd);
