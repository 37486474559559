import React from "react";
import { jobs } from "../../constant/Jobs";

function View({ fullReport, getClientStatus }) {
  return (
    <div
      className="modal fade"
      id="viewpage"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title" id="exampleModalLongTitle">
              {jobs.VIEW_JOBS}
            </h1>

            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            {fullReport?.map((item, index) => {
              return (
                <div key={index}>
                  <h3 style={{ color: "red" }}>{jobs.JOBS_INFO}</h3>
                  <table className="table table-align-middle">
                    <tbody>
                      <tr>
                        <td>
                          <h4>{jobs.JOBS_ID}</h4>
                          {item.id}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <h4>{jobs.JOB_TITLE}</h4>
                          {item.job_title}
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={2}>
                          <h4>{jobs.IMAGE}</h4>
                          <img src={item.image} height={50} width={50} alt={item.job_title} />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <h4>{jobs.JOB_TYPE}</h4>
                          {item.job_type}
                        </td>
                        <td>
                          <h4>{jobs.EXPERIENCE}</h4>
                          {item.experience}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <h4>{jobs.LOCATION}</h4>
                          {item.location}
                        </td>
                        <td>
                          <h4>{jobs.TIME}</h4>
                          {item.time}
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={2}>
                          <h4>{jobs.DUTIES}</h4>
                          {item.Duties}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <h4>{jobs.STATUS}</h4>
                          {getClientStatus(item.status, "jobs")}
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={2}>
                          <h4>{jobs.LANGUAGE}</h4>
                          {item.language.map((item, index) => {
                            return (
                              <>
                                <tr>
                                  [{index + 1}] {item.technologies}
                                </tr>
                              </>
                            );
                          })}
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={2}>
                          <h4>{jobs.REQUIREMENTS}</h4>
                          {item.requirements.map((item, index) => {
                            return (
                              <>
                                <tr>
                                  [{index + 1}] {item.description}
                                </tr>
                              </>
                            );
                          })}
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={2}>
                          <h4>{jobs.BENEFITS}</h4>
                          {item.benefits.map((item, index) => {
                            return (
                              <>
                                <tr>
                                  [{index + 1}] {item.description}
                                </tr>
                              </>
                            );
                          })}
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={2}>
                          <h4>{jobs.ROLE}</h4>
                          {item?.roles?.map((item, index) => {
                            return (
                              <>
                                <tr>
                                  [{index + 1}] {item.description}
                                </tr>
                              </>
                            );
                          })}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              );
            })}
          </div>
          <div className="modal-footer"></div>
        </div>
      </div>
    </div>
  );
}

export default View;
