import React,{ useEffect, useState } from "react";
import Config from "../../config";
import HeaderNavbarHOC from "../../HOC/HeaderNavbar";
import { trackEvent, EVENT_NAME } from "../../constant/MixpanelConstant";
import Useuser from "../../Contexct";
import * as AxiosActions from "../../Actions/axiosActions";
import { HeaderTitle, Pagination, Filter } from "../../components/Common";
import Table from "./Table";

function Quotes() {
  const { adminData } = Useuser();
  const [anchorEl6, setAnchorEl6] = useState(false);

  const [loading, setLoading] = useState(false);
  const [inputStatus, setInputStatus] = useState("");
  const [applicants, setApplicants] = useState([]);
  const [isFiltered, setIsFiltered] = useState(false);
  const [status, setStatus] = useState("Applied");
  const [updateId, setUpdateId] = useState("");
  const [deleteId, setDeleteId] = useState("");
  const [applicantsNotes, setApplicantsNotes] = useState([]);
  const [fullReport, setFullReport] = useState([]);
  const [filterStatus, setFilterStatus] = useState("All");
  const [filterjobopening, setFilterJobOpening] = useState("All");
  const [jobopening, setJobOpening] = useState([]);
  const [count, setCount] = useState(0);
  const [filter, setFilter] = useState("name");
  const [inputList, setInputList] = useState("");
  const [filterArr, setFilterArr] = useState([]);
  const [multipleInputStatus, setMultipleInputStatus] = useState("");
  const [multipleStatus, setMultipleStatus] = useState("Applied");
  const [selectedinfo, setSelectedInfo] = useState({
    selectedID: [],
  });
  const [controller, setController] = useState({
    page: 0,
    rowsPerPage: 10,
    preventRefresh: false,
  });
  const [isDisable, setIsDisable] = useState(false);
  const isAllSelected =
    applicants.length > 0 &&
    selectedinfo.selectedID.length === applicants.length;
  const token = localStorage.getItem("access_token");

  // renderdata(getdata from api)

  const renderdata = async () => {
    try {
      setLoading(true);
      await AxiosActions.QuoteList({
        pageNo: controller.page,
        size: controller.rowsPerPage,
        token: token,
      })
        .then((data) => {
          console.log(data);
          setRenderState(data.data.data.rows, data.data.data.noRecords);
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const setRenderState = (applicant, noOfRecords) => {
    setApplicants(applicant);
    setCount(noOfRecords);

    if (applicant.length === 0) {
      console.log("no applicant");
      setAnchorEl6(true);
    }
  };

  const getJobOpening = () => {
    AxiosActions.JobList({}).then((data) => {
      setJobOpening(data.data.data);
    });
  };

  useEffect(() => {
    if (jobopening == 0) getJobOpening();

    if (isFiltered === true) {
      if (!controller.preventRefresh) filterRecord();
    } else {
      renderdata();
    }
  }, [controller]);

  // New Pagination

  const handlePageChange = (event, newPage) => {
    setController({
      ...controller,
      page: newPage,
      preventRefresh: false,
    });
    setSelectedInfo({ selectedID: [] });
  };

  const handleChangeRowsPerPage1 = (event) => {
    setController({
      ...controller,
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0,
      preventRefresh: false,
    });

    setSelectedInfo({ selectedID: [] });
  };

  // Filter record

  const filterRecord = async () => {
    trackEvent(EVENT_NAME.QUOTES_LIST_FILTER, { data: "" });
    let pageNumber = controller.page;

    if (!isFiltered) {
      pageNumber = 0;
      setIsFiltered(true);
      setController((prevController) => {
        return {
          ...prevController,
          page: 0,
          preventRefresh: true,
        };
      });
    }
    AxiosActions.QuoteFilter({
      pageNo: pageNumber,
      size: controller.rowsPerPage,
      filter: filter,
      filterValue: inputList,
      status: filterStatus,
      job: filterjobopening,
    })
      .then((data) => {
        setRenderState(data.data.data.records, data.data.data.noRecords);
      })
      .catch((err) => {
        alert("Please update again");
      });
    setSelectedInfo({ selectedID: [] });
  };

  const handleFilterStatusChange = (event) => {
    setFilterStatus(event.target.value);
  };

  const handleFilterJobOpeningChange = (event) => {
    setFilterJobOpening(event.target.value);
  };

  // Update status

  const changeStatus = (statusid, title) => {
    setIsDisable(true);
    trackEvent(EVENT_NAME.QUOTES_CHANGE_STATUS, { data: statusid });
    console.log(statusid);
    const token = localStorage.getItem("access_token");
    AxiosActions.QuoteStatus({
      applied_id: statusid,
      id: statusid,
      status: status,
      note: inputStatus,
      token: token,
      adminId: adminData._id,
    })
      .then((data) => {
        setIsDisable(false);
        console.log("Apply Job Table", data);

        renderdata();
      })
      .catch((err) => {
        setIsDisable(false);
        console.log(err);
        alert("Please update again");
      });
    setStatus("Applied");
    setInputStatus("");

    setSelectedInfo({ selectedID: [] });
  };

  const handleClick = (event, id) => {
    setUpdateId(id);
    event.preventDefault();
  };

  const handleClose = () => {
    setStatus("Applied");
    setInputStatus("");
  };

  //Download Resume

  const handleDownload = (link) => {
    trackEvent(EVENT_NAME.QUOTES_DOWNLOAD, { data: "" });
    window.open(link);
  };

  //Reset Filter

  const reset = () => {
    trackEvent(EVENT_NAME.QUOTES_LIST_RESET, { data: "" });
    setApplicants(filterArr);
    setInputList("");
    setFilter("name");
    setFilterStatus("All");
    setFilterJobOpening("All");
    setController({
      ...controller,
      page: 0,
      rowsPerPage: 20,
      preventRefresh: false,
    });
    setSelectedInfo({ selectedID: [] });
    setIsFiltered(false);
  };

  //Status Change

  const changeStatusValue = (event) => {
    setStatus(event.target.value);
  };

  const changeInputStatus = (event) => {
    setInputStatus(event.target.value);
  };

  //View Status

  const handleViewClick = (event, id) => {
    trackEvent(EVENT_NAME.QUOTES_STATUS_VIEW, { data: "" });
    AxiosActions.QuoteNotesList({
      applied_id: id,
    })
      .then((data) => {
        console.log("QuoteNotesList", data.data.data);
        setApplicantsNotes(data.data.data);
      })
      .catch((err) => {
        alert("Please Check Again.");
      });
    event.preventDefault();
  };
  const handleDeleteClick = (event, id) => {
    setDeleteId(id);

    event.preventDefault();
  };

  //Delete Record

  const deleteRecord = (event, id) => {
    setIsDisable(true);
    trackEvent(EVENT_NAME.QUOTES_DELETE, { data: "" });
    console.log(id);
    AxiosActions.DeleteQuote({
      _id: id,
      adminId: adminData._id,
    })
      .then((data) => {
        renderdata();
        setIsDisable(false);
      })
      .catch((err) => {
        alert("Please Delete Record Again.");
        setIsDisable(false);
      });

    event.preventDefault();
  };

  //View Full Report

  const handleViewReportClick = (event, id) => {
    trackEvent(EVENT_NAME.QUOTES_VIEW, { data: "" });
    const filterRecord = applicants.filter((item) => {
      if (id === item._id) {
        return true;
      }
    });
    setFullReport(filterRecord);
  };

  //Select Multiple

  const handleCheckBoxChange = (e) => {
    const value = e.target.value;
    const { checked } = e.target;
    const list = [];
    const { selectedID } = selectedinfo;

    if (value === "all") {
      if (value === "all" && checked) {
        applicants.map((item) => {
          list.push(item._id);
        });
        setSelectedInfo({
          selectedID: list,
        });
      } else {
        setSelectedInfo({
          selectedID: list.filter((e) => e !== value),
        });
      }
      return;
    } else if (checked) {
      setSelectedInfo({
        selectedID: [...selectedID, value],
      });
    } else {
      setSelectedInfo({
        selectedID: selectedID.filter((e) => e !== value),
      });
    }
  };

  //Delete Multiple

  const handleDeleteMultipleClick = (event) => {
    event.preventDefault();
  };
  const deleteAllRecord = (event) => {
    setIsDisable(true);
    trackEvent(EVENT_NAME.QUOTES_DELETE_ALL, { data: "" });
    console.log("Delete All Records");
    AxiosActions.MultipleDeleteQuote({
      _id: selectedinfo.selectedID,
      adminId: adminData._id,
    })
      .then((data) => {
        setIsDisable(false);
        renderdata();
      })
      .catch((err) => {
        setIsDisable(false);
        alert("Please Delete Record Again");
      });

    setSelectedInfo({ selectedID: [] });
    event.preventDefault();
  };

  //Multiple Status

  const handleStatusMultipleClick = (event) => {
    event.preventDefault();
  };

  const changeMultipleStatus = (event) => {
    setIsDisable(true);
    trackEvent(EVENT_NAME.QUOTES_CHANGE_STATUS_ALL, { data: "" });
    const token = localStorage.getItem("access_token");
    AxiosActions.MultipleQuoteStatus({
      id: selectedinfo.selectedID,
      status: multipleStatus,
      token: token,
      data: selectedinfo.selectedID,
      note: multipleInputStatus,
      adminId: adminData._id,
    })
      .then((data) => {
        renderdata();
        setIsDisable(false);
        console.log("Change Many Status", data);
      })
      .catch((err) => {
        setIsDisable(false);
        alert("Please update again");
      });

    setMultipleStatus("Applied");
    setMultipleInputStatus("");
    setSelectedInfo({ selectedID: [] });
  };

  //Multiple Status Change

  const changeMultipleInputStatus = (event) => {
    setMultipleInputStatus(event.target.value);
  };

  const changeMultipleStatusValue = (event) => {
    setMultipleStatus(event.target.value);
  };

  const handleNoRecordClose = () => {
    setAnchorEl6(false);
  };
  console.log(applicants);
  return (
    <>
      {token === null ? (
        (window.location.href = "/")
      ) : (
        <main id="content" role="main" className="main">
          <div className="content container-fluid">
            <HeaderTitle title="Quotes" />

            <>
              <div className="card mb-3 mb-lg-5">
                <div className="card-header">
                  <div className="row justify-content-between align-items-center flex-grow-1">
                    <div className="col-md">
                      <div className="d-flex justify-content-between align-items-center">
                        <h4 className="card-header-title">Users</h4>
                      </div>
                      <Filter
                        status={true}
                        statusValue={filterStatus}
                        statusUpdate={setFilterStatus}
                        statusList={Config?.STATUS_LIST}
                        multipleSelect={selectedinfo?.selectedID?.length}
                        multipleDelete={true}
                        isDisable={isDisable}
                        handleDelete={handleDeleteMultipleClick}
                        deleteAllRecord={deleteAllRecord}
                        multipleStatus={true}
                        handleStatus={handleStatusMultipleClick}
                        changeMultipleStatusValue={changeMultipleStatusValue}
                        changeMultipleInputStatus={changeMultipleInputStatus}
                        multipleStatusList={Config?.STATUS_LIST}
                        changeMultipleStatus={changeMultipleStatus}
                        filterRecord={filterRecord}
                        resetRecord={reset}
                      />
                    </div>
                  </div>
                </div>
                <div className="table-responsive datatable-custom">
                  <Table
                    handleCheckBoxChange={handleCheckBoxChange}
                    isAllSelected={isAllSelected}
                    loading={loading}
                    applicants={applicants}
                    selectedinfo={selectedinfo}
                    handleClick={handleClick}
                    changeStatusValue={changeStatusValue}
                    changeInputStatus={changeInputStatus}
                    isDisable={isDisable}
                    changeStatus={changeStatus}
                    updateId={updateId}
                    handleViewClick={handleViewClick}
                    applicantsNotes={applicantsNotes}
                    handleDeleteClick={handleDeleteClick}
                    deleteRecord={deleteRecord}
                    deleteId={deleteId}
                  />
                </div>

                <div className="card-footer">
                  <Pagination
                    handlePageChange={handlePageChange}
                    controller={controller}
                    count={count}
                    handleChangeRowsPerPage1={handleChangeRowsPerPage1}
                  />
                </div>
              </div>
            </>
          </div>
          <div className="footer">
            <div className="row justify-content-between align-items-center">
              <div className="col">
                <p className="fs-6 mb-0">
                  © Front.{" "}
                  <span className="d-none d-sm-inline-block">
                    2022 Htmlstream.
                  </span>
                </p>
              </div>

              <div className="col-auto">
                <div className="d-flex justify-content-end">
                  <ul className="list-inline list-separator">
                    <li className="list-inline-item">
                      <a className="list-separator-link" href="#">
                        FAQ
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a className="list-separator-link" href="#">
                        License
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <button
                        className="btn btn-ghost-secondary btn btn-icon btn-ghost-secondary rounded-circle"
                        type="button"
                        data-bs-toggle="offcanvas"
                        data-bs-target="#offcanvasKeyboardShortcuts"
                        aria-controls="offcanvasKeyboardShortcuts"
                      >
                        <i className="bi-command" />
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </main>
      )}
    </>
  );
}

export default HeaderNavbarHOC(Quotes);
