import React, { useState, useEffect, useMemo } from "react";
import moment from "moment";
import HeaderNavbarHOC from "../../HOC/HeaderNavbar";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { trackEvent, EVENT_NAME } from "../../constant/MixpanelConstant";
import * as AxiosActions from "../../Actions/axiosActions";
import { Pagination, Filter } from "../../components/Common";

function TrackingTools() {
  const [loading, setLoading] = useState(false);
  const [getclient, setgetcilent] = useState([]);
  const [totalproductivityhours, settotalproductivityhours] = useState();
  const [totalnonproductivityhours, settotalnonproductivityhours] = useState();
  const [trackingToolId, setTrackingToolId] = useState("");
  const [startdate, setstartdate] = useState("");
  const [TrackingTools, setTrackingTools] = useState([]);
  const [enddate, setenddate] = useState("");
  const [clientId, setClientId] = useState("");
  const [getdata, setgetdata] = useState([]);
  const [applicants, setApplicants] = useState([]);
  const [isFiltered, setIsFiltered] = useState(false);
  const [filterStatus, setFilterStatus] = useState("all");
  const [count, setCount] = useState(0);
  const [filterArr, setFilterArr] = useState([]);
  const [controller, setController] = useState({
    page: 0,
    rowsPerPage: 10,
    preventRefresh: false,
  });

  const token = localStorage.getItem("access_token");

  // renderdata(getdata from api)

  const renderdata = async () => {
    try {
      setLoading(true);
      await AxiosActions.TrackingToolSummary({
        page: controller.page,
        limit: controller.rowsPerPage,
        type: filterStatus,
        startDate: startdate,
        endDate: enddate,
        //empid: empid,
        clientId: clientId,
        trackingToolId: trackingToolId,
      })
        .then((data) => {
          console.log("Productivity Data", data.data);
          if (data.status === 200) {
            setRenderState(data.data.results, data.data.count);
          }
          setLoading(false);
        })
        .catch((error) => {
          console.log("Productivity data error", error);
        });

      await AxiosActions.TrackingToolSummaryCount({
        type: filterStatus,
        startDate: startdate,
        endDate: enddate,
        trackingToolId: trackingToolId,
        clientId: clientId,
      })
        .then((data) => {
          console.log(data);
          if (data.status === 200) {
            settotalproductivityhours(
              data.data.totalProductivityHours.toFixed(3)
            );
            settotalnonproductivityhours(
              data.data.totalNonProductivityHours.toFixed(3)
            );
          }
        })
        .catch((error) => {
          console.log("Productivity data error", error);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const setRenderState = (applicant, noOfRecords) => {
    setApplicants(applicant);
    setCount(noOfRecords);
  };

  const getTrackingTools = () => {
    AxiosActions.TrackingList({})
      .then((response) => {
        console.log("getTrackingTools", response);
        if (response.status == 200) {
          setTrackingTools(response.data.data);
        }
      })
      .catch((error) => {
        console.log("Error in getTrackingTools", error);
      });
  };

  const getClient = () => {
    AxiosActions.ProdutivityClientList({})
      .then((response) => {
        console.log("get Client", response);
        if (response.status == 200) {
          setgetcilent(response.data.data);
        }
      })
      .catch((error) => {
        console.log("Error in get Client", error);
      });
  };

  useEffect(() => {
    getClient();
    getTrackingTools();
    if (isFiltered === true) {
      if (!controller.preventRefresh) filterRecord();
    } else {
      renderdata();
    }
  }, [controller]);

  // New Pagination

  const handlePageChange = (event, newPage) => {
    setController({
      ...controller,
      page: newPage,
      preventRefresh: false,
    });
  };

  const handleChangeRowsPerPage1 = (event) => {
    setController({
      ...controller,
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0,
      preventRefresh: false,
    });
  };

  // Filter record

  const filterRecord = async () => {
    trackEvent(EVENT_NAME.TRACKING_TOOLS_SUMMARY_FILTER, {
      data: "Filter list of tracking tools summary",
    });
    let pageNumber = controller.page;

    if (!isFiltered) {
      pageNumber = 0;
      setIsFiltered(true);
      setController((prevController) => {
        return {
          ...prevController,
          page: 0,
          preventRefresh: true,
        };
      });
    }
    renderdata();
  };

  const reset = () => {
    trackEvent(EVENT_NAME.TRACKING_TOOLS_SUMMARY_RESET, {
      data: "Reset list of tracking tools summary",
    });
    setApplicants(filterArr);

    setFilterStatus("all");
    setController({
      ...controller,
      page: 0,
      rowsPerPage: 10,
      preventRefresh: false,
    });

    setIsFiltered(false);
  };
  const applicantFilter = [...applicants].sort((a, b) =>
    a.date > b.date ? 1 : -1
  );

  const datefilter = useMemo(() => {
    const applicantDate = applicantFilter.map((ele) =>
      moment(ele.date).format("DD-MM-YYYY")
    );
    const applicantFilteredArr = [...new Set(applicantDate)];
    return applicantFilteredArr;
  }, [applicants]);
  console.log("applicantFilter", applicantFilter);
  const productivity = useMemo(() => {
    const groupedData = [];
    const series = [];
    applicantFilter.forEach((item) => {
      const existingRecord = groupedData.find(
        (record) =>
          record.date === item.date &&
          record.trackingToolName === item.trackingToolName
      );

      if (existingRecord) {
        existingRecord.productivityHours += item.productivityHours;
        existingRecord.nonProductivityHours += item.nonProductivityHours;
      } else {
        groupedData.push({
          date: item.date,
          trackingToolName: item.trackingToolName,
          productivityHours: item.productivityHours,
          nonProductivityHours: item.nonProductivityHours,
        });
      }
    });
    console.log("groupedData", groupedData);
    groupedData.forEach((item) => {
      const existingSeries = series.find(
        (s) => s.name.toLowerCase() === item.trackingToolName.toLowerCase()
      );
      const ProductivityFormatted =
        Math.round(parseFloat(item.productivityHours) * 100) / 100;
      if (existingSeries) {
        existingSeries.data.push(ProductivityFormatted);
      } else {
        const newSeries = {
          name: item.trackingToolName,
          data: [],
        };
        newSeries.data.push(ProductivityFormatted);
        series.push(newSeries);
      }
    });

    return series;
  }, [applicants, datefilter]);

  const nonproductivity = useMemo(() => {
    const series = [];
    const groupedData = [];
    applicantFilter.forEach((item) => {
      const existingRecord = groupedData.find(
        (record) =>
          record.date === item.date &&
          record.trackingToolName === item.trackingToolName
      );

      if (existingRecord) {
        existingRecord.productivityHours += item.productivityHours;
        existingRecord.nonProductivityHours += item.nonProductivityHours;
      } else {
        groupedData.push({
          date: item.date,
          trackingToolName: item.trackingToolName,
          productivityHours: item.productivityHours,
          nonProductivityHours: item.nonProductivityHours,
        });
      }
    });
    groupedData.forEach((item) => {
      const existingSeries = series.find(
        (s) => s.name.toLowerCase() === item.trackingToolName.toLowerCase()
      );

      const nonProductivityFormatted =
        Math.round(parseFloat(item.nonProductivityHours) * 100) / 100;

      if (existingSeries) {
        existingSeries.data.push(nonProductivityFormatted);
      } else {
        const newSeries = {
          name: item.trackingToolName,
          data: [],
        };
        newSeries.data.push(nonProductivityFormatted);
        series.push(newSeries);
      }
    });

    return series;
  }, [applicants, datefilter]);

  return (
    <>
      {token === null ? (
        true((window.location.href = "/"))
      ) : (
        <main id="content" role="main" className="main">
          <div className="content container-fluid">
            <div className="page-header">
              <div className="row align-items-center">
                <div
                  className="col align-items-center"
                  style={{ alignItems: "center" }}
                >
                  <h1
                    className="page-header-title align-items-center"
                    style={{ alignSelf: "center" }}
                  >
                    Tracking tools summary
                  </h1>
                </div>
              </div>
            </div>

            <>
              <div className="row">
                <div className="col-sm-6 col-lg-3 mb-3 mb-lg-5">
                  {/* Card */}
                  <a className="card card-hover-shadow h-100" href="#">
                    <div className="card-body">
                      <h3 className="card-subtitle">
                        Total Productivity Hours
                      </h3>
                      <div className="row align-items-center gx-2 mb-1">
                        <div className="col-6">
                          <h2 className="card-title text-inherit">
                            {totalproductivityhours}
                          </h2>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
                <div className="col-sm-6 col-lg-3 mb-3 mb-lg-5">
                  {/* Card */}
                  <a className="card card-hover-shadow h-100" href="#">
                    <div className="card-body">
                      <h3 className="card-subtitle">
                        Total Non Productivity Hours
                      </h3>
                      <div className="row align-items-center gx-2 mb-1">
                        <div className="col-6">
                          <h2 className="card-title text-inherit">
                            {totalnonproductivityhours}
                          </h2>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
              </div>

              <div className="row mb-4">
                <div className="col-md-4 mb-4" style={{ width: 1000 }}>
                  <div className="card card-bordered h-100">
                    <div className="card-body">
                      {productivity.length > 0 && (
                        <HighchartsReact
                          highcharts={Highcharts}
                          options={{
                            title: {
                              text: "Productivity Hours",
                              align: "center",
                            },
                            yAxis: {
                              title: {
                                text: "Number of hours",
                              },
                            },

                            xAxis: {
                              categories: datefilter,
                              crosshair: true,
                              accessibility: {
                                rangeDescription: "Date",
                              },
                            },

                            legend: {
                              layout: "vertical",
                              align: "right",
                              verticalAlign: "middle",
                            },

                            plotOptions: {
                              series: {
                                dataLabels: {
                                  enabled: true,
                                },
                                label: {
                                  connectorAllowed: false,
                                },
                              },
                            },
                            series: productivity,
                            responsive: {
                              rules: [
                                {
                                  condition: {
                                    maxWidth: 500,
                                  },
                                  chartOptions: {
                                    legend: {
                                      layout: "horizontal",
                                      align: "center",
                                      verticalAlign: "bottom",
                                    },
                                  },
                                },
                              ],
                            },
                          }}
                        />
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-md-4 mb-4" style={{ width: 1000 }}>
                  <div className="card card-bordered h-100">
                    {loading ? (
                      <>
                        {" "}
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <div
                            className="spinner-border text-dark m-5"
                            role="status"
                          >
                            <span className="sr-only"></span>
                          </div>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                    <div className="card-body">
                      {nonproductivity.length > 0 && (
                        <HighchartsReact
                          highcharts={Highcharts}
                          options={{
                            title: {
                              text: "Non Productivity Hours",
                              align: "center",
                            },
                            yAxis: {
                              title: {
                                text: "Number of hours",
                              },
                            },

                            xAxis: {
                              categories: datefilter,
                              crosshair: true,
                              accessibility: {
                                rangeDescription: "Date",
                              },
                            },

                            legend: {
                              layout: "vertical",
                              align: "right",
                              verticalAlign: "middle",
                            },

                            plotOptions: {
                              series: {
                                dataLabels: {
                                  enabled: true,
                                },
                                label: {
                                  connectorAllowed: false,
                                },
                              },
                            },
                            series: nonproductivity,
                            responsive: {
                              rules: [
                                {
                                  condition: {
                                    maxWidth: 500,
                                  },
                                  chartOptions: {
                                    legend: {
                                      layout: "horizontal",
                                      align: "center",
                                      verticalAlign: "bottom",
                                    },
                                  },
                                },
                              ],
                            },
                          }}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="card mb-3 mb-lg-5">
                <div className="card-header">
                  <Filter
                    date={true}
                    dateValue={filterStatus}
                    dateUpdate={setFilterStatus}
                    startDateValue={startdate}
                    startDateUpdate={setstartdate}
                    endDateValue={enddate}
                    endDateUpdate={setenddate}
                    project={true}
                    projectValue={clientId}
                    projectUpdate={setClientId}
                    projectList={getclient}
                    TrackingTool={true}
                    TrackingToolValue={trackingToolId}
                    TrackingToolUpdate={setTrackingToolId}
                    TrackingToolList={TrackingTools}
                    filterRecord={filterRecord}
                    resetRecord={reset}
                  />
                </div>
                <div className="table-responsive datatable-custom">
                  <table
                    id="datatable"
                    className="table table-borderless table-thead-bordered table-nowrap table-align-middle card-table"
                  >
                    <thead className="thead-light">
                      <tr>
                        <th>Date</th>
                        <th>Tracking Tool</th>
                        <th>Client Name</th>

                        <th>Productivity Hours</th>
                        <th>Non Productivity Hours</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {loading ? (
                        <>
                          <tr>
                            <td colSpan={8} style={{ textAlign: "center" }}>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <div
                                  className="spinner-border text-dark m-5"
                                  role="status"
                                >
                                  <span className="sr-only"></span>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </>
                      ) : (
                        <>
                          {" "}
                          {applicants.length === 0 && (
                            <>
                              <tr>
                                <td colSpan={6} style={{ textAlign: "center" }}>
                                  {" "}
                                  <h3>No record found</h3>
                                </td>
                              </tr>
                            </>
                          )}
                          {applicants.map((app, index) => {
                            return (
                              <tr key={"app" + index}>
                                <td style={{ width: "5%" }}>
                                  {moment(app.date).format("DD-MM-YYYY")}
                                </td>
                                <td>
                                  <h5>{app.trackingToolName}</h5>
                                </td>
                                <td>{app.clientName}</td>
                                <td>{app.productivityHours.toFixed(3)}</td>
                                <td>{app.nonProductivityHours.toFixed(3)}</td>
                                <td>{app.status}</td>

                                <></>
                              </tr>
                            );
                          })}
                        </>
                      )}
                    </tbody>
                  </table>
                </div>

                <div className="card-footer">
                  <Pagination
                    handlePageChange={handlePageChange}
                    controller={controller}
                    count={count}
                    handleChangeRowsPerPage1={handleChangeRowsPerPage1}
                  />
                </div>
              </div>
            </>
          </div>
          <div className="footer"></div>
        </main>
      )}
    </>
  );
}

export default HeaderNavbarHOC(TrackingTools);
