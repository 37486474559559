export const reminder={
    ADD_REMINDER_INFO : 'Add reminder information',
    DATE_AND_TIME : 'Date & Time',
    SAVE_CHANGES : 'Save changes',
    REMINDER_NAME : 'Reminder Name',
    EMPLOYEE_NAME : 'Employee Name',
    DESCRIPTION : 'Description',
    STATUS : 'Status',
    CLOSE : 'Close',
    REMINDER_SHEET : 'Reminder Sheet',
    NAME : 'Name',
    SELECT_EMPLOYEE : 'Select Employee',
    FILTER : 'Filter',
    VIEW_REMINDER : 'View Reminder',
    HYY : 'hyy',
    ACTION : 'Action',
    NO_RECORD_FOUND : 'No record found',
}
