import React from "react";

function BackgroundImage() {
  return (
    <div
      className="position-fixed top-0 end-0 start-0 bg-img-start"
      style={{
        height: "32rem",
        backgroundImage: "url(https://admin.3elixir.com/assets/svg/components/card-6.svg)",
      }}
    >
      <div className="shape shape-bottom zi-1">
        <svg
          preserveAspectRatio="none"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 1921 273"
        >
          <polygon fill="#fff" points="0,273 1921,273 1921,0 " />
        </svg>
      </div>
    </div>
  );
}

export default BackgroundImage;
