import React from "react";
import { productivity } from "../../constant/Productivity";

function AddNotes({
  fullReport,
  adminData,
  setaddnote,
  addnote,
  isDisable,
  Addnotes,
  updateId,
}) {
  return (
    <div
      className="modal fade"
      id="status"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h2 className="modal-title" id="exampleModalLongTitle">
              {productivity.ADD_NOTES}
            </h2>

            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <div className="modal-content">
              <table>
                <tbody>
                  {fullReport.map((item, index) => {
                    return (
                      <>
                        <tr>
                          <td>
                            <h4>{productivity.ADMIN_NAME}</h4>
                            {adminData.adminData.empid &&
                              adminData.adminData.empid.employeeName}
                          </td>
                        </tr>
                        <br></br>
                        <tr>
                          <td>
                            <h4>{productivity.EMPLOYEE_NAME}</h4>
                            {item.empid.employeeName}
                          </td>
                        </tr>
                        <br></br>
                        <tr>
                          <td>
                            <h4>{productivity.NOTES}</h4>
                            <textarea
                              className="form-control"
                              placeholder="Notes"
                              id="validationValidTextarea1"
                              rows="2"
                              name="note"
                              onChange={(event) =>
                                setaddnote(event.target.value)
                              }
                              value={addnote}
                            ></textarea>
                          </td>
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-white"
              data-bs-dismiss="modal"
            >
              {productivity.CLOSE}
            </button>
            <button
              disabled={isDisable === true}
              type="button"
              className="btn btn-primary"
              data-bs-dismiss="modal"
              onClick={() => {
                Addnotes(updateId);
              }}
            >
              {productivity.SAVE_CHANGES}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddNotes;
