import React from "react";
import moment from "moment";
import { productivity } from "../../constant/Productivity";

function View({ fullReport }) {
  return (
    <div
      className="modal fade"
      id="viewpage"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title" id="exampleModalLongTitle">
              {productivity.VIEW_PRODUCTIVITY}
            </h1>

            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            {fullReport.map((item, index) => {
              return (
                <div key={index}>
                  <h3 style={{ color: "red" }}>
                    {productivity.PRODUCTIVITY_INFO}
                  </h3>
                  <table className="table table-align-middle">
                    <thead>
                      <tr>
                        <th scope="col"></th>
                        <th scope="col"></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <h4>{productivity.DATE}</h4>
                          {moment(item.date).format("DD-MM-YYYY")}
                        </td>
                        <td>
                          <h4>{productivity.NAME}</h4>

                          {item.empid && item.empid.employeeName}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <h4>{productivity.CLIENT_NAME}</h4>
                          {item.clientId.clientName}
                        </td>
                        <td>
                          <h4>{productivity.TRAKING_TOOLS}</h4>
                          {item.trackingToolId.trackingToolName}
                        </td>
                      </tr>
                      <tr>
                        {item.typeOfHours === true ? (
                          <td>
                            <h4>{productivity.TYPE_OF_HOURS}</h4>
                            {productivity.PRODUCTIVITY_HOURS}
                          </td>
                        ) : (
                          <td>
                            <h4>{productivity.TYPE_OF_HOURS}</h4>
                            {productivity.NON_PRODUCTIVITY_HOURS}
                          </td>
                        )}
                        <td>
                          <h4>{productivity.STATUS}</h4>
                          {item.status}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <h4>{productivity.ESTIMATED_HOURS}</h4>
                          {item.estimatedHours}
                        </td>
                        <td>
                          <h4>{productivity.ACTUAL_HOURS}</h4>
                          {item.actualHours}
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={2}>
                          <h4>{productivity.TASK}</h4>
                          {item.TaskId && item.TaskId.summary}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <h4>{productivity.NOTES}</h4>
                          {item.note}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              );
            })}
          </div>
          <div className="modal-footer"></div>
        </div>
      </div>
    </div>
  );
}

export default View;
