export const employee = {
    EMPLOYEE_PERSONAL_INFO: 'Employee Personal Information',
    EMPLOYEE_CONTACT : 'Employee Contact:',
    EMERGENCY_CONTACT : 'Emergency Contact:',
    DATE_OF_BIRTH : 'Date of Birth:',
    BANK_DETAILS : 'Bank Details:',
    EMPLOYEE_DOCUMENT : 'Employee Document:',
    SELECT_DOCUMENT : 'Select document',
    CENCEL : 'X',
    COMPANY_INFO : 'Company Information',
    DATE_OF_JOINING : 'Date of joining',
    DATE_OF_LEAVING : 'Date of leaving',
    OFFERED_CTC : 'Offer CTC',
    PER_MONTH : 'Per month',
    CONTRACT_PERIOD : 'Contract Period',
    MONTH : 'Month',
    UPLOAD : 'Upload',
    EMPLOYEES : 'Employees',
    ID : 'Id',
    EMPLOYEE_NAME : 'Employee Name',
    CURRENT_STATUS : 'Current Status',
    EMERGENCY_NAME : 'Emergency Name',
    EMERGENCY_NUMBER : 'Emergency Number',
    STATUS : 'Status',
    ACTION : 'Action',
    NO_RECORD_FOUND : 'No record found',
    DELETE : 'Delete',
    ALERT_MSG : '“Are you sure, you want to delete the record?”',
    NO : 'No',
    YES : 'Yes',
    VIEW_EMPLOYEE_DETAILS : 'View the details',
    PERSONAL_INFO: 'Personal information',
    NAME : 'Name',
    EMAIL : 'Email',
    CONTACT_NUMBER : 'Contact Number',
    EMERGENCY_CONTACT_NUMBER : 'Emergancy Contact Number',
    EMERGENCY_CONTACT_NAME : 'Emergency Contact Name',
    QUALIFICATION : 'Qualification',
    ADDRESS : 'Address',
    BANK_NAME : 'Bank Name',
    BANK_ACCOUNT_NUMBER : 'Bank Account Number',
    BANK_IFSC_CODE : 'Bank IFSC Code',
    DOCUMENT_NAME : 'Document Name',
    DOCUMENT_PATH : 'Document Path',
    EMPLOYEE_ROLE : 'Employee Role/Job Position',
    DATE_OF_EXIT : 'Date of exit',
    DURATION : 'Duration',
    EDIT_EMPLOYEE_DETAILS : 'Edit Employee Details',
    CLOSE : 'Close',
    SAVE_CHANGES : 'Save Changes',
}