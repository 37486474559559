import React from "react";
import moment from "moment";
import PropTypes from "prop-types";
import { Autocomplete, TextField } from "@mui/material";
import Datepicker from "../../Datepicker";
import Config from "../../config";
import { client } from "../../constant/Client";

function Edit({
  fullReport,
  setclientName,
  clientName,
  setdate,
  date,
  setstatus,
  status,
  isDisable,
  changeStatus,
  updateId,
}) {
  return (
    <div
      className={"modal fade EditClient"}
      id="exampleModalLong"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h2 className="modal-title" id="exampleModalLongTitle">
              {client.EDIT_CLIENT}
            </h2>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <div className="modal-content">
              <table>
                <tbody>
                  {fullReport.map((item, index) => {
                    return (
                      <>
                        <tr>
                          <td>
                            <h4>{client.CLIENT_NAME}</h4>{" "}
                            <input
                              type="text"
                              className="form-control"
                              name="acthours"
                              id="firstNameLabel"
                              placeholder="acthours"
                              onChange={(event) =>
                                setclientName(event.target.value)
                              }
                              value={clientName}
                            />
                          </td>
                        </tr>
                        <br></br>
                        <tr>
                          <td>
                            <h4>{client.DATE}</h4>{" "}
                            <Datepicker
                              onChange={(newDate) => {
                                setdate(newDate);
                              }}
                              value={moment(date).format("YYYY-MM-DD")}
                            />
                          </td>
                        </tr>
                        <br></br>
                        <tr>
                          <td>
                            <h4>{client.STATUS}</h4>{" "}
                            <Autocomplete
                              options={Config.CLIENT_STATUS}
                              getOptionLabel={(option) => option.name}
                              value={
                                Config.CLIENT_STATUS.find(
                                  (option) => option.value === status
                                ) || { name: "Select status" }
                              }
                              size="small"
                              renderInput={(params) => (
                                <TextField {...params} />
                              )}
                              onChange={(event, newValue) => {
                                setstatus(
                                  newValue === null
                                    ? "Select status"
                                    : newValue.value
                                );
                              }}
                            />
                          </td>
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-white"
              data-bs-dismiss="modal"
            >
              {client.CLOSE}
            </button>
            <button
              disabled={isDisable === true}
              type="button"
              className="btn btn-primary"
              data-bs-dismiss="modal"
              onClick={() => {
                changeStatus(updateId);
              }}
            >
              {client.SAVE_CHANGES}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Edit;

Edit.propTypes = {
  fullReport: PropTypes.array.isRequired,
  setclientName: PropTypes.func.isRequired,
  clientName: PropTypes.string.isRequired,
  setdate: PropTypes.func.isRequired,
  date: PropTypes.object.isRequired,
  setstatus: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired,
  isDisable: PropTypes.bool.isRequired,
  changeStatus: PropTypes.func.isRequired,
  updateId: PropTypes.number.isRequired,
};
