import React, { useState, useEffect, useContext, useCallback } from "react";
import FullCalendar from "@fullcalendar/react"; // must go before plugins
import dayGridPlugin from "@fullcalendar/daygrid";
import HeaderNavbarHOC from "../../HOC/HeaderNavbar";
import Config from "../../config";
import { Filter } from "../../components/Common";
import Axios from "axios";
import { AuthContext } from "../../Login/Protected";
import * as AxiosActions from "../../Actions/axiosActions";

function Timesheet() {
  const adminData = useContext(AuthContext);
  const [clientId, setClientId] = useState("");
  const [getclient, setGetclient] = useState([]);
  const [gettask, setGettask] = useState([]);
  const [taskId, setTaskId] = useState("");
  const [getdata, setGetdata] = useState([]);
  const [empid, setEmpid] = useState(adminData?.adminData?.empid?._id);
  const [data, setData] = useState([]);
  const [hourData, setHourData] = useState(0);
  const [filterStatus, setFilterStatus] = useState("all");

  const currentDate = new Date();
  const nowMonth = currentDate.getMonth().toString();
  const nowYear = currentDate.getFullYear().toString();
  const [currentMonth, setCurrentMonth] = useState(Number(nowMonth));
  const [currentYear, setCurrentYear] = useState(nowYear);

  const getEmployeeList = () => {
    AxiosActions.EmployeeList({})
      .then((response) => {
        if (response.status === 200) {
          setGetdata(response.data.data);
        }
      })
      .catch((error) => {
        console.log("Error in get employee list", error);
      });
  };

  const getClient = () => {
    Axios.get(Config.LOCALURL + "client/list")
      .then((response) => {
        if (response.status === 200) {
          setGetclient(response.data.data);
        }
      })
      .catch((error) => {
        console.log("Error in get Client", error);
      });
  };

  const getTask = useCallback(() => {
    Axios.post(Config.LOCALURL + "tasks/list/client-wise", { clientId: clientId })
      .then((response) => {
        if (response.status === 200) {
          setGettask(response.data.data);
        }
      })
      .catch((error) => {
        console.log("Error in get Client", error);
      });
  }, [clientId]);

  const renderHourDetails = useCallback(async () => {
    AxiosActions.TrackingHourDetails({
      empid: adminData.adminData.isAdmin
        ? empid
        : adminData.adminData.empid._id,
      year: currentYear,
      month: currentMonth,
      task: taskId,
      project: clientId || "",
    })
      .then((response) => {
        if (response.data.code === 200) {
          setHourData(response.data.data);
        }
      })
      .catch((error) => {
        setHourData(0);
        console.log("error occure in tracking details ", error);
      });
  }, [clientId, taskId, adminData.adminData.isAdmin, adminData.adminData.empid._id, empid, currentMonth, currentYear]);

  const renderdata = useCallback(async () => {
    AxiosActions.TrackingDetails({
      type: filterStatus,
      empid: adminData.adminData.isAdmin
        ? empid
        : adminData.adminData.empid._id,
      year: currentYear,
      month: currentMonth,
      task: taskId,
      project: clientId || "",
    })
      .then((response) => {
        if (response.data.code === 200) {
          setData(response.data.data);
        }
      })
      .catch((error) => {
        console.log("error occure in tracking details ", error);
      });
  }, [clientId, taskId, adminData.adminData.isAdmin, adminData.adminData.empid._id, empid, currentMonth, filterStatus, currentYear]);

  const filterRecord = async () => {
    renderdata();
    renderHourDetails();
  };

  const reset = () => {
    setFilterStatus("all");
    setTaskId('');
    setClientId('');
    setEmpid(adminData?.adminData?.empid?._id);
  };

  useEffect(() => {
    getEmployeeList();
    getClient();
    if(clientId) {
      getTask();
    }
    renderdata();
    renderHourDetails();
  }, [adminData.adminData, clientId, renderdata, renderHourDetails, getTask]);

  // Function to convert milliseconds to time
  const convertMillisecondsToTime = (milliseconds) => {
    const totalMinutes = Math.floor(milliseconds / (1000 * 60));
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;

    return hours || minutes ? `${hours} h ${minutes} m` : 0;
  };

  const formattedEvents = data?.map((event) => {
    let title;
  
    if (event.totalHours === "Weekend") {
      title = "Holiday";
    } else if (event.totalHours === 0 || event.totalHours === null) {
      title = "Leave";
    } else {
      title = convertMillisecondsToTime(event.totalHours.toFixed(2));
    }
    return {
      title,
      start: event.date,
      end: event.date,
    };
  });

  const handleEventContent = (e) => {
    const title = e.event.title;
    const color =
      (parseFloat(title) >= 9 && !isNaN(parseFloat(title))) || title === "Leave"
        ? "green"
        : "red";

    return {
      html: `<div className='attendance-hour' style="color: ${color}; background-color: white; font-size: 16px; padding: 5px; text-align:center;">${title}</div>`,
    };
  };

  const handleDatesSet = async (arg) => {
    const start = arg.view.currentStart;
    const month = start.getMonth();
    const year = start.getFullYear();

    if (month !== currentMonth || year !== currentYear) {
      setCurrentMonth(month);
      setCurrentYear(year);
      renderdata(month, year);
      renderHourDetails(month, year);
    }
  };

  return (
    <main id="content" role="main" className="main">
      <div className="content container-fluid">
        <div className="card">
          <div className="card-body ">
            <h1 className="card-title" style={{ marginLeft: 20 }}>
              Monthly Timesheet
            </h1>
          </div>
          <div
            className="row justify-content-between align-items-center flex-grow-5 mb-4"
            style={{ marginLeft: 30 }}
          >
            <Filter
              task={true}
              taskValue={taskId}
              taskUpdate={setTaskId}
              taskList={gettask}
              project={true}
              projectValue={clientId}
              projectUpdate={setClientId}
              projectList={getclient}
              name={true}
              nameValue={empid}
              nameUpdate={setEmpid}
              employeeList={getdata}
              filterRecord={filterRecord}
              resetRecord={reset}
            />
          </div>
          <div className="card" style={{ padding: 25 }}>
            <div className="card-body p-0">
              <div className="d-flex justify-content-start align-items-center mb-3">
                <div className="d-flex" style={{ background: '#ECF0FC', padding: '10px 20px', borderRadius: '6px' }}>
                  <p className="m-0 fw-bold">Total Hour</p>
                  <span className="ms-3 fw-semibold text-black">{convertMillisecondsToTime(hourData)}</span>
                </div>
              </div>
              <FullCalendar
                datesSet={(arg) => handleDatesSet(arg)}
                eventChange={(arg) => console.log("Arg", arg)}
                eventAdd={(arg) => console.log("Arg", arg)}
                navLinkDayClick={(arg) => console.log("Arg", arg)}
                navLinks={(arg) => console.log("Arg", arg)}
                moreLinkClick={(arg) => console.log("Arg", arg)}
                plugins={[dayGridPlugin]}
                eventContent={handleEventContent}
                initialView="dayGridMonth"
                events={formattedEvents}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="footer"></div>
    </main>
  );
}

export default HeaderNavbarHOC(Timesheet);
