export const productivity = {
    PRODUCTIVITY_INFO : 'Productivity information',
    EMPLOYEE_NAME : 'Employee Name',
    SELECT_EMPLOYEE_NAME : 'Select Employee Name',
    DATE : 'Date',
    CLIENT_NAME : 'Project Name',
    SELECT_CLIENT_NAME : 'Select project',
    TASK : 'Task',
    SELECT_TASK : 'Select Task',
    TRAKING_TOOLS : 'Tracking Tools',
    SELECT_TRAKING_TOOLS : 'Select Tracking Tools',
    HOURS_TYPE : 'Hours Type',
    SELECT_HOURS_TYPE : 'Select Hours Type',
    ESTIMATED_HOURS : 'Estimated Hours',
    ACTUAL_HOURS : 'Actual Hours',
    STATUS : 'Status',
    SELECT_STATUS : 'Select Status',
    NOTES : 'Notes',
    VIEW_PRODUCTIVITY : 'View Productivity',
    NAME : 'Name',
    TYPE_OF_HOURS : 'Types Of Hours',
    PRODUCTIVITY_HOURS : 'Productivity Hours',
    NON_PRODUCTIVITY_HOURS : 'Non Productivity Hours',
    ADD_NOTES : 'Add Notes',
    ADMIN_NAME : 'Admin Name',
    EDIT_PRODUCTIVITY : 'Edit Productivity',
    NO_DATA_FOUND : 'No Data Found',
    NOTES_STATUS : 'Notes Status',
    NO_RECORD_FOUND : 'No Record Found',
    UPDATED_BY : 'Updated By -',
    
    //ACTION
    ACTION : 'Action',
    DELETE : 'Delete',
    SAVE_CHANGES : 'Save Changes',
    CLOSE : 'Close',
    
    //ALERT MSG
    ALERT_MSG : '"Are you sure you want to delete this record?"',
    NO : 'No',
    YES : 'Yes',
}